import axios from "axios";
import { retrieveToken } from "./utils/localStorage";
import { toastMessage } from "../src/components/ToastMessage";
import { internalServerError } from "./constants";

axios.defaults.headers.post["Content-Type"] = "application/json";

const instance = axios.create({
  baseURL: `${window.location.protocol}//`,
});

instance.all = axios.all;
instance.spread = axios.spread;

instance.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${retrieveToken()}`;
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return new Promise((resolve, reject) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/login";
      } else if (error.response?.data?.message) {
        return reject(error);
      } else {
        toastMessage("error", internalServerError);
        return reject(error);
      }
    });
  }
);

export default instance;
