import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SettingsProfileInfo from "./SettingsProfileInfo";
import Subscriptions from "./Subscriptions";
import PaymentHistory from "./PaymentHistory";
import AdminManagement from "./AdminManagement";
import LoadingState from "../../common/component/loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { getPaymentHistoryService } from "../../services";
import { getBusinessAdminsService } from "../../services";
import "./settings.css";

const Settings = (props) => {
  const types = [
    "Profile Info",
    "Subscription",
    "Payment History",
    "Admin Management",
  ];
  const [active, setActive] = useState(types[0]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [stopPagination, setStopPagination] = useState(false);
  const [page, setPage] = useState(0);
  const [adminList, setAdminList] = useState([]);
  const [updater, setUpdater] = useState(true);

  useEffect(() => {
    (async function () {
      const id =
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id;
      const data = await getBusinessAdminsService(id);
      setAdminList(data.data);
    })();
  }, [updater]);

  useEffect(() => {
    props.toggleMainLoader(true);
    props.getUserDetails("checkStatus", props.user.accountId);
  }, []);
  useEffect(() => {
    if (!stopPagination) {
      getData();
    }
  }, []);
  const getData = (currentPage = page) => {
    const requestData = {
      accountId: props.user.accountId,
      subscriptionId: props.user.subscription.id,
      limit: 10,
      afterId:
        paymentHistory.length === 0
          ? null
          : paymentHistory[paymentHistory.length - 1].id,
    };

    props.toggleSecondLoader(true);
    getPaymentHistoryService(requestData)
      .then((res) => {
        if (res.data.length > 0) {
          if (currentPage === 0) {
            setPaymentHistory(res.data);
          } else {
            setPaymentHistory((prevState) => [...prevState, ...res.data]);
          }
          setPage((prevState) => prevState + 1);
        }
        if (res.data.length < 10) {
          setStopPagination(true);
        }

        props.toggleSecondLoader(false);
      })
      .catch((err) => {
        props.toggleSecondLoader(false);
      });
  };
  return (
    <div className="add-info-main-div">
      <div>
        <div className="add-info-heading">
          {active === "Profile Info"
            ? "Edit my account info"
            : active === "Subscription"
            ? "Your Subscription"
            : active === "Payment History"
            ? "Your Payment History"
            : "Manage your Admins"}
        </div>
        <div style={{ paddingLeft: "4%" }}>
          <div style={{ display: "inline-flex", marginTop: "11px" }}>
            {types.map((type) => (
              <div
                className={
                  active === type ? "courses-tab active-tab" : "courses-tab"
                }
                onClick={() => setActive(type)}
              >
                {type}
              </div>
            ))}
          </div>
          {props.loader.mainLoader || props.loader.secondLoader ? (
            <div className="main-loader-center">
              <LoadingState color="#2963ca" />
            </div>
          ) : active === "Profile Info" ? (
            <SettingsProfileInfo {...props} />
          ) : active === "Subscription" ? (
            <Subscriptions {...props} />
          ) : active === "Payment History" ? (
            <InfiniteScroll
              dataLength={paymentHistory?.length}
              next={getData}
              hasMore={!stopPagination}
              height={600}
              scrollThreshold={1}
              loader={
                <div className="table-loader-center">
                  <LoadingState color="#2963ca" />
                </div>
              }
              scrollableTarget={"list"}
              className="recco-table-scroll"
            >
              <PaymentHistory paymentHistory={paymentHistory} />
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={paymentHistory?.length}
              next={getData}
              hasMore={!stopPagination}
              height={600}
              scrollThreshold={1}
              loader={
                <div className="table-loader-center">
                  <LoadingState color="#2963ca" />
                </div>
              }
              scrollableTarget={"list"}
              className="recco-table-scroll"
            >
              <AdminManagement
                adminList={adminList}
                props={props}
                setUpdater={setUpdater}
                updater={updater}
              />
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    plans: state.subscription,
    loader: state.loader,
    paymentHistory: state.paymentHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutSuccess: ActionCreators.logoutSuccess,
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getSubscriptionPlans: ActionCreators.getSubscriptionPlans,
      getUserDetails: ActionCreators.getUserDetails,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      getPaymentHistory: ActionCreators.getPaymentHistory,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
