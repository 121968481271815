import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import S3 from "react-aws-s3";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  IMG,
  ROUTES_PATH,
  invalidImgType,
  uploadFailed,
  internalServerError,
  getReccoBreadcrumbsDetails,
} from "../../constants";
import { toastMessage } from "../../components/ToastMessage";
import ButtonItem from "../../common/component/button";
import "./addInfo.css";
import ImageTemplatesPopup from "../../components/ImageTemplatesPopup";
import InfoMessages from "../../components/InfoMessages";
import BottomButtons from "../../components/BottomButtons";
import PhonePreview from "../../common/component/phonePreview";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import axios from "../../axios";
import { saveAddInfoUrl } from "../../utils/urls";
import LoadingState from "../../common/component/loader";
import ScheduleReminder from "../../components/ScheduleReminder";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import {
  launchReccoService,
  updateReccoListDetailsService,
} from "../../services";

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  dirName: "ReccoImages" /* optional */,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_REGION_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRETE_KEY,
  s3Url: process.env.REACT_APP_S3_URL,
};

const S3Client = new S3(config);

const AddInfo = (props) => {
  const uploadImgRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [fileLoader, setFileLoader] = useState(false);
  const [fileObject, setFileObject] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isTemplateModalOpen, setTemplateModal] = useState(false);
  const [htmlText, setHtmlText] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [uploadedImg, setUploadedImg] = useState(null);
  const [imgType, setImgType] = useState("");
  const [textTemplate, setTextTemplate] = useState("");
  const [actionType, setActionType] = useState("Save");
  const [textTemplateId, setTextTemplateId] = useState("");
  const [imgTemplateId, setImgTemplateId] = useState("");
  const [selectedText, setSelectedText] = useState(null);
  const [isScheduleModalOpen, setScheduleModal] = useState(false);
  const [removePerkModal, setRemovePerkModal] = useState(false);
  const [perkLoader, setPerkLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [entity, setEntity] = useState("");

  useEffect(() => {
    const requestData = {
      type: "image",
      skip: 0,
      limit: 20,
    };
    props.getTemplates(requestData, props.imgTemplates);
    const reccoListId = sessionStorage.getItem("recco-list");
    props.toggleSecondLoader(true);
    props.getTemplatesInfo(reccoListId);
    const data = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0]
          .businessForAccount.id,
      reccoListId: reccoListId,
    };
    props.toggleMainLoader(true);
    props.getReccoListDetails(data);
    const entitiesData = {
      accountId: props.user.account.id,
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0]
          .businessForAccount.id,
    };
    props.getEntityList(entitiesData);
  }, []);

  useEffect(() => {
    if (props.templatesInfo) {
      setHtmlText(
        props.templatesInfo?.text.length > 0
          ? props.templatesInfo?.text[0]?.content
          : ""
      );
      setTextTemplate(
        props.templatesInfo?.text.length > 0
          ? props.templatesInfo?.text[0]?.content
          : ""
      );
      setTextTemplateId(
        props.templatesInfo?.text.length > 0
          ? props.templatesInfo?.text[0]?.id
          : ""
      );
      setImgTemplateId(
        props.templatesInfo?.image.length > 0
          ? props.templatesInfo?.image[0]?.id
          : ""
      );
      setFileObject(
        props.templatesInfo?.image.length > 0
          ? props.templatesInfo?.image[0]?.content
          : ""
      );
      setSelectedImg(
        props.templatesInfo?.image.length > 0
          ? props.templatesInfo?.image[0]
          : null
      );
      setSelectedText(
        props.templatesInfo?.text.length > 0
          ? props.templatesInfo?.text[0]
          : null
      );

      setIsFileUploaded(props.templatesInfo?.image[0]?.content ? true : false);
      setActionType(
        props.templatesInfo.text.length !== 0 &&
          props.templatesInfo.image.length !== 0
          ? "Update"
          : "Save"
      );
    }
  }, [props.templatesInfo]);

  useEffect(() => {
    if (props.reccoListDetails.entityPage) {
      setEntity(props.reccoListDetails.entityPage);
    }
  }, [props.reccoListDetails.entityPage]);

  const handleRemovePerkModal = () => {
    setRemovePerkModal(!removePerkModal);
  };
  const handleTemplateModalToggle = () => {
    setTemplateModal(!isTemplateModalOpen);
  };
  const handleScheduleModalToggle = () => {
    setScheduleModal(!isScheduleModalOpen);
  };
  const handleUploadImgBtn = () => {
    uploadImgRef.current.click();
  };
  const getSelectedText = (text) => {
    setTextTemplate(text.content);
  };
  const handleSelectedText = (text) => {
    setSelectedText(text);
  };
  const handleImageDataChange = (event) => {
    setIsFileUploaded(true);
    const file = event.target.files[0];

    if (file !== undefined) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        setUploadedImg(file);
        setImgType("upload");
        // event.target.value = null;
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
          setFileObject([reader.result]);
        };
      } else {
        toastMessage("error", invalidImgType);
      }
    }
  };
  const uploadToS3Bucket = (type) => {
    type === "launch" ? setFileLoader(true) : setSaveLoader(true);
    if (uploadedImg !== undefined) {
      const fileName = uploadedImg.name?.split(".").slice(0, -1).join(".");
      var d = new Date();
      const newName =
        d.getDate().toString() +
        "_" +
        d.getTime().toString() +
        "_" +
        fileName.replaceAll(" ", "_");

      S3Client.uploadFile(uploadedImg, newName)
        .then((data) => {
          handleSelectedImg(data.location, "upload");
          if (type === "saveForLatter") {
            handleUpdateReccoList(data.location, type);
          } else {
            handleAPICall(data.location);
          }
        })
        .catch((err) => {
          setFileLoader(false);
          setSaveLoader(false);
          toastMessage("error", uploadFailed);
        });
    } else {
      setFileLoader(false);
      setSaveLoader(false);
    }
  };
  const handleRemoveImage = () => {
    setIsFileUploaded(false);
    setFileObject("");
    handleSelectedImg(null, "");
  };
  const handleSelectTemplate = (selectedImg) => {
    setIsFileUploaded(true);
    setFileObject(selectedImg.content);
    setTemplateModal(!isTemplateModalOpen);
  };
  const handleSelectText = (selectedText) => {
    setHtmlText(selectedText);
  };
  const handleSelectedImg = (img) => {
    setSelectedImg(img);
    setImgType("template");
  };

  const handleBusinessEntity = ({ target: input }) => {
    setEntity(input.value);
    const data = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0]
          .businessForAccount.id,
      reccoListId: sessionStorage.getItem("recco-list"),
    };
    const payload = {
      entityPageId: input.value,
    };
    updateReccoListDetailsService(data, payload)
      .then((response) => {
        if (response.status === 201) {
          setFileLoader(false);
          toastMessage("success", "Updated entity successfully");
        }
      })
      .catch((error) => {
        setFileLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleLaunchRecco = () => {
    setFileLoader(true);
    const data = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0]
          .businessForAccount.id,
      reccoListId: sessionStorage.getItem("recco-list"),
    };
    launchReccoService(data)
      .then((response) => {
        if (response.status === 201) {
          setFileLoader(false);
          toastMessage("success", "Recco launched successfully");
          navigate(ROUTES_PATH.GET_RECCO);
        }
      })
      .catch((error) => {
        setFileLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const handleSaveBtn = () => {
    if (
      (htmlText === null ||
        htmlText === "" ||
        htmlText === undefined ||
        htmlText === "<p><br></p>") &&
      (fileObject === null || fileObject === undefined || fileObject === "")
    ) {
      toastMessage("error", "Please add image and message to send recco.");
    } else if (
      fileObject === null ||
      fileObject === undefined ||
      fileObject === ""
    ) {
      toastMessage("error", "Please add image to send recco.");
    } else if (entity === null || entity === undefined || entity === "") {
      toastMessage("error", "Please select a product to send recco.");
    } else if (
      htmlText === null ||
      htmlText === "" ||
      htmlText === undefined ||
      htmlText === "<p><br></p>"
    ) {
      toastMessage("error", "Please add message to send recco.");
    } else {
      if (imgType === "upload") {
        uploadToS3Bucket("launch");
      } else {
        handleAPICall();
      }
    }
  };
  const handleSaveForLatter = () => {
    if (
      props.templatesInfo?.text[0]?.content !== htmlText ||
      props.templatesInfo?.image[0]?.content !== selectedImg?.content ||
      props.templatesInfo?.image[0]?.content !== fileObject
    ) {
      if (imgType === "upload") {
        uploadToS3Bucket("saveForLatter");
      } else {
        handleUpdateReccoList(selectedImg, "saveForLatter");
      }
    } else {
      navigate(ROUTES_PATH.GET_RECCO);
    }
  };
  const handleAPICall = (img) => {
    if ((selectedImg || uploadedImg) && htmlText) {
      setFileLoader(true);
      const reccoListId = sessionStorage.getItem("recco-list");
      const payload = [
        {
          content: imgType === "upload" ? img : selectedImg.content,
          type: "image",
        },
        {
          content: htmlText.replace(/\n/g, ""),
          type: "text",
        },
      ];
      if (actionType === "Save") {
        axios
          .post(saveAddInfoUrl(reccoListId), payload)
          .then((response) => {
            if (response.status === 201) {
              toastMessage("success", "Information saved successfully");
              if (location.pathname === ROUTES_PATH.SEND_RECCO) {
                handleLaunchRecco();
              } else {
                navigate(ROUTES_PATH.ADD_PERK);
              }
            }
          })
          .catch((error) => {
            setFileLoader(false);
            if (
              error.response?.status === 400 ||
              error.response?.status === 404
            ) {
              const errorMessage =
                error.response.data.failures.length > 0
                  ? error.response.data.failures[0]["message"]
                  : error.response.data.message;
              toastMessage("error", errorMessage);
            } else {
              toastMessage("error", internalServerError);
            }
          });
      } else {
        if (
          props.templatesInfo?.text[0]?.content === htmlText &&
          (props.templatesInfo?.image[0]?.content === selectedImg?.content ||
            props.templatesInfo?.image[0]?.content === img)
        ) {
          if (location.pathname === ROUTES_PATH.SEND_RECCO) {
            handleLaunchRecco();
          } else {
            setFileLoader(false);
            navigate(ROUTES_PATH.ADD_PERK);
          }
        } else {
          handleUpdateReccoList(img);
        }
      }
    }
  };
  const handleUpdateReccoList = (img, type) => {
    type === "saveForLatter" ? setSaveLoader(true) : setFileLoader(true);
    const reccoListId = sessionStorage.getItem("recco-list");
    const payload = [
      {
        templateId: imgTemplateId,
        content:
          imgType === "upload"
            ? img
            : selectedImg
            ? selectedImg?.content
            : null,
        type: "image",
      },
      {
        templateId: textTemplateId,
        content: htmlText ? htmlText.replace(/\n/g, "") : null,
        type: "text",
      },
    ];

    axios
      .patch(saveAddInfoUrl(reccoListId), payload)
      .then((response) => {
        if (response.status === 200) {
          toastMessage(
            "success",
            type !== "saveForLatter"
              ? "Information updated successfully"
              : "Information saved successfully"
          );
          if (location.pathname === ROUTES_PATH.SEND_RECCO) {
            type !== "saveForLatter"
              ? handleLaunchRecco()
              : navigate(ROUTES_PATH.GET_RECCO);
          } else {
            setFileLoader(false);
            setSaveLoader(false);
            navigate(ROUTES_PATH.ADD_PERK);
          }
        }
      })
      .catch((error) => {
        setFileLoader(false);
        setSaveLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleReccoListPerk = (perkId) => {
    const data = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0]
          .businessForAccount.id,
      reccoListId: sessionStorage.getItem("recco-list"),
    };
    const payload = {
      perk: props.reccoListDetails?.perk ? null : perkId,
    };
    setPerkLoader(true);
    updateReccoListDetailsService(data, payload)
      .then((response) => {
        if (response.status === 200) {
          setPerkLoader(false);
          if (props.reccoListDetails?.perk) {
            toastMessage(
              "success",
              "Perk removed from the recco list successfully"
            );
            handleRemovePerkModal();
          } else {
            toastMessage(
              "success",
              "Perk applied to the recco list successfully"
            );
          }
          const data = {
            businessId:
              props.user.business[localStorage.getItem("businessIndex") || 0]
                .businessForAccount.id,
            reccoListId: sessionStorage.getItem("recco-list"),
          };
          props.toggleMainLoader(true);
          props.getReccoListDetails(data);
        }
      })
      .catch((error) => {
        setPerkLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  return (
    <div className="add-info-main-div">
      <ImageTemplatesPopup
        openModal={isTemplateModalOpen}
        handleModal={handleTemplateModalToggle}
        handleSelectTemplate={handleSelectTemplate}
        data={props.imgTemplates}
        handleSelectedImg={handleSelectedImg}
        selectedImg={selectedImg}
        {...props}
      />
      <ScheduleReminder
        openModal={isScheduleModalOpen}
        handleModal={handleScheduleModalToggle}
        businessId={
          props.user.business[localStorage.getItem("businessIndex") || 0]
            .businessForAccount.id
        }
      />
      <ConfirmationPopup
        openModal={removePerkModal}
        handleModal={handleRemovePerkModal}
        handleBtnClick={handleReccoListPerk}
        showLoader={perkLoader}
        subText={`Are you sure you want to remove this perk from  ${sessionStorage.getItem(
          "recco-list-title"
        )}?`}
        heading="Remove perk"
        btnValue="Remove"
      />
      {props.loader.secondLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <Breadcrumbs
            breadcrumbsDetails={getReccoBreadcrumbsDetails}
            active={
              location.pathname === ROUTES_PATH.SEND_RECCO
                ? "Send ‘Get Recco’"
                : "Add Info."
            }
            isEnable={
              props.templatesInfo?.text?.length !== 0 &&
              props.templatesInfo?.image?.length !== 0
            }
          />
          <div className="images-div-border">
            <div className="add-info-heading">
              {location.pathname === ROUTES_PATH.SEND_RECCO
                ? "Send ‘Get Recco’"
                : "Add Information : Express your gratitude !"}
              {location.pathname === ROUTES_PATH.SEND_RECCO &&
              props.reccoListDetails.reminderStatus !== "SENT" ? (
                <div style={{ paddingRight: "54px" }}>
                  <ButtonItem
                    className="skip-perk-button"
                    buttonValue={"Schedule Reminder"}
                    handleButtonAction={handleScheduleModalToggle}
                  />
                </div>
              ) : null}
            </div>
            <div className="choose-img-heading">
              {location.pathname === ROUTES_PATH.SEND_RECCO
                ? "Edit image template for this ‘Get Recco’"
                : "Choose image template or Upload image for this ‘Get Recco’"}
            </div>
            <Row style={{ marginRight: "0px" }}>
              {isFileUploaded ? (
                <Col md={12} lg={7} xl={7} className="image-selected-col">
                  <div className="preview-img-div">
                    <img src={fileObject} alt="" className="preview-img-css" />
                  </div>
                  <ButtonItem
                    className="img-remove-button"
                    buttonValue="Remove"
                    handleButtonAction={handleRemoveImage}
                    disabled={
                      props.reccoListDetails.reminderStatus === "SENT"
                        ? true
                        : false
                    }
                  />
                </Col>
              ) : (
                <Col
                  md={12}
                  lg={7}
                  xl={7}
                  className="flex-center"
                  style={{ height: "max-content" }}
                >
                  <img
                    src={IMG.chooseImg}
                    alt=""
                    className="cursor-pointer"
                    onClick={handleTemplateModalToggle}
                  />
                  <div className="or-text-css">or</div>
                  <div>
                    <img
                      src={IMG.uploadImg}
                      alt=""
                      onClick={handleUploadImgBtn}
                      className="cursor-pointer"
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg, image/jpg"
                      multiple={false}
                      onChange={handleImageDataChange}
                      ref={uploadImgRef}
                    />
                  </div>
                  <div className="img-upload-text">
                    Start by uploading an image or by choosing a template for
                    this ‘Get Recco’.Go ahead and Personalize !
                  </div>
                </Col>
              )}
              {location.pathname === ROUTES_PATH.SEND_RECCO &&
                props.reccoListDetails?.perk && (
                  <Col className="perk-div">
                    <div className="send-perk-added-text">Perk Added :</div>
                    {props.reccoListDetails?.perk ? (
                      <div>
                        <div style={{ display: "flex", marginBottom: "9px" }}>
                          <div>
                            <img
                              src={props.reccoListDetails?.perk?.image}
                              alt=""
                              height="90px"
                              width="100px"
                            />
                          </div>
                          <div style={{ marginLeft: "12px" }}>
                            <div className="perk-name-css">
                              {props.reccoListDetails?.perk?.name}
                            </div>
                            <div className="perk-details-name">
                              YOUR PROMO CODE :{" "}
                              <span className="perk-details-value">
                                {props.reccoListDetails?.perk?.promoCode}
                              </span>
                            </div>
                            {props.reccoListDetails?.perk?.productUrl && (
                              <div className="perk-details-name">
                                YOUR PROMO LINK :{" "}
                                <span className="perk-details-value">
                                  {props.reccoListDetails?.perk?.productUrl}
                                </span>
                              </div>
                            )}

                            <div className="perk-details-name">
                              VALIDITY :{" "}
                              <span className="perk-details-value">
                                {moment(
                                  props.reccoListDetails?.perk?.expirationDate
                                ).format("MM-DD-YYYY")}
                              </span>
                            </div>
                            <div className="recco-remove-perk-div">
                              <ButtonItem
                                className="skip-perk-button"
                                buttonValue={
                                  props.reccoListDetails?.perk
                                    ? "Remove Perk"
                                    : "Apply Perk"
                                }
                                handleButtonAction={() => {
                                  props.reccoListDetails?.perk
                                    ? handleRemovePerkModal()
                                    : handleReccoListPerk(
                                        props.reccoListDetails?.perk?.id
                                      );
                                }}
                                showLoader={perkLoader}
                                disabled={
                                  perkLoader ||
                                  props.reccoListDetails.reminderStatus ===
                                    "SENT"
                                }
                                loaderColor="#2963ca"
                                loaderHeight="20px"
                                loaderWidth="20px"
                                type="popup-btn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </Col>
                )}
            </Row>
          </div>
          <Row>
            <Col md={12} lg={12} xl={6}>
              <div className="select-business-entity">
                <div className="select-text">
                  Select the product you want to get Recco for
                </div>
                <Dropdown isOpen={dropdownOpen} toggle={toggle} disabled={
              props.reccoListDetails.reminderStatus === "SENT" ? true : false
            }>
                  <DropdownToggle className="choose-product">
                    {entity ? (
                      <div className="selected-entity">
                        {props.entityList.find((el) => el.id === entity)?.entity
                          .businessName ||
                          props.entityList.find((el) => el.id === entity)?.entity
                            .name}
                      </div>
                    ) : (
                      "Choose Product"
                    )}
                    <KeyboardArrowDownOutlinedIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    {props.entityList.map((v, index) => (
                      <FormGroup check key={index}>
                        <Input
                          id="exampleCheck"
                          name="check"
                          type="radio"
                          onChange={(e) => handleBusinessEntity(e)}
                          value={v.id}
                        />
                        <Label check for="exampleCheck">
                          {v.entity.businessName || v.entity.name}
                        </Label>
                        <CheckCircleOutlineOutlinedIcon className="check-circle" />
                      </FormGroup>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <InfoMessages
                handleSelectText={handleSelectText}
                textTemplate={textTemplate}
                getSelectedText={getSelectedText}
                selectedText={selectedText}
                handleSelectedText={handleSelectedText}
                location={location}
                {...props}
              />
            </Col>
            <Col md={12} lg={12} xl={6} className="template-preview-col">
              <div>
                <div className="phone-preview-heading">Preview</div>
                <PhonePreview
                  type="Phone"
                  htmlText={htmlText}
                  imgPath={fileObject}
                  perkDetails={props.reccoListDetails?.perk}
                  isSendPage={location.pathname === ROUTES_PATH.SEND_RECCO}
                />
              </div>
              {/* <div style={{ marginLeft: "45px" }}>
                <div className="phone-preview-heading">Web Preview</div>
                <PhonePreview
                  type="Web"
                  htmlText={htmlText}
                  imgPath={fileObject}
                  perkDetails={props.reccoListDetails?.perk}
                  isSendPage={location.pathname === ROUTES_PATH.SEND_RECCO}
                />
              </div> */}
            </Col>
          </Row>
          <BottomButtons
            handleBackBtn={() =>
              location.pathname === ROUTES_PATH.SEND_RECCO
                ? props.reccoListDetails.reminderStatus !== "SENT"
                  ? handleSaveForLatter()
                  : navigate(ROUTES_PATH.GET_RECCO)
                : navigate(ROUTES_PATH.RECCO_LIST)
            }
            handleSaveBtn={() => {
              props.reccoListDetails.reminderStatus === "SENT" &&
              location.pathname === ROUTES_PATH.SEND_RECCO
                ? navigate(ROUTES_PATH.GET_RECCO)
                : handleSaveBtn();
            }}
            loader={fileLoader}
            btn1Loader={saveLoader}
            btnName1={
              props.reccoListDetails.reminderStatus === "SENT"
                ? "Back"
                : location.pathname === ROUTES_PATH.SEND_RECCO
                ? "Save For Later"
                : "Back"
            }
            btnName2={
              props.reccoListDetails.reminderStatus === "SENT" &&
              location.pathname === ROUTES_PATH.ADD_INFO
                ? "Continue"
                : props.reccoListDetails.reminderStatus === "SENT" &&
                  location.pathname === ROUTES_PATH.SEND_RECCO
                ? `Back to Get Recco`
                : props.reccoListDetails.reminderStatus !== "SENT" &&
                  location.pathname === ROUTES_PATH.SEND_RECCO
                ? `Save & Launch`
                : `Save and Continue`
            }
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    imgTemplates: state.imgTemplates,
    textTemplates: state.textTemplates,
    templatesInfo: state.templatesInfo,
    reccoListDetails: state.reccoListDetails,
    entityList: state.entityList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      getTemplates: ActionCreators.getTemplates,
      getTemplatesInfo: ActionCreators.getTemplatesInfo,
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getReccoListDetails: ActionCreators.getReccoListDetails,
      getEntityList: ActionCreators.getEntityList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInfo);
