import { ROUTES_PATH } from ".";

export const plansArray = [
  { name: "GET RECCOS", key: "getReccos" },
  { name: "NURTURES", key: "nurtures" },
  { name: "RECCO FAM SIZE", key: "reccoFamSize" },
  { name: "CUSTOM INTEGRATIONS", key: "customIntegration" },
];
export const tipsArray = [
  ["Make it about community, not a sale", "Be authentic"],
  ["Offer meaningful rewards", "Keep it simple"],
];
export const overviewArray = [
  {
    count: "55363",
    title: "No. of Reccos",
    subTitle: " ",
    percentage: "0",
  },
  { count: "20%", title: "Recco Index", subTitle: " ", percentage: "0" },
  { count: "2232", title: "Recco Views", subTitle: " ", percentage: "0" },
  { count: "2232", title: "T.A.M.", subTitle: " ", percentage: "0" },
];

export const getReccoBreadcrumbsDetails = [
  { text: "Add Info.", route: ROUTES_PATH.ADD_INFO },
  { text: "Add Perk.", route: ROUTES_PATH.ADD_PERK },
  {
    text: "Send ‘Get Recco’",
    route: ROUTES_PATH.SEND_RECCO,
  },
];

export const nurtureBreadcrumbsDetails = [
  { text: "Add Info.", route: ROUTES_PATH.NURTURE_ADD_INFO },
  { text: "Add Perk.", route: ROUTES_PATH.NURTURE_ADD_PERK },
  {
    text: "Nurture Preview",
    route: ROUTES_PATH.SEND_NURTURE,
  },
];
export const overviewInfoArray = [
  { name: "No. of Reccos", info: "Total Reccos your business has received." },
  {
    name: "Recco Index",
    info: " Ratio of total customer's from whom you've requested Reccos to the customers who have Recco'd you.",
  },
  { name: "Recco Views", info: "Total views of Reccos of your business." },
  {
    name: "T.A.M.",
    info: "Total number of users who have received a Recco of your business from a friend.",
  },
];
export const insightsInfoArray = [
  {
    name: "Recco fam",
    info: "Number of your customers who have Recco'd you to their Fam (Friends).",
  },
  {
    name: "T.A.M.",
    info: "Total number of users who have received a Recco of your business from a friend.",
  },
  {
    name: "Reccos saved",
    info: "Total users who have saved a Recco of your business.",
  },
  {
    name: "Recco Index",
    info: " Ratio of total customer's from whom you've requested Reccos to the customers who have Recco'd you.",
  },
  {
    name: "‘Get Reccos’ sent",
    info: "Total number of customers you've requested Reccos from.",
  },
  {
    name: "Perks seen",
    info: "Total number of users on the Recco platform who have accessed your Perk, from a Recco made to them.",
  },
  { name: "No. of Reccos", info: "Total number of Reccos you've received." },

  {
    name: "Recco-dittos",
    info: "Total number of dittos (agrees) to Reccos you've received.",
  },
];
