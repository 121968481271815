import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../common/component/searchBar";
import ReccoProgressBar from "../../components/ReccoProgressBar";
import CustomersTable from "./customersTable";
import BottomButtons from "../../components/BottomButtons";
import "./reccoList.css";
import { IMG, ROUTES_PATH } from "../../constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import {
  getReccoListAdvocatesService,
  searchAdvocatesService,
} from "../../services";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingState from "../../common/component/loader";
import { toastMessage } from "../../components/ToastMessage";
import {
  loginEmailNotExist,
  emailPwdInvalid,
  internalServerError,
} from "../../constants";

const ReccoList = (props) => {
  const [advocates, setAdvocates] = useState([]);
  const [searchAdvocates, setSearchAdvocates] = useState([]);
  const [loader, setLoader] = useState(false);

  const [stopPagination, setStopPagination] = useState(false);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  const handleInputChange = (e) => {
    setSearchAdvocates([]);
    setPage(0);
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search.length > 0) {
      getSearchData();
    } else {
      if (!stopPagination) {
        getData();
      }
    }
  }, [search.length]);

  useEffect(() => {
    const data = {
      accountId: props.user.accountId,
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
    };
    props.toggleMainLoader(true);
    props.getRemainingRecco(data);
  }, []);

  const handleSort = (sortBy, orderBy) => {
    getData(0, sortBy, orderBy);
  };

  const getData = (currentPage = page, sortBy, orderBy) => {
    const requestData = {
      reccoListId: sessionStorage.getItem("recco-list"),
      skip: currentPage * 10,
      limit: 10,
      sortBy: sortBy,
      orderBy: orderBy,
    };
    setLoader(true);
    getReccoListAdvocatesService(requestData)
      .then((res) => {
        if (res.data.length > 0) {
          if (currentPage === 0) {
            setAdvocates(res.data);
          } else {
            setAdvocates((prevState) => [...prevState, ...res.data]);
          }
          setPage((prevState) => prevState + 1);
        }
        if (res.data.length < 10) {
          setStopPagination(true);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          if (
            errorMessage ===
            "Reccolist with specified recco list id does not exist."
          ) {
            navigate(ROUTES_PATH.GET_RECCO);
          } else {
            toastMessage("error", errorMessage);
          }
        } else if (error.response?.status === 404) {
          toastMessage("error", loginEmailNotExist);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const resetInitialState = () => {
    setAdvocates([]);
    getData(0);
  };

  const getSearchData = (currentPage = page) => {
    const requestData = {
      reccoListId: sessionStorage.getItem("recco-list"),
      search: search,
      skip: currentPage * 10,
      limit: 10,
    };
    setLoader(true);
    searchAdvocatesService(requestData)
      .then((res) => {
        if (res.data.length > 0) {
          setSearchAdvocates(res.data);
          setPage((prevState) => prevState + 1);
        }
        if (res.data.length < 10) {
          setStopPagination(true);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <div className="recco-list-div">
      {props.loader.mainLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <Row>
            <Col md={{ size: 1 }} lg={{ size: 1 }} xl={{ size: 1 }}>
              <img
                src={IMG.backIcon}
                className="cursor-pointer"
                onClick={() => navigate(-1)}
              />
              {/* <span className="back-text"> Back</span> */}
            </Col>

            <Col
              md={{ size: 4, offset: 7 }}
              lg={{ size: 4, offset: 7 }}
              xl={{ size: 3, offset: 8 }}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div className="progress-bar-text">
                Get Reccos remaining this Month
              </div>
              <ReccoProgressBar remainingReccos={props.remainingRecco} />
            </Col>
          </Row>
          <Row>
            <Col className="recco-list-heading">
              {sessionStorage.getItem("recco-list-title")}
            </Col>
          </Row>
          <Row className="serach-row-margin">
            <Col md={6}>
              <SearchBar
                className="serach-input-field"
                placeholder="Search"
                maxLength="500"
                name="search"
                type="search"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidEmailMsg", "isValidEmail")
                }
              />
            </Col>
            {/* <Col
              md={{ size: 3, offset: 3 }}
              lg={{ size: 3, offset: 3 }}
              xl={{ size: 2, offset: 4 }}
              className="nudge-btn-col"
            >
              <ButtonItem
                className="schedule-nudge-button"
                buttonValue="Schedule Nudge"
                // handleButtonAction={handleSubmit}
              />
            </Col> */}
          </Row>
          <Row>
            <Col>
              {loader ? (
                <div className="main-loader-center">
                  <LoadingState color="#2963ca" />
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={
                    search || searchAdvocates.length > 0
                      ? searchAdvocates?.length
                      : advocates?.length
                  }
                  next={
                    search || searchAdvocates.length > 0
                      ? getSearchData
                      : getData
                  }
                  hasMore={!stopPagination}
                  height={440}
                  scrollThreshold={1}
                  loader={
                    <div className="main-loader-center">
                      <LoadingState color="#2963ca" />
                    </div>
                  }
                  scrollableTarget={"list"}
                >
                  <CustomersTable
                    updatedAdvocates={
                      search || searchAdvocates.length > 0
                        ? searchAdvocates
                        : advocates
                    }
                    search={search}
                    resetState={resetInitialState}
                    handleSort={handleSort}
                    loader={loader}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                  />
                </InfiniteScroll>
              )}
            </Col>
          </Row>
          <BottomButtons
            handleBackBtn={() => navigate(ROUTES_PATH.GET_RECCO)}
            handleSaveBtn={() => navigate(ROUTES_PATH.ADD_INFO)}
            btnName1="Back"
            btnName2="Continue"
          />
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    advocates: state.advocates,
    loader: state.loader,
    user: state.user,
    remainingRecco: state.remainingRecco,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getReccoListAdvocates: ActionCreators.getReccoListAdvocates,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      getRemainingRecco: ActionCreators.getRemainingRecco,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReccoList);
