import { combineReducers } from "redux";
import * as actionTypes from "../actions/actionTypes";
import loader from "./loader";
import user from "./user";
import account from "./account";
import subscription from "./subscription";
import reccoList from "./reccoList";
import reccoFam from "./reccoFam";
import imgTemplates from "./imgTemplates";
import textTemplates from "./textTemplates";
import advocates from "./advocates";
import templatesInfo from "./templatesInfo";
import remainingRecco from "./remainingRecco";
import perkDetails from "./perkDetails";
import reccoListDetails from "./reccoListDetails";
import nurtureDetails from "./nurtureDetails";
import remainingReccoFam from "./remainingReccoFam";
import reccoCloud from "./reccoCloud";
import communityOverview from "./communityOverview";
import insightsCards from "./insightsCards";
import reccoFamGraph from "./reccoFamGraph";
import perkPerformance from "./perkPerformance";
import reccoMedium from "./reccoMedium";
import paymentHistory from "./paymentHistory";
import nurturePerformance from "./nurturePerformance";
import entityList from "./entityList";

const reducer = combineReducers({
  user: user,
  loader: loader,
  account: account,
  entityList: entityList,
  subscription: subscription,
  reccoList: reccoList,
  reccoFam: reccoFam,
  imgTemplates: imgTemplates,
  textTemplates: textTemplates,
  advocates: advocates,
  templatesInfo: templatesInfo,
  remainingRecco: remainingRecco,
  perkDetails: perkDetails,
  reccoListDetails: reccoListDetails,
  nurtureDetails: nurtureDetails,
  remainingReccoFam: remainingReccoFam,
  reccoCloud: reccoCloud,
  communityOverview: communityOverview,
  insightsCards: insightsCards,
  reccoFamGraph: reccoFamGraph,
  perkPerformance: perkPerformance,
  reccoMedium: reccoMedium,
  paymentHistory: paymentHistory,
  nurturePerformance: nurturePerformance,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = undefined;
    localStorage.clear();
  }
  return reducer(state, action);
};

export default rootReducer;
