import { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ButtonItem from "../../common/component/button";
import InputField from "../../common/component/inputField";
import PhoneInputField from "../../common/component/phoneInput";
import {
  validateEmail,
  validateName,
  validateContact,
} from "../../utils/validation";
import {
  requiredFieldMessage,
  inputFieldValidaionMessage,
  internalServerError,
} from "../../constants";
import { toastMessage } from "../ToastMessage";
import axios from "../../axios";
import {
  addIndividualAdvocatesUrl,
  createReccoListUrl,
} from "../../utils/urls";

const AddIndividualCustomer = ({
  businessId,
  handleModal,
  toggleMainLoader,
  type,
  resetState,
  childFunc,
  remainingReccos,
  getRemainingRecco,
  accountId,
  toggleSecondLoader,
  getReccoFam,
  handleUpgradePlanModal,
}) => {
  const [customerDetails, setCustomerDetails] = useState({
    email: "",
    phoneNumber: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    isValidEmail: false,
    invalidEmailMsg: "",
    isValidPhoneNumber: false,
    invalidPhoneNumberMsg: "",
    isValidFirstName: false,
    invalidFirstNameMsg: "",
    isValidLastName: false,
    invalidLastNameMsg: "",
  });
  const [customerDetailsArr, setCustomerDetailsArr] = useState([]);
  const [loader, setLoader] = useState(false);
  const [country, setCountry] = useState("us");

  useEffect(() => {
    childFunc.current = handleCloseModal;
  }, [customerDetailsArr]);

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const CustomerDetails = { ...customerDetails };
    CustomerDetails[input.name] = input.value;
    CustomerDetails[msgType] = "";
    CustomerDetails[validationType] = false;
    if (input.name === "email") {
      CustomerDetails["invalidPhoneNumberMsg"] = "";
      CustomerDetails["isValidPhoneNumber"] = false;
    }
    setCustomerDetails(CustomerDetails);
  };
  const handlePhoneInput = (value, data) => {
    const CustomerDetails = { ...customerDetails };
    CustomerDetails["phoneNumber"] = value;
    CustomerDetails["countryCode"] = data.dialCode;
    CustomerDetails["invalidPhoneNumberMsg"] = "";
    CustomerDetails["isValidPhoneNumber"] = false;
    CustomerDetails["invalidEmailMsg"] = "";
    CustomerDetails["isValidEmail"] = false;
    setCustomerDetails(CustomerDetails);
  };
  const handleSubmit = () => {
    const CustomerDetails = { ...customerDetails };

    if (
      (customerDetails.phoneNumber === "" ||
        customerDetails.phoneNumber === null ||
        customerDetails.phoneNumber === undefined) &&
      (customerDetails.email === "" ||
        customerDetails.email === null ||
        customerDetails.email === undefined)
    ) {
      CustomerDetails.isValidPhoneNumber = true;
      CustomerDetails.invalidPhoneNumberMsg = requiredFieldMessage(
        "phone number or email address"
      );
      CustomerDetails.isValidEmail = true;
      CustomerDetails.invalidEmailMsg = requiredFieldMessage(
        "email address or phone number"
      );
    } else if (customerDetails.email && !validateEmail(customerDetails.email)) {
      CustomerDetails.isValidEmail = true;
      CustomerDetails.invalidEmailMsg =
        inputFieldValidaionMessage("email address");
    } else if (
      customerDetails.phoneNumber &&
      !validateContact(customerDetails.phoneNumber)
    ) {
      CustomerDetails.isValidPhoneNumber = true;
      CustomerDetails.invalidPhoneNumberMsg =
        inputFieldValidaionMessage("phone number");
    }

    if (
      customerDetails.lastName === "" ||
      customerDetails.lastName === null ||
      customerDetails.lastName === undefined
    ) {
      CustomerDetails.isValidLastName = true;
      CustomerDetails.invalidLastNameMsg = requiredFieldMessage("last name");
    } else if (!validateName(customerDetails.lastName.trim())) {
      CustomerDetails.isValidLastName = true;
      CustomerDetails.invalidLastNameMsg =
        inputFieldValidaionMessage("last name");
    }
    if (
      customerDetails.firstName === "" ||
      customerDetails.firstName === null ||
      customerDetails.firstName === undefined
    ) {
      CustomerDetails.isValidFirstName = true;
      CustomerDetails.invalidFirstNameMsg = requiredFieldMessage("first name");
    } else if (!validateName(customerDetails.firstName.trim())) {
      CustomerDetails.isValidFirstName = true;
      CustomerDetails.invalidFirstNameMsg =
        inputFieldValidaionMessage("first name");
    }

    setCustomerDetails(CustomerDetails);

    const {
      isValidPhoneNumber,
      isValidFirstName,
      isValidLastName,
      isValidEmail,
    } = CustomerDetails;

    if (
      !isValidEmail &&
      !isValidFirstName &&
      !isValidLastName &&
      !isValidPhoneNumber
    ) {
      const CustomerDetailsArr = [...customerDetailsArr];
      CustomerDetailsArr.push({
        email: CustomerDetails.email,
        firstName: CustomerDetails.firstName.trim(),
        lastName: CustomerDetails.lastName.trim(),
        phoneNumber: CustomerDetails.phoneNumber
          ? CustomerDetails.phoneNumber.slice(
              CustomerDetails.countryCode.length
            )
          : "",
        countryCode: CustomerDetails.countryCode
          ? "+" + CustomerDetails.countryCode
          : "",
      });

      if (CustomerDetailsArr.length > remainingReccos) {
        // toastMessage(
        //   "error",
        //   "Your current plan allows for only " +
        //     remainingReccos +
        //     " ‘Get Reccos’"
        // );
        handleUpgradePlanModal();
      } else {
        setCustomerDetailsArr(CustomerDetailsArr);
      }
      setCustomerDetails({
        email: "",
        phoneNumber: "",
        countryCode: "",
        firstName: "",
        lastName: "",
      });
      setCountry("us");
    }
  };
  const handleAddAdvocates = (reccoListId) => {
    axios
      .post(
        addIndividualAdvocatesUrl(businessId, reccoListId),
        customerDetailsArr
      )
      .then((response) => {
        if (response.status === 201) {
          setLoader(false);
          setCustomerDetailsArr([]);
          if (response.data.failed > 0) {
            let message =
              response.data.failed > 1
                ? response.data.failed +
                  " customers failed to add due to invalid data"
                : response.data.failed +
                  " customer failed to add due to invalid data";
            toastMessage("error", message);
          }
          if (response.data.success > 0) {
            toastMessage(
              "success",
              response.data.success > 1
                ? response.data.success + " customers added successfully"
                : response.data.success + " customer added successfully"
            );
          }
          if (type === "recco") {
            const data = {
              accountId: accountId,
              businessId: businessId,
            };
            toggleMainLoader(true);
            getRemainingRecco(data);
            toggleSecondLoader(true);
            getReccoFam(businessId);
          }

          resetState();
          handleModal();
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const createReccoList = () => {
    setLoader(true);
    axios
      .post(createReccoListUrl(businessId))
      .then((response) => {
        if (response.status === 201) {
          handleAddAdvocates(response.data.id);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleCloseModal = () => {
    if (customerDetailsArr.length > 0) {
      createReccoList();
    } else {
      handleModal();
    }
  };

  return (
    <div style={{ marginTop: "29px" }}>
      <div className="cust-heading">
        Customer {customerDetailsArr.length + 1}
      </div>
      <Row>
        <Col>
          <InputField
            className="cust-input-box"
            placeholder="First name"
            maxLength="100"
            label="First Name"
            name="firstName"
            labelClassName="cust-label-style"
            handleInputChange={(e) =>
              handleInputChange(e, "invalidFirstNameMsg", "isValidFirstName")
            }
            value={customerDetails.firstName}
            invalid={customerDetails.isValidFirstName}
            invalidMsg={customerDetails.invalidFirstNameMsg}
            errorClassName="cust-field-icon"
          />
        </Col>
        <Col>
          <InputField
            className="cust-input-box"
            placeholder="Last name"
            maxLength="100"
            label="Last Name"
            name="lastName"
            labelClassName="cust-label-style"
            handleInputChange={(e) =>
              handleInputChange(e, "invalidLastNameMsg", "isValidLastName")
            }
            value={customerDetails.lastName}
            invalid={customerDetails.isValidLastName}
            invalidMsg={customerDetails.invalidLastNameMsg}
            errorClassName="cust-field-icon"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "7px" }}>
        <Col>
          <InputField
            className="cust-input-box"
            placeholder="email@example.com"
            maxLength="100"
            label="E-mail"
            name="email"
            labelClassName="cust-label-style"
            handleInputChange={(e) =>
              handleInputChange(e, "invalidEmailMsg", "isValidEmail")
            }
            value={customerDetails.email}
            invalid={customerDetails.isValidEmail}
            invalidMsg={customerDetails.invalidEmailMsg}
            errorClassName="cust-field-icon"
          />
        </Col>
        <Col>
          <div
            className={
              customerDetails.isValidPhoneNumber
                ? "cust-phone-input cust-phone-input-error"
                : "cust-phone-input"
            }
          >
            <PhoneInputField
              placeholder="xxxxxxxxxxxx"
              label="Phone no."
              labelClassName="cust-label-style"
              value={customerDetails.phoneNumber}
              invalid={customerDetails.isValidPhoneNumber}
              invalidMsg={customerDetails.invalidPhoneNumberMsg}
              handleInputChange={handlePhoneInput}
              errorClassName="cust-field-icon"
              defaultCountry={country}
            />
          </div>
        </Col>
      </Row>
      <div className="done-div">
        <ButtonItem
          className="done-button-css"
          buttonValue="Done"
          handleButtonAction={handleSubmit}
        />
      </div>
      <div style={{ textAlign: "right", marginTop: "28px" }}>
        <ButtonItem
          className="add-custmers-button"
          buttonValue="Add Customers"
          handleButtonAction={createReccoList}
          disabled={loader || customerDetailsArr.length === 0}
          showLoader={loader}
          loaderWidth="25px"
          loaderHeight="25px"
        />
      </div>
    </div>
  );
};
export default AddIndividualCustomer;
