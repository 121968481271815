import { REGEX } from "../constants";

export const validateEmail = (email) => {
  return REGEX.EMAIL.test(email);
};

export const validatePassword = (password) => {
  return REGEX.PASSWORD.test(password);
};

export const validateConfirmPassword = (password, confirmPassword) => {
  return password === confirmPassword && confirmPassword.length > 0;
};

export const validateContact = (contact) => {
  return REGEX.CONTACT.test(contact);
};
export const validatePhone = (contact) => {
  return REGEX.PHONE.test(contact);
};

export const validateName = (name) => {
  if (name === "") {
    return false;
  } else {
    return REGEX.NAME.test(name);
  }
};

export const validateFax = (fax) => {
  return REGEX.FAX.test(fax);
};

export const validateCode = (code) => {
  return REGEX.USERNAME.test(code);
};

export const validateBlankSpace = (name) => {
  return REGEX.NOT_BLANK_SPACE.test(name);
};

export const validateBusinessName = (name) => {
  if (name === "") {
    return false;
  } else {
    return REGEX.BUSINESS_NAME.test(name);
  }
};

export const validateMessage = (msg) => {
  let onlyNoValid = REGEX.ONLY_TEXT.test(msg);
  const result = onlyNoValid;
  return result;
};

export const containsChar = (inputtxt) => {
  var letters = /^[0-9a-zA-Z]+$/;
  if (inputtxt.value.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const validateURL = (url) => {
  var regex = new RegExp(REGEX.CHECK_URL);
  if (url.match(regex)) {
    return true;
  } else {
    return false;
  }
};

export const validateVideo = (str) => {
  return REGEX.ONLY_VIDEOS.test(str);
};

export const sanitizeBlankSpaces = (str) => {
  return str.replace(/  +/g, ' ');
}