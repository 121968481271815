import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReccoCloud from "../ReccoCloud";
import ReccoFamGraph from "../ReccoFamGraph";
import FamHeader from "./famHeader";
import ButtonItem from "../../common/component/button";
import "./reccoFam.css";
import { Fragment, useState } from "react";
import { ROUTES_PATH } from "../../constants";
import WordCloudPopup from "../../components/WordCloudPopup";

const ReccoFamOverview = (props) => {
  const navigate = useNavigate();
  const [openCloudModal, setCloudModal] = useState(false);

  const handleCloudModal = () => {
    setCloudModal(!openCloudModal);
  };

  return (
    <Fragment>
      <Row>
        <Col md={12} lg={6} xl={6}>
          <FamHeader
            heading="Recco Fam Overview"
            subHeading="Compare and analyse what your Recco fam and customers."
            getFilterValue={props.getFilterValue}
          />
          <ReccoFamGraph {...props} />
        </Col>
        <Col md={12} lg={6} xl={6}>
          <FamHeader
            heading="Recco Cloud"
            subHeading="See what your Recco Fam is saying about you on Recco."
            getFilterValue={props.getFilterValue}
          />
          <ReccoCloud {...props} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ display: "flex", float: "right" }}>
            <ButtonItem
              className="button-css"
              buttonValue="View on Dashboard"
              handleButtonAction={() => navigate(ROUTES_PATH.GET_INSIGHTS)}
            />
            <ButtonItem
              className="button-css"
              buttonValue="Go to Recco Fam"
              style={{ marginLeft: "20px" }}
              handleButtonAction={() => navigate(ROUTES_PATH.NURTURE)}
            />
          </div>
        </Col>
        <Col>
          <div style={{ float: "right" }}>
            <ButtonItem
              className="button-css"
              buttonValue="View in Detail"
              handleButtonAction={handleCloudModal}
            />
          </div>
        </Col>
      </Row>
      <WordCloudPopup
        openModal={openCloudModal}
        handleModal={handleCloudModal}
        {...props}
      />
    </Fragment>
  );
};
export default ReccoFamOverview;
