import { useState } from "react";
import DateRangePicker from "../../components/RemindersCalender/dateRangePicker";
import "./common.css";

const FiltersBlock = ({ getFilterValue, isDateRequired, graphType }) => {
  const [selectedFilter, selectFilter] = useState("week");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleFilter = (value) => {
    selectFilter(value);
    setDateRange([null, null]);
    getFilterValue("currentFilter", value, graphType);
  };
  const handleDateSelection = (value) => {
    setDateRange(value);
    selectFilter("");
    if (value[0] && value[1]) {
      getFilterValue("dateRange", value, graphType);
    }
  };
  return (
    <>
      <div className="filters-div filters-text">
        <span
          onClick={() => handleFilter("week")}
          className={
            selectedFilter === "week" ? "selected-filter" : "cursor-pointer"
          }
        >
          1 Week
        </span>
        <span
          onClick={() => handleFilter("month")}
          className={
            selectedFilter === "month" ? "selected-filter" : "cursor-pointer"
          }
        >
          1 Month
        </span>
        <span
          onClick={() => handleFilter("year")}
          className={
            selectedFilter === "year" ? "selected-filter" : "cursor-pointer"
          }
        >
          1 Year
        </span>
      </div>
      {isDateRequired && (
        <DateRangePicker
          setDateRange={handleDateSelection}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
};

FiltersBlock.propTypes = {};

FiltersBlock.defaultProps = {};

export default FiltersBlock;
