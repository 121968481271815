import { useNavigate } from "react-router-dom";
import ButtonItem from "../../common/component/button";
import { IMG, ROUTES_PATH } from "../../constants";

const HomeNew = ({ setStatus }) => {
  const navigate = useNavigate();
  const handleBtnClick = () => {
    localStorage.setItem("isFirstHome", false);
    localStorage.setItem("isFirstRecco", true);
    setStatus();
    navigate(ROUTES_PATH.GET_RECCO);
  };
  return (
    <div className="home-new-main-div">
      <div className="home-text-div">
        <span className="welcome-text"> Welcome to Recco!</span>
        <div className="home-small-text">
          Foster authentic and trusted communities of loyal business customers
          by asking your existing customers for a Recco.
        </div>
      </div>
      <div className="col-md-10 offset-md-1">
        <img src={IMG.homeImg} alt="" width="100%" height="100%" />
        <div className="home-button-div">
          <ButtonItem
            className="home-button-input"
            buttonValue="Lets get started"
            handleButtonAction={handleBtnClick}
          />
        </div>
      </div>
    </div>
  );
};
export default HomeNew;
