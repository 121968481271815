import { Row, Col } from "reactstrap";
import { IMG } from "../../constants";
import "./subscriptionFooter.css";

const SubscriptionFooter = () => {
  return (
    <Row className="footer-main-div row-mlr">
      <Col
        md={{ size: 4, offset: 2 }}
        lg={{ size: 3, offset: 3 }}
        xl={{ size: 3, offset: 3 }}
      >
        <div className="footer-large-plan-text">
          Here’s why you should consider subscribing to Recco Large Plan ?
        </div>
        <div className="footer-small-text">
          A customized plan offers more flexibility catered specifically to your organization.
          We would love to hear about the exciting work you are doing and how we can make it even
          better.
        </div>
      </Col>
      <Col
        md={{ size: 5, offset: 1 }}
        lg={{ size: 4, offset: 1 }}
        xl={{ size: 4, offset: 1 }}
        style={{ display: "flex" }}
      >
        <div>
          <img src={IMG.customPlanIcon} alt="" />
        </div>
        <div style={{ marginLeft: "25px" }}>
          <div className="footer-custon-int-text">Custom Integrations</div>
          <div className="footer-small-text">
            With custom integrations we have added support with some of the most popular tools out
            there so that you feel at ease while using Recco.
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default SubscriptionFooter;
