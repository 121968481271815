import * as actionTypes from "./actionTypes";

export const getReccoCloud = (data) => ({
  type: actionTypes.GET_RECCO_CLOUD,
  data,
});

export const saveReccoCloud = (data) => ({
  type: actionTypes.SAVE_RECCO_CLOUD,
  data,
});

export const getCommunityOverview = (data) => ({
  type: actionTypes.GET_COMMUNITY_OVERVIEW,
  data,
});

export const saveCommunityOverview = (data) => ({
  type: actionTypes.SAVE_COMMUNITY_OVERVIEW,
  data,
});
export const getInsightsCard = (data) => ({
  type: actionTypes.GET_INSIGHTS_CARD,
  data,
});

export const saveInsightsCard = (data) => ({
  type: actionTypes.SAVE_INSIGHTS_CARD,
  data,
});

export const getReccoFamGraph = (data) => ({
  type: actionTypes.GET_RECCO_FAM_GRAPH,
  data,
});

export const saveReccoFamGraph = (data) => ({
  type: actionTypes.SAVE_RECCO_FAM_GRAPH,
  data,
});

export const getPerkPerformance = (data) => ({
  type: actionTypes.GET_PERK_PERFORMANCE,
  data,
});

export const savePerkPerformance = (data) => ({
  type: actionTypes.SAVE_PERK_PERFORMANCE,
  data,
});

export const getReccoMedium = (data) => ({
  type: actionTypes.GET_RECCO_MEDIUM,
  data,
});

export const saveReccoMedium = (data) => ({
  type: actionTypes.SAVE_RECCO_MEDIUM,
  data,
});
export const getNurturePerformance = (data) => ({
  type: actionTypes.GET_NURTURE_PERFORMANCE,
  data,
});

export const saveNurturePerformance = (data) => ({
  type: actionTypes.SAVE_NURTURE_PERFORMANCE,
  data,
});
