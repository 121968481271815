import { useEffect, useState } from "react";
import GetReccoNew from "../../components/GetReccoNew";
import ReccoReturning from "./getReccoReturning";
import "./getRecco.css";

const GetRecco = () => {
  const [status, setStatus] = useState("false");

  useEffect(() => {
    const localStatus = localStorage.getItem("isFirstRecco");
    setStatus(localStatus);
  }, [status]);

  return (
    <div className="recco-div">
      {status === null || status === "false" ? (
        <ReccoReturning />
      ) : (
        <GetReccoNew setStatus={() => setStatus("false")} />
      )}
    </div>
  );
};
export default GetRecco;
