import ButtonItem from "../../common/component/button";

const ToastComp = ({ user, handleResendVerifyEmail, loader }) => {
  return (
    <div>
      <div className="email-verify-text">
        Your account is pending verification. Please check the link in your
        inbox {user.account && user.account.email} to activate your account.
      </div>
      <div className="small-text">
        No Email? Click here to send new activation link.
        <ButtonItem
          className="modal-button-input"
          buttonValue="Resend"
          handleButtonAction={handleResendVerifyEmail}
          disabled={loader}
          showLoader={loader}
          loaderWidth="20px"
          loaderHeight="20px"
        />
      </div>
    </div>
  );
};
export default ToastComp;
