import { useState } from "react";
import { Row, Col } from "reactstrap";
import OverviewCard from "../../common/component/overviewCard";
import { IMG, overviewInfoArray } from "../../constants";
import CardsInfoPopup from "../CardsInfoPopup";
import "./communityOverview.css";

const CommunityOverview = ({ communityOverview }) => {
  const [isCardInfoModalOpen, setCardInfoModal] = useState(false);

  const handleConfirmationToggle = () => {
    setCardInfoModal(!isCardInfoModalOpen);
  };
  return (
    <div className="overview-div">
      <CardsInfoPopup
        openModal={isCardInfoModalOpen}
        handleModal={handleConfirmationToggle}
        dataArray={overviewInfoArray}
      />
      <div className="overview-header-text">
        Community Overview
        <img
          src={IMG.infoIcon}
          alt="Information"
          style={{ marginLeft: "10px" }}
          className="cursor-pointer"
          onClick={handleConfirmationToggle}
        />
      </div>
      <Row className="row-mlr">
        <Col md={6} lg={4} xl={3} className="card-bottom">
          <OverviewCard
            data={{
              count: communityOverview["reccosCount"]?.totalreccos,
              title: "No. of Reccos",
              subTitle: "",
              percentage: communityOverview["reccosCount"]?.changesThisMonth,
            }}
          />
        </Col>
        <Col md={6} lg={4} xl={3} className="card-bottom">
          <OverviewCard
            data={{
              count: communityOverview["reccoIndex"]?.reccoIndex,
              title: "Recco Index",
              subTitle: "",
              percentage: communityOverview["reccoIndex"]?.changesThisMonth,
            }}
          />
        </Col>

        <Col md={6} lg={4} xl={3} className="card-bottom">
          <OverviewCard
            data={{
              count: communityOverview["reccoViews"]?.reccoView,
              title: "Recco Views",
              subTitle: "",
              percentage: communityOverview["reccoViews"]?.changesThisMonth,
            }}
          />
        </Col>

        <Col md={6} lg={4} xl={3} className="card-bottom">
          <OverviewCard
            data={{
              count: communityOverview["tam"]?.tam,
              title: "T.A.M.",
              subTitle: "",
              percentage: communityOverview["tam"]?.changesThisMonth,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default CommunityOverview;
