import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";
import ButtonItem from "../../common/component/button";
import NoDataFound from "../../common/component/noDataFound";

const columns = [
  { id: "date", label: "Date", sort: "date" },
  { id: "planPurchased", label: "Plan Purchased" },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

const rows = [
  {
    date: "12/21/2021",
    planPurchased: "Medium",
    price: "$123",
    invoiceNumber: "12345",
  },
  {
    date: "12/21/2021",
    planPurchased: "Medium",
    price: "$123",
    invoiceNumber: "12345",
  },
  {
    date: "12/21/2021",
    planPurchased: "Medium",
    price: "$123",
    invoiceNumber: "12345",
  },
  {
    date: "12/21/2021",
    planPurchased: "Medium",
    price: "$123",
    invoiceNumber: "12345",
  },
];

const PaymentHistory = (props) => {
  const StyledTableRow = withStyles((theme) => ({}))(TableRow);
  const StyledTableCell = withStyles((theme) => ({
    root: {
      "&:first-child": {
        borderRadius: "4px 0 0 4px",
        borderLeft: "0.5px solid #C8C8C8",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
        borderRight: "0.5px solid #C8C8C8",
      },
      borderBottom: "0.5px solid #C8C8C8",
    },
  }))(TableCell);
  const StyledTableHeadCell = withStyles((theme) => ({
    root: {
      borderBottom: "0.5px solid #C8C8C8",
    },
  }))(TableCell);
  return (
    <div className="settings-main-div">
      {props.paymentHistory.length === 0 ? (
        <NoDataFound
          className="no-data-text"
          img={IMG.noDataImg}
          message="You don’t have any payment history."
        />
      ) : (
        <TableContainer sx={{ maxHeight: 440 }} className="customers-table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableHeadCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      textAlign:
                        column.id === "planPurchased" ? "left" : "center",
                    }}
                    className="payments-column-headers"
                  >
                    {column.id === "date" ? (
                      <div style={{ display: "flex", justifyContent: "left" }}>
                        {column.label}
                        {/* <img
                          src={IMG.arrowDownIcon}
                          style={{ marginLeft: "7px", cursor: "pointer" }}
                          onClick={() => {
                            props.setSortOrder(!props.sortOrder);
                            props.handleSort(
                              column.sort,
                              props.sortOrder ? "asc" : "desc"
                            );
                          }}
                        /> */}
                      </div>
                    ) : (
                      column.label
                    )}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.paymentHistory.map((row, index) => {
                return (
                  <StyledTableRow tabIndex={-1} key={index}>
                    <StyledTableCell className="date-col-css">
                      <div>{moment(row.date).format("MM-DD-YYYY")}</div>
                    </StyledTableCell>
                    <StyledTableCell className="col-data-css">
                      {row.plan.name}
                      <div style={{ color: "#2B2B2B", fontWeight: "400" }}>
                        {row.plan.brand}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className="col-data-css text-align-center">
                      ${row.price}
                    </StyledTableCell>
                    <StyledTableCell className="col-data-css text-align-center">
                      {row.number}
                    </StyledTableCell>
                    <StyledTableCell
                      className="col-data-css text-align-center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell className="text-align-center">
                      {/* <ButtonItem
                        className="view-button-css"
                        buttonValue="View Invoice"
                        handleButtonAction={() => window.open(row.viewUrl)}
                      /> */}
                      <ButtonItem
                        className="download-button"
                        buttonValue="View Invoice"
                        type="popup-btn"
                        handleButtonAction={() => window.open(row.viewUrl)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PaymentHistory;
