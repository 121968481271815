import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import "./common.css";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#F7F7F7",
    fontSize: 25,
    "&::before": {},
  },
  tooltip: {
    backgroundColor: "#F7F7F7",
    padding: 0,
    maxWidth: "315px",
  },
}));

const MyTooltip = (props) => {
  const classes = useStylesBootstrap();
  return (
    <>
      <Tooltip
        classes={classes}
        title={
          <div
            style={{
              color: "#466DB4",
              borderBottom: "4px solid #466DB4",
              backgroundColor: "#F7F7F7",
            }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  fontFamily: "Inter,sans-serif",
                  textTransform: "uppercase",
                }}
              >
                {props.label ? props.label : "Tooltip"}
              </div>
              <div className="tooltip-text">
                {typeof props.value === "object"
                  ? props.value.map((ele, index) => {
                      return index === props.value.length - 1
                        ? ele
                        : `${ele}, `;
                    })
                  : props.value}
              </div>
            </div>
          </div>
        }
        interactive={false}
        arrow
        enterTouchDelay={100}
        placement="bottom-end"
      >
        {props.txt}
      </Tooltip>
    </>
  );
};

export default MyTooltip;
