import { useState } from "react";
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import ButtonItem from "../../common/component/button";
import moment from "moment";
import CreatePerkPopup from "../CreatePerkPopup";
import {
  updateReccoListDetailsService,
  updateNurtureService,
} from "../../services";
import { toastMessage } from "../ToastMessage";
import { internalServerError, ROUTES_PATH } from "../../constants";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import "./perk.css";

const PerkDetails = ({
  user,
  perk,
  reccoListPerk,
  toggleMainLoader,
  getReccoListDetails,
  reccoListDetails,
  getNurtureDetails,
  nurtureId,
}) => {
  const location = useLocation();
  const [createPerkModal, setCreatePerkModal] = useState(false);
  const [removePerkModal, setRemovePerkModal] = useState(false);
  const [updatePerkModal, setUpdatePerkModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const handlePerkModal = () => {
    setCreatePerkModal(!createPerkModal);
  };

  const handleRemovePerkModal = () => {
    setRemovePerkModal(!removePerkModal);
  };

  const handleUpdatePerkModal = () => {
    setUpdatePerkModal(!updatePerkModal);
  };

  const handleReccoListPerk = (perkId) => {
    const payload = {
      perk: reccoListPerk ? null : perkId,
    };
    setLoader(true);
    if (location.pathname.includes(ROUTES_PATH.NURTURE)) {
      const data = {
        businessId: user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
        nurtureId: nurtureId,
      };
      updateNurtureService(data, payload)
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            if (reccoListPerk) {
              toastMessage(
                "success",
                "Perk removed from the nurture successfully"
              );
              handleRemovePerkModal();
            } else {
              toastMessage(
                "success",
                "Perk applied to the nurture successfully"
              );
            }

            toggleMainLoader(true);
            getNurtureDetails(user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id);
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response?.status === 400) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            toastMessage("error", errorMessage);
          } else {
            toastMessage("error", internalServerError);
          }
        });
    } else {
      const data = {
        businessId: user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
        reccoListId: sessionStorage.getItem("recco-list"),
      };
      updateReccoListDetailsService(data, payload)
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            if (reccoListPerk) {
              toastMessage(
                "success",
                "Perk removed from the recco list successfully"
              );
              handleRemovePerkModal();
            } else {
              toastMessage(
                "success",
                "Perk applied to the recco list successfully"
              );
            }
            const data = {
              businessId: user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
              reccoListId: sessionStorage.getItem("recco-list"),
            };
            toggleMainLoader(true);
            getReccoListDetails(data);
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response?.status === 400) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            toastMessage("error", errorMessage);
          } else {
            toastMessage("error", internalServerError);
          }
        });
    }
  };

  return (
    <>
      <CreatePerkPopup
        openModal={createPerkModal}
        handleModal={handlePerkModal}
        businessId={user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id}
        perk={perk}
        toggleMainLoader={toggleMainLoader}
        getReccoListDetails={getReccoListDetails}
        getNurtureDetails={getNurtureDetails}
      />
      <ConfirmationPopup
        openModal={removePerkModal}
        handleModal={handleRemovePerkModal}
        handleBtnClick={handleReccoListPerk}
        showLoader={loader}
        subText={
          location.pathname.includes(ROUTES_PATH.NURTURE)
            ? "Are you sure you want to remove this perk from nurture?"
            : `Are you sure you want to remove this perk from  ${sessionStorage.getItem(
                "recco-list-title"
              )}?`
        }
        heading="Remove perk"
        btnValue="Remove"
      />
      <ConfirmationPopup
        openModal={updatePerkModal}
        handleModal={handleUpdatePerkModal}
        handleBtnClick={() => {
          handleUpdatePerkModal();
          handlePerkModal();
        }}
        showLoader={loader}
        subText="Your existing recco list's perk will be replaced after updating this perk. Are you sure you want to update this perk?"
        heading="Update perk"
        btnValue="Continue"
      />
      <div className="add-perk-heading">Pay it Forward with a Perk</div>
      <div className="show-perk-div">
        <div className="show-gratitude-text">Show your gratitude now!</div>
        <div className="attach-perk-text">
          Attach a Perk to your ‘Get Recco List’.
        </div>
        <Row>
          <Col md={8} lg={7} xl={6}>
            {perk ? (
              <div className="perk-data-div">
                <div style={{ display: "flex", marginBottom: "9px" }}>
                  <div>
                    <img src={perk?.image} alt="" height="90px" width="100px" />
                  </div>
                  <div style={{ marginLeft: "12px" }}>
                    <div className="perk-added-text">Perk added !</div>
                    <div className="perk-name-css">{perk?.name}</div>
                    <div className="perk-details-name">
                      YOUR PROMO CODE :{" "}
                      <span className="perk-details-value">
                        {perk?.promoCode}
                      </span>
                    </div>
                    {perk?.productUrl && (
                      <div className="perk-details-name">
                        YOUR PROMO LINK :{" "}
                        <span className="perk-details-value">
                          {perk?.productUrl}
                        </span>
                      </div>
                    )}

                    <div className="perk-details-name">
                      VALIDITY :{" "}
                      <span className="perk-details-value">
                        {moment(perk?.expirationDate).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                {reccoListDetails?.reminderStatus !== "SENT" && (
                  <div className="remove-perk-div">
                    <ButtonItem
                      className="skip-perk-button"
                      buttonValue={reccoListPerk ? "Remove Perk" : "Apply Perk"}
                      handleButtonAction={() => {
                        reccoListPerk
                          ? handleRemovePerkModal()
                          : handleReccoListPerk(perk?.id);
                      }}
                      showLoader={loader}
                      disabled={loader}
                      loaderColor="#2963ca"
                      loaderHeight="20px"
                      loaderWidth="20px"
                      type="popup-btn"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="no-perk-data-div">
                <span className="no-perk-text">No Perk Added Yet.</span>
              </div>
            )}
          </Col>
          <Col
            md={{ size: 4 }}
            lg={{ size: 3, offset: 2 }}
            xl={{ size: 2, offset: 4 }}
          >
            {/* <ButtonItem
              className="skip-perk-button"
              buttonValue="Skip Adding Perk"
              // handleButtonAction={handleBackBtn}
            /> */}
            <ButtonItem
              className="create-perk-button"
              buttonValue={perk ? "Update Perk" : "Create Perk"}
              handleButtonAction={() => {
                perk ? handleUpdatePerkModal() : handlePerkModal();
              }}
              disabled={
                location.pathname.includes(ROUTES_PATH.GET_RECCO) &&
                reccoListDetails.reminderStatus === "SENT"
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PerkDetails;
