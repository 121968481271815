import SelectPlan from "../../components/SelectPlan";
import SubscriptionFooter from "../../components/SubscriptionFooter";
import SubscriptionHeader from "../../components/SubscriptionHeader";
import LoadingState from "../../common/component/loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import "./subscription.css";

let stripe_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripe_key);

const Subscriptions = (props) => {
  useEffect(() => {
    props.toggleSecondLoader(true);
    props.getSubscriptionPlans();
    props.toggleMainLoader(true);
    props.getUserDetails("checkStatus", props.user.accountId);
  }, []);

  return (
    <div className="subscription-main-div">
      <SubscriptionHeader {...props} />
      {props.loader.mainLoader || props.loader.secondLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <SelectPlan stripePromise={stripePromise} plans={props.plans} />
      )}
      <SubscriptionFooter />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    plans: state.subscription,
    loader: state.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutSuccess: ActionCreators.logoutSuccess,
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getSubscriptionPlans: ActionCreators.getSubscriptionPlans,
      getUserDetails: ActionCreators.getUserDetails,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
