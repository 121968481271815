import { useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  getReccoBreadcrumbsDetails,
  nurtureBreadcrumbsDetails,
  ROUTES_PATH,
} from "../../constants";
import BottomButtons from "../../components/BottomButtons";
import PerkDetails from "../../components/PerkDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as ActionCreators from "../../modules/actions";
import LoadingState from "../../common/component/loader";
import "./addPerk.css";

const AddPerk = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(ROUTES_PATH.GET_RECCO)) {
      const data = {
        businessId:
          props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
        reccoListId: sessionStorage.getItem("recco-list"),
      };
      props.toggleMainLoader(true);
      props.getReccoListDetails(data);
    } else {
      props.toggleMainLoader(true);
      props.getNurtureDetails(
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
      );
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes(ROUTES_PATH.GET_RECCO) &&
      props.reccoListDetails?.perk === null
    ) {
      props.toggleMainLoader(true);
      props.getBusinessPerk(
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
      );
    }
  }, [props.reccoListDetails]);

  useEffect(() => {
    if (
      location.pathname.includes(ROUTES_PATH.NURTURE) &&
      props.nurtureDetails?.perk === null
    ) {
      props.toggleMainLoader(true);
      props.getBusinessPerk(
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
      );
    }
  }, [props.nurtureDetails]);

  return (
    <div className="add-perk-main-div">
      <Breadcrumbs
        breadcrumbsDetails={
          location.pathname.includes(ROUTES_PATH.NURTURE)
            ? nurtureBreadcrumbsDetails
            : getReccoBreadcrumbsDetails
        }
        active="Add Perk."
        isEnable={true}
      />
      {props.loader?.mainLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <PerkDetails
            {...props}
            perk={
              props.reccoListDetails?.perk === null ||
              props.nurtureDetails?.perk === null
                ? props.perkDetails
                : location.pathname.includes(ROUTES_PATH.NURTURE)
                ? props.nurtureDetails?.perk
                : props.reccoListDetails?.perk
            }
            reccoListPerk={
              location.pathname.includes(ROUTES_PATH.NURTURE)
                ? props.nurtureDetails?.perk
                : props.reccoListDetails?.perk
            }
            nurtureId={props.nurtureDetails?.id}
          />
          <BottomButtons
            handleBackBtn={
              location.pathname.includes(ROUTES_PATH.NURTURE)
                ? () => navigate(ROUTES_PATH.NURTURE_ADD_INFO)
                : () => navigate(ROUTES_PATH.ADD_INFO)
            }
            handleSaveBtn={
              location.pathname.includes(ROUTES_PATH.NURTURE)
                ? () => navigate(ROUTES_PATH.SEND_NURTURE)
                : () => navigate(ROUTES_PATH.SEND_RECCO)
            }
            btnName1="Back"
            btnName2={`Continue`}
          />
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    reccoListDetails: state.reccoListDetails,
    perkDetails: state.perkDetails,
    nurtureDetails: state.nurtureDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getReccoListDetails: ActionCreators.getReccoListDetails,
      getBusinessPerk: ActionCreators.getBusinessPerk,
      getNurtureDetails: ActionCreators.getNurtureDetails,
      toggleMainLoader: ActionCreators.toggleMainLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPerk);
