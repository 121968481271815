import { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import OverviewCard from "../../common/component/overviewCard";
import LoadingState from "../../common/component/loader";
import PerkDetails from "./perkDetails";
import "./managePerk.css";

const ManagePerks = (props) => {
  useEffect(() => {
    props.toggleMainLoader(true);
    props.getBusinessPerk(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
  }, []);

  return (
    <div className="manage-perk-main-div">
      {props.loader.mainLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <div className="manage-perk-heading">
            Pay it forward with a ‘friends and family’ Perk.
          </div>
          <div className="manage-perk-sub-heading">
            Add, manage, and customize your Perk.
          </div>
          <Row className="manage-perk-card-div row-mlr">
            <Col
              md={{ size: 6, offset: 3 }}
              lg={{ size: 4, offset: 3 }}
              xl={{ size: 3, offset: 3 }}
            >
              <OverviewCard
                data={{
                  count: props.perkDetails ? props.perkDetails?.count : 0,
                  title: "Perks Viewed",
                  subTitle: "",
                }}
              />
            </Col>
          </Row>
          <Row>
            <PerkDetails
              {...props}
              perk={props.perkDetails}
              reccoListPerk={null}
              nurtureId={null}
            />
          </Row>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    perkDetails: state.perkDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getBusinessPerk: ActionCreators.getBusinessPerk,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagePerks);
