import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import LoadingState from "./loader";
import { IMG } from "../../constants";

const AddAdvocateBtn = (props) => {
  let {
    className,
    buttonValue,
    handleButtonAction,
    disabled,
    showLoader,
    style,
  } = props;
  return (
    <React.Fragment>
      <Button
        className={className}
        disabled={disabled || showLoader}
        onClick={handleButtonAction}
        style={style}
      >
        <img src={IMG.logoMark} alt="" height="48px" width="48px" />
        {showLoader && (
          <div className="loader-center">
            <LoadingState Width="30px" Height="30px" />
          </div>
        )}
        {!showLoader && buttonValue}
        <img src={IMG.addIcon} alt="" />
      </Button>
    </React.Fragment>
  );
};

AddAdvocateBtn.propTypes = {
  buttonValue: PropTypes.string,
  handleButtonAction: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showLoader: PropTypes.bool,
};

AddAdvocateBtn.defaultProps = {
  buttonValue: "",
  className: undefined,
  disabled: false,
  showLoader: false,
};

export default AddAdvocateBtn;
