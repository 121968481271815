import * as actionTypes from "./actionTypes";

export const loginSuccess = (data) => ({
  type: actionTypes.LOGIN_SUCCESS,
  data,
});

export const toggleMainLoader = (data) => ({
  type: actionTypes.SHOW_OR_HIDE_MAIN_LOADER,
  data,
});

export const logoutSuccess = () => ({ type: actionTypes.LOGOUT_SUCCESS });

export const toggleEmailVerifyLoader = (data) => ({
  type: actionTypes.SHOW_OR_HIDE_EMAIL_VERIFY_LOADER,
  data,
});

export const toggleSecondLoader = (data) => ({
  type: actionTypes.SHOW_OR_HIDE_SECOND_LOADER,
  data,
});
export const toggleSubscribeLoader = (data) => ({
  type: actionTypes.SHOW_OR_HIDE_SUBSCRIBE_LOADER,
  data,
});
export const toggleSortLoader = (data) => ({
  type: actionTypes.SHOW_OR_HIDE_SORT_LOADER,
  data,
});
export const toggleWordCloudLoader = (data) => ({
  type: actionTypes.SHOW_OR_HIDE_WORD_CLOUD_LOADER,
  data,
});
