import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import "./common.css";

const PlanHeading = (props) => {
  let { planName, brand, price, style } = props;

  return (
    <Col>
      <div className="plan-head-div" style={style}>
        <div className="select-plan-name">{planName}</div>
        <div className="select-plan-brand">{brand}</div>
        <div className="select-plan-price">
          {price === "Customized" ? price : "$" + price + "/month"}
        </div>
      </div>
    </Col>
  );
};

PlanHeading.propTypes = {
  planName: PropTypes.string,
  brand: PropTypes.string,
  price: PropTypes.string,
};

export default PlanHeading;
