import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonItem from "../../common/component/button";
import { ROUTES_PATH } from "../../constants";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "150px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },

  heading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "120%",
    padding: "40px 40px 24px 40px",
    letterSpacing: "-0.01em",
    color: "#323232",
    borderBottom: "1px solid #EBEBEB",
  },
  dialogContainer: {
    padding: "24px 40px 30px 40px",
  },
  bodyContent: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#222222",
    marginBottom: "24px",
    maxWidth: "418px",
  },
  resetButton: {
    backgroundColor: "transparent !important",
    borderColor: "#2963ca !important",
    borderRadius: "4px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2963CA !important",
    height: "50px",
    width: "150px",
    marginRight: "16px",
    padding: "14px 12px",
  },
  buttonStyle: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff !important",
    height: "50px",
    width: "160px",
  },
}));

const EmailExistPopup = ({ openModal, handleModal }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleModal}
        fullWidth="md"
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        disableBackdropClick
      >
        <div>
          <div className={classes.heading}>Email already exists!</div>
          <div className={classes.dialogContainer}>
            <div className={classes.bodyContent}>
              Your email id is already registered with us.
              <div style={{ marginTop: "16px" }}>
                Try logging in or you can reset your password.
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonItem
                className={classes.resetButton}
                buttonValue="Reset Password"
                handleButtonAction={() => navigate(ROUTES_PATH.FORGOT_PASSWORD)}
              />
              <ButtonItem
                className={classes.buttonStyle}
                buttonValue="Take me to Login"
                handleButtonAction={() => navigate(ROUTES_PATH.LOGIN)}
                type="popup-btn"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
export default EmailExistPopup;
