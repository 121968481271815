import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoadingState from "../../common/component/loader";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "../../axios";
import { choosePlansUrl } from "../../utils/urls";
import { toastMessage } from "../../components/ToastMessage";
import { internalServerError } from "../../constants";
import { updatePlanService } from "../../services";
import "./AddCardForm.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#117ACA",
      color: "#000000",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        fontfamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.35)",
      },
      backgroundColor: "transperant",
    },
    invalid: {
      iconColor: "#eb1c26",
      color: "#eb1c26",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const SubmitButton = ({ showLoader, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={showLoader || disabled || error}
  >
    {showLoader ? (
      // <div className="loader-center">
      <LoadingState Width="30px" Height="30px" />
    ) : (
      // </div>
      children
    )}
  </button>
);

const CheckoutForm = ({
  handleCardFormOpen,
  user,
  planId,
  getUserDetails,
  toggleMainLoader,
  mainLoader,
  type,
}) => {
  const stripe = useStripe();

  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setShowLoader(true);
    }

    const card = elements.getElement(CardElement);
    const payload = await stripe.createToken(card);
    const cardToken = payload.token.id;

    if (payload.error) {
      setError(payload.error);
    } else {
      const payload = {
        priceId: planId.pricing.priceId,
        paymentMethodType: "card",
        token: cardToken,
        planId: planId.id,
      };
      if (type === "update") {
        handleUpdateSubscription(payload);
      } else {
        handleCreateSubscription(payload);
      }
    }
  };
  const handleCreateSubscription = (payload) => {
    axios
      .post(choosePlansUrl(user.accountId), payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          toggleMainLoader(true);
          getUserDetails("subscription", user.accountId, response.data);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleUpdateSubscription = (payload) => {
    const data = {
      accountId: user.accountId,
      subscriptionId: user.subscription.id,
    };
    updatePlanService(data, payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setShowLoader(false);
          handleCardFormOpen();
          toggleMainLoader(true);
          getUserDetails("subscription", user.accountId, response.data);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  return (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
            setDisabled(false);
          }}
        />
      </fieldset>
      <div
        style={{
          display: "flex",
          justifyContent: error ? "space-between" : "flex-end",
        }}
      >
        {error && <div className="input-error-style">{error.message}</div>}
        <div className="payment-date">
          Estimated recurring payment date :
          {" " + moment().add(1, "months").format("MM/DD/YYYY")}
        </div>
      </div>
      <SubmitButton
        showLoader={showLoader || mainLoader}
        error={error}
        disabled={!stripe || disabled}
      >
        Pay Now
      </SubmitButton>
      <div
        className="payment-note-css"
        style={{ marginTop: error ? "20px" : "10px" }}
      >
        Recco does not store your card details.
      </div>
    </form>
  );
};

const AddCardForm = ({
  stripePromise,
  handleCardFormOpen,
  user,
  planId,
  getUserDetails,
  toggleMainLoader,
  mainLoader,
  type,
}) => {
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise}>
        <CheckoutForm
          handleCardFormOpen={handleCardFormOpen}
          user={user}
          planId={planId}
          getUserDetails={getUserDetails}
          toggleMainLoader={toggleMainLoader}
          mainLoader={mainLoader}
          type={type}
        />
      </Elements>
    </div>
  );
};

export default AddCardForm;
