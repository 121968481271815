import React from "react";
import Plot from "react-plotly.js";
import LoadingState from "./loader";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import FiltersBlock from "./filtersBlock";

const InsightsGraph = ({
  intervals,
  views,
  yaxis_title,
  getFilterValue,
  graphType,
  loader,
}) => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "40px",
        }}
      >
        <FiltersBlock
          getFilterValue={getFilterValue}
          isDateRequired={true}
          graphType={graphType}
        />
      </div>
      {(yaxis_title === "Perks Viewed" && loader?.sortLoader) ||
      (yaxis_title === "Nurture Viewed" && loader?.verifyLoader) ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <Plot
          id="canvas"
          data={[
            {
              x: intervals,
              y: views,

              type: "scatter",
              mode: "none",
              textposition: "auto",
              marker: {
                color: "#9ECDFF",
              },
              fill: "tozeroy",
              fillcolor: "#9ECDFF",
              showlegend: false,
              line: { shape: "spline" },
              name: "NURTURE",
            },
          ]}
          layout={{
            autosize: true,
            margin: {
              l: 0,
              r: 0,
              b: 20,
              t: 0,
              pad: 20,
            },
            width: width,
            title: {
              text: null,
            },
            xaxis_type: "category",
            xaxis: {
              title: { text: "Time Period", standoff: 20 },
              titlefont: {
                family: "Work Sans",
                size: 15,
                color: "#9C9C9C",
                height: 12,
              },
              rangemode: "tozero",
              fixedrange: true,
              automargin: true,
              zeroline: true,
              showgrid: true,
              showticklabels: true,
            },
            yaxis_type: "category",
            yaxis: {
              title: { text: yaxis_title, standoff: 20 },
              titlefont: {
                family: "Work Sans",
                size: 15,
                color: "#9C9C9C",
                height: 12,
              },
              rangemode: "tozero",
              zerolinecolor: "#9ECDFF",
              fixedrange: true,
              automargin: true,
              zeroline: true,
              showgrid: false,
              showticksuffix: "all",
              // tickformat: ",d",
              // ticksuffix: "K",
            },
            legend: {
              x: 1,
              xanchor: "right",
              y: 1.05,
              orientation: "h",
            },
          }}
          displayModeBar={false}
          displaylogo={false}
          staticPlot={true}
          responsive={true}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </div>
  );
};

export default InsightsGraph;
