import { useState, useEffect } from "react";
import ButtonItem from "../../common/component/button";
import QuillComp from "../../common/component/QuillComp";
import TextTemplatesPopup from "../TextTemplatePopup";
import { ROUTES_PATH } from "../../constants";
import "./infoMessages.css";

const InfoMessages = (props) => {
  const [textTemplateModal, setTextTemplateModal] = useState(false);

  useEffect(() => {
    const requestData = {
      type: "text",
      skip: 0,
      limit: 10,
    };
    if (!props.location.pathname.includes(ROUTES_PATH.NURTURE)) {
      props.getTemplates(requestData, props.textTemplates);
    }
  }, []);

  const toggleTextTemplateModal = () => {
    setTextTemplateModal(!textTemplateModal);
  };

  return (
    <div className="infomessages-div">
      {props.location.pathname.includes(ROUTES_PATH.NURTURE) ? null : (
        <TextTemplatesPopup
          openModal={textTemplateModal}
          handleModal={toggleTextTemplateModal}
          getSelectedText={props.getSelectedText}
          data={props.textTemplates}
          selectedText={props.selectedText}
          handleSelectedText={props.handleSelectedText}
          getTemplates={props.getTemplates}
          {...props}
        />
      )}
      <div className="choose-msg-heading">
        {props.location.pathname.includes(ROUTES_PATH.NURTURE)
          ? "Add a message for your Fam"
          : props.location.pathname === ROUTES_PATH.SEND_RECCO
          ? "Edit a message for your Customers"
          : "Add a message for your Customers"}
      </div>
      {props.location.pathname.includes(ROUTES_PATH.NURTURE) ? null : (
        <div>
          <ButtonItem
            className="choose-template-button"
            buttonValue="Choose Text Templates"
            handleButtonAction={toggleTextTemplateModal}
            disabled={
              props.reccoListDetails.reminderStatus === "SENT" ? true : false
            }
          />
        </div>
      )}
      <QuillComp
        textTemplate={
          props.location.pathname.includes(ROUTES_PATH.NURTURE)
            ? null
            : props.textTemplate
        }
        isNurture={props.location.pathname.includes(ROUTES_PATH.NURTURE)}
        reccoListDetails={props.reccoListDetails}
        {...props}
      />
    </div>
  );
};
export default InfoMessages;
