import { useEffect } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import CommunityOverview from "../../components/HomeCommunityOverview";
import HomeHeader from "../../components/HomeHeader";
import ReccoFamOverview from "../../components/ReccoFamOverview";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import LoadingState from "../../common/component/loader";
import "./home.css";

const HomeReturning = (props) => {
  const handleReccoCloud = (from, to) => {
    const requestedData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      from,
      to,
    };
    props.toggleWordCloudLoader(true);
    props.getReccoCloud(requestedData);
  };

  const handleReccoFamOverview = (from, to, filterBy) => {
    const requestedData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      from,
      to,
      filterBy,
    };
    props.toggleSortLoader(true);
    props.getReccoFamGraph(requestedData);
  };
  useEffect(() => {
    props.toggleMainLoader(true);
    const data = {
      accountId: props.user.accountId,
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
    };
    props.getRemainingRecco(data);
    const from = moment().startOf("week").format("MM/DD/YYYY");
    const to = moment().endOf("week").format("MM/DD/YYYY");
    handleReccoCloud(from, to);
    handleReccoFamOverview(from, to, "week");
    props.toggleSecondLoader(true);
    props.getCommunityOverview(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
  }, []);

  const getFilterValue = (type, value, graphType) => {
    let from, to;
    if (type === "dateRange") {
      from = moment(value[0]).format("MM/DD/YYYY");
      to = moment(value[1]).format("MM/DD/YYYY");
    } else {
      from = moment().startOf(value).format("MM/DD/YYYY");
      to = moment().endOf(value).format("MM/DD/YYYY");
    }
    if (graphType === "reccoCloud") {
      handleReccoCloud(from, to);
    } else if (graphType === "famOverview") {
      handleReccoFamOverview(from, to, value);
    }
  };

  return (
    <div className="home-div">
      {props.loader.mainLoader || props.loader.secondLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <HomeHeader {...props} />
          <CommunityOverview communityOverview={props.communityOverview} />
          <Row style={{ margin: "40px 0px" }}>
            <Col md={4} lg={4} xl={4} className="graph-header-text col-plr">
              You are getting noticed!
            </Col>
            <Col md={8} lg={8} xl={8} className="col-plr">
              <hr></hr>
            </Col>
          </Row>
          <ReccoFamOverview getFilterValue={getFilterValue} {...props} />
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    remainingRecco: state.remainingRecco,
    reccoCloud: state.reccoCloud,
    communityOverview: state.communityOverview,
    reccoFamGraph: state.reccoFamGraph,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: ActionCreators.toggleMainLoader,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      getRemainingRecco: ActionCreators.getRemainingRecco,
      getReccoCloud: ActionCreators.getReccoCloud,
      toggleWordCloudLoader: ActionCreators.toggleWordCloudLoader,
      getCommunityOverview: ActionCreators.getCommunityOverview,
      getReccoFamGraph: ActionCreators.getReccoFamGraph,
      toggleSortLoader: ActionCreators.toggleSortLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeReturning);
