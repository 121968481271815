import * as actionTypes from "./actionTypes";

export const getNurtureDetails = (data) => ({
  type: actionTypes.GET_NURTURE_DETAILS,
  data,
});

export const saveNurtureDetails = (data) => ({
  type: actionTypes.SAVE_NURTURE_DETAILS,
  data,
});
export const getRemainingReccoFam = (data) => ({
  type: actionTypes.GET_REMAINING_RECCO_FAM,
  data,
});
export const saveRemainingReccoFam = (data) => ({
  type: actionTypes.SAVE_REMAINING_RECCO_FAM,
  data,
});
