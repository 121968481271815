import { useNavigate } from "react-router-dom";
import { IMG } from "../../constants";
import "./breadcrumbs.css";

const Breadcrumbs = ({ breadcrumbsDetails, active, isEnable }) => {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb-div">
      {breadcrumbsDetails.map((breadcrumb, index) => (
        <div className="breadcrumb-align" key={index}>
          <div
            className={
              active === breadcrumb.text
                ? "active-round"
                : isEnable
                ? "round cursor-pointer"
                : "round"
            }
            onClick={() => (isEnable ? navigate(breadcrumb.route) : null)}
          >
            {index + 1}
          </div>
          <div
            className={
              active === breadcrumb.text
                ? "active-breadcrumb-text"
                : isEnable
                ? "breadcrumb-text cursor-pointer"
                : "breadcrumb-text"
            }
            onClick={() => (isEnable ? navigate(breadcrumb.route) : null)}
          >
            {breadcrumb.text}
          </div>
          {breadcrumbsDetails.length !== index + 1 && (
            <img
              src={active === breadcrumb.text ? IMG.blueArrow : IMG.grayArrow}
              alt=""
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default Breadcrumbs;
