import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";
import ButtonItem from "../../common/component/button";
import "./textTemplate.css";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingState from "../../common/component/loader";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "100px",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  dialogContainer: {
    padding: "40px 80px",
  },
  title: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
    color: "#4D4D4D",
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  textDiv: {
    padding: "13px 30px",
    border: "1px solid #EBEBEB",
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#222222",
    marginBottom: "12px",
    cursor: "pointer",
  },
  selectedTextDiv: {
    background: "#2963CA",
    color: "#FFFFFF",
    padding: "13px 30px",
    border: "1px solid #EBEBEB",
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "12px",
    cursor: "pointer",
  },
}));

const TextTemplatesPopup = ({
  openModal,
  handleModal,
  getSelectedText,
  getTemplates,
  selectedText,
  handleSelectedText,
  data,
}) => {
  const classes = useStyles();
  const [stopPagination, setStopPagination] = useState(false);
  const [page, setPage] = useState(1);

  const getData = (currentPage = page) => {
    const requestData = {
      type: "text",
      skip: currentPage * 10,
      limit: 10,
    };
    getTemplates(requestData, data);
    setPage(page + 1);
    setStopPagination(true);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleModal}
      fullWidth="md"
      maxWidth="md"
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      disableBackdropClick
    >
      <div className={classes.dialogContainer}>
        <div className={classes.headingDiv}>
          <div className={classes.title}>Choose Template Text</div>
          <img
            src={IMG.cancelIcon}
            alt="Close"
            width="29px"
            height="29px"
            onClick={() => {
              // handleSelectedText(null);
              handleModal();
            }}
            className="cursor-pointer"
          />
        </div>
        <InfiniteScroll
          dataLength={data.length}
          next={getData}
          hasMore={!stopPagination}
          height={"480px"}
          scrollThreshold={1}
          loader={
            <div className="table-loader-center">
              <LoadingState color="#2963ca" />
            </div>
          }
          scrollableTarget={"list"}
          className="scroll"
        >
          {data?.map((text, index) => (
            <div
              className={
                selectedText === null
                  ? classes.textDiv
                  : text.id === selectedText.id
                  ? classes.selectedTextDiv
                  : classes.textDiv
              }
              style={{ marginBottom: index === 9 ? "0" : "" }}
              onClick={() => handleSelectedText(text)}
            >
              {text.content}
            </div>
          ))}
        </InfiniteScroll>
        <div className="template-done-div">
          <ButtonItem
            className="template-done-button"
            buttonValue="Done"
            handleButtonAction={() => {
              getSelectedText(selectedText);
              handleModal();
            }}
            disabled={selectedText ? false : true}
          />
        </div>
      </div>
    </Dialog>
  );
};
export default TextTemplatesPopup;
