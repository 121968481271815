import React, { Fragment, useState, useEffect } from "react";
import ButtonItem from "../../common/component/button";
import { makeStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";
import axios from "../../axios";
import { otpVerify, sendOtp, linkedAccounts } from "../../utils/urls";
import OtpInput from "react-otp-input";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "150px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },

  heading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "120%",
    padding: "40px 20px 24px 20px",
    letterSpacing: "-0.01em",
    color: "#323232",
    borderBottom: "1px solid #EBEBEB",
  },
  dialogContainer: {
    padding: "24px 50px 30px 50px",
    backgroundColor: "#fff",
    font: "inter",
  },
  bodyContent: {},
  cancelButton: {
    backgroundColor: "transparent !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2963CA !important",
    height: "50px",
    width: "102px",
    marginRight: "12px",
  },
  skipBtn: {
    backgroundColor: "transparent !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2963CA !important",
    height: "50px",
    width: "176px",
    marginRight: "12px",
    marginRight: "20px",
  },
  buttonStyle: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff !important",
    height: "50px",
    width: "202px",
  },
  searchBar: {
    maxLength: 500,
    width: "99%",
    background: "#F5F5F5",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px 0px 10px 10px",
    padding: 10,
  },
  modalHeading: {
    background: "linear-gradient(91.45deg, #fafafa 35.93%, #f1f1f1 73.39%)",
    borderRadius: "8px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
    color: "#1a1a1a",
    padding: "30px 40px",
    display: "flex",
    justifyContent: "space-between",
  },
  simpleBtn: {
    background: "none",
    border: "none",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeText: {
    color: "#2963CA",
    fontSize: 14,
    fontWeight: 600,
    margin: 10,
  },
  inactiveText: {
    color: "#858585",
    fontSize: 14,
    fontWeigth: 600,
    margin: 10,
  },
  enterOtpCol: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
    marginTop: 40,
  },
  profileBox: {
    display: "flex",
    border: "1px solid #D9D9D9",
    borderRadius: 10,
    width: 400,
    padding: 20,
    alignItems: "center",
  },
  profileImg: {
    width: 32,
    height: 32,
    borderRadius: "100%",
  },
  name: {
    fontSize: 13,
    fontWeight: "700",
    marginLeft: 10,
  },
  title: {
    background: "#EBEBEB",
    borderRadius: 10,
    padding: "4px 9px",
    fontSize: 7,
    color: "#6D6D6D",
    marginLeft: 10,
  },
  countRow: {
    display: "flex",
    paddingLeft: 10,
    alignItems: "center",
    fontSize: 7,
    color: "#828282",
    marginTop: -10,
  },
  otpTextCol: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  otpText: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
    width: 400,
  },
  resendCol: {
    display: "flex",
    justifyContent: "center",
    fontSize: 12,
  },
  activeResend: {
    border: "none",
    background: "none",
    fontWeight: 700,
    fontSize: 12,
    color: "#2963CA",
  },
  inactiveResend: {
    border: "none",
    background: "none",
    color: "#7C7676",
    fontWeight: 700,
    fontSize: 12,
  },
  timerText: { color: "#7C7676", fontSize: 12, margin: 10 },
  flexCol: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  profileBoxSuccess: {
    display: "flex",
    border: "1px solid #D9D9D9",
    borderRadius: 10,
    width: 250,
    padding: 20,
    alignItems: "center",
  },
  businessTextCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: 16,
    marginLeft: 30,
    fontWeight: "600",
    color: "#2963CA",
  },
  successBottomRow: {
    fontSize: 16,
    color: "#2963CA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 30,
  },
  otpSentRequestCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
  },
  bottomBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 50,
  },
  errorDiv:{ display: "flex", justifyContent: "center", color:'#DC1919', fontSize:10, fontWeight:400 }
}));

const OtpModal = ({ localBusinessIndex, user, closeModal }) => {
  const classes = useStyles();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [otp, setOtp] = useState("");
  const [otpSucess, setOtpSuccess] = useState(false);
  const [active, setActive] = useState(true);
  const [message, setMessage] = useState("");

  const openOtpDialog = () => {
    setMessage("");
    closeModal();
    setMinutes(1);
    setSeconds(0);
    setActive(true);
    if (otpSucess) {
      window.location.reload();
    }
  };
  useEffect(() => {
    if (!active) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  useEffect(() => {
    if (otp.length === 5) {
      (async () => {
        try {
          const payload = {
            otp: otp,
          };
          setMessage("");
          const res = await axios.post(otpVerify(user.account.id), payload);
          if (res.status === 200) {
            setOtpSuccess(true);
            const payload = {
              consumerAccountId: user?.account?.consumerAccount?.id,
              businessAccountId: user?.account?.id,
            };
            await axios.post(
              linkedAccounts(user?.business[localBusinessIndex]?.businessForAccount.id),
              payload
            );
          }
        } catch (err) {
          setMessage("Incorrect code. Please try again by clicking Resend.");
        }
      })();
    }
  }, [otp]);

  return (
    <Fragment>
      <div>
        <div className={classes.modalHeading}>
          Connect with Customer Account{" "}
          <button onClick={openOtpDialog} className={classes.simpleBtn}>
            <img src={IMG.crossIcon} />
          </button>
        </div>
        <div className={classes.dialogContainer}>
          <>
            <div className={classes.flexCenter}>
              <img
                src={active ? IMG.otp1Active : IMG.otp1Inactive}
                style={
                  active ? { width: 34, height: 34 } : { width: 24, height: 24 }
                }
              />
              <p className={active ? classes.activeText : classes.inactiveText}>
                Account Linking
              </p>
              <img
                src={active ? IMG.activeOtpArrow : IMG.inactiveOtpArrow}
                style={{ width: 5, height: 11, margin: 10 }}
              />
              <img
                src={!active ? IMG.otp2Active : IMG.otp2Inactive}
                style={
                  !active
                    ? { width: 34, height: 34, margin: 10 }
                    : { width: 24, height: 24, margin: 10 }
                }
              />
              <p
                className={!active ? classes.activeText : classes.inactiveText}
              >
                OTP Verification
              </p>
            </div>
            {!active ? (
              !otpSucess ? (
                <>
                  <div className={classes.enterOtpCol}>
                    <div className={classes.profileBox}>
                      <img
                        className={classes.profileImg}
                        src={
                          user?.account?.consumerAccount?.profilePictureResource?.url
                            ? user?.account?.consumerAccount?.profilePictureResource?.url
                            : IMG.profileImg
                        }
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex" }}>
                          <p className={classes.name}>
                            {user.account?.consumerAccount?.displayName}
                          </p>
                          {user?.account?.title?.length > 0 && (
                            <p className={classes.title}>
                              {user?.account?.consumerAccount?.title}
                            </p>
                          )}
                        </div>
                        <div className={classes.countRow}>
                          <img
                            src={IMG.famCount}
                            style={{
                              width: 16,
                              height: 16,
                              marginRight: 10,
                            }}
                          />
                          {user.account.consumerAccount.squadMembersCount}
                          <img
                            src={IMG.reccoCount}
                            style={{
                              width: 16,
                              height: 16,
                              marginRight: 10,
                              marginLeft: 10,
                            }}
                          />
                          {user.account.consumerAccount.reccosCount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.otpTextCol}>
                    <p className={classes.otpText}>
                      A code has been sent to your no.{" "}
                      <span style={{ fontWeight: 700 }}>
                        {user.account.consumerAccount.phoneNumber.countryCode}{" "}
                        {user.account.consumerAccount.phoneNumber.phoneNumber}.
                      </span>{" "}
                      Please enter the code here to confirm that its you.{" "}
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", flexDirection:'column' }}>
                    <OtpInput
                      value={otp}
                      onChange={(otp) => {
                        setOtp(otp);
                        setMessage("");
                      }}
                      numInputs={5}
                      inputStyle={{
                        fontSize: "24px",
                        width: "36px",
                        height: "36px",
                        margin: "4px",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                        outline: "none",
                        borderColor: "#CFCFCF",
                      }}
                      containerStyle={{
                        margin: "20px auto",
                        padding: "10px",
                        marginBottom:'0px'
                      }}
                      isInputNum
                    />
                    {message?.length > 0 && (
                    <div className={classes.errorDiv}>
                      {message}
                    </div>
                  )}
                  </div>
                  

                  <div className={classes.resendCol}>
                    <p> Didn’t receive code ? </p>
                  </div>
                  <div className={classes.flexCenter}>
                    <button
                      onClick={
                        minutes === 0 && seconds === 0
                          ? async () => {
                              setMinutes(1);
                              setSeconds(0);
                              await axios.post(sendOtp(user.account.id));
                            }
                          : () => {}
                      }
                      className={
                        minutes === 0 && seconds === 0
                          ? classes.activeResend
                          : classes.inactiveResend
                      }
                    >
                      Resend
                    </button>
                    <p className={classes.timerText}>
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  </div>
                </>
              ) : (
                <div className={classes.flexCol}>
                  <div className={classes.enterOtpCol}>
                    <div className={classes.profileBoxSuccess}>
                      <img
                        className={classes.profileImg}
                        src={
                          user?.account?.consumerAccount?.profilePictureResource?.url
                            ? user?.account?.consumerAccount?.profilePictureResource?.url
                            : IMG.profileImg
                        }
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex" }}>
                          <p className={classes.name}>
                            {user.account?.consumerAccount?.displayName}
                          </p>
                          {user?.account?.consumerAccount?.title?.length > 0 && (
                            <p className={classes.title}>
                              {user?.account?.consumerAccount?.title}
                            </p>
                          )}
                        </div>
                        <div className={classes.countRow}>
                          <img
                            src={IMG.famCount}
                            style={{
                              width: 16,
                              height: 16,
                              marginRight: 10,
                            }}
                          />
                          {user.account.consumerAccount.squadMembersCount}
                          <img
                            src={IMG.reccoCount}
                            style={{
                              width: 16,
                              height: 16,
                              marginRight: 10,
                              marginLeft: 10,
                            }}
                          />
                          {user.account.consumerAccount.reccosCount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    src={IMG.linkIcon}
                    style={{ width: 22, height: 22, marginBottom: 30 }}
                  />
                  <div className={classes.profileBoxSuccess}>
                    <img
                      style={{
                        width: 50,
                        height: 50,
                      }}
                      src={IMG.logoMark}
                    />
                    <div className={classes.businessTextCol}>Business</div>
                  </div>
                  <div className={classes.successBottomRow}>
                    <img
                      src={IMG.greenTickIcon}
                      style={{ width: 23, height: 23, marginRight: 20 }}
                    />
                    Accounts Connected !
                  </div>
                  <div>
                    <ButtonItem
                      className={classes.buttonStyle}
                      buttonValue="Close"
                      handleButtonAction={openOtpDialog}
                    />
                  </div>
                </div>
              )
            ) : (
              <div>
                <div className={classes.otpSentRequestCol}>
                  <img
                    src={IMG.logoMark}
                    style={{ width: 80, height: 80, marginRight: 20 }}
                  />
                  <div style={{ width: 300 }}>
                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                      We detected your phone number registered in the Recco App.{" "}
                    </p>
                    <p style={{ fontSize: 18, fontWeight: 500 }}>
                      Is this you ?
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 40,
                  }}
                >
                  <div className={classes.profileBox}>
                    <img
                      className={classes.profileImg}
                      src={
                        user?.account?.consumerAccount?.profilePictureResource?.url
                          ? user?.account?.consumerAccount?.profilePictureResource?.url
                          : IMG.profileImg
                      }
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <p className={classes.name}>
                          {user.account?.consumerAccount?.displayName}
                        </p>
                        {user?.account?.consumerAccount?.title?.length > 0 && (
                          <p className={classes.title}>
                            {user?.account?.consumerAccount?.title}
                          </p>
                        )}
                      </div>
                      <div className={classes.countRow}>
                        <img
                          src={IMG.famCount}
                          style={{ width: 16, height: 16, marginRight: 10 }}
                        />
                        {user.account.consumerAccount.squadMembersCount}
                        <img
                          src={IMG.reccoCount}
                          style={{
                            width: 16,
                            height: 16,
                            marginRight: 10,
                            marginLeft: 10,
                          }}
                        />
                        {user.account.consumerAccount.reccosCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
          {active && (
            <div className={classes.bottomBtnContainer}>
              <ButtonItem
                className={classes.skipBtn}
                buttonValue="Not me, Skip"
                handleButtonAction={openOtpDialog}
              />
              <ButtonItem
                className={classes.buttonStyle}
                buttonValue="Its me, Continue"
                handleButtonAction={async () => {
                  setActive(false);
                  await axios.post(sendOtp(user.account.id));
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default OtpModal;
