import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { IMG } from "../../constants";

const SearchBar = (props) => {
  let {
    handleInputChange,
    handleKeyPress,
    className,
    maxLength,
    placeholder,
    name,
    type,
    value,
  } = props;

  return (
    <React.Fragment>
      <img src={IMG.searchIcon} alt="" className="serach-field-icon" />
      <Input
        type={type}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        className={className}
        maxLength={maxLength}
        autoComplete="off"
        name={name}
        value={value}
      />
    </React.Fragment>
  );
};

SearchBar.propTypes = {
  handleInputChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  invalidMsg: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

SearchBar.defaultProps = {
  type: "text",
  className: undefined,
  placeholder: "",
  invalid: false,
  labelClassName: "page-input-label",
  errorClassName: "field-icon",
};

export default SearchBar;
