import * as actionTypes from "./actionTypes";

export const getAccountDetails = (accountId, tokenDetails) => ({
  type: actionTypes.GET_ACCOUNT_DETAILS,
  accountId,
  tokenDetails,
});
export const saveAccountDetails = (data) => ({
  type: actionTypes.SAVE_ACCOUNT_DETAILS,
  data,
});

export const getUserDetails = (action, data, cb) => ({
  type: actionTypes.GET_USER_DETAILS,
  action,
  data,
  cb,
});
export const saveUserDetails = (data) => ({
  type: actionTypes.SAVE_USER_DETAILS,
  data,
});
export const getBusinessDetails = (action, data, cb) => ({
  type: actionTypes.GET_BUSINESS_DETAILS,
  action,
  data,
  cb,
});
export const saveBusinessDetails = (data) => ({
  type: actionTypes.SAVE_BUSINESS_DETAILS,
  data,
});
export const getSubscriptionDetails = (action, data, cb) => ({
  type: actionTypes.GET_SUBSCRIPTION_DETAILS,
  action,
  data,
  cb,
});
export const saveSubscriptionDetails = (data) => ({
  type: actionTypes.SAVE_SUBSCRIPTION_DETAILS,
  data,
});
