import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import LoadingState from "./loader";

// common button component with loader
const ButtonItem = (props) => {
  let {
    className,
    buttonValue,
    handleButtonAction,
    disabled,
    showLoader,
    loaderHeight,
    loaderWidth,
    style,
    type,
    loaderColor,
  } = props;
  return (
    <React.Fragment>
      <Button
        className={className}
        disabled={disabled || showLoader}
        onClick={handleButtonAction}
        style={style}
      >
        {showLoader && (
          <div className={type === "popup-btn" ? "" : "loader-center"}>
            <LoadingState
              Width={loaderWidth}
              Height={loaderHeight}
              color={loaderColor}
            />
          </div>
        )}
        {!showLoader && buttonValue}
      </Button>
    </React.Fragment>
  );
};

ButtonItem.propTypes = {
  buttonValue: PropTypes.string,
  handleButtonAction: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showLoader: PropTypes.bool,
};

ButtonItem.defaultProps = {
  buttonValue: "",
  className: undefined,
  disabled: false,
  showLoader: false,
  loaderWidth: "30px",
  loaderHeight: "30px",
  type: "page-btn",
  loaderColor: "#FFFFFF",
};

export default ButtonItem;
