import React, { Fragment } from "react";
import { useNavigate } from "react-router";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMG, ROUTES_PATH } from "../../constants";
import IconButton from "../../common/component/iconButton";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "150px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },
  headingDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "-0.01em",
    color: "#1A1A1A",
    marginLeft: "26px",
  },
  dialogContainer: {
    padding: "18px 22px",
  },
  bodyContent: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000",
    marginLeft: "50px",
  },
  buttonStyle: {
    background: "linear-gradient(94.28deg, #dc5ba9 7.01%, #1675cc 102.63%)",
    borderRadius: "8px !important",
    border: "1px solid !important",
    borderColor: "unset !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "left",
    color: "#ffffff !important",
    padding: "12px 13px !important",
    minHeight: "46px",
    width: "170px",
    marginTop: "30px",
  },
  circleDiv: {
    width: "5px",
    height: "5px",
    background: "#000000",
    borderRadius: "50%",
  },
}));

const UpgradePlanPopup = ({ openModal, handleModal, allowedValues }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleModal}
        fullWidth="md"
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        disableBackdropClick
      >
        <div className={classes.dialogContainer}>
          <div className={classes.headingDiv}>
            <div>
              <img src={IMG.rightArrowIcon} alt="" />
              <span className={classes.heading}>
                Subscribed Plan Limit Exceeded
              </span>
            </div>
            <img
              src={IMG.cancelIcon}
              alt="cancel"
              onClick={handleModal}
              className="cursor-pointer"
            />
          </div>
          <div className={classes.bodyContent}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <div className={classes.circleDiv}></div>
              <div style={{ marginLeft: "15px" }}>
                Your current plan allows for only {allowedValues} ‘Get Reccos’
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.circleDiv}></div>
              <div style={{ marginLeft: "15px" }}>
                Consider an upgrade to enjoy more benefits
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              className={classes.buttonStyle}
              buttonValue="Upgrade Plan"
              type="upload"
              icon={IMG.upgradePlan}
              handleButtonAction={() =>
                navigate(ROUTES_PATH.UPDATE_SUBSCRIPTION_PLAN)
              }
            />
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
export default UpgradePlanPopup;
