import React, { Fragment, useState } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "150px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
    height: "216px",
  },
  cancelDiv: {
    padding: "10px 40px 2px 0",
    textAlign: "end",
  },
  dialogContainer: {
    padding: "0px 40px 30px 40px",
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "36px",
    color: "#4D4D4D",
    letterSpacing: "-0.01em",
    textAlign: "center",
  },
  subHeading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4D4D4D",
    textAlign: "center",
  },
  bodyContent: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#222222",
    textAlign: "center",
    margin: "2px 40px 0 40px",
  },
  arrowDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
  },
}));

const CardsInfoPopup = ({ openModal, handleModal, dataArray }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleModal}
        fullWidth="md"
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        disableBackdropClick
      >
        <div>
          <div className={classes.cancelDiv}>
            <img
              src={IMG.cancelIcon}
              alt="Cancel"
              className="cursor-pointer"
              onClick={handleModal}
            />
          </div>
          <div className={classes.dialogContainer}>
            <div className={classes.heading}>
              {dataArray[index].name}
              {dataArray[index].name === "T.A.M." && (
                <div className={classes.subHeading}>
                  Total Addressable Market
                </div>
              )}
            </div>
            <div className={classes.arrowDiv}>
              <img
                src={index === 0 ? IMG.inactiveLeftArrow : IMG.activeLeftArrow}
                alt="Prevoius"
                className={index === 0 ? "" : "cursor-pointer"}
                onClick={() => (index === 0 ? false : setIndex(index - 1))}
              />
              <img
                src={
                  index === dataArray.length - 1
                    ? IMG.inactiveRightArrow
                    : IMG.activeRightArrow
                }
                alt="Next"
                className={
                  index === dataArray.length - 1 ? "" : "cursor-pointer"
                }
                onClick={() =>
                  index === dataArray.length - 1 ? false : setIndex(index + 1)
                }
              />
            </div>
            <div className={classes.bodyContent}>{dataArray[index].info}</div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
export default CardsInfoPopup;
