import React, { Fragment, useState } from "react";
import { Grid, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";
import InputField from "../../common/component/inputField";
import PhoneInputField from "../../common/component/phoneInput";
import ButtonItem from "../../common/component/button";
import {
  requiredFieldMessage,
  inputFieldValidaionMessage,
  internalServerError,
} from "../../constants";
import {
  validateMessage,
  validateEmail,
  validateContact,
  validateName,
} from "../../utils/validation";
import "./getInTouch.css";
import { toastMessage } from "../ToastMessage";
import axios from "../../axios";
import { getInTouchUrl } from "../../utils/urls";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Inter,sans-serif",
    fontSize: "30px",
    lineHeight: "36px",
    letterSpacing: "-0.01em",
    fontWeight: "bold",
    background: "linear-gradient(91.45deg, #FAFAFA 35.93%, #F1F1F1 73.39%)",
    padding: "40px 78px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContainer: {
    padding: "32px 78px",
    background: "#FFFFFF",
    ["@media screen and (max-width:820px)"]: {
      padding: "32px 60px",
    },
  },
  inputLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140%",
    color: "#000000",
  },
  inputBox: {
    border: "1px solid #CACACA !important",
    borderRadius: "4px",
    height: "50px !important",
    padding: "19px 24px !important",
  },
  mesgInputBox: {
    border: "1px solid #CACACA !important",
    borderRadius: "4px",
    height: "212px !important",
    padding: "12px 16px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "normal !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#a7a7a7",
  },
  mb24: {
    marginBottom: "24px",
  },
  buttonInput: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#ffffff !important",
    height: "55px",
    width: "100%",
    maxWidth: "184px",
    justifyContent: "center",
  },
}));

const GetInTouch = ({ openModal, handleModal, accountId, user }) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [getInTouchDetails, setgetInTouchDetails] = useState({
    email: "",
    phoneNumber: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    message: "",
    isValidEmailName: false,
    invalidEmailMsg: "",
    isValidPhoneNumber: false,
    invalidPhoneNumberMsg: "",
    isValidFirstName: false,
    invalidFirstNameMsg: "",
    isValidLastName: false,
    invalidLastNameMsg: "",
    isValidMessage: false,
    invalidMsg: "",
  });

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const GetInTouchDetails = { ...getInTouchDetails };
    GetInTouchDetails[input.name] = input.value;
    GetInTouchDetails[msgType] = "";
    GetInTouchDetails[validationType] = false;
    setgetInTouchDetails(GetInTouchDetails);
  };
  const handlePhoneInput = (value, data) => {
    const GetInTouchDetails = { ...getInTouchDetails };
    GetInTouchDetails["phoneNumber"] = value;
    GetInTouchDetails["countryCode"] = data.dialCode;
    GetInTouchDetails["invalidPhoneNumberMsg"] = "";
    GetInTouchDetails["isValidPhoneNumber"] = false;
    setgetInTouchDetails(GetInTouchDetails);
  };

  const handleSubmit = () => {
    const GetInTouchDetails = { ...getInTouchDetails };
    if (
      getInTouchDetails.email === "" ||
      getInTouchDetails.email === null ||
      getInTouchDetails.email === undefined
    ) {
      GetInTouchDetails.isValidEmailName = true;
      GetInTouchDetails.invalidEmailMsg = requiredFieldMessage("email address");
    } else if (!validateEmail(getInTouchDetails.email)) {
      GetInTouchDetails.isValidEmailName = true;
      GetInTouchDetails.invalidEmailMsg =
        inputFieldValidaionMessage("email address");
    }
    if (
      getInTouchDetails.lastName === "" ||
      getInTouchDetails.lastName === null ||
      getInTouchDetails.lastName === undefined
    ) {
      GetInTouchDetails.isValidLastName = true;
      GetInTouchDetails.invalidLastNameMsg = requiredFieldMessage("last name");
    } else if (!validateName(getInTouchDetails.lastName.trim())) {
      GetInTouchDetails.isValidLastName = true;
      GetInTouchDetails.invalidLastNameMsg =
        inputFieldValidaionMessage("last name");
    }
    if (
      getInTouchDetails.firstName === "" ||
      getInTouchDetails.firstName === null ||
      getInTouchDetails.firstName === undefined
    ) {
      GetInTouchDetails.isValidFirstName = true;
      GetInTouchDetails.invalidFirstNameMsg =
        requiredFieldMessage("first name");
    } else if (!validateName(getInTouchDetails.firstName.trim())) {
      GetInTouchDetails.isValidFirstName = true;
      GetInTouchDetails.invalidFirstNameMsg =
        inputFieldValidaionMessage("first name");
    }
    if (
      getInTouchDetails.phoneNumber === "" ||
      getInTouchDetails.phoneNumber === null ||
      getInTouchDetails.phoneNumber === undefined
    ) {
      GetInTouchDetails.isValidPhoneNumber = true;
      GetInTouchDetails.invalidPhoneNumberMsg =
        requiredFieldMessage("phone number");
    } else if (!validateContact(getInTouchDetails.phoneNumber)) {
      GetInTouchDetails.isValidPhoneNumber = true;
      GetInTouchDetails.invalidPhoneNumberMsg =
        inputFieldValidaionMessage("phone number");
    }
    if (
      getInTouchDetails.message === "" ||
      getInTouchDetails.message === null ||
      getInTouchDetails.message === undefined
    ) {
      GetInTouchDetails.isValidMessage = true;
      GetInTouchDetails.invalidMsg = requiredFieldMessage("message");
    } else if (!validateMessage(getInTouchDetails.message)) {
      GetInTouchDetails.isValidMessage = true;
      GetInTouchDetails.invalidMsg = inputFieldValidaionMessage("message");
    }

    setgetInTouchDetails(GetInTouchDetails);

    const {
      isValidPhoneNumber,
      isValidFirstName,
      isValidLastName,
      isValidEmailName,
      isValidMessage,
    } = GetInTouchDetails;

    if (
      !isValidEmailName &&
      !isValidFirstName &&
      !isValidLastName &&
      !isValidPhoneNumber &&
      !isValidMessage
    ) {

      const businessIndex = localStorage.getItem('businessIndex');
      const businessId = businessIndex ? user.business[businessIndex].businessForAccount.id : user.business[0].businessForAccount.id;

      const payload = {
        businessId,
        email: getInTouchDetails.email,
        firstName: getInTouchDetails.firstName.trim(),
        lastName: getInTouchDetails.lastName.trim(),
        phoneNumber: "+" + getInTouchDetails.phoneNumber,
        message: getInTouchDetails.message,
      };
      setLoader(true);
      axios
        .post(getInTouchUrl(accountId), payload)
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            toastMessage("success", "Contact details sent successfully");
            handleClose();
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response?.status === 400) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            toastMessage("error", errorMessage);
          } else {
            toastMessage("error", internalServerError);
          }
        });
    }
  };
  const handleClose = () => {
    setgetInTouchDetails({
      email: "",
      phoneNumber: "",
      countryCode: "",
      firstName: "",
      lastName: "",
      message: "",
      isValidEmailName: false,
      invalidEmailMsg: "",
      isValidPhoneNumber: false,
      invalidPhoneNumberMsg: "",
      isValidFirstName: false,
      invalidFirstNameMsg: "",
      isValidLastName: false,
      invalidLastNameMsg: "",
      isValidMessage: false,
      invalidMsg: "",
    });

    handleModal();
  };

  return (
    <Fragment>
      {openModal && (
        <Dialog
          scroll={"body"}
          fullWidth={true}
          maxWidth="md"
          open={openModal}
          onClose={handleClose}
          disableBackdropClick
        >
          <div className={classes.title}>
            <span>Get in touch</span>
            <img
              src={IMG.cancelIcon}
              alt="Close"
              width="29px"
              height="29px"
              onClick={handleClose}
              className="cursor-pointer"
            />
          </div>
          <div className={classes.dialogContainer}>
            <Grid container className={classes.mb24}>
              <Grid item md={6} lg={6} className="pr15">
                <InputField
                  className={classes.inputBox}
                  labelClassName={classes.inputLabel}
                  placeholder="e.g. Biz User"
                  maxLength="100"
                  label="First Name"
                  name="firstName"
                  handleInputChange={(e) =>
                    handleInputChange(
                      e,
                      "invalidFirstNameMsg",
                      "isValidFirstName"
                    )
                  }
                  value={getInTouchDetails.firstName}
                  invalid={getInTouchDetails.isValidFirstName}
                  invalidMsg={getInTouchDetails.invalidFirstNameMsg}
                />
              </Grid>
              <Grid item md={6} lg={6} className="pl15">
                <InputField
                  className={classes.inputBox}
                  labelClassName={classes.inputLabel}
                  placeholder="e.g. Biz User"
                  maxLength="100"
                  label="Last Name"
                  name="lastName"
                  handleInputChange={(e) =>
                    handleInputChange(
                      e,
                      "invalidLastNameMsg",
                      "isValidLastName"
                    )
                  }
                  value={getInTouchDetails.lastName}
                  invalid={getInTouchDetails.isValidLastName}
                  invalidMsg={getInTouchDetails.invalidLastNameMsg}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.mb24}>
              <Grid item md={6} lg={6} className="pr15">
                <InputField
                  className={classes.inputBox}
                  labelClassName={classes.inputLabel}
                  placeholder="e.g. abc@abc.com"
                  maxLength="100"
                  label="Email"
                  name="email"
                  handleInputChange={(e) =>
                    handleInputChange(e, "invalidEmailMsg", "isValidEmailName")
                  }
                  value={getInTouchDetails.email}
                  invalid={getInTouchDetails.isValidEmailName}
                  invalidMsg={getInTouchDetails.invalidEmailMsg}
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                className={
                  getInTouchDetails.isValidPhoneNumber
                    ? "popup-phone-input popup-phone-input-error pl15"
                    : "popup-phone-input pl15"
                }
              >
                <PhoneInputField
                  placeholder="(XXX) XXX XXXX"
                  labelClassName={classes.inputLabel}
                  label="Phone Number"
                  value={getInTouchDetails.phoneNumber}
                  invalid={getInTouchDetails.isValidPhoneNumber}
                  invalidMsg={getInTouchDetails.invalidPhoneNumberMsg}
                  handleInputChange={handlePhoneInput}
                />
              </Grid>
            </Grid>
            <Grid container>
              <InputField
                type="textarea"
                className={classes.mesgInputBox}
                labelClassName={classes.inputLabel}
                placeholder="Please write your message here."
                maxLength="3000"
                label="Message"
                name="message"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidMsg", "isValidMessage")
                }
                value={getInTouchDetails.message}
                invalid={getInTouchDetails.isValidMessage}
                invalidMsg={getInTouchDetails.invalidMsg}
              />
            </Grid>
            <Grid style={{ textAlign: "-webkit-right", marginTop: "64px" }}>
              <ButtonItem
                className={classes.buttonInput}
                buttonValue="Submit"
                handleButtonAction={handleSubmit}
                disabled={loader}
                showLoader={loader}
                type="popup-btn"
              />
            </Grid>
          </div>
        </Dialog>
      )}
    </Fragment>
  );
};
export default GetInTouch;
