import { put, call } from "redux-saga/effects";
import * as serviceCreators from "../../services";
import * as actionCreators from "../actions";

export function* getSubscriptionPlans() {
  try {
    const resp = yield call(serviceCreators.getSubscriptionPlansService);
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleSecondLoader(false));
      yield put(actionCreators.saveSubscriptionPlans(resp.data));
    } else {
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSecondLoader(false));
  }
}
export function* chooseSubscriptionPlans(action) {
  try {
    const response = yield call(
      serviceCreators.chooseSubscriptionPlansService,
      action.urlPayload
    );

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
