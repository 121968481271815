import React from "react";
import PropTypes from "prop-types";
import { Label, Input, FormGroup } from "reactstrap";
import { IMG } from "../../constants";
import "./common.css";

// common component for check box field
const CheckBoxField = (props) => {
  let { handleInputChange, name, label, invalidMsg, invalid } = props;

  return (
    <FormGroup check style={{ marginTop: "33px", maxWidth: "392px" }}>
      <Label check className="checkbox-label">
        <Input
          type="checkbox"
          className="page-checkbox-input"
          name={name}
          onChange={handleInputChange}
        />
        {label}
        {invalid && (
          <div className="input-error-style">
            <img
              src={IMG.errorIcon}
              alt=" "
              width="25px"
              height="20px"
              style={{ marginRight: "10px" }}
            />
            {invalidMsg}
          </div>
        )}
      </Label>
    </FormGroup>
  );
};

CheckBoxField.propTypes = {
  handleInputChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
};

CheckBoxField.defaultProps = {
  className: undefined,
};

export default CheckBoxField;
