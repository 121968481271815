import React from "react";
import { IMG } from "../../constants";

const PhoneMediaPreview = (props) => {
  return (
    <div className="phone-preview-image">
      {props.isFileTypeVideo ? (
        <video controls width="100%" height="100%">
          <source src={props.imgPath} type={props.fileType} />
        </video>
      ) : (
        <img
          src={props.imgPath ? props.imgPath : IMG.template}
          alt=""
          width="100%"
          height={props.type === "Web" ? "175px" : "125px"}
          style={{ borderRadius: "10px", objectFit: "contain" }}
        />
      )}
      {props.isNurture ? (
        <div className="phone-preview-image-scroller">
          <div className="phone-preview-image-tabs">
            <div className="phone-preview-image-active-tab" />
            <div className="phone-preview-image-tab" />
            <div className="phone-preview-image-tab" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PhoneMediaPreview;
