import SelectPlan from "../../components/SelectPlan";
import SubscriptionFooter from "../../components/SubscriptionFooter";
import LoadingState from "../../common/component/loader";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { IMG } from "../../constants";
import "./upgardeSubscription.css";

let stripe_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripe_key);

const UpgradeSubscriptions = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.toggleSecondLoader(true);
    props.getSubscriptionPlans();
  }, []);

  return (
    <div className="upgrade-subscription-main-div">
      <div className="upgarde-heading-div">
        <img
          src={IMG.backIcon}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
          style={{ paddingTop: "7px", paddingRight: "15px" }}
        />
        <div className="upgarde-heading-text">
          Upgrade your plan to have a better reach.
          <div className="upgarde-sub-heding">
            You can upgrade or degrade your plan at any time.
          </div>
        </div>
      </div>

      {props.loader.secondLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <SelectPlan
          stripePromise={stripePromise}
          plans={props.plans}
          type="update"
        />
      )}
      <SubscriptionFooter />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    plans: state.subscription,
    loader: state.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutSuccess: ActionCreators.logoutSuccess,
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getSubscriptionPlans: ActionCreators.getSubscriptionPlans,
      getUserDetails: ActionCreators.getUserDetails,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeSubscriptions);
