import React from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { IMG, ROUTES_PATH } from "../../constants";
import IconButton from "../../common/component/iconButton";
import { useNavigate } from "react-router";

const Subscriptions = ({ user }) => {
  const navigate = useNavigate();

  return (
    <div className="settings-main-div">
      <Row>
        <Col md={4} lg={4} xl={3} className="subscription-label-css">
          Plan Name
        </Col>
        <Col md={6} lg={6} xl={4} className="subscription-values-css">
          {user.subscription.plan.name}
          {user.subscription.plan?.brand === " "
            ? null
            : " (" + user.subscription.plan?.brand + ")"}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={4} xl={3} className="subscription-label-css">
          Amount
        </Col>
        <Col md={6} lg={6} xl={4} className="subscription-values-css">
          {user.subscription.plan.pricing.pricePerUnit === "Customized"
            ? user.subscription.plan.pricing.pricePerUnit
            : "$" + user.subscription.plan.pricing.pricePerUnit + "/month"}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={4} xl={3} className="subscription-label-css">
          Reccos
        </Col>
        <Col md={6} lg={6} xl={4} className="subscription-values-css">
          {user.subscription.plan.metaData.getReccos}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={4} xl={3} className="subscription-label-css">
          Nurtures
        </Col>
        <Col md={6} lg={6} xl={4} className="subscription-values-css">
          {user.subscription.plan.metaData.nurtures === " "
            ? "-"
            : user.subscription.plan.metaData.nurtures}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={4} xl={3} className="subscription-label-css">
          Reco Fam Size
        </Col>
        <Col md={6} lg={6} xl={4} className="subscription-values-css">
          {user.subscription.plan.metaData.reccoFamSize}
        </Col>
      </Row>
      { user.subscription.plan.name !== 'Free' ? <Row>
        <Col md={4} lg={4} xl={3} className="subscription-label-css">
          Date of Expiry
        </Col>
        <Col md={6} lg={6} xl={4} className="subscription-values-css">
          {moment(user.subscription.expiresOn).format("MM-DD-YYYY")}
        </Col>
      </Row>: <></>
      }
      { user.subscription.plan.name !== 'Free' ? <Row>
        <Col md={6} lg={5} xl={3}>
          <div className="expiring-subscription-plan">
            <div className="imp-top-div">
              <img src={IMG.impIcon} alt="" />
              <span className="subscription-imp-text"> Important</span>
            </div>
            <div className="subscription-end-text">
              {moment(user.subscription.expiresOn).diff(
                moment(new Date()),
                "days"
              ) > 0
                ? "Your plan is expiring in next " +
                  moment(user.subscription.expiresOn).diff(
                    moment(new Date()),
                    "days"
                  ) +
                  " days."
                : "Your plan is expired!"}
            </div>
          </div>
        </Col>
      </Row>: <></>
      }
      <div>
        <IconButton
          className="upgrade-plan-button"
          buttonValue="Upgrade Plan"
          type="upload"
          icon={IMG.upgradePlan}
          handleButtonAction={() =>
            navigate(ROUTES_PATH.UPDATE_SUBSCRIPTION_PLAN)
          }
        />
      </div>
    </div>
  );
};

export default Subscriptions;
