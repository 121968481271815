import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES_PATH } from "../constants";
import LoadingState from "../common/component/loader";

import Login from "../pages/Login";
import LandingPage from "./LandingPage";
import SignUp from "../pages/SignUp";
import AddUserDetails from "../pages/SignUp/addUserDetails";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Subscriptions from "../container/Subscriptions";
import EmailVerify from "../pages/EmailVerify";

const App = (props) => {
  let routes = props.isAuthenticated ? (
    props.isTellUsAboutYourselfFilled ? (
      props.subscribedToPlan ? (
        <Routes>
          <Route path={"/*"} element={<LandingPage />} />
          <Route
            path="*"
            element={<Navigate replace to={ROUTES_PATH.HOME} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            path={"/accounts/:accountId/email/:slug"}
            element={<EmailVerify {...props} />}
          />
          <Route path={ROUTES_PATH.EMAIL_VERIFY} element={<EmailVerify />} />
          <Route
            path={ROUTES_PATH.SELECT_SUBSCRIPTION_PLAN}
            element={<Subscriptions />}
          />
          <Route
            path="*"
            element={
              <Navigate replace to={ROUTES_PATH.SELECT_SUBSCRIPTION_PLAN} />
            }
          />
        </Routes>
      )
    ) : (
      <Routes>
        <Route
          path={"/accounts/:accountId/email/:slug"}
          element={<EmailVerify {...props} />}
        />
        <Route path={ROUTES_PATH.EMAIL_VERIFY} element={<EmailVerify />} />
        <Route path={ROUTES_PATH.ADD_DETAILS} element={<AddUserDetails />} />
        <Route
          path="*"
          element={<Navigate replace to={ROUTES_PATH.ADD_DETAILS} />}
        />
      </Routes>
    )
  ) : (
    <Routes>
      <Route
        path={"/accounts/:accountId/email/:slug"}
        element={<EmailVerify {...props} />}
      />
      <Route path={ROUTES_PATH.LOGIN} element={<Login />} />
      <Route path={ROUTES_PATH.SIGNUP} element={<SignUp />} />
      <Route path={ROUTES_PATH.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route
        path={"/accounts/:accountId/:slug"}
        element={<ResetPassword {...props} />}
      />
      <Route path={ROUTES_PATH.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path="*" element={<Navigate replace to={ROUTES_PATH.LOGIN} />} />
    </Routes>
  );

  return (
    <Suspense
      fallback={
        <div className="main-loader-center">
          <LoadingState />
        </div>
      }
    >
      {routes}
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  if (state.user) {
    try {
      return {
        isAuthenticated: state.user.token ? true : false,
        isTellUsAboutYourselfFilled:
          state.user.business?.length > 0 ? true : false,
        subscribedToPlan: state.user.subscription ? true : false,
        user: state.user,
      };
    } catch (e) {
      return {
        isAuthenticated: false,
        user: state.user,
        isTellUsAboutYourselfFilled: false,
        subscribedToPlan: false,
      };
    }
  }
};

export default connect(mapStateToProps)(App);
