import * as actionTypes from "./actionTypes";

export const getReccoListAdvocates = requestData => ({
  type: actionTypes.GET_RECCO_LIST_ADVOCATES,
  requestData,
});
export const saveReccoListAdvocates = data => {
  return {
    type: actionTypes.SAVE_RECCO_LIST_ADVOCATES,
    data,
  };
};
