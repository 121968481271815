import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IMG } from "../../../constants";
import "./quillComp.css";

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold" title="Bold" />
    <button className="ql-italic" title="Italic"></button>
    <button className="ql-underline" title="Underline"></button>
    <button className="ql-strike" title="Strike"></button>
    <button class="ql-header" value="1" title="Heading1"></button>
    <button class="ql-header" value="2" title="Heading2"></button>
    {/* <select className="ql-color">
      <option value="red" />
      <option value="green" />
      <option value="blue" />
      <option value="orange" />
      <option value="violet" />
      <option value="#d0d1d2" />
      <option selected />
    </select> */}
    <button class="ql-list" value="ordered" title="Ordered List"></button>
    <button class="ql-list" value="bullet" title="Unordered List"></button>
    <button class="ql-indent" value="-1" title="Indent"></button>
    <button class="ql-indent" value="+1" title="Indent"></button>
    <button class="ql-direction" value="rtl" title="Direction"></button>
    <select class="ql-align" title="Align"></select>
    <button class="ql-link" title="Link"></button>
    <button class="ql-undo" title="Undo">
      <img src={IMG.undoIcon} alt="" className="toolbar-image-position" />
    </button>
    <button class="ql-redo" title="Redo" style={{ marginRight: "0" }}>
      <img src={IMG.redoIcon} alt="" className="toolbar-image-position" />
    </button>
  </div>
);

function undo() {
  this.quill.history.undo();
}

function redo() {
  this.quill.history.redo();
}
function focus() {
  this.quill.state.editor.focus();
}
const QuillComp = ({
  textTemplate,
  handleSelectText,
  isNurture,
  reccoListDetails,
}) => {
  const [editText, setEditText] = useState("");

  useEffect(() => {
    setEditText(textTemplate);
  }, [textTemplate]);

  const handleText = (content, delta, source, editor) => {
    setEditText(content);
    handleSelectText(editor.getText());
  };

  return (
    <div className={isNurture ? "quill-nurture-css" : "quill-css"}>
      {/* <CustomToolbar /> */}
      <ReactQuill
        theme="snow"
        modules={QuillComp.modules}
        // formats={QuillComp.formats}
        value={editText}
        onChange={(content, delta, source, editor) =>
          handleText(content, delta, source, editor)
        }
        readOnly={reccoListDetails.reminderStatus === "SENT" ? true : false}
        placeholder={
          isNurture
            ? "Type your message here..."
            : "Type your message here or choose text template..."
        }
      />
    </div>
  );
};
export default QuillComp;
/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
QuillComp.modules = {
  toolbar: false,
  // {
  //   container: "#toolbar",
  //   handlers: {
  //     undo: undo,
  //     redo: redo,
  //   },
  // },
  // clipboard: {
  //   matchVisual: false,
  // },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
QuillComp.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "align",
  "direction",
  "script",
];
