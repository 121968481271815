import WordCloud from "react-d3-cloud";
import LoadingState from "../../common/component/loader";
import "./reccoCloud.css";

const data = [
  { text: "Hey" },
  { text: "lol" },
  { text: "first impression" },
  { text: "very cool" },
  { text: "duck" },
  { text: "Hey" },
  { text: "lol" },
  { text: "first impression" },
  { text: "very cool" },
  { text: "duck" },
  { text: "Hey" },
  { text: "lol" },
  { text: "first impression" },
  { text: "very cool" },
  { text: "duck" },
  { text: "Hey" },
  { text: "lol" },
  { text: "first impression" },
  { text: "very cool" },
  { text: "U+1F60A" },
];

const ReccoCloud = ({ reccoCloud, isGetInsights, loader }) => {
  const newData = reccoCloud?.map((item) => ({
    text: item.text,
    value: Math.floor(Math.random() * 31) + 20,
    count: item.count,
  }));

  return (
    <div className="recco-cloud-div">
      <div
        className={
          isGetInsights ? "get-insights-recco-cloud-bg" : "recco-cloud-bg"
        }
      >
        {loader?.wordCloudLoader ? (
          <div
            className="main-loader-center"
            style={{ height: isGetInsights ? "300px" : "500px" }}
          >
            <LoadingState color="#2963ca" />
          </div>
        ) : reccoCloud.length > 0 ? (
          <WordCloud
            font="Inter, sans-serif"
            fontStyle="normal"
            fontWeight="500"
            fontSize={(word) => {
              return word.count * 30;
            }}
            // lineHeight="66"
            // spiral="rectangular"
            // random={Math.random}
            data={newData}
            rotate={(word) => 0}
            padding={5}
            height={isGetInsights ? 420 : 670}
          />
        ) : (
          <div
            className="no-words-div"
            style={{ height: isGetInsights ? "360px" : "500px" }}
          >
            No reccos found.
          </div>
        )}
      </div>
    </div>
  );
};
export default ReccoCloud;
