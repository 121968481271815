import { Row, Col } from "reactstrap";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import "./signup.css";
import InputField from "../../common/component/inputField";
import {
  IMG,
  inputFieldValidaionMessage,
  internalServerError,
  requiredFieldMessage,
} from "../../constants";
import ButtonItem from "../../common/component/button";
import {
  validateBusinessName,
  validateContact,
  validateName,
  validateEmail,
} from "../../utils/validation";
import PhoneInputField from "../../common/component/phoneInput";
import EmailVerify from "../../components/EmailVerifyPopup";
import axios from "../../axios";
import {
  addUserDetailsUrl,
  resendEmailVerifyUrl,
  getAccountDetailsUrl,
} from "../../utils/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { toastMessage } from "../../components/ToastMessage";
import ToastComp from "../../components/EmailVerifyPopup/toastComp";
import LoadingState from "../../common/component/loader";

const AddUserDetails = (props) => {
  const [signupDetails, setSignupDetails] = useState({
    businessName: "",
    phoneNumber: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    slug: "",
    isValidBusinessName: false,
    invalidBusinessNameMsg: "",
    isValidPhoneNumber: false,
    invalidPhoneNumberMsg: "",
    isValidFirstName: false,
    invalidFirstNameMsg: "",
    isValidLastName: false,
    invalidLastNameMsg: "",
    isValidSlugName: false,
    invalidSlugNameMsg: "",
  });

  const [loader, setLoader] = useState(false);

  const toastId = useRef(null);

  const notify = () => {
    toastId.current = EmailVerify(
      props.user,
      props.loader.verifyLoader,
      handleResendVerifyEmail
    );
  };
  const dismiss = () => toast.dismiss(toastId.current);

  const updateToast = () => {
    if (toast.isActive(toastId.current)) {
      toast.update(toastId.current, {
        render: (
          <ToastComp
            user={props.user}
            loader={props.loader.verifyLoader}
            handleResendVerifyEmail={handleResendVerifyEmail}
          />
        ),
        containerId: "email-verify",
      });
    }
  };

  useEffect(() => {
    props.toggleMainLoader(true);
    props.getUserDetails("checkStatus", props.user.accountId);
    return () => {
      dismiss();
    };
  }, []);

  useEffect(() => {
    if (
      !props.loader.mainLoader
      // && props.loader.mainLoader !== undefined
    ) {
      if (
        props.user.account &&
        props.user.account.status === "PENDING_VERIFICATION"
      ) {
        notify();
      } else {
        dismiss();
      }
    }
  }, [props.user.account.status]);

  useEffect(() => {
    updateToast();
  }, [props.loader.verifyLoader]);

  const handleResendVerifyEmail = () => {
    props.toggleEmailVerifyLoader(true);
    axios
      .post(resendEmailVerifyUrl(props.user.accountId))
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          props.toggleEmailVerifyLoader(false);
          toastMessage("success", "Email verification mail sent successfully.");
        }
      })
      .catch((error) => {
        props.toggleEmailVerifyLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const SignupDetails = { ...signupDetails };
    SignupDetails[input.name] = input.value;
    SignupDetails[msgType] = "";
    SignupDetails[validationType] = false;
    setSignupDetails(SignupDetails);
  };
  const handlePhoneInput = (value, data) => {
    const SignupDetails = { ...signupDetails };
    SignupDetails["phoneNumber"] = value;
    SignupDetails["countryCode"] = data.dialCode;
    SignupDetails["invalidPhoneNumberMsg"] = "";
    SignupDetails["isValidPhoneNumber"] = false;
    setSignupDetails(SignupDetails);
  };
  const handleSubmit = async () => {
    const SignupDetails = { ...signupDetails };
    if (
      signupDetails.businessName === "" ||
      signupDetails.businessName === null ||
      signupDetails.businessName === undefined
    ) {
      SignupDetails.isValidBusinessName = true;
      SignupDetails.invalidBusinessNameMsg =
        requiredFieldMessage("business name");
    } else if (!validateBusinessName(signupDetails.businessName.trim())) {
      SignupDetails.isValidBusinessName = true;
      SignupDetails.invalidBusinessNameMsg =
        inputFieldValidaionMessage("business name");
    }

    if (
      signupDetails.lastName === "" ||
      signupDetails.lastName === null ||
      signupDetails.lastName === undefined
    ) {
      SignupDetails.isValidLastName = true;
      SignupDetails.invalidLastNameMsg = requiredFieldMessage("last name");
    } else if (!validateName(signupDetails.lastName.trim())) {
      SignupDetails.isValidLastName = true;
      SignupDetails.invalidLastNameMsg =
        inputFieldValidaionMessage("last name");
    }
    if (
      signupDetails.firstName === "" ||
      signupDetails.firstName === null ||
      signupDetails.firstName === undefined
    ) {
      SignupDetails.isValidFirstName = true;
      SignupDetails.invalidFirstNameMsg = requiredFieldMessage("first name");
    } else if (!validateName(signupDetails.firstName.trim())) {
      SignupDetails.isValidFirstName = true;
      SignupDetails.invalidFirstNameMsg =
        inputFieldValidaionMessage("first name");
    }
    if (
      signupDetails.phoneNumber === "" ||
      signupDetails.phoneNumber === null ||
      signupDetails.phoneNumber === undefined
    ) {
      SignupDetails.isValidPhoneNumber = true;
      SignupDetails.invalidPhoneNumberMsg =
        requiredFieldMessage("phone number");
    } else if (signupDetails.phoneNumber.length < 11) {
      SignupDetails.isValidPhoneNumber = true;
      SignupDetails.invalidPhoneNumberMsg =
        inputFieldValidaionMessage("phone number");
    } else if (!validateContact(signupDetails.phoneNumber)) {
      SignupDetails.isValidPhoneNumber = true;
      SignupDetails.invalidPhoneNumberMsg =
        inputFieldValidaionMessage("phone number");
    }

    setSignupDetails(SignupDetails);

    const {
      isValidPhoneNumber,
      isValidFirstName,
      isValidLastName,
      isValidBusinessName,
    } = SignupDetails;

    if (
      !isValidBusinessName &&
      !isValidFirstName &&
      !isValidLastName &&
      !isValidPhoneNumber
    ) {
      const businessPayload = {
        businessName: signupDetails.businessName.trim(),
        slug: signupDetails.slug.trim(),
        appType:'business'
      };
      const accountPayload = {
        firstName: signupDetails.firstName.trim(),
        lastName: signupDetails.lastName.trim(),
        countryCode: "+" + signupDetails.countryCode,
        phoneNumber: signupDetails.phoneNumber.slice(
          signupDetails.countryCode.length
        ),
      };
      setLoader(true);
      await Promise.all([
        axios.patch(getAccountDetailsUrl(props.user.accountId), accountPayload),
        axios.post(addUserDetailsUrl(props.user.accountId), businessPayload),
      ])
        .then((response) => {
          if (
            (response[0].status === 201 || response[0].status === 200) &&
            (response[1].status === 201 || response[1].status === 200)
          ) {
            props.getUserDetails("userDetails", props.user.accountId);
          }
        })
        .catch((error) => {
          setLoader(false);
          if (
            error.response?.status === 400 ||
            error.response?.status === 404
          ) {
            const errorMessage =
              error.response.data.failures?.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            toastMessage("error", errorMessage);
          } else {
            toastMessage("error", internalServerError);
          }
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!loader) {
        handleSubmit();
      }
    }
  };

  return (
    <div>
      {props.loader.mainLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <Row className="row-mlr">
          <Col
            sm={12}
            md={{ size: 5 }}
            lg={{ size: 4 }}
            xl={{ size: 4 }}
            className="second-signup-div"
          >
            <div>
              <img src={IMG.logo} alt="Recco" />
            </div>
            <div className="signup-heading">Tell us about yourself</div>
            <div className="signup-sub-heading" style={{ marginTop: "20px" }}>
              Just a couple more details to make the experience better for you
            </div>
            <div style={{ marginTop: "48px" }}>
              <div className="mb-22">
                <InputField
                  className="page-input-box"
                  placeholder="First name"
                  maxLength="100"
                  label="First Name"
                  name="firstName"
                  handleInputChange={(e) =>
                    handleInputChange(
                      e,
                      "invalidFirstNameMsg",
                      "isValidFirstName"
                    )
                  }
                  value={signupDetails.firstName}
                  invalid={signupDetails.isValidFirstName}
                  invalidMsg={signupDetails.invalidFirstNameMsg}
                />
              </div>
              <div className="mb-22">
                <InputField
                  className="page-input-box"
                  placeholder="Last name"
                  maxLength="100"
                  label="Last Name"
                  name="lastName"
                  handleInputChange={(e) =>
                    handleInputChange(
                      e,
                      "invalidLastNameMsg",
                      "isValidLastName"
                    )
                  }
                  value={signupDetails.lastName}
                  invalid={signupDetails.isValidLastName}
                  invalidMsg={signupDetails.invalidLastNameMsg}
                />
              </div>

              <div
                className={
                  signupDetails.isValidPhoneNumber
                    ? "mb-22 phone-input phone-input-error"
                    : "mb-22 phone-input"
                }
              >
                <PhoneInputField
                  placeholder="xxxxxxxxxxxx"
                  label="Phone Number"
                  value={signupDetails.phoneNumber}
                  invalid={signupDetails.isValidPhoneNumber}
                  invalidMsg={signupDetails.invalidPhoneNumberMsg}
                  handleInputChange={handlePhoneInput}
                />
              </div>

              <div>
                <InputField
                  className="page-input-box"
                  placeholder="Business.."
                  maxLength="50"
                  label="Business Name"
                  name="businessName"
                  handleInputChange={(e) =>
                    handleInputChange(
                      e,
                      "invalidBusinessNameMsg",
                      "isValidBusinessName"
                    )
                  }
                  handleKeyPress={handleKeyPress}
                  value={signupDetails.businessName}
                  invalid={signupDetails.isValidBusinessName}
                  invalidMsg={signupDetails.invalidBusinessNameMsg}
                />
              </div>
              <br />
              <div>
                <InputField
                  className="page-input-box"
                  placeholder="Unique identifier for your business"
                  maxLength="50"
                  label="Business Identifier"
                  name="slug"
                  handleInputChange={(e) =>
                    handleInputChange(
                      e,
                      "invalidSlugNameMsg",
                      "isValidSlugName"
                    )
                  }
                  handleKeyPress={handleKeyPress}
                  value={signupDetails.slug}
                  invalid={signupDetails.isValidSlugName}
                  invalidMsg={signupDetails.invalidSlugNameMsg}
                />
              </div>
              <ButtonItem
                className="page-button-input"
                buttonValue="Continue"
                handleButtonAction={handleSubmit}
                disabled={loader}
                showLoader={loader}
                style={{ marginTop: "84px" }}
              />
            </div>
          </Col>
          <Col
            md={{ size: 6, offset: 1 }}
            lg={{ size: 7, offset: 1 }}
            xl={{ size: 7, offset: 1 }}
            className="col-plr"
          >
            <img src={IMG.signup2Img} alt="" className="side-image" />
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginSuccess: ActionCreators.loginSuccess,
      toggleEmailVerifyLoader: ActionCreators.toggleEmailVerifyLoader,
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getUserDetails: ActionCreators.getUserDetails,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUserDetails);
