import { Row, Col } from "reactstrap";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "../../constants";
import NavBar from "../../components/Navbar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import "./landing.css";
import SecondNavBar from "../../components/SecondNavBar";
import GetRecco from "../GetRecco";
import Home from "../Home";
import GetInsights from "../GetInsights";
import Nurture from "../Nurture";
import ManagePerks from "../ManagePerks";
import ReccoList from "../ReccoList";
import AddInfo from "../AddInfo";
import AddPerk from "../AddPerk";
import AddNurtureInfo from "../AddNurtureInfo";
import Settings from "../../pages/Settings";
import UpgradeSubscriptions from "../UpgradeSubscriptions";

const LandingPage = (props) => {
  const navigate = useNavigate();
  return (
    <div className="home-container-box">
      <NavBar {...props} navigation={navigate} />
      <SecondNavBar />
      <Row className="row-mlr">
        <Col role="main" className="main" style={{ paddingBottom: "15px" }}>
          <Routes>
            <Route path={ROUTES_PATH.GET_RECCO} element={<GetRecco />} />
            <Route path={ROUTES_PATH.RECCO_LIST} element={<ReccoList />} />
            <Route path={ROUTES_PATH.ADD_INFO} element={<AddInfo />} />
            <Route
              path={ROUTES_PATH.NURTURE_ADD_INFO}
              element={<AddNurtureInfo />}
            />
            <Route
              path={ROUTES_PATH.SEND_NURTURE}
              element={<AddNurtureInfo />}
            />
            <Route path={ROUTES_PATH.NURTURE_ADD_PERK} element={<AddPerk />} />
            <Route path={ROUTES_PATH.ADD_PERK} element={<AddPerk />} />
            <Route path={ROUTES_PATH.SEND_RECCO} element={<AddInfo />} />
            <Route path={ROUTES_PATH.NURTURE} element={<Nurture />} />
            <Route path={ROUTES_PATH.GET_INSIGHTS} element={<GetInsights />} />
            <Route path={ROUTES_PATH.MANAGE_PERKS} element={<ManagePerks />} />
            <Route path={ROUTES_PATH.HOME} element={<Home />} />
            <Route path={ROUTES_PATH.SETTINGS} element={<Settings />} />
            <Route
              path={ROUTES_PATH.UPDATE_SUBSCRIPTION_PLAN}
              element={<UpgradeSubscriptions />}
            />
            <Route
              path="*"
              element={<Navigate replace to={ROUTES_PATH.HOME} />}
            />
          </Routes>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutSuccess: ActionCreators.logoutSuccess,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
