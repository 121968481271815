import { useState } from "react";
import FiltersBlock from "../../common/component/filtersBlock";

const FamHeader = ({ heading, subHeading, getFilterValue }) => {
  return (
    <div
      style={{ display: "flex", marginBottom: "40px", alignItems: "center" }}
    >
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="col-md-6"
      >
        <div className="graph-cloud-heading">{heading}</div>
        <div className="graph-cloud-sub-heading">{subHeading}</div>
      </div>
      <div className="col-md-6 display-flex-end">
        <FiltersBlock
          getFilterValue={getFilterValue}
          isDateRequired={false}
          graphType={
            heading === "Recco Fam Overview" ? "famOverview" : "reccoCloud"
          }
        />
      </div>
    </div>
  );
};
export default FamHeader;
