import React from "react";
import Plot from "react-plotly.js";

const InsightsBarGraph = ({ data }) => {
  return (
    <div>
      <Plot
        id="canvas"
        data={[
          {
            x: Object.keys(data).map(
              (str) => str.charAt(0).toUpperCase() + str.slice(1)
            ),
            y: Object.values(data),
            text: Object.values(data).map(String),
            textposition: "outside",
            type: "bar",
            xaxis: { fixed: true },
            yaxis: { fixed: true },
            marker: {
              color: "#D4E9FF",
            },
            showlegend: false,
            textfont: {
              color: "rgba(37, 43, 65, 0.64)",
              size: 16,
              family: "Inter",
              weight: 700,
            },
          },
        ]}
        layout={{
          autosize: true,
          margin: {
            l: 0,
            r: 0,
            b: 20,
            t: 0,
          },

          dragmode: false,
          title: {
            text: null,
          },
          xaxis: {
            rangemode: "tozero",
            fixedrange: true,
            automargin: true,
            zeroline: true,
            showgrid: false,
            showticklabels: true,
            tickfont: {
              color: "#000000",
              size: 16,
              family: "Inter",
              weight: 400,
            },
          },
          yaxis: {
            rangemode: "tozero",
            zeroline: true,
            zerolinecolor: "#D4E9FF",
            showgrid: true,
            title: { text: "NO. OF CUSTOMERS", standoff: 20 },
            titlefont: {
              family: "Work Sans",
              size: 10,
              color: "#9C9C9C",
              height: 12,
            },
            fixedrange: true,
            automargin: true,
            showticklabels: true,
            showticksuffix: "all",
          },
        }}
        displayModeBar={false}
        displaylogo={false}
        responsive={true}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default InsightsBarGraph;
