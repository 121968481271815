import React from "react";
import { toast } from "react-toastify";
import "./emailVerify.css";
import ToastComp from "./toastComp";

const EmailVerify = (user, loader, handleResendVerifyEmail) => {
  return toast(
    <ToastComp
      user={user}
      loader={loader}
      handleResendVerifyEmail={handleResendVerifyEmail}
    />,
    {
      position: toast.POSITION.TOP_CENTER,
      className: "verify-modal",
      autoClose: false,
      draggable: false,
      closeButton: false,
      closeOnClick: false,
      containerId: "email-verify",
    }
  );
};

export default EmailVerify;
