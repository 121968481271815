import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IMG } from "../../constants";
import "./common.css";

// common component for phone input field
const PhoneInputField = (props) => {
  let {
    handleInputChange,
    placeholder,
    invalid,
    invalidMsg,
    label,
    value,
    labelClassName,
    errorClassName,
    defaultCountry,
  } = props;

  return (
    <React.Fragment>
      <Label className={labelClassName}>{label}</Label>
      <PhoneInput
        inputExtraProps={{
          name: "Phone",
          required: true,
          autoFocus: true,
        }}
        country={defaultCountry}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        disableCountryCode={false}
        preferredCountries={["us"]}
        countryCodeEditable={true}
        disableDropdown={false}
        dropdownClass="phone-flag-box"
      />

      {invalid && (
        <span className={errorClassName}>
        </span>
      )}
      {invalid && <div className="input-error-style">{invalidMsg}</div>}
    </React.Fragment>
  );
};

PhoneInputField.propTypes = {
  handleInputChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  invalidMsg: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultCountry: PropTypes.string,
};

PhoneInputField.defaultProps = {
  className: undefined,
  placeholder: "",
  invalid: false,
  labelClassName: "page-input-label",
  errorClassName: "field-icon",
  defaultCountry: "us",
};

export default PhoneInputField;
