import * as actionTypes from "./actionTypes";

export const getPaymentHistory = (data) => ({
  type: actionTypes.GET_PAYMENT_HISTORY,
  data,
});
export const savePaymentHistory = (data) => ({
  type: actionTypes.SAVE_PAYMENT_HISTORY,
  data,
});
