import { useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";
import IconButton from "../../common/component/iconButton";
import "./addAdvocates.css";
import ButtonItem from "../../common/component/button";
import { FileDrop } from "react-file-drop";
import AddIndividualCustomer from "./addIndividualCust";
import { internalServerError } from "../../constants";
import { toastMessage } from "../ToastMessage";
import axios from "../../axios";
import { uploadReccoListFileUrl, createReccoListUrl } from "../../utils/urls";
import UpgradePlanPopup from "../UpgradePlanPopup";

const AddAdvocatesPopup = (props) => {
  const {
    openModal,
    handleModal,
    businessId,
    toggleMainLoader,
    getReccoList,
    resetState,
    remainingReccos,
    getRemainingRecco,
    accountId,
    toggleSecondLoader,
    getReccoFam,
    type,
  } = props;
  const [fileLoader, setFileLoader] = useState(false);
  const [fileObject, setFileObject] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);

  const classes = useStyles();
  const fileInputRef = useRef(null);
  const downloadFileRef = useRef(null);
  const childFunc = useRef(null);

  const handleAddAdvocates = (reccoListId) => {
    let formData = new FormData();
    formData.append("file", fileObject);
    axios
      .post(uploadReccoListFileUrl(businessId, reccoListId), formData)
      .then((response) => {
        if (response.status === 201) {
          setFileLoader(false);
          handleRemoveFile();
          if (response.data.failed > 0) {
            let message =
              response.data.failed > 1
                ? response.data.failed +
                  " customers failed to add due to invalid data"
                : response.data.failed +
                  " customer failed to add due to invalid data";

            toastMessage("error", message);
          }
          if (response.data.success > 0) {
            toastMessage(
              "success",
              response.data.success > 1
                ? response.data.success + " customers added successfully"
                : response.data.success + " customer added successfully"
            );
          }
          if (type === "recco") {
            const data = {
              accountId: accountId,
              businessId: businessId,
            };
            toggleMainLoader(true);
            getRemainingRecco(data);
            toggleSecondLoader(true);
            getReccoFam(businessId);
          }
          resetState();
          handleModal();
        }
      })
      .catch((error) => {
        setFileLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          if (error.response.data.failures[0]["field"] === "Recco limit") {
            handleUpgradePlanModal();
          } else {
            toastMessage("error", errorMessage);
          }
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const createReccoList = () => {
    setFileLoader(true);
    axios
      .post(createReccoListUrl(businessId))
      .then((response) => {
        if (response.status === 201) {
          handleAddAdvocates(response.data.id);
        }
      })
      .catch((error) => {
        setFileLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const handleUploadFileBtn = () => {
    fileInputRef.current.click();
  };
  const handleFileDataChange = (event) => {
    setIsFileUploaded(true);
    const file = event.target.files[0];
    setFileObject(file);
  };

  const onFileDrop = (files, event) => {
    event.preventDefault();
    if (files.length > 1) {
      toastMessage("error", "Please select only one file");
    } else {
      setIsFileUploaded(true);
      setFileObject(files[0]);
    }
  };
  const handleDownloadTemplate = () => {
    downloadFileRef.current.click();
  };
  const handleRemoveFile = () => {
    setIsFileUploaded(false);
    setFileObject("");
  };
  const handleUpgradePlanModal = () => {
    setUpgradePlanModal(!upgradePlanModal);
  };

  return (
    <>
      <UpgradePlanPopup
        openModal={upgradePlanModal}
        handleModal={handleUpgradePlanModal}
        allowedValues={remainingReccos}
      />
      <Dialog
        open={openModal}
        onClose={handleModal}
        fullWidth="md"
        maxWidth="md"
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        disableBackdropClick
      >
        <div className={classes.dialogContainer}>
          <div className="close-icon-css">
            <img
              src={IMG.cancelIcon}
              alt="close"
              onClick={() => {
                handleRemoveFile();
                childFunc.current();
              }}
              className="cursor-pointer"
            />
          </div>
          <div className="recco-pages-heading">Add Customers</div>
          <div className="batch-advocates-div">
            <div style={{ float: "right", marginLeft: "24px" }}>
              <IconButton
                className="download-template-btn"
                buttonValue="Download Template"
                icon={IMG.downloadIcon}
                handleButtonAction={handleDownloadTemplate}
              />
              <a
                href={IMG.customerFile}
                download="AddBatchCustomer Template.csv"
                ref={downloadFileRef}
              ></a>
            </div>
            <div className="add-customers-heading batch-heading-div">
              Batch add customers
            </div>
            <div style={{ marginTop: "11px", display: "flex" }}>
              <img
                src={IMG.batchCustomer}
                alt=""
                style={{ marginTop: "6px" }}
              />
              <div style={{ marginLeft: "15px" }}>
                <div className="upload-file-text">
                  Upload your Excel / CSV data file here.
                </div>
                {isFileUploaded ? (
                  <div
                    className="drag-drop-div"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      className="upload-file-text"
                      style={{ padding: "6px 17px" }}
                    >
                      {fileObject.name}
                    </div>
                    <div className="remove-text" onClick={handleRemoveFile}>
                      Remove
                    </div>
                  </div>
                ) : (
                  <FileDrop onDrop={onFileDrop} multiple={false}>
                    <div className="drag-drop-div">
                      <IconButton
                        className="upload-img-btn"
                        buttonValue="Upload file"
                        icon={IMG.uploadIcon}
                        type="upload"
                        handleButtonAction={handleUploadFileBtn}
                        disabled={fileLoader}
                        showLoader={fileLoader}
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept={".csv, .xlsx, .xls"}
                        multiple={false}
                        onChange={handleFileDataChange}
                        ref={fileInputRef}
                      />

                      <div className="drag-drop-text">
                        or drop file here to upload.
                      </div>
                    </div>
                  </FileDrop>
                )}
              </div>
            </div>
            <div style={{ textAlign: "right", marginTop: "28px" }}>
              <ButtonItem
                className="add-custmers-button"
                buttonValue="Add Customers"
                handleButtonAction={createReccoList}
                disabled={fileLoader || fileObject === ""}
                showLoader={fileLoader}
                loaderHeight="25px"
                loaderWidth="25px"
              />
            </div>
          </div>
          <div className="individual-advocates-div">
            <div className="add-customers-heading individual-heading-div">
              Add individual customers
            </div>
            <div style={{ marginTop: "21px", display: "flex" }}>
              <img src={IMG.individualCustomer} alt="" />
              <div style={{ marginLeft: "15px" }}>
                <div className="upload-file-text" style={{ maxWidth: "159px" }}>
                  Add a single customer’s information.
                </div>
              </div>
            </div>
            <AddIndividualCustomer
              businessId={businessId}
              handleModal={handleModal}
              toggleMainLoader={toggleMainLoader}
              getReccoList={getReccoList}
              childFunc={childFunc}
              resetState={resetState}
              remainingReccos={remainingReccos}
              getRemainingRecco={getRemainingRecco}
              accountId={accountId}
              toggleSecondLoader={toggleSecondLoader}
              getReccoFam={getReccoFam}
              type={type}
              handleUpgradePlanModal={handleUpgradePlanModal}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "100px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },
  dialogContainer: {
    padding: "40px 80px",
  },
}));
export default AddAdvocatesPopup;
