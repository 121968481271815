import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

const NoDataFound = ({ className, message, img }) => {
  return (
    <React.Fragment>
      <Row className="row-mlr">
        <Col
          lg={{
            size: 4,
            offset: 4,
          }}
          md={{
            size: 4,
            offset: 4,
          }}
          className="align-center"
        >
          <img src={img} alt="" />
          <div className={className}>{message}</div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

NoDataFound.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  img: PropTypes.string,
};

NoDataFound.defaultProps = {
  className: undefined,
  message: "No Data Found",
  img: "",
};

export default NoDataFound;
