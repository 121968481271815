import React from "react";
import { IMG } from "../../constants";

const InsightsCard = (props) => {
  return (
    <div className="insight-card-div">
      <div className="insight-card-header">{props.header}</div>
      <div className="insight-card-values-div">
        <div className="insight-card-values-count">
          {props.header === "Recco Index" ? props.value + "%" : props.value}
        </div>
        <div className="insight-card-values-percentage">
          <div className="insight-card-values-percentage-value">
            +{props.percentage}%
          </div>
          <img src={IMG.rightUpArrow} />
        </div>
      </div>
    </div>
  );
};

export default InsightsCard;
