import { useState, useRef, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ButtonItem from "../../common/component/button";
import PlanHeading from "../../common/component/planHeading";
import Plans from "../../common/component/plans";
import StripeCards from "../StripeCards";
import { getPlanDetails } from "../../utils/commonMethods";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { toast } from "react-toastify";
import EmailVerify from "../../components/EmailVerifyPopup";
import axios from "../../axios";
import { choosePlansUrl, getAccountDetailsUrl, resendEmailVerifyUrl } from "../../utils/urls";
import { toastMessage } from "../../components/ToastMessage";
import { internalServerError, plansArray } from "../../constants";
import ToastComp from "../EmailVerifyPopup/toastComp";
import "./selectPlan.css";
import GetInTouch from "../GetInTouch";
import { updatePlanService } from "../../services";
import { saveToken } from "../../utils/localStorage";

const SelectPlan = (props) => {
  const { stripePromise, plans, user, type } = props;
  const [openStripeModal, setStripeModal] = useState(false);
  const [planId, setPlanId] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openGetInTouchModal, setGetInTouchModal] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const toastId = useRef(null);

  const notify = () => {
    toastId.current = EmailVerify(
      user,
      props.loader.verifyLoader,
      handleResendVerifyEmail
    );
  };
  const dismiss = () => toast.dismiss(toastId.current);

  const updateToast = () => {
    if (toast.isActive(toastId.current)) {
      toast.update(toastId.current, {
        render: (
          <ToastComp
            user={props.user}
            loader={props.loader.verifyLoader}
            handleResendVerifyEmail={handleResendVerifyEmail}
          />
        ),
        containerId: "email-verify",
      });
    }
  };

  useEffect(() => {

    return () => {
      dismiss();
    };
  }, []);

  useEffect(() => {
    handleSelectedPlan();
  }, [plans, user.subscription]);

  useEffect(() => {
    updateToast();
  }, [props.loader.verifyLoader]);

  const handleSelectedPlan = () => {
    plans.map((plan, index) => {
      if (type === "update" && user.subscription?.plan?.name === plan.name) {
        setSelectedPlanId(index);
      }
    });
  };
  const handleResendVerifyEmail = () => {
    const payload = {
      appType: "business",
    };
    props.toggleEmailVerifyLoader(true);
    axios
      .post(resendEmailVerifyUrl(props.user.accountId))
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          props.toggleEmailVerifyLoader(false);
          toastMessage("success", "Email verification mail sent successfully.");
        }
      })
      .catch((error) => {
        props.toggleEmailVerifyLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleSubmit = async (plan) => {
    const accountRes = await axios.get(getAccountDetailsUrl(user.account.id));
    const account = accountRes.data;

    if (type !== "update") {
      localStorage.setItem("isFirstHome", true);
    }

    if (user.account && !account.isEmailVerified) {
      setBtnDisabled(true);
      window.scrollTo(0, 0);
      notify();
    } else {
      dismiss();
      setBtnDisabled(false);

      if (plan.name === "Free") {
        handleFreePlanSubscription(plan);
      } else if (plan.name === "LARGE") {
        handleGetInTouchModal();
      } else {
        setPlanId(plan);
        setStripeModal(!openStripeModal);
      }
    }
  };
  const handleStripeModal = () => {
    setStripeModal(!openStripeModal);
  };
  const handleGetInTouchModal = () => {
    setGetInTouchModal(!openGetInTouchModal);
  };
  const handleFreePlanSubscription = (plan) => {
    setLoader(true);

    const businessIndex = localStorage.getItem('businessIndex');
    const businessId = businessIndex ? user.business[businessIndex].businessForAccount.id : user.business[0].businessForAccount.id

    const payload = {
      businessId,
      priceId: plan.pricing.priceId,
      planId: plan.id,
    };

    if (type === "update") {
      handleUpdateFreePlan(payload);
    } else {
      handleCreateFreePlan(payload);
    }
  };
  const handleCreateFreePlan = (payload) => {
    axios
      .post(choosePlansUrl(user.accountId), payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setLoader(false);
          props.toggleSubscribeLoader(true);
          props.getUserDetails("subscription", user.accountId, response.data);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleUpdateFreePlan = (payload) => {
    const data = {
      accountId: user.accountId,
      subscriptionId: user.subscription.id,
    };
    updatePlanService(data, payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setLoader(false);
          props.toggleSubscribeLoader(true);
          props.getUserDetails("subscription", user.accountId, response.data);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  return (
    <div className="plan-main-div">
      <div className="select-plan-heading">Select a subscription plan here</div>
      <Row>
        <Col className="select-plan-fetures">Features / mo</Col>
        {plans &&
          plans.map((plan, index) => (
            <PlanHeading
              planName={plan.name}
              brand={plan.brand}
              price={plan.pricing.pricePerUnit}
              key={index}
              style={{
                background:
                  type === "update" && index === selectedPlanId
                    ? "rgba(255, 205, 120, 0.3)"
                    : "linear-gradient(93.41deg, #fffefe 13.24%, #f3f3f3 95.82%)padding-box,linear-gradient(to right, #dc5ba9, #1675cc) border-box",
              }}
            />
          ))}
      </Row>
      {plansArray.map((data, index) => (
        <Plans
          planName={data.name}
          planDeatils={getPlanDetails(plans, data.key)}
          className="mt-24"
          key={index}
          value={selectedPlanId}
          type={type}
        />
      ))}
      <Row style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Col></Col>
        {plans &&
          plans.map((plan, index) => (
            <Col key={index}>
              <ButtonItem
                className="plans-button"
                buttonValue={
                  plan.name === "LARGE"
                    ? "Get in touch with us"
                    : "Choose this plan"
                }
                handleButtonAction={() => handleSubmit(plan)}
                disabled={
                  btnDisabled ||
                  loader ||
                  props.loader.subscribeLoader ||
                  (type === "update" &&
                    user.subscription?.plan?.name === plan.name)
                }
                showLoader={
                  plan.name === "Free"
                    ? loader || props.loader.subscribeLoader
                    : false
                }
              />
            </Col>
          ))}

        <StripeCards
          openStripeModal={openStripeModal}
          handleStripeModal={handleStripeModal}
          stripePromise={stripePromise}
          user={user}
          planId={planId}
          getUserDetails={props.getUserDetails}
          toggleMainLoader={props.toggleSubscribeLoader}
          mainLoader={props.loader.subscribeLoader}
          type={type}
        />
        <GetInTouch
          openModal={openGetInTouchModal}
          handleModal={handleGetInTouchModal}
          accountId={user.accountId}
          user={user}
        />
      </Row>
      <Row>
        <Col md={{ size: 10, offset: 2 }}>
          <div className="bottom-line"></div>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: ActionCreators.toggleMainLoader,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      getUserDetails: ActionCreators.getUserDetails,
      saveUserDetails: ActionCreators.saveUserDetails,
      toggleEmailVerifyLoader: ActionCreators.toggleEmailVerifyLoader,
      toggleSubscribeLoader: ActionCreators.toggleSubscribeLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlan);
