import React from "react";
import PropTypes from "prop-types";
import { IMG } from "../../constants";

const OverviewCard = (props) => {
  let { data } = props;
  return (
    <div className="card-div">
      <div
        style={{
          display: "flex",
          justifyContent:
            data.percentage || data.percentage === 0
              ? "space-between"
              : "flex-end",
        }}
      >
        {(data.percentage || data.percentage === 0) && (
          <div className="percent-div">
            +{data.percentage}%
            <img src={IMG.arrowTopRight} alt="" style={{ marginLeft: "4px" }} />
          </div>
        )}

        <div className="count-text">
          {data.title === "Recco Index" ? data.count + "%" : data.count}
          <div className="below-count-text">{data.subTitle}</div>
        </div>
      </div>
      <div className="logo-div">
        <img src={IMG.logoMark} alt="" />
        <span className="logo-div-text">
          {data.title}
          {data.title === "T.A.M." && (
            <div className="overview-tam-text">Total Addressable Market</div>
          )}
        </span>
      </div>
    </div>
  );
};

OverviewCard.propTypes = {
  data: PropTypes.object,
};

export default OverviewCard;
