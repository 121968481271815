import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import LoadingState from "./loader";

const IconButton = (props) => {
  let {
    className,
    buttonValue,
    handleButtonAction,
    disabled,
    showLoader,
    loaderWidth,
    loaderHeight,
    icon,
    iconWidth,
    iconHeight,
    style,
    type,
    loaderColor,
  } = props;
  return (
    <React.Fragment>
      <Button
        className={className}
        disabled={disabled || showLoader}
        onClick={handleButtonAction}
        style={style}
      >
        {!showLoader && (
          <img
            src={icon}
            alt=""
            height={iconHeight}
            width={iconWidth}
            style={{ marginRight: type === "upload" ? "10px" : "4px" }}
          />
        )}
        {showLoader && (
          <div className="loader-center">
            <LoadingState
              Width={loaderWidth}
              Height={loaderHeight}
              color={loaderColor}
            />
          </div>
        )}
        {!showLoader && buttonValue}
      </Button>
    </React.Fragment>
  );
};

IconButton.propTypes = {
  buttonValue: PropTypes.string,
  handleButtonAction: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showLoader: PropTypes.bool,
  loaderColor: PropTypes.string,
};

IconButton.defaultProps = {
  buttonValue: "",
  className: undefined,
  disabled: false,
  showLoader: false,
  loaderWidth: "30px",
  loaderHeight: "30px",
  loaderColor: "#2963CA",
};

export default IconButton;
