import { Row, Col } from "reactstrap";
import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./resetPassword.css";
import PasswordField from "../../common/component/passwordField";
import {
  ROUTES_PATH,
  IMG,
  passwordValidation,
  requiredFieldMessage,
  confirmPasswordValidation,
  internalServerError,
} from "../../constants";
import ButtonItem from "../../common/component/button";
import {
  validatePassword,
  validateConfirmPassword,
} from "../../utils/validation";
import axios from "axios";
import { resetpasswordUrl } from "../../utils/urls";
import { toastMessage } from "../../components/ToastMessage";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountId } = useParams();
  const [resetPwdDetails, setResetPwdDetails] = useState({
    password: "",
    confirmPassword: "",
    isValidPassword: false,
    invalidPasswordMsg: "",
    isValidConfirmPassword: false,
    invalidConfirmPasswordMsg: "",
    isSuccessReset: false,
  });
  const [pathState, setPathState] = useState({
    accountId: "",
    token: "",
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (null !== location.state && location.state) {
      const { accountId, token } = location.state;
      setPathState({
        accountId,
        token,
      });
    } else {
      if (
        location.search !== null &&
        undefined !== location.search &&
        undefined !== accountId &&
        accountId !== null
      ) {
        const token = new URLSearchParams(location.search).get("token");
        setPathState({
          accountId,
          token,
        });
        navigate(ROUTES_PATH.RESET_PASSWORD, {
          state: { accountId: accountId, token: token },
        });
      } else {
        navigate(ROUTES_PATH.LOGIN);
      }
    }
  }, []);

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const ResetPwdDetails = { ...resetPwdDetails };

    ResetPwdDetails[input.name] = input.value;
    ResetPwdDetails[msgType] = "";
    ResetPwdDetails[validationType] = false;

    setResetPwdDetails(ResetPwdDetails);
  };
  const handleSubmit = () => {
    const ResetPwdDetails = { ...resetPwdDetails };
    if (
      resetPwdDetails.password === "" ||
      resetPwdDetails.password === null ||
      resetPwdDetails.password === undefined
    ) {
      ResetPwdDetails.isValidPassword = true;
      ResetPwdDetails.invalidPasswordMsg = requiredFieldMessage("password");
    } else if (!validatePassword(resetPwdDetails.password)) {
      ResetPwdDetails.isValidPassword = true;
      ResetPwdDetails.invalidPasswordMsg = passwordValidation;
    }
    if (
      resetPwdDetails.confirmPassword === "" ||
      resetPwdDetails.confirmPassword === null ||
      resetPwdDetails.confirmPassword === undefined
    ) {
      ResetPwdDetails.isValidConfirmPassword = true;
      ResetPwdDetails.invalidConfirmPasswordMsg =
        requiredFieldMessage("confirm password");
    } else if (
      resetPwdDetails.password &&
      !validateConfirmPassword(
        resetPwdDetails.password,
        resetPwdDetails.confirmPassword
      )
    ) {
      ResetPwdDetails.isValidConfirmPassword = true;
      ResetPwdDetails.invalidConfirmPasswordMsg = confirmPasswordValidation;
    }

    setResetPwdDetails(ResetPwdDetails);
    const { isValidConfirmPassword, isValidPassword } = ResetPwdDetails;

    if (!isValidPassword && !isValidConfirmPassword) {
      const payload = {
        token: pathState.token,
        password: resetPwdDetails.password,
      };
      setLoader(true);
      axios
        .post(resetpasswordUrl(pathState.accountId), payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setLoader(false);
            setResetPwdDetails({ isSuccessReset: true });
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response?.status === 400) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            toastMessage("error", errorMessage);
          } else {
            toastMessage("error", internalServerError);
          }
        });
      // setResetPwdDetails({ isSuccessReset: true });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!loader) {
        handleSubmit();
      }
    }
  };
  return (
    <div>
      <Row className="row-mlr">
        <Col
          md={{ size: 6 }}
          lg={{ size: 6 }}
          xl={{ size: 4 }}
          className="main-div"
        >
          <div>
            <img src={IMG.logo} alt="Recco" />
          </div>
          <div className="reset-heading">
            {resetPwdDetails.isSuccessReset
              ? "Password Updated"
              : "Setup Your Password"}
          </div>
          <div className="reset-sub-heading">
            {resetPwdDetails.isSuccessReset
              ? "Your new password is updated to your account. Login with your new password now."
              : "Enter your new password and confirm the new password."}
          </div>
          <div style={{ marginTop: "48px" }}>
            {resetPwdDetails.isSuccessReset ? (
              <ButtonItem
                className="page-button-input"
                buttonValue="Back to Login"
                handleButtonAction={() => navigate(ROUTES_PATH.LOGIN)}
                disabled={false}
                showLoader={false}
              />
            ) : (
              <Fragment>
                <div className="mb-22">
                  <PasswordField
                    handlePasswordChange={(e) =>
                      handleInputChange(
                        e,
                        "invalidPasswordMsg",
                        "isValidPassword"
                      )
                    }
                    className="page-input-box"
                    maxLength="15"
                    placeholder="password"
                    label="Enter New Password"
                    name="password"
                    value={resetPwdDetails.password}
                    invalid={resetPwdDetails.isValidPassword}
                    invalidMsg={resetPwdDetails.invalidPasswordMsg}
                  />
                  {!resetPwdDetails.isValidPassword && (
                    <div className="password-msg-style">
                      {passwordValidation}
                    </div>
                  )}
                </div>
                <div className="button-top">
                  <PasswordField
                    handlePasswordChange={(e) =>
                      handleInputChange(
                        e,
                        "invalidConfirmPasswordMsg",
                        "isValidConfirmPassword"
                      )
                    }
                    handleKeyPress={handleKeyPress}
                    className="page-input-box"
                    maxLength="15"
                    placeholder="confirm password"
                    label="Confirm New Password"
                    name="confirmPassword"
                    value={resetPwdDetails.confirmPassword}
                    invalid={resetPwdDetails.isValidConfirmPassword}
                    invalidMsg={resetPwdDetails.invalidConfirmPasswordMsg}
                  />
                </div>
                <ButtonItem
                  className="page-button-input button-bottom"
                  buttonValue="Save"
                  handleButtonAction={handleSubmit}
                  disabled={loader}
                  showLoader={loader}
                />
              </Fragment>
            )}
            <div className="forgot-pwd-link reset-privacy-terms">
              <a
                href="https://reccojoy.com/terms-conditions/"
                className="policy-link"
              >
                Terms of Service
              </a>
              |
              <a
                href="https://reccojoy.com/privacy-policy/"
                className="policy-link"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </Col>
        <Col
          md={{ size: 6 }}
          lg={{ size: 6 }}
          xl={{ size: 6, offset: 2 }}
          className="col-plr reset-second-div"
        >
          <img
            src={
              resetPwdDetails.isSuccessReset
                ? IMG.afterResetPwd
                : IMG.resetPwdImg
            }
            alt=""
            className="reset-side-image"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
