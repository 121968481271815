import React from "react";
import moment from "moment";
import { IMG } from "../../constants";

const WebPerkPreview = props => {
  return (
    <div className="web-perk-preview-div">
      <div className="web-perk-preview-header">Perks available!</div>
      <div className="web-perk-preview-secondary-header">
        {"<Business> has shared perks for this Recco."}
      </div>
      <div style={{ marginBottom: "30px", marginTop: "26px" }}>
        <img src={IMG.perkGiftImg} width={"100%"} />
      </div>
      <div className="perk-preview-div">
        <div
          className={!props.isSendNurturePage ? "recco-perkInfo" : "perkInfo"}
        >
          <div className="perk-preview-image">
            <img
              src={props.perkDetails?.image}
              width={props.perkDetails?.image ? 30 : 17}
              height={props.perkDetails?.image ? 46 : 21}
            />
          </div>
          <div className="perkInfo-details">
            <span
              className={
                !props.isSendNurturePage && props.type === "Web"
                  ? "recco-perkInfo-full-name"
                  : !props.isSendNurturePage
                  ? "recco-perkInfo-name"
                  : "perkInfo-name"
              }
            >
              {props.perkDetails?.name}
            </span>
            <div
              className={
                !props.isSendNurturePage
                  ? "recco-perkInfo-validity"
                  : "perkInfo-validity"
              }
            >
              VALIDITY :
              <span
                className={
                  !props.isSendNurturePage
                    ? "recco-perkInfo-date"
                    : "perkInfo-date"
                }
              >
                {" " +
                  moment(props.perkDetails.expirationDate).format("MM-DD-YYYY")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebPerkPreview;
