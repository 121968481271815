import * as actionTypes from "../actions/actionTypes";

const initialState = {};

export default (state = false, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_REMAINING_RECCO_FAM:
      return data;
    default:
      return state;
  }
};
