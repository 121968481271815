import { useState, useRef, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "reactstrap";
import S3 from "react-aws-s3";
import { useLocation } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  IMG,
  requiredFieldMessage,
  inputFieldValidaionMessage,
  internalServerError,
  invalidImgType,
  uploadFailed,
  ROUTES_PATH,
} from "../../constants";
import InputField from "../../common/component/inputField";
import { Calendar } from "../../common/component/dateInputField";
import ButtonItem from "../../common/component/button";
import {
  validateBlankSpace,
  validateURL,
  validateMessage,
  sanitizeBlankSpaces,
} from "../../utils/validation";
import { toastMessage } from "../ToastMessage";
import { createPerkUrl, updatePerkUrl } from "../../utils/urls";
import axios from "../../axios";
import "./createPerk.css";

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  dirName: "ReccoImages" /* optional */,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_REGION_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRETE_KEY,
  s3Url: process.env.REACT_APP_S3_URL,
};

const S3Client = new S3(config);

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "100px",
    borderRadius: "10px",
  },
  dialogContainer: {
    padding: "20px 105px 40px 105px",
  },
}));
const CreatePerkPopup = ({
  openModal,
  handleModal,
  businessId,
  perk,
  toggleMainLoader,
  getReccoListDetails,
  getNurtureDetails,
  getBusinessPerk,
}) => {
  const location = useLocation();
  const [isCalenderOpen, setCalenderOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [imgObject, setImgObject] = useState("");
  const [uploadedImg, setUploadedImg] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [perkDetails, setPerkDetails] = useState({
    perkName: "",
    image: "",
    promoCode: "",
    url: "",
    expiryDate: "",
    desc: "",
    legal: "",
    isValidPerkName: false,
    invalidPerkNameMsg: "",
    isValidImage: false,
    invalidImageMsg: "",
    isValidPromoCode: false,
    invalidPromoCodeMsg: "",
    isValidUrl: false,
    invalidUrlMsg: "",
    isValidExpiryDate: false,
    invalidExpiryDateMsg: "",
    isValidDesc: false,
    invalidDescMsg: "",
    isValidLegal: false,
    invalidLegalMsg: "",
  });

  const classes = useStyles();
  const imgRef = useRef(null);

  useEffect(() => {
    if (perk) {
      const PerkDetails = { ...perkDetails };
      PerkDetails.perkName = perk?.name;
      PerkDetails.promoCode = perk?.promoCode;
      PerkDetails.url = perk?.productUrl;
      PerkDetails.expiryDate = moment(perk?.expirationDate).format(
        "MM/DD/YYYY"
      );
      PerkDetails.desc = perk?.description;
      PerkDetails.legal = perk?.legalDescription;
      setPerkDetails(PerkDetails);
      setImgObject(perk?.image);
    }
  }, [perk]);

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const PerkDetails = { ...perkDetails };
    PerkDetails[input.name] = sanitizeBlankSpaces(input.value);
    PerkDetails[msgType] = "";
    PerkDetails[validationType] = false;
    setPerkDetails(PerkDetails);
    setIsUpdated(true);
  };
  const handleDateChange = (event) => {
    const PerkDetails = { ...perkDetails };
    PerkDetails["expiryDate"] = moment(event).format("MM/DD/YYYY");
    PerkDetails["invalidExpiryDateMsg"] = "";
    PerkDetails["isValidExpiryDate"] = false;
    setPerkDetails(PerkDetails);
    setIsUpdated(true);
  };

  const handleImageDataChange = (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        const PerkDetails = { ...perkDetails };
        // PerkDetails["image"] = input.value;
        PerkDetails["invalidImageMsg"] = "";
        PerkDetails["isValidImage"] = false;
        setPerkDetails(PerkDetails);
        setUploadedImg(file);
        setIsUpdated(true);
        // event.target.value = null;
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
          setImgObject([reader.result]);
        };
      } else {
        toastMessage("error", invalidImgType);
      }
    }
  };
  const handleUploadImgBtn = () => {
    imgRef.current.click();
  };

  const uploadToS3Bucket = () => {
    setLoader(true);
    if (uploadedImg !== undefined) {
      const fileName = uploadedImg.name?.split(".").slice(0, -1).join(".");
      var d = new Date();
      const newName =
        d.getDate().toString() +
        "_" +
        d.getTime().toString() +
        "_" +
        fileName.replaceAll(" ", "_");

      S3Client.uploadFile(uploadedImg, newName)
        .then((data) => {
          //   handleSelectedImg(data.location);
          handleAPICall(data.location);
        })
        .catch((err) => {
          setLoader(false);
          toastMessage("error", uploadFailed);
        });
    } else {
      setLoader(false);
    }
  };
  const handleSubmit = () => {
    const PerkDetails = { ...perkDetails };
    if (
      perkDetails.perkName === "" ||
      perkDetails.perkName === null ||
      perkDetails.perkName === undefined
    ) {
      PerkDetails.isValidPerkName = true;
      PerkDetails.invalidPerkNameMsg = requiredFieldMessage("perk name");
    } else if (!validateBlankSpace(perkDetails.perkName)) {
      PerkDetails.isValidPerkName = true;
      PerkDetails.invalidPerkNameMsg = inputFieldValidaionMessage("perk name");
    }
    if (
      perkDetails.promoCode === "" ||
      perkDetails.promoCode === null ||
      perkDetails.promoCode === undefined
    ) {
      PerkDetails.isValidPromoCode = true;
      PerkDetails.invalidPromoCodeMsg = requiredFieldMessage("promo code");
    } else if (!validateBlankSpace(perkDetails.promoCode)) {
      PerkDetails.isValidPromoCode = true;
      PerkDetails.invalidPromoCodeMsg =
        inputFieldValidaionMessage("promo code");
    }
    if (perkDetails.url && !validateURL(perkDetails.url)) {
      PerkDetails.isValidUrl = true;
      PerkDetails.invalidUrlMsg = inputFieldValidaionMessage(
        "promotion/product URL"
      );
    }

    if (
      perkDetails.expiryDate === "" ||
      perkDetails.expiryDate === null ||
      perkDetails.expiryDate === undefined
    ) {
      PerkDetails.isValidExpiryDate = true;
      PerkDetails.invalidExpiryDateMsg = requiredFieldMessage("expiry date");
    }
    if (
      perkDetails.desc === "" ||
      perkDetails.desc === null ||
      perkDetails.desc === undefined
    ) {
      PerkDetails.isValidDesc = true;
      PerkDetails.invalidDescMsg = requiredFieldMessage("description");
    } else if (perkDetails.desc && !validateMessage(perkDetails.desc)) {
      PerkDetails.isValidDesc = true;
      PerkDetails.invalidDescMsg = inputFieldValidaionMessage("description");
    }

    if (perkDetails.legal && !validateMessage(perkDetails.legal)) {
      PerkDetails.isValidLegal = true;
      PerkDetails.invalidLegalMsg =
        inputFieldValidaionMessage("legal information");
    }

    setPerkDetails(PerkDetails);

    const {
      isValidPerkName,
      isValidImage,
      isValidPromoCode,
      isValidUrl,
      isValidExpiryDate,
      isValidLegal,
      isValidDesc,
    } = PerkDetails;

    if (
      !isValidPerkName &&
      !isValidImage &&
      !isValidPromoCode &&
      !isValidUrl &&
      !isValidExpiryDate & !isValidLegal &&
      !isValidDesc
    ) {
      if (uploadedImg) {
        uploadToS3Bucket();
      } else {
        handleAPICall(imgObject);
      }
    }
  };
  const handleAPICall = (img) => {
    const payload = {
      name: perkDetails.perkName,
      productUrl: perkDetails.url,
      promoCode: perkDetails.promoCode,
      legalDescription: perkDetails.legal,
      image: img
        ? img
        : "https://recco-dev-assets.s3.amazonaws.com/ImageTemplates/giftBox.png",
      expirationDate: perkDetails.expiryDate,
      description: perkDetails.desc,
    };
    setLoader(true);
    if (_.isEmpty(perk)) {
      axios
        .post(createPerkUrl(businessId), payload)
        .then((response) => {
          if (response.status === 201) {
            setLoader(false);
            toastMessage("success", "Perk created successfully");

            toggleMainLoader(true);
            if (location.pathname.includes(ROUTES_PATH.NURTURE)) {
              getNurtureDetails(businessId);
            } else if (location.pathname.includes(ROUTES_PATH.MANAGE_PERKS)) {
              getBusinessPerk(businessId);
            } else {
              const data = {
                businessId: businessId,
                reccoListId: sessionStorage.getItem("recco-list"),
              };
              getReccoListDetails(data);
            }
            handleModal();
          }
        })
        .catch((error) => {
          setLoader(false);
          if (
            error.response?.status === 400 ||
            error.response?.status === 404
          ) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            toastMessage("error", errorMessage);
          } else {
            toastMessage("error", internalServerError);
          }
        });
    } else {
      if (isUpdated) {
        axios
          .patch(updatePerkUrl(businessId, perk?.id), payload)
          .then((response) => {
            if (response.status === 200) {
              setLoader(false);
              toastMessage("success", "Perk updated successfully");
              toggleMainLoader(true);
              if (location.pathname.includes(ROUTES_PATH.NURTURE)) {
                getNurtureDetails(businessId);
              } else if (location.pathname.includes(ROUTES_PATH.MANAGE_PERKS)) {
                getBusinessPerk(businessId);
              } else {
                const data = {
                  businessId: businessId,
                  reccoListId: sessionStorage.getItem("recco-list"),
                };
                getReccoListDetails(data);
              }
              handleModal();
            }
          })
          .catch((error) => {
            setLoader(false);
            if (
              error.response?.status === 400 ||
              error.response?.status === 404
            ) {
              const errorMessage =
                error.response.data.failures.length > 0
                  ? error.response.data.failures[0]["message"]
                  : error.response.data.message;
              toastMessage("error", errorMessage);
            } else {
              toastMessage("error", internalServerError);
            }
          });
      } else {
        setLoader(false);
        handleModal();
      }
    }
  };
  const handleRemoveImage = () => {
    setImgObject("");
    setUploadedImg("");
    setIsUpdated(true);
  };
  const handleCancel = () => {
    setPerkDetails({
      perkName: perk?.name ? perk.name : "",
      image: "",
      promoCode: perk?.promoCode ? perk.promoCode : "",
      url: perk?.productUrl ? perk.productUrl : "",
      expiryDate: perk?.expirationDate
        ? moment(perk?.expirationDate).format("MM/DD/YYYY")
        : "",
      desc: perk?.description ? perk.description : "",
      legal: perk?.legalDescription ? perk.legalDescription : "",
      isValidPerkName: false,
      invalidPerkNameMsg: "",
      isValidImage: false,
      invalidImageMsg: "",
      isValidPromoCode: false,
      invalidPromoCodeMsg: "",
      isValidUrl: false,
      invalidUrlMsg: "",
      isValidExpiryDate: false,
      invalidExpiryDateMsg: "",
      isValidDesc: false,
      invalidDescMsg: "",
      isValidLegal: false,
      invalidLegalMsg: "",
    });
    setLoader(false);
    setImgObject(perk?.image ? perk.image : "");
    setUploadedImg("");
    handleModal();
  };

  return (
    <Dialog
      scroll={"body"}
      fullWidth={true}
      maxWidth="lg"
      open={openModal}
      onClose={handleModal}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      disableBackdropClick
    >
      <div className="create-perk-heading">
        {perk ? "Update a" : "Create a New"} Perk
      </div>
      <div className={classes.dialogContainer}>
        <Row>
          <Col md={5}>
            <div className="mb-22">
              <img
                src={imgObject ? imgObject : IMG.perkIcon}
                alt=""
                onClick={handleUploadImgBtn}
                height="90px"
                width="100px"
                className="cursor-pointer"
              />
              <span
                className="upload-img-text cursor-pointer"
                onClick={() =>
                  !imgObject ||
                  imgObject ===
                    "https://recco-dev-assets.s3.amazonaws.com/ImageTemplates/giftBox.png"
                    ? handleUploadImgBtn()
                    : handleRemoveImage()
                }
              >
                {imgObject &&
                imgObject !==
                  "https://recco-dev-assets.s3.amazonaws.com/ImageTemplates/giftBox.png"
                  ? "Remove Image"
                  : "Upload Image"}
              </span>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg"
                multiple={false}
                onChange={handleImageDataChange}
                ref={imgRef}
              />
              {perkDetails.isValidImage && (
                <div className="input-error-style">
                  <img
                    src={IMG.errorIcon}
                    alt=" "
                    width="25px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {perkDetails.invalidImageMsg}
                </div>
              )}
            </div>
            <div className="mb-22">
              <InputField
                className="create-perk-input"
                labelClassName="create-perk-label"
                placeholder="e.g. 5% Off Your Entire Cart!"
                maxLength="100"
                label="Perk Name"
                name="perkName"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidPerkNameMsg", "isValidPerkName")
                }
                value={perkDetails.perkName}
                invalid={perkDetails.isValidPerkName}
                invalidMsg={perkDetails.invalidPerkNameMsg}
              />
            </div>
            <div className="mb-22">
              <InputField
                className="create-perk-input"
                labelClassName="create-perk-label"
                placeholder="e.g. RECCO2021"
                maxLength="100"
                label="Promo Code"
                name="promoCode"
                handleInputChange={(e) =>
                  handleInputChange(
                    e,
                    "invalidPromoCodeMsg",
                    "isValidPromoCode"
                  )
                }
                value={perkDetails.promoCode}
                invalid={perkDetails.isValidPromoCode}
                invalidMsg={perkDetails.invalidPromoCodeMsg}
              />
            </div>
            <div className="mb-22">
              <InputField
                className="create-perk-input"
                labelClassName="create-perk-label"
                placeholder="http://"
                maxLength="100"
                label="Include a URL to your promotion/product"
                name="url"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidUrlMsg", "isValidUrl")
                }
                value={perkDetails.url}
                invalid={perkDetails.isValidUrl}
                invalidMsg={perkDetails.invalidUrlMsg}
              />
            </div>
            <div className="mb-22">
              <Calendar
                className="create-perk-date-input"
                labelClassName="create-perk-label"
                label="Choose an expiration date"
                placeholder="Choose date"
                handleInputClick={() => {
                  setCalenderOpen(!isCalenderOpen);
                }}
                visibleState={isCalenderOpen}
                handleClick={(e) => handleDateChange(e)}
                value={perkDetails.expiryDate}
                minDate={new Date()}
                invalid={perkDetails.isValidExpiryDate}
                invalidMsg={perkDetails.invalidExpiryDateMsg}
              />
            </div>
          </Col>
          <Col md={{ size: 6, offset: 1 }}>
            <div className="col-margin" style={{ marginTop: "114px" }}>
              <InputField
                type="textarea"
                className=" perk-desc"
                labelClassName="create-perk-label"
                placeholder="Add description here."
                maxLength="3000"
                label="Description"
                name="desc"
                handleInputChange={(e) =>
                  handleInputChange(e, "isValidDesc", "invalidDescMsg")
                }
                value={perkDetails.desc}
                invalid={perkDetails.isValidDesc}
                invalidMsg={perkDetails.invalidDescMsg}
              />
            </div>
            <div>
              <InputField
                type="textarea"
                className="perk-legal"
                labelClassName="create-perk-label"
                placeholder="Put terms, conditions and any legal info here."
                maxLength="3000"
                label="Legal"
                name="legal"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidLegalMsg", "isValidLegal")
                }
                value={perkDetails.legal}
                invalid={perkDetails.isValidLegal}
                invalidMsg={perkDetails.invalidLegalMsg}
              />
            </div>
          </Col>
        </Row>
        <div className="bottom-btn-div">
          <ButtonItem
            className="cancel-button"
            buttonValue="Cancel"
            handleButtonAction={handleCancel}
          />
          <ButtonItem
            className="create-perk-popup-btn"
            buttonValue={perk ? "Update Perk" : "Create Perk"}
            handleButtonAction={handleSubmit}
            disabled={loader}
            showLoader={loader}
            // type="popup-btn"
          />
        </div>
      </div>
    </Dialog>
  );
};
export default CreatePerkPopup;
