import { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "reactstrap";
import {
  validateEmail,
  validateName,
  validateContact,
} from "../../utils/validation";
import {
  requiredFieldMessage,
  inputFieldValidaionMessage,
  IMG,
} from "../../constants";
import InputField from "../../common/component/inputField";
import PhoneInputField from "../../common/component/phoneInput";
import ButtonItem from "../../common/component/button";
import "./editCustomer.css";
import { toastMessage } from "../ToastMessage";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "100px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },
  dialogContainer: {
    padding: "40px 80px",
  },
}));

const EditCustomerPopup = ({
  openModal,
  handleModal,
  customer,
  handleUpdateAdvocate,
}) => {
  const classes = useStyles();
  const [customerDetails, setCustomerDetails] = useState({
    email: "",
    phoneNumber: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    isValidEmail: false,
    invalidEmailMsg: "",
    isValidPhoneNumber: false,
    invalidPhoneNumberMsg: "",
    isValidFirstName: false,
    invalidFirstNameMsg: "",
    isValidLastName: false,
    invalidLastNameMsg: "",
  });

  const [isUpdated, setIsUpdated] = useState(false);

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const CustomerDetails = { ...customerDetails };
    CustomerDetails[input.name] = input.value;
    CustomerDetails[msgType] = "";
    CustomerDetails[validationType] = false;
    if (input.name === "email") {
      CustomerDetails["invalidPhoneNumberMsg"] = "";
      CustomerDetails["isValidPhoneNumber"] = false;
    }
    setCustomerDetails(CustomerDetails);
    setIsUpdated(true);
  };
  const handlePhoneInput = (value, data) => {
    const CustomerDetails = { ...customerDetails };

    CustomerDetails["phoneNumber"] = value;
    CustomerDetails["countryCode"] = data.dialCode;
    CustomerDetails["invalidPhoneNumberMsg"] = "";
    CustomerDetails["isValidPhoneNumber"] = false;
    CustomerDetails["invalidEmailMsg"] = "";
    CustomerDetails["isValidEmail"] = false;
    setCustomerDetails(CustomerDetails);
    setIsUpdated(true);
  };

  useEffect(() => {
    setCustomerDetails({
      ...customerDetails,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      countryCode: customer?.phoneNumber?.countryCode,
      phoneNumber: customer?.phoneNumber?.phoneNumber
        ? customer?.phoneNumber?.countryCode +
          customer?.phoneNumber?.phoneNumber
        : "",
    });
  }, [customer]);

  const handleSubmit = () => {
    const CustomerDetails = { ...customerDetails };

    if (
      (customerDetails.phoneNumber === "" ||
        customerDetails.phoneNumber === null ||
        customerDetails.phoneNumber === undefined) &&
      (customerDetails.email === "" ||
        customerDetails.email === null ||
        customerDetails.email === undefined)
    ) {
      CustomerDetails.isValidPhoneNumber = true;
      CustomerDetails.invalidPhoneNumberMsg = requiredFieldMessage(
        "phone number or email address"
      );
      CustomerDetails.isValidEmail = true;
      CustomerDetails.invalidEmailMsg = requiredFieldMessage(
        "email address or phone number"
      );
    } else if (customerDetails.email && !validateEmail(customerDetails.email)) {
      CustomerDetails.isValidEmail = true;
      CustomerDetails.invalidEmailMsg =
        inputFieldValidaionMessage("email address");
    } else if (
      customerDetails.phoneNumber &&
      !validateContact(customerDetails.phoneNumber)
    ) {
      CustomerDetails.isValidPhoneNumber = true;
      CustomerDetails.invalidPhoneNumberMsg =
        inputFieldValidaionMessage("phone number");
    }

    if (
      customerDetails.lastName === "" ||
      customerDetails.lastName === null ||
      customerDetails.lastName === undefined
    ) {
      CustomerDetails.isValidLastName = true;
      CustomerDetails.invalidLastNameMsg = requiredFieldMessage("last name");
    } else if (!validateName(customerDetails.lastName.trim())) {
      CustomerDetails.isValidLastName = true;
      CustomerDetails.invalidLastNameMsg =
        inputFieldValidaionMessage("last name");
    }
    if (
      customerDetails.firstName === "" ||
      customerDetails.firstName === null ||
      customerDetails.firstName === undefined
    ) {
      CustomerDetails.isValidFirstName = true;
      CustomerDetails.invalidFirstNameMsg = requiredFieldMessage("first name");
    } else if (!validateName(customerDetails.firstName.trim())) {
      CustomerDetails.isValidFirstName = true;
      CustomerDetails.invalidFirstNameMsg =
        inputFieldValidaionMessage("first name");
    }

    setCustomerDetails(CustomerDetails);

    const {
      isValidPhoneNumber,
      isValidFirstName,
      isValidLastName,
      isValidEmail,
    } = CustomerDetails;

    if (
      !isValidEmail &&
      !isValidFirstName &&
      !isValidLastName &&
      !isValidPhoneNumber
    ) {
      const updatedPayload = {
        firstName: customerDetails.firstName.trim(),
        lastName: customerDetails.lastName.trim(),
        email: customerDetails.email,
        countryCode: customerDetails.countryCode
          ? customerDetails.countryCode.includes("+")
            ? customerDetails.countryCode
            : "+" + customerDetails.countryCode
          : "",
        phoneNumber: customerDetails.phoneNumber
          ? customerDetails.phoneNumber.slice(
              customerDetails.countryCode.length
            )
          : "",
      };
      if (isUpdated) {
        handleUpdateAdvocate(updatedPayload);
      }
      handleModal();
    }
  };
  const handleCancel = () => {
    setCustomerDetails({
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      countryCode: customer?.phoneNumber?.countryCode,
      phoneNumber: customer?.phoneNumber?.phoneNumber
        ? customer?.phoneNumber?.countryCode +
          customer?.phoneNumber?.phoneNumber
        : "",
      isValidEmail: false,
      invalidEmailMsg: "",
      isValidPhoneNumber: false,
      invalidPhoneNumberMsg: "",
      isValidFirstName: false,
      invalidFirstNameMsg: "",
      isValidLastName: false,
      invalidLastNameMsg: "",
    });
    handleModal();
  };
  return (
    <Dialog
      open={openModal}
      onClose={handleModal}
      fullWidth="md"
      maxWidth="md"
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      disableBackdropClick
    >
      <div className={classes.dialogContainer}>
        <div className="heading-div">
          <div className="edit-heading-text">Edit Customer Details</div>
          <img
            src={IMG.cancelIcon}
            alt="Close"
            width="29px"
            height="29px"
            onClick={handleCancel}
            className="cursor-pointer"
          />
        </div>
        <div className="edit-customer-div">
          <Row>
            <Col>
              <InputField
                className="edit-cust-input"
                placeholder="First name"
                maxLength="100"
                label="First Name"
                name="firstName"
                labelClassName="edit-cust-label"
                handleInputChange={(e) =>
                  handleInputChange(
                    e,
                    "invalidFirstNameMsg",
                    "isValidFirstName"
                  )
                }
                value={customerDetails.firstName}
                invalid={customerDetails.isValidFirstName}
                invalidMsg={customerDetails.invalidFirstNameMsg}
                errorClassName="cust-field-icon"
              />
            </Col>
            <Col>
              <InputField
                className="edit-cust-input"
                placeholder="Last name"
                maxLength="100"
                label="Last Name"
                name="lastName"
                labelClassName="edit-cust-label"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidLastNameMsg", "isValidLastName")
                }
                value={customerDetails.lastName}
                invalid={customerDetails.isValidLastName}
                invalidMsg={customerDetails.invalidLastNameMsg}
                errorClassName="cust-field-icon"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "7px" }}>
            <Col>
              <InputField
                className="edit-cust-input"
                placeholder="email@example.com"
                maxLength="100"
                label="E-mail"
                name="email"
                labelClassName="edit-cust-label"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidEmailMsg", "isValidEmail")
                }
                value={customerDetails.email}
                invalid={customerDetails.isValidEmail}
                invalidMsg={customerDetails.invalidEmailMsg}
                errorClassName="cust-field-icon"
              />
            </Col>
            <Col>
              <div
                className={
                  customerDetails.isValidPhoneNumber
                    ? "edit-cust-phone-input edit-cust-phone-input-error"
                    : "edit-cust-phone-input"
                }
              >
                <PhoneInputField
                  placeholder="xxxxxxxxxxxx"
                  label="Phone no."
                  labelClassName="edit-cust-label"
                  value={customerDetails.phoneNumber}
                  invalid={customerDetails.isValidPhoneNumber}
                  invalidMsg={customerDetails.invalidPhoneNumberMsg}
                  handleInputChange={handlePhoneInput}
                  errorClassName="edit-cust-field-icon"
                />
              </div>
            </Col>
          </Row>
          <div className="edit-done-div">
            <ButtonItem
              className="edit-done-button"
              buttonValue="Done"
              handleButtonAction={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default EditCustomerPopup;
