import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router";
import OverviewCard from "../../common/component/overviewCard";
import ReccoProgressBar from "../ReccoProgressBar";
import IconButton from "../../common/component/iconButton";
import { ROUTES_PATH, IMG } from "../../constants";
import "./nurtureFamHeading.css";

const NurtureFamHeading = ({ reccoFam, perk, remainingReccoFam }) => {
  const navigate = useNavigate();
  return (
    <div className="narture-fam-div">
      <div className="narture-fam-head-div">
        Manage your community of authentic customers.
      </div>
      <Row>
        <Col
          md={{ size: 6 }}
          lg={{ size: 4 }}
          xl={{ size: 3 }}
          style={{ margin: "30px 0px" }}
        >
          <OverviewCard
            data={{
              count: reccoFam.totalAdvocates,
              title: "Recco Fam",
              subTitle: "",
              percentage: reccoFam.changesThisMonth,
            }}
          />
        </Col>
        <Col md={{ size: 6 }} lg={4} xl={{ size: 3, offset: 1 }}>
          <div className="perk-perform-heading">Current Perk Performance</div>
          <OverviewCard
            data={{
              count: perk ? perk?.count : 0,
              title: "Perks Viewed",
              subTitle: "",
            }}
          />
        </Col>
        <Col
          md={12}
          lg={3}
          xl={{ size: 4, offset: 1 }}
          style={{ marginTop: "20px" }}
          className="recco-progressbar-responsive"
        >
          {remainingReccoFam.reccoFamSize <= 0 ? (
            <>
              <div className="input-error-style">
                Your Fam Size Limit exceeded. Your current plan allows for only{" "}
                {remainingReccoFam?.allowedReccoFam} recco fam's.
              </div>
              <IconButton
                className="upgrade-plan-button"
                buttonValue="Upgrade Plan"
                type="upload"
                icon={IMG.upgradePlan}
                handleButtonAction={() =>
                  navigate(ROUTES_PATH.UPDATE_SUBSCRIPTION_PLAN)
                }
              />
            </>
          ) : (
            <>
              <ReccoProgressBar
                remainingReccos={{
                  reccosRemaining: remainingReccoFam.reccoFamSize,
                  reccoAllowed: remainingReccoFam?.allowedReccoFam,
                }}
              />
              <div className="fam-size-text">Your Fam Size</div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default NurtureFamHeading;
