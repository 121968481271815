export const ROUTES_PATH = {
  LOGIN: "/login",
  SIGNUP: "/sign-up",
  ADD_DETAILS: "/add-user-details",
  RESET_PASSWORD: "/setup-password",
  FORGOT_PASSWORD: "/reset-password",
  HOME: "/home",
  SELECT_SUBSCRIPTION_PLAN: "/select-subscription-plan",
  EMAIL_VERIFY: "/verify-email",
  GET_RECCO: "/get-recco",
  NURTURE: "/nurture",
  MANAGE_PERKS: "/manage-perks",
  GET_INSIGHTS: "/dashboard",
  RECCO_LIST: "/get-recco/recco-list",
  ADD_INFO: "/get-recco/add-info",
  ADD_PERK: "/get-recco/add-perk",
  SEND_RECCO: "/get-recco/send-get-recco",
  NURTURE_ADD_INFO: "/nurture/add-info",
  NURTURE_ADD_PERK: "/nurture/add-perk",
  SEND_NURTURE: "/nurture/send-nurture",
  SETTINGS: "/settings",
  UPDATE_SUBSCRIPTION_PLAN: "/update-subscription-plan",
};
