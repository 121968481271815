import React, { useState, useRef, useMemo, useEffect } from "react";
import { Row, Col, Label } from "reactstrap";
import countryList from "react-select-country-list";
import AccountInputField from "../../common/component/accountInputField";
import AccountPhoneInputField from "../../common/component/accountPhoneInput";
import ButtonItem from "../../common/component/button";
import BottomButtons from "../../components/BottomButtons";
import {
  IMG,
  invalidImgType,
  inputFieldValidaionMessage,
  requiredFieldMessage,
  internalServerError,
} from "../../constants";
import { toastMessage } from "../../components/ToastMessage";
import {
  validateBusinessName,
  validateContact,
  validateName,
  validateEmail,
} from "../../utils/validation";
import CountrySelector from "../../common/component/countrySelector";
import OtpModal from "../../components/otp-modal";
import {
  createBusinessResourceService,
  updateProfileDetailsService,
} from "../../services";
import _ from "lodash";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "150px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },

  heading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "120%",
    padding: "40px 20px 24px 20px",
    letterSpacing: "-0.01em",
    color: "#323232",
    borderBottom: "1px solid #EBEBEB",
  },
  dialogContainer: {
    padding: "24px 50px 30px 50px",
    backgroundColor: "#fff",
    font: "inter",
  },
  bodyContent: {},
  cancelButton: {
    backgroundColor: "transparent !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2963CA !important",
    height: "50px",
    width: "102px",
    marginRight: "12px",
  },
  skipBtn: {
    backgroundColor: "transparent !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2963CA !important",
    height: "50px",
    width: "176px",
    marginRight: "12px",
    marginRight: "20px",
  },
  buttonStyle: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff !important",
    height: "50px",
    width: "202px",
  },
  searchBar: {
    maxLength: 500,
    width: "99%",
    background: "#F5F5F5",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px 0px 10px 10px",
    padding: 10,
  },
  modalHeading: {
    background: "linear-gradient(91.45deg, #fafafa 35.93%, #f1f1f1 73.39%)",
    borderRadius: "8px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
    color: "#1a1a1a",
    padding: "30px 40px",
  },
}));

const SettingsProfileInfo = ({ user, getUserDetails, toggleMainLoader }) => {
  const uploadImgRef = useRef(null);
  const uploadBusinessLogoRef = useRef(null);
  const [fileObject, setFileObject] = useState(null);
  const [businessFileObject, setBusinessFileObject] = useState(null);
  const [country, setCountry] = useState(null);
  const options = useMemo(() => countryList().getLabels(), []);
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isProfileChange, setProfileChange] = useState(false);
  const [otpDialog, setOtpDialog] = useState(false);
  const classes = useStyles();

  let localBusinessIndex = localStorage.getItem("businessIndex")
    ? parseInt(localStorage.getItem("businessIndex"))
    : 0;

  const [signupDetails, setSignupDetails] = useState({
    businessName: "",
    phoneNumber: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    description: "",
    email: "",
    isValidBusinessName: false,
    invalidBusinessNameMsg: "",
    isValidPhoneNumber: false,
    invalidPhoneNumberMsg: "",
    isValidFirstName: false,
    invalidFirstNameMsg: "",
    isValidLastName: false,
    invalidLastNameMsg: "",
    isValidEmail: false,
    invalidEmailMsg: "",
  });

  useEffect(() => {
    setSignupDetails({
      ...signupDetails,
      businessName: user?.business[localBusinessIndex].businessForAccount.businessName,
      description: user?.business[localBusinessIndex].businessForAccount.description,
      firstName: user?.account?.firstName,
      lastName: user?.account?.lastName,
      email: user?.account?.email,
      countryCode: user?.account?.phoneNumber?.countryCode,
      phoneNumber: user?.account?.phoneNumber?.phoneNumber
        ? user?.account?.phoneNumber?.countryCode +
          user?.account?.phoneNumber?.phoneNumber
        : "",
    });

    setCountry(
      user?.business[localBusinessIndex].businessForAccount.country
        ? {
            value: user?.business[localBusinessIndex].businessForAccount.country,
            label: user?.business[localBusinessIndex].businessForAccount.country,
          }
        : null
    );
    setFileObject(user?.account?.profilePictureResource);
    setBusinessFileObject(user?.business[localBusinessIndex].businessForAccount.resource);
  }, [user]);

  const handleCountryChange = (val) => {
    setCountry(val);
    setDisabled(false);
  };
  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const SignupDetails = { ...signupDetails };
    SignupDetails[input.name] = input.value;
    SignupDetails[msgType] = "";
    SignupDetails[validationType] = false;
    setSignupDetails(SignupDetails);
    setDisabled(false);
  };

  const handlePhoneInput = (value, data) => {
    const SignupDetails = { ...signupDetails };
    SignupDetails["phoneNumber"] = value;
    SignupDetails["countryCode"] = data.dialCode;
    SignupDetails["invalidPhoneNumberMsg"] = "";
    SignupDetails["isValidPhoneNumber"] = false;
    setSignupDetails(SignupDetails);

    setDisabled(false);
  };
  const handleUploadImgBtn = () => {
    uploadImgRef.current.click();
  };

  const handleUploadBusinessLogoBtn = () => {
    uploadBusinessLogoRef.current.click();
  };

  const handleImageDataChange = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      if (file.type.split("/")[0] === "image") {
        var reader = new FileReader();

        reader.onloadend = function (e) {
          setFileObject({ url: reader.result, file: file });
        };
        reader.readAsDataURL(file);
        setProfileChange(true);
        setDisabled(false);
      } else {
        toastMessage("error", invalidImgType);
        event.target.value = null;
      }
    }
  };

  const handleBusinessLogoDataChange = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      if (file.type.split("/")[0] === "image") {
        var reader = new FileReader();

        reader.onloadend = function (e) {
          setBusinessFileObject({ url: reader.result, file: file });
        };
        reader.readAsDataURL(file);
        setProfileChange(true);
        setDisabled(false);
      } else {
        toastMessage("error", invalidImgType);
        event.target.value = null;
      }
    }
  };

  const handleRemoveImage = () => {
    setFileObject(null);
    setDisabled(false);
    setProfileChange(true);
  };

  const handleRemoveBusinessLogo = () => {
    setBusinessFileObject(null);
    setDisabled(false);
    setProfileChange(true);
  };

  const createResourceAPICall = (payload) => {
    let formData = new FormData();
    formData.append("file", fileObject.file);

    createBusinessResourceService(user.account.id, formData)
      .then((response) => {
        if (response.status === 201) {
          payload["profilePictureResource"] = response.data.id;
          handleUpdateAPICall(payload);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const createBusinessResourceAPICall = (payload) => {
    let formData = new FormData();
    formData.append("file", businessFileObject.file);

    createBusinessResourceService(user.account.id, formData)
      .then((response) => {
        if (response.status === 201) {
          payload["resource"] = response.data.id;
          handleUpdateAPICall(payload);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const handleUpdateAPICall = (payload) => {
    const data = {
      businessId: user?.business[localBusinessIndex]?.businessForAccount.id,
      accountId: user.account.id,
    };

    updateProfileDetailsService(data, payload)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setDisabled(true);
          toastMessage("success", "Account information updated successfully");
          toggleMainLoader(true);
          getUserDetails("userDetails", user.accountId);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleSubmit = async () => {
    const SignupDetails = { ...signupDetails };
    if (
      signupDetails.businessName === "" ||
      signupDetails.businessName === null ||
      signupDetails.businessName === undefined
    ) {
      SignupDetails.isValidBusinessName = true;
      SignupDetails.invalidBusinessNameMsg =
        requiredFieldMessage("business name");
    } else if (!validateBusinessName(signupDetails.businessName.trim())) {
      SignupDetails.isValidBusinessName = true;
      SignupDetails.invalidBusinessNameMsg =
        inputFieldValidaionMessage("business name");
    }
    if (
      signupDetails.email === "" ||
      signupDetails.email === null ||
      signupDetails.email === undefined
    ) {
      SignupDetails.isValidEmail = true;
      SignupDetails.invalidEmailMsg = requiredFieldMessage("email address");
    } else if (!validateEmail(signupDetails.email)) {
      SignupDetails.isValidEmail = true;
      SignupDetails.invalidEmailMsg =
        inputFieldValidaionMessage("email address");
    }
    if (
      signupDetails.lastName === "" ||
      signupDetails.lastName === null ||
      signupDetails.lastName === undefined
    ) {
      SignupDetails.isValidLastName = true;
      SignupDetails.invalidLastNameMsg = requiredFieldMessage("last name");
    } else if (!validateName(signupDetails.lastName.trim())) {
      SignupDetails.isValidLastName = true;
      SignupDetails.invalidLastNameMsg =
        inputFieldValidaionMessage("last name");
    }
    if (
      signupDetails.firstName === "" ||
      signupDetails.firstName === null ||
      signupDetails.firstName === undefined
    ) {
      SignupDetails.isValidFirstName = true;
      SignupDetails.invalidFirstNameMsg = requiredFieldMessage("first name");
    } else if (!validateName(signupDetails.firstName.trim())) {
      SignupDetails.isValidFirstName = true;
      SignupDetails.invalidFirstNameMsg =
        inputFieldValidaionMessage("first name");
    }
    if (
      signupDetails.phoneNumber === "" ||
      signupDetails.phoneNumber === null ||
      signupDetails.phoneNumber === undefined
    ) {
      SignupDetails.isValidPhoneNumber = true;
      SignupDetails.invalidPhoneNumberMsg =
        requiredFieldMessage("phone number");
    } else if (signupDetails.phoneNumber.length < 11) {
      SignupDetails.isValidPhoneNumber = true;
      SignupDetails.invalidPhoneNumberMsg =
        inputFieldValidaionMessage("phone number");
    } else if (!validateContact(signupDetails.phoneNumber)) {
      SignupDetails.isValidPhoneNumber = true;
      SignupDetails.invalidPhoneNumberMsg =
        inputFieldValidaionMessage("phone number");
    }

    setSignupDetails(SignupDetails);

    const {
      isValidPhoneNumber,
      isValidFirstName,
      isValidLastName,
      isValidBusinessName,
      isValidEmail,
    } = SignupDetails;

    if (
      !isValidEmail &&
      !isValidBusinessName &&
      !isValidFirstName &&
      !isValidLastName &&
      !isValidPhoneNumber
    ) {
      const payload = {
        ...(signupDetails.businessName !==
          user?.business[localBusinessIndex]?.businessForAccount.businessName && {
          businessName: signupDetails.businessName.trim(),
        }),
        ...(signupDetails.description !==
          user?.business[localBusinessIndex]?.businessForAccount.description && {
          description: signupDetails.description,
        }),
        ...(signupDetails.firstName.trim() !== user?.account.firstName && {
          firstName: signupDetails.firstName.trim(),
        }),
        ...(signupDetails.lastName.trim() !== user?.account.lastName && {
          lastName: signupDetails.lastName.trim(),
        }),
        ...(signupDetails.email !== user?.account.email && {
          email: signupDetails.email,
        }),
        ...(signupDetails.countryCode !==
          user?.account?.phoneNumber?.countryCode &&
          signupDetails.phoneNumber !==
            user?.account?.phoneNumber?.phoneNumber && {
            countryCode: signupDetails.countryCode
              ? signupDetails.countryCode.includes("+")
                ? signupDetails.countryCode
                : "+" + signupDetails.countryCode
              : "",
            phoneNumber: signupDetails.phoneNumber
              ? signupDetails.phoneNumber.slice(
                  signupDetails.countryCode.length
                )
              : "",
          }),
        ...(country !== null &&
          country.value !== user?.business[localBusinessIndex]?.businessForAccount.country && {
            country: country.value,
          }),
        ...(fileObject === null &&
          user?.account?.profilePictureResource !== fileObject && {
            profilePictureResource: null,
          }),
        ...(businessFileObject === null &&
          user?.business[localBusinessIndex]?.businessForAccount.resource !==
            businessFileObject && {
            resource: null,
          }),
      };

      if (_.isEmpty(payload) && !isProfileChange) {
        toastMessage("success", "Account information updated successfully");
        setDisabled(true);
      } else {
        setLoader(true);

        let profilePicUpdated = false;
        let businessLogoUpdated = false;

        // Update profile picture.
        if (
          fileObject &&
          !_.isEqual(user?.account?.profilePictureResource, fileObject)
        ) {
          profilePicUpdated = true;
          createResourceAPICall(payload);
        }

        // Update business logo.
        if (
          businessFileObject &&
          !_.isEqual(
            user?.business[localBusinessIndex]?.businessForAccount.resource,
            businessFileObject
          )
        ) {
          businessLogoUpdated = true;
          createBusinessResourceAPICall(payload);
        }

        if (!profilePicUpdated && !businessLogoUpdated) {
          handleUpdateAPICall(payload);
        }
      }
    }
  };
  const handleCancel = () => {
    setSignupDetails({
      businessName: user?.business[localBusinessIndex]?.businessForAccount.businessName,
      description:
        user?.business[localBusinessIndex]?.businessForAccount.description === null
          ? ""
          : user?.business[localBusinessIndex]?.businessForAccount.description,
      firstName: user?.account?.firstName,
      lastName: user?.account?.lastName,
      email: user?.account?.email,
      countryCode: user?.account?.phoneNumber?.countryCode,
      phoneNumber: user?.account?.phoneNumber?.phoneNumber
        ? user?.account?.phoneNumber?.countryCode +
          user?.account?.phoneNumber?.phoneNumber
        : "",
      isValidBusinessName: false,
      invalidBusinessNameMsg: "",
      isValidPhoneNumber: false,
      invalidPhoneNumberMsg: "",
      isValidFirstName: false,
      invalidFirstNameMsg: "",
      isValidLastName: false,
      invalidLastNameMsg: "",
      isValidEmail: false,
      invalidEmailMsg: "",
    });
    setFileObject(user?.account?.profilePictureResource);
    setBusinessFileObject(user?.business[localBusinessIndex].businessForAccount.resource);
    setCountry(
      user?.business[localBusinessIndex]?.businessForAccount.country
        ? {
            value: user?.business[localBusinessIndex]?.businessForAccount.country,
            label: user?.business[localBusinessIndex]?.businessForAccount.country,
          }
        : null
    );
    setDisabled(true);
  };

  const closeModal = () => {
    setOtpDialog(!otpDialog);
  };

  return (
    <div className="settings-main-div">
      <div className="settings-div">
        <div className="setting-personal-info-header">Business information</div>
        <div style={{ width: "100%", marginBottom: "13px" }}>
          <Row>
            <Col md={4}>
              <Label className={"create-perk-label"}>Business Logo</Label>
            </Col>
            <Col md={8} className={"upload-image-col"}>
              {businessFileObject ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <img
                    src={businessFileObject.url}
                    height={181}
                    width={181}
                    className="settings-profile-pic"
                  />
                  <div>
                    <ButtonItem
                      className="remove-button"
                      buttonValue="Remove"
                      handleButtonAction={handleRemoveBusinessLogo}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="upload-image-div">
                    <img src={IMG.emptyImage} height={27} width={27} />
                  </div>
                  <div>
                    <ButtonItem
                      className="upload-button"
                      buttonValue={
                        <div>
                          <img
                            src={IMG.uploadImageIcon}
                            width={20}
                            height={13}
                          />{" "}
                          Upload
                        </div>
                      }
                      handleButtonAction={handleUploadBusinessLogoBtn}
                    />
                  </div>
                </>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                multiple={false}
                ref={uploadBusinessLogoRef}
                onChange={handleBusinessLogoDataChange}
              />
            </Col>
          </Row>
        </div>
        <div style={{ width: "100%", marginBottom: "16px" }}>
          <AccountInputField
            errorClassName="account-field-icon"
            className="setting-input-box"
            placeholder="Business"
            maxLength="100"
            label="Business Name"
            name="businessName"
            handleInputChange={(e) =>
              handleInputChange(
                e,
                "invalidBusinessNameMsg",
                "isValidBusinessName"
              )
            }
            value={signupDetails.businessName}
            invalid={signupDetails.isValidBusinessName}
            invalidMsg={signupDetails.invalidBusinessNameMsg}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "16px" }}>
          <AccountInputField
            type="textarea"
            className="setting-input-area"
            labelClassName="create-perk-label"
            placeholder="Type something"
            maxLength="3000"
            label="Description"
            name="description"
            handleInputChange={(e) => handleInputChange(e, "", "")}
            value={signupDetails.description}
          />
        </div>
        {user?.account?.hasConsumer && !user?.account?.isConsumerLinked && (
          <div>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col md={4}></Col>
              <Col md={8}>
                <ButtonItem
                  className="save-continue-button"
                  style={{ maxWidth: 300, marginTop: 10 }}
                  buttonValue="Connect with Customer Account"
                  handleButtonAction={closeModal}
                />
              </Col>
            </Row>
          </div>
        )}
        <br />
        <br />
        <div className="setting-personal-info-header">Personal information</div>
        <div className="setting-personal-info-secondary-header">
          Use a permanent address where you can receive mail.
        </div>
        <div style={{ width: "100%", marginBottom: "13px" }}>
          <Row>
            <Col md={4}>
              <Label className={"create-perk-label"}>Profile picture</Label>
            </Col>
            <Col md={8} className={"upload-image-col"}>
              {fileObject ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <img
                    src={fileObject.url}
                    height={181}
                    width={181}
                    className="settings-profile-pic"
                  />
                  <div>
                    <ButtonItem
                      className="remove-button"
                      buttonValue="Remove"
                      handleButtonAction={handleRemoveImage}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="upload-image-div">
                    <img src={IMG.emptyImage} height={27} width={27} />
                  </div>
                  <div>
                    <ButtonItem
                      className="upload-button"
                      buttonValue={
                        <div>
                          <img
                            src={IMG.uploadImageIcon}
                            width={20}
                            height={13}
                          />{" "}
                          Upload
                        </div>
                      }
                      handleButtonAction={handleUploadImgBtn}
                    />
                  </div>
                </>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                multiple={false}
                ref={uploadImgRef}
                onChange={handleImageDataChange}
              />
            </Col>
          </Row>
        </div>
        <div style={{ width: "100%", marginBottom: "16px" }}>
          <AccountInputField
            errorClassName="account-field-icon"
            className="setting-input-box"
            placeholder="Type something"
            maxLength="100"
            label="First name"
            name="firstName"
            handleInputChange={(e) =>
              handleInputChange(e, "invalidFirstNameMsg", "isValidFirstName")
            }
            value={signupDetails.firstName}
            invalid={signupDetails.isValidFirstName}
            invalidMsg={signupDetails.invalidFirstNameMsg}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "16px" }}>
          <AccountInputField
            errorClassName="account-field-icon"
            className="setting-input-box"
            placeholder="Type something"
            maxLength="100"
            label="Last name"
            name="lastName"
            handleInputChange={(e) =>
              handleInputChange(e, "invalidLastNameMsg", "isValidLastName")
            }
            value={signupDetails.lastName}
            invalid={signupDetails.isValidLastName}
            invalidMsg={signupDetails.invalidLastNameMsg}
          />
        </div>
        <Dialog
          open={otpDialog}
          onClose={closeModal}
          maxWidth="md"
          fullWidth="md"
          scroll={"body"}
          classes={{
            scrollPaper: classes.topScrollPaper,
            paperScrollBody: classes.topPaperScrollBody,
          }}
        >
          <OtpModal closeModal={closeModal} localBusinessIndex={localBusinessIndex} user={user} />
        </Dialog>
        <div
          className={
            signupDetails.isValidPhoneNumber
              ? "phone-input phone-input-error"
              : "phone-input"
          }
          style={{ width: "100%", marginBottom: "16px" }}
        >
          <AccountPhoneInputField
            errorClassName="account-field-icon"
            placeholder="xxxxxxxxxxxx"
            label="Phone"
            name="phone"
            value={signupDetails.phoneNumber}
            invalid={signupDetails.isValidPhoneNumber}
            invalidMsg={signupDetails.invalidPhoneNumberMsg}
            handleInputChange={handlePhoneInput}
            className="setting-input-box"
          />
        </div>

        <div style={{ width: "100%", marginBottom: "16px" }}>
          <AccountInputField
            errorClassName="account-field-icon"
            className="setting-input-box"
            placeholder="email@example.com"
            maxLength="100"
            label="Email address"
            name="email"
            disabled={true}
            handleInputChange={(e) =>
              handleInputChange(e, "invalidEmailMsg", "isValidEmail")
            }
            value={signupDetails.email}
            invalid={signupDetails.isValidEmail}
            invalidMsg={signupDetails.invalidEmailMsg}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "16px" }}>
          <CountrySelector
            onChange={(value) => handleCountryChange(value)}
            value={country}
            name="country"
            options={options}
          />
        </div>
      </div>
      <BottomButtons
        btnName1="Cancel"
        btnName2="Save my profile"
        handleSaveBtn={() => handleSubmit()}
        loader={loader}
        handleBackBtn={handleCancel}
        btn2Disabled={disabled}
      />
    </div>
  );
};

export default SettingsProfileInfo;
