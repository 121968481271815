import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ButtonItem from "../../common/component/button";
import { IMG, ROUTES_PATH } from "../../constants";
import "./reccoNew.css";
const reccoNewSteps = [
  {
    heading: "Connect with existing advocates",
    subHeading: "Populate your Recco community with existing customers",
    img: IMG.stepOneImg,
  },
  {
    heading: "Get Reccos",
    subHeading:
      "Provide incentives for existing communities to advocate on your behalf",
    img: IMG.stepTwoImg,
  },
  {
    heading: "Nurture the connection",
    subHeading:
      "Build relationships with customers through community-driven marketing",
    img: IMG.stepThreeImg,
  },
];
const GetReccoNew = ({ setStatus }) => {
  const navigate = useNavigate();
  const handleBtnClick = () => {
    localStorage.setItem("isFirstRecco", false);
    setStatus();
    navigate(ROUTES_PATH.GET_RECCO);
  };
  return (
    <Row>
      <Col md={5}>
        <div className="recco-pages-heading">Share the Love!</div>
        <div className="recco-pages-sub-heading">
          Show existing customers appreciation to help build community.
        </div>
        {reccoNewSteps.map((steps, index) => (
          <div
            style={{
              display: "flex",
              marginTop: index === 0 ? "53px" : "40px",
            }}
            key={index}
          >
            <div style={{ marginRight: "23px" }}>
              <img src={steps.img} alt="1." height="73px" width="48px" />
            </div>
            <div>
              <div className="recco-steps-heading">{steps.heading}</div>
              <div className="recco-steps-sub-heading">{steps.subHeading}</div>
            </div>
          </div>
        ))}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonItem
            className="recco-new-button"
            buttonValue="Lets Go Recco !"
            handleButtonAction={handleBtnClick}
          />
        </div>
      </Col>
      <Col md={{ size: 7, offset: 0 }}>
        <img src={IMG.reccoNewImg} alt="" width="100%" height="100%" />
      </Col>
    </Row>
  );
};
export default GetReccoNew;
