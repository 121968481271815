import { put, call } from "redux-saga/effects";
import * as serviceCreators from "../../services";
import * as actionCreators from "../actions";
import { loadState } from "../../utils/localStorage";
import { toastMessage } from "../../components/ToastMessage";
import { planSubscribed } from "../../constants";

export function* getAccountDetails(data) {
  const state = loadState();
  const { user } = state;
  try {
    const resp = yield call(
      serviceCreators.getAccountDetailsService,
      data.accountId
    );
    if (resp.status === 200 || resp.status === 201) {
      let userDetails;
      if (data.tokenDetails) {
        userDetails = { ...user, ...resp.data, ...data.tokenDetails };
      } else {
        userDetails = { ...user, ...resp.data };
      }
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveAccountDetails(resp.data));
      yield put(actionCreators.loginSuccess(userDetails));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getUserDetails(data) {
  const state = loadState();

  const { user } = state;
  const businessIndex = localStorage.getItem('businessIndex');
  let businessId = null;

  if (user?.business?.length) {
    businessId = businessIndex ? user.business[businessIndex].businessForAccount.id : user.business[0].businessForAccount.id;
  }
  
  try {
    const accountId = data.data ? data.data : user.account.id;
    const resp = yield call(serviceCreators.getUserDetailsService, accountId, businessId);

    const res = {
      account: resp.accountResponse.data,
      business: resp.businessResponse.data,
      subscription: resp.subscriptionResponse.data,
    };

    if (
      (resp.accountResponse.status === 200 ||
        resp.accountResponse.status === 201) &&
      (resp.businessResponse.status === 200 ||
        resp.businessResponse.status === 201)
    ) {
      let userDetails;
      if (data.cb) {
        userDetails = { ...user, ...res, ...data.cb };
      } else {
        userDetails = { ...user, ...res };
      }

      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveUserDetails(res));
      yield put(actionCreators.loginSuccess(userDetails));
      if (data.action === "subscription") {
        yield put(actionCreators.toggleSecondLoader(false));
        yield put(actionCreators.toggleSubscribeLoader(false));
        toastMessage("success", planSubscribed);
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.toggleSecondLoader(false));
      yield put(actionCreators.toggleSubscribeLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.toggleSecondLoader(false));
    yield put(actionCreators.toggleSubscribeLoader(false));
  }
}
export function* getBusinessDetails(data) {
  const state = loadState();
  const { user } = state;
  try {
    const resp = yield call(
      serviceCreators.getBusinessDetailsService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      let userDetails;
      if (data.cb) {
        userDetails = { ...user, ...resp.data, ...data.cb };
      } else {
        userDetails = { ...user, ...resp.data };
      }
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveBusinessDetails(resp.data));
      yield put(actionCreators.loginSuccess(userDetails));
      if (data.action === "subscription") {
        yield put(actionCreators.toggleSecondLoader(false));
        toastMessage("success", planSubscribed);
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.toggleSecondLoader(false));
  }
}
export function* getSubscriptionDetails(data) {
  const state = loadState();
  const { user } = state;
  try {
    const resp = yield call(
      serviceCreators.getSubscriptionDetailsService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      let userDetails;
      if (data.cb) {
        userDetails = { ...user, ...resp.data, ...data.cb };
      } else {
        userDetails = { ...user, ...resp.data };
      }
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveSubscriptionDetails(resp.data));
      yield put(actionCreators.loginSuccess(userDetails));
      if (data.action === "subscription") {
        yield put(actionCreators.toggleSecondLoader(false));
        toastMessage("success", planSubscribed);
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    yield put(actionCreators.toggleSecondLoader(false));
  }
}
