import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../utils/store";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer
            enableMultiContainer
            containerId={"root"}
            hideProgressBar={true}
            newestOnTop={false}
            autoClose={8000}
          />
          <ToastContainer
            enableMultiContainer
            containerId={"email-verify"}
            hideProgressBar={true}
            newestOnTop={false}
            autoClose={8000}
            className="verify-popup-container"
          />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default Root;
