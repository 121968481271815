import { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import { ROUTES_PATH } from "../../constants";
import { useNavigate } from "react-router-dom";
import "./subscriptionHeader.css";
import ConfirmationPopup from "../../common/component/confirmationPopup";

const SubscriptionHeader = (props) => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const handleLogout = () => {
    props.logoutSuccess();
    navigate(ROUTES_PATH.LOGIN);
  };

  return (
    <Fragment>
      <ConfirmationPopup
        openModal={openLogoutModal}
        handleModal={() => setOpenLogoutModal(!openLogoutModal)}
        handleBtnClick={handleLogout}
        showLoader={loader}
        subText="Are you sure you want to logout?"
        heading="Logout"
        btnValue="Logout"
      />
      <Row className="row-mlr">
        <Col
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
          xl={{ size: 6, offset: 3 }}
          className="subscription-header-div"
        >
          <div className="logout-text">
            <span
              className="cursor-pointer"
              onClick={() => setOpenLogoutModal(!openLogoutModal)}
            >
              Logout
            </span>
          </div>
          <div className="subscription-head-text">
            Welcome to Recco ! Great to see you here.
          </div>
          <div className="subscription-subheading">
            Begin by choosing a plan for your Business. Upgrade to better plans
            as you grow your trusted customers to suit your business.
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default SubscriptionHeader;
