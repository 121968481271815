import { useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { IMG, ROUTES_PATH, tipsArray } from "../../constants";
import AddAdvocateBtn from "../../common/component/addAdvocateBtn";
import AddAdvocatesPopup from "../AddAdvocates";
import UpgradePlanPopup from "../../components/UpgradePlanPopup";
import "./homeHeader.css";

const HomeHeader = (props) => {
  const navigate = useNavigate();
  const [isAddAdvocatesOpen, setAddAdvocatesOpen] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);

  const handleAddAdvocatesModel = () => {
    setAddAdvocatesOpen(!isAddAdvocatesOpen);
  };
  const handleUpgradePlanModal = () => {
    setUpgradePlanModal(!upgradePlanModal);
  };
  return (
    <Row className="home-header-div row-mlr">
      <AddAdvocatesPopup
        openModal={isAddAdvocatesOpen}
        handleModal={handleAddAdvocatesModel}
        businessId={
          props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
        }
        accountId={props.user.accountId}
        resetState={() => navigate(ROUTES_PATH.GET_RECCO)}
        remainingReccos={props.remainingRecco?.reccosRemaining}
        type="home"
        {...props}
      />
      <UpgradePlanPopup
        openModal={upgradePlanModal}
        handleModal={handleUpgradePlanModal}
        allowedValues={props.remainingRecco.reccoAllowed}
      />
      <Col className="col-plr" md={4} xl={4}>
        <div className="header-text">Welcome Back!</div>
        <div className="sub-heading">
          Super fans are waiting to engage. Let’s help them.
        </div>
        <AddAdvocateBtn
          className="advocates-button-input"
          buttonValue="Add Customers"
          handleButtonAction={handleAddAdvocatesModel}
          disabled={props.remainingRecco?.reccosRemaining <= 0 ? true : false}
        />
        {props.remainingRecco?.reccosRemaining <= 0 && (
          <div className="input-error-style" style={{ marginLeft: "15px" }}>
            Reccos Limit exceeded.
            <span
              style={{
                color: "rgba(22, 117, 204, 1)",
                marginLeft: "10px",
              }}
              className="cursor-pointer"
              onClick={() => handleUpgradePlanModal()}
            >
              Upgrade Plan
            </span>
          </div>
        )}
      </Col>
      <Col
        md={{ size: 1, offset: 1 }}
        xl={{ size: 1, offset: 2 }}
        style={{ padding: "65px 15px 0px 15px" }}
      >
        <div className="vertical-line"></div>
      </Col>
      <Col className="col-plr" md={{ size: 6 }} xl={{ size: 5 }}>
        <div className="tips-header">Tips for enhancing your connections</div>

        <div style={{ display: "flex" }}>
          {tipsArray.map((row, index) => (
            <div className="tips-row-div" key={index}>
              {row.map((tips, i) => {
                return (
                  <div
                    className={index === 1 ? "tips-div mrl-35" : "tips-div"}
                    key={i}
                  >
                    <img src={IMG.logoMark} alt="" height="40px" width="40px" />
                    <div className="tips-text">{tips}</div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};
export default HomeHeader;
