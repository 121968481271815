import React from "react";
import { toast } from "react-toastify";
import { IMG } from "../../constants";
import "./toaster.css";

export function toastMessage(type, toastMessage) {
  switch (type) {
    case "error":
      return toast.error(
        <div className="display-flex" style={{ padding: "10px" }}>
          <img
            style={{ marginRight: "10px" }}
            src={IMG.toastErrorIcon}
            alt="icon"
          />
          <div>{toastMessage}</div>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-error",
          autoClose: 3000,
          icon: false,
          containerId: "root",
        }
      );
    case "success":
      return toast.success(
        <div className="display-flex" style={{ padding: "10px" }}>
          <img
            style={{ marginRight: "10px" }}
            src={IMG.successIcon}
            alt="icon"
          />
          <div>{toastMessage}</div>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-success",
          autoClose: 3000,
          icon: false,
          containerId: "root",
        }
      );
  }
}
