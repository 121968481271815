import * as d3 from "d3";
import { useEffect, useState } from "react";
import * as venn from "venn.js";
import { formatNumber } from "../../utils/commonMethods";

var chart = venn.VennDiagram().wrap(true);
const VennDiagram = ({ tam, reccoFam }) => {
  const [sets, setSets] = useState([]);

  useEffect(() => {
    const sets = [
      {
        sets: ["TAM"],
        size: tam,
        label: "TAM\n" + formatNumber(tam),
      },
      {
        sets: ["RECCO FAM"],
        size: reccoFam,
        label: "RECCO FAM\n" + formatNumber(reccoFam),
      },
      { sets: ["TAM", "RECCO FAM"], size: reccoFam },
    ];
    setSets(sets);
  }, [tam, reccoFam]);

  useEffect(() => {
    d3.select("#venn").datum(sets).call(chart);
    var colours = ["#F0F0F0", "#FCB030"];
    var textColours = ["#000000", "#FFFFFF"];
    d3.selectAll("#venn .venn-circle path")
      .style("fill-opacity", 1)
      .style("stroke-width", 5)
      .style("stroke", "#ffffff")
      .style("stroke-opacity", 1)
      .style("fill", function (d, i) {
        return colours[i];
      });

    d3.selectAll("#venn text")
      .style("font-family", "Inter")
      .style("overflow-wrap", "break-word")
      .style("white-space", "normal")
      .style("fill", function (d, i) {
        return textColours[i];
      });

    // const textLabels = d3.selectAll("#venn .label"); // get all Venn diagram labels
    // console.log(textLabels);
    // const alpha = 0.5; // How fast to animate labels de-overlapping
    // const spacing = 10; // Label line height
    // let again = false; // Whether overlap needs to be recalculated
    // textLabels.each(function (d, i) {
    //   const a = this;
    //   const da = d3.select(a);
    //   const y1 = da.attr("x");
    //   console.log(y1, a);
    //   textLabels.each(function (d, j) {
    //     const b = this;
    //     if (a === b) return; // a & b are the same element and don't collide.
    //     const db = d3.select(b);
    //     if (da.attr("text-anchor") !== db.attr("text-anchor")) return; // a & b are on opposite sides of the chart and don't collide
    //     // Now let's calculate the distance between these elements.
    //     const y2 = db.attr("x");
    //     // const deltaY = y1 - y2;
    //     // if (Math.abs(deltaY) > spacing) return; // If spacing is greater than our specified spacing, they don't collide.
    //     // // If the labels collide, we'll push each of the two labels up and down a little bit.
    //     // again = true;
    //     // const sign = deltaY > 0 ? 1 : -1;
    //     // const adjust = sign * alpha;
    //     console.log(y2, b);
    //     da.attr("x", +y1 - 50);
    //     db.attr("x", +y2 + 10);
    //   });
    // });
    // Adjust our line leaders here so that they follow the labels.
    // if (again) setTimeout(this.deOverlapLabels, 20);
  }, [sets]);
  return (
    <div style={{ marginTop: "100px", height: "100%" }}>
      <svg id="venn" width="500" height="350"></svg>
    </div>
  );
};
export default VennDiagram;
