import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Label } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IMG } from "../../constants";
import "./common.css";

// common component for phone input field
const AccountPhoneInputField = props => {
  let {
    handleInputChange,
    placeholder,
    invalid,
    invalidMsg,
    label,
    value,
    labelClassName,
    errorClassName,
    disabled
  } = props;

  return (
    <React.Fragment>
      <Row style={{ display: "flex", alignItems: "flex-start" }}>
        <Col md={4}>
          <Label className={labelClassName}>{label}</Label>
        </Col>
        <Col md={8} className="setting-phone-input">
          <PhoneInput
            inputExtraProps={{
              name: "Phone",
              required: true,
              autoFocus: true,
            }}
            country={"us"}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            disableCountryCode={false}
            preferredCountries={["us"]}
            countryCodeEditable={true}
            disableDropdown={false}
            dropdownClass="phone-flag-box"
            disabled={disabled}
          />

          {invalid && (
            <span className={errorClassName}>
              <img
                src={IMG.errorIcon}
                alt=" "
                width="25px"
                height="20px"
                className="cursor-pointer"
              />
            </span>
          )}
          {invalid && <div className="input-error-style">{invalidMsg}</div>}
        </Col>
      </Row>
    </React.Fragment>
  );
};

AccountPhoneInputField.propTypes = {
  handleInputChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  invalidMsg: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

AccountPhoneInputField.defaultProps = {
  className: undefined,
  placeholder: "",
  invalid: false,
  labelClassName: "page-input-label",
  errorClassName: "field-icon",
};

export default AccountPhoneInputField;
