export const getPlanDetails = (plans, name) => {
  const data = [];
  name === "getReccos"
    ? plans &&
      plans.map((map) => data.push(map.metaData && map.metaData.getReccos))
    : name === "nurtures"
    ? plans &&
      plans.map((map) => data.push(map.metaData && map.metaData.nurtures))
    : name === "reccoFamSize"
    ? plans &&
      plans.map((map) => data.push(map.metaData && map.metaData.reccoFamSize))
    : plans &&
      plans.map((map) =>
        data.push(map.metaData && map.metaData.customIntegration)
      );
  return data;
};

export const removeEmpty = (arr) => {
  return arr.filter((item) => item);
};
export const formatNumber = (no) => {
  if (no < 1e3) return no;
  if (no >= 1e3 && no < 1e6) return +(no / 1e3).toFixed(1) + "K";
  if (no >= 1e6 && no < 1e9) return +(no / 1e6).toFixed(1) + "M";
  if (no >= 1e9 && no < 1e12) return +(no / 1e9).toFixed(1) + "B";
  if (no >= 1e12) return +(no / 1e12).toFixed(1) + "T";
};

export const counrtyOptions = (data) => {
  let array = [];
  data.map((option) => {
    array.push({ value: option, label: option });
  });
  return array;
};
