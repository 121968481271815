import { useState, useEffect } from "react";

const PhoneTextPreview = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (props.htmlText.length > 160) {
      document
        .getElementById("showText")
        .addEventListener("click", toggle, false);
    }
  });
  const displayString = isOpen
    ? `${props.htmlText}<a id="showText">
              see less
            </a>
          `
    : `${props.htmlText.slice(0, 157)}<a id="showText">
              see more
            </a>
          `;
  return (
    <div className="phone-preview-text">
      <div
        dangerouslySetInnerHTML={{
          __html: props.htmlText.length > 160 ? displayString : props.htmlText,
        }}
        className="preview-scroll"
      ></div>
    </div>
  );
};

export default PhoneTextPreview;
