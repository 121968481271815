import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Row, Col } from "reactstrap";
import { IMG } from "../../constants";
import "./remindersCalender.css";

const DateRangePicker = ({ setDateRange, startDate, endDate }) => {
  {
    const [isOpen, setIsOpen] = useState(false);
    const CustomInput = React.forwardRef((props, ref) => {
      return (
        <Row onClick={props.onClick} className="date-range-picker row-mlr">
          <Col md={2}>
            <img
              src={IMG.grayCalenderIcon}
              alt=" "
              width="25px"
              height="20px"
            />
          </Col>
          <Col md={8}>
            <label className="date-range-picker-label">
              {props.value || props.placeholder}
            </label>
          </Col>
          <Col md={2}>
            <img src={IMG.downGrayArrow} alt=" " width={15} height={12} />
          </Col>
        </Row>
      );
    });

    return (
      <div>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
            if (update[0] && update[1]) {
              setIsOpen(false);
            }
          }}
          customInput={<CustomInput />}
          popperPlacement={"bottom-end"}
          open={isOpen}
          onClickOutside={() => setIsOpen(false)}
          onInputClick={() => setIsOpen(true)}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          placeholderText={"Select dates"}
          maxDate={new Date()}
        />
      </div>
    );
  }
};

export default DateRangePicker;
