export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\.])[A-Za-z\d@$!%*?&\.]{8,15}$/,
  CONTACT: /^[\s()+-]*([0-9][\s()+-]*){10,15}$/,
  PHONE: /^\+?([0-9]{0,1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  NAME: /^[A-Za-z ]+$/,
  // /^(?!\s)(?![\s\S]*\s$)[A-Za-z ]+$/,
  USERNAME: /^[a-zA-Z '.-]*$/,
  ONLY_TEN_DIGIT: /^[0-9]{10}$/,
  NOT_BLANK_SPACE: /^(?!\s)(?![\s\S]*\s$)/,
  ONLY_NO: /^\d+$/,
  ONLY_CHAR: /^[0-9a-zA-Z\_-]$/,
  PINCODE: /^(\d{6}|\d{8})$/,
  URL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  ALPHA_NUMERIC: /^([a-zA-Z0-9@&.,_ -]+)$/,
  SPECIAL_CHAR: /^[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
  BUSINESS_NAME: /^[a-zA-Z0-9\s&.,@#*!'-]*$/,
  ONLY_TEXT: /\D/,
  ONLY_VIDEOS:
    /(\.mp4)|(\.webm)|(\.mpg)| (\.mp2)| (\.mpeg)| (\.mpe)| (\.mpv)|(\.ogg)|(\.m4p)| (\.m4v)|(\.avi)| (\.wmv)|(\.mov)| (\.qt)|(\.flv)| (\.swf)$/i,
  CHECK_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
};
