import React from "react";
import PropTypes from "prop-types";
import "./common.css";
import PhoneMediaPreview from "./phoneMediaPreview";
import PhoneTextPreview from "./phoneTextPreview";
import PhonePerkPreview from "./phonePerkPreview";
import { IMG } from "../../constants";
import ButtonItem from "../../common/component/button";
import WebPerkPreview from "./webPerkPreview";
import WebTextPreview from "./webTextPreview";

const PhonePreview = (props) => {
  let {
    type,
    htmlText,
    imgPath,
    isFileTypeVideo,
    perkDetails,
    isSendPage,
    isSendNurturePage,
    isNurture,
    fileType,
  } = props;

  return (
    <div className={type === "Web" ? "web-preview-bg" : "phone-preview-bg"}>
      <div className="phone-preview-overflow">
        {!isSendNurturePage && isSendPage && (imgPath || htmlText) ? (
          <>
            <div className="phone-preview-overflow-secondary-header">
              Share the Love !
            </div>
            <div className="phone-preview-overflow-name-header">
              {"Hi <Name>,"}
            </div>
          </>
        ) : null}
        {imgPath && (
          <div style={{ width: "100%" }}>
            <PhoneMediaPreview
              imgPath={imgPath}
              isFileTypeVideo={isFileTypeVideo}
              type={type}
              isNurture={isNurture}
              fileType={fileType}
            />
          </div>
        )}
        <div style={{ width: "100%" }}>
          {type === "Web" ? (
            <WebTextPreview htmlText={htmlText} />
          ) : (
            <PhoneTextPreview htmlText={htmlText} />
          )}
        </div>
        {perkDetails && isSendPage && (imgPath || htmlText) ? (
          <div
            className={
              type === "Web" ? "perk-preview-div" : "phone-perk-preview-div"
            }
          >
            {type === "Web" ? (
              <WebPerkPreview
                perkDetails={perkDetails}
                type={type}
                isSendNurturePage={isSendNurturePage}
              />
            ) : (
              <PhonePerkPreview
                perkDetails={perkDetails}
                type={type}
                isSendNurturePage={isSendNurturePage}
              />
            )}
          </div>
        ) : null}
        {!isSendNurturePage && isSendPage && (imgPath || htmlText) ? (
          <>
            <div
              style={{
                width: "100%",
              }}
            >
              <div className="phone-preview-button">
                <ButtonItem
                  className="save-continue-recco-button"
                  buttonValue={"Sure, I’ll give you a Recco!"}
                  showLoader={false}
                  disabled={false}
                  type="popup-btn"
                />
              </div>
              <div className="phone-preview-second-button">
                <ButtonItem
                  className="download-recco-button-css"
                  buttonValue={"Download Recco"}
                  showLoader={false}
                  disabled={false}
                  type="popup-btn"
                />
              </div>
            </div>
            <div className="phone-preview-second-button">
              <a className="recco-hyperlink-css">What’s Recco?</a>
            </div>
            <div
              className="phone-preview-second-button"
              style={{ marginTop: "10px" }}
            >
              <img
                src={
                  type === "Web"
                    ? IMG.reccoWebPreviewImage
                    : IMG.reccoPreviewImage
                }
                width={"100%"}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

PhonePreview.propTypes = {
  type: PropTypes.string,
};

export default PhonePreview;
