import { Row, Col } from "reactstrap";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import S3 from "react-aws-s3";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  IMG,
  invalidImgType,
  ROUTES_PATH,
  nurtureBreadcrumbsDetails,
  internalServerError,
  uploadFailed,
  videoLimit,
} from "../../constants";
import { toastMessage } from "../../components/ToastMessage";
import ButtonItem from "../../common/component/button";
import "../AddInfo/addInfo.css";
import "./addNurtureInfo.css";
import InfoMessages from "../../components/InfoMessages";
import BottomButtons from "../../components/BottomButtons";
import PhonePreview from "../../common/component/phonePreview";
import {
  createNurtureService,
  updateNurtureService,
  deleteNurtureService,
  createResourceService,
} from "../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import LoadingState from "../../common/component/loader";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import _ from "lodash";

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  dirName: "NurtureMedia" /* optional */,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_REGION_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRETE_KEY,
  s3Url: process.env.REACT_APP_S3_URL,
};

const S3Client = new S3(config);

const AddNurtureInfo = (props) => {
  const uploadImgRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [fileLoader, setFileLoader] = useState(false);
  const [fileObject, setFileObject] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [htmlText, setHtmlText] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [uploadedImg, setUploadedImg] = useState(null);
  const [imgType, setImgType] = useState("");
  const [textTemplate, setTextTemplate] = useState("");
  const [selectedText, setSelectedText] = useState(null);
  const [removePerkModal, setRemovePerkModal] = useState(false);
  const [actionType, setActionType] = useState("Save");
  const [perkLoader, setPerkLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  useEffect(() => {
    props.toggleMainLoader(true);
    props.getNurtureDetails(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
  }, []);

  useEffect(() => {
    setActionType(props.nurtureDetails ? "Update" : "Save");
    setHtmlText(props.nurtureDetails ? props.nurtureDetails?.textContent : "");
    setTextTemplate(
      props.nurtureDetails ? props.nurtureDetails?.textContent : ""
    );
    setFileObject(
      props.nurtureDetails ? props.nurtureDetails?.resources : null
    );
    setSelectedImg(
      props.nurtureDetails ? props.nurtureDetails?.resources : null
    );
    setSelectedText(
      props.nurtureDetails ? props.nurtureDetails?.textContent : null
    );
    setIsFileUploaded(props.nurtureDetails?.resources ? true : false);
  }, [props.nurtureDetails]);

  const handleRemovePerkModal = () => {
    setRemovePerkModal(!removePerkModal);
  };

  const handleUploadImgBtn = () => {
    uploadImgRef.current.click();
  };
  const getSelectedText = (text) => {
    setTextTemplate(text.content);
  };
  const handleSelectedText = (text) => {
    setSelectedText(text);
  };
  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const mediaReader = new FileReader();
      mediaReader.onload = () => {
        const media = new Audio(mediaReader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      mediaReader.readAsDataURL(file);
    });

  const handleImageDataChange = async (event) => {
    setIsFileUploaded(true);
    const file = event.target.files[0];

    if (file !== undefined) {
      if (
        file.type.split("/")[0] === "image" ||
        file.type.split("/")[0] === "video"
      ) {
        let uploadedContent = uploadedImg ? [...uploadedImg] : [];
        let fileContent = fileObject ? [...fileObject] : [];

        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        if (file.type.split("/")[0] === "video") {
          setIsFileUploading(true);
          await getVideoDuration(file).then((time) => {
            if (time < 30) {
              reader.onloadend = function (e) {
                fileContent.push({
                  resourceType: file.type,
                  url: reader.result,
                });
                uploadedContent.push({
                  resourceType: file.type,
                  mediaUrl: file,
                  url: reader.result,
                });
                setUploadedImg(uploadedContent);
                setImgType("upload");
                setFileObject(fileContent);
                setIsFileUploaded(true);
                setIsFileUploading(false);
              };
              reader.readAsDataURL(file);
            } else {
              toastMessage("error", videoLimit);
              event.target.value = null;
              setIsFileUploading(false);
            }
          });
        } else {
          reader.onloadend = function (e) {
            fileContent.push({
              resourceType: file.type,
              url: reader.result,
            });
            uploadedContent.push({
              resourceType: file.type,
              mediaUrl: file,
              url: reader.result,
            });
            setUploadedImg(uploadedContent);
            setImgType("upload");
            setFileObject(fileContent);
            setIsFileUploaded(true);
            setIsFileUploading(false);
          };
        }
      } else {
        toastMessage("error", invalidImgType);
        event.target.value = null;
        setIsFileUploading(false);
      }
    }
  };
  const uploadToS3Bucket = (type) => {
    type === "launch" ? setFileLoader(true) : setSaveLoader(true);
    let media = [];
    if (actionType === "Update") {
      media = selectedImg ? [...selectedImg] : [];
    }
    const requestArr = [];

    if (uploadedImg) {
      uploadedImg.map((files) => {
        if (files.mediaUrl !== undefined) {
          const fileName = files.mediaUrl.name
            ?.split(".")
            .slice(0, -1)
            .join(".");
          var d = new Date();
          const newName =
            d.getDate().toString() +
            "_" +
            d.getTime().toString() +
            "_" +
            fileName.replaceAll(" ", "_");
          requestArr.push(S3Client.uploadFile(files.mediaUrl, newName));
        } else {
          setFileLoader(false);
          setSaveLoader(false);
        }
      });

      Promise.all(requestArr)
        .then((data) => {
          data.map((response, index) => {
            if (response.status === 204) {
              media.push({
                resourceType: uploadedImg[index].resourceType,
                url: response.location,
              });
            }
          });
          handleSelectedImg(media);
          handleAPICall(media, type);
        })
        .catch((err) => {
          setFileLoader(false);
          setSaveLoader(false);
          toastMessage("error", uploadFailed);
        });
    } else {
      handleAPICall(selectedImg, type);
    }
  };

  const handleRemoveImage = (index, url) => {
    setIsFileUploading(false);
    let uploadedMedia = uploadedImg ? [...uploadedImg] : [];

    uploadedMedia = uploadedMedia.filter(function (obj) {
      return obj.url !== url;
    });

    if (uploadedMedia.length === 0) {
      setUploadedImg(null);
    } else {
      setUploadedImg(uploadedMedia);
    }
    let contentMedia = [...fileObject];
    contentMedia.splice(index, 1);
    if (contentMedia.length === 0) {
      setIsFileUploaded(false);
    }
    setFileObject(contentMedia);
    let media = [...selectedImg];
    media.splice(index, 1);
    if (media.length === 0) {
      setSelectedImg(null);
    } else {
      setSelectedImg(media);
    }
  };
  const handleSelectText = (selectedText) => {
    setHtmlText(selectedText);
  };
  const handleSelectedImg = (img) => {
    setSelectedImg(img);
    setImgType("upload");
  };

  const handleSaveBtn = () => {
    if (
      (htmlText === null ||
        htmlText === "" ||
        htmlText === undefined ||
        htmlText === "<p><br></p>") &&
      (fileObject === null ||
        fileObject === undefined ||
        fileObject === "" ||
        fileObject?.length === 0)
    ) {
      toastMessage("error", "Please add media and message for the nurture.");
    } else if (
      fileObject === null ||
      fileObject === undefined ||
      fileObject === "" ||
      fileObject?.length === 0
    ) {
      toastMessage("error", "Please add media for the nurture.");
    } else if (
      htmlText === null ||
      htmlText === "" ||
      htmlText === undefined ||
      htmlText === "<p><br></p>"
    ) {
      toastMessage("error", "Please add message for the nurture.");
    } else if (fileObject?.length > 3) {
      toastMessage("error", "Only 3 medias allowed for the nurture.");
    } else {
      if (imgType === "upload") {
        // uploadToS3Bucket("launch");
        createResourceAPICall("launch");
      } else {
        let resourceIds = selectedImg.map((data) => data.id);
        handleAPICall(resourceIds, "launch");
      }
    }
  };

  const createResourceAPICall = (type) => {
    type === "launch" ? setFileLoader(true) : setSaveLoader(true);

    let formData = new FormData();
    formData.append("source", "NURTURE");
    uploadedImg.map((file) => {
      formData.append("file", file.mediaUrl);
    });
    createResourceService(props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id, formData)
      .then((response) => {
        if (response.status === 201) {
          let resourceIds = [];
          if (actionType === "Update") {
            resourceIds = selectedImg ? selectedImg.map((data) => data.id) : [];
          }
          response.data.map((data) => {
            resourceIds.push(data.id);
          });

          handleAPICall(resourceIds, type);
        }
      })
      .catch((error) => {
        setFileLoader(false);
        setSaveLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const handleSaveForLatter = () => {
    if (
      props.nurtureDetails?.textContent !== htmlText ||
      !_.isEqual(props.nurtureDetails?.resources, fileObject)
    ) {
      if (imgType === "upload") {
        createResourceAPICall("saveForLatter");
      } else {
        let payload = {
          resourceIds: selectedImg.map((data) => data.id),
          textContent: htmlText.replace(/\n/g, ""),
        };
        setSaveLoader(true);
        handleUpdateAPICall(payload, "saveForLatter");
      }
    } else {
      navigate(ROUTES_PATH.NURTURE);
    }
  };
  const handleAPICall = (img, type) => {
    if (
      (selectedImg?.length > 0 || uploadedImg?.length > 0 || img.length > 0) &&
      htmlText
    ) {
      type === "launch" ? setFileLoader(true) : setSaveLoader(true);
      const payload = {
        resourceIds:
          imgType === "upload" ? img : selectedImg.map((data) => data.id),
        textContent: htmlText.replace(/\n/g, ""),
      };
      if (actionType === "Save") {
        createNurtureService(props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id, payload)
          .then((response) => {
            if (response.status === 201) {
              setFileLoader(false);
              setSaveLoader(false);
              toastMessage("success", "Information saved successfully");
              if (location.pathname === ROUTES_PATH.SEND_NURTURE) {
                navigate(ROUTES_PATH.NURTURE);
              } else {
                navigate(ROUTES_PATH.NURTURE_ADD_PERK);
              }
            }
          })
          .catch((error) => {
            setFileLoader(false);
            setSaveLoader(false);
            if (
              error.response?.status === 400 ||
              error.response?.status === 404
            ) {
              const errorMessage =
                error.response.data.failures.length > 0
                  ? error.response.data.failures[0]["message"]
                  : error.response.data.message;
              toastMessage("error", errorMessage);
            } else {
              toastMessage("error", internalServerError);
            }
          });
      } else {
        if (
          props.nurtureDetails?.textContent === htmlText &&
          _.isEqual(
            props.nurtureDetails?.resources.map((data) => data.id),
            imgType === "upload" ? img : selectedImg.map((data) => data.id)
          )
        ) {
          if (location.pathname === ROUTES_PATH.SEND_NURTURE) {
            const payload = {
              status: "ACTIVE",
            };
            props.nurtureDetails?.status === "ACTIVE"
              ? navigate(ROUTES_PATH.NURTURE)
              : handleUpdateAPICall(payload);
          } else {
            navigate(ROUTES_PATH.NURTURE_ADD_PERK);
          }
        } else {
          let payload = {
            resourceIds:
              imgType === "upload" ? img : selectedImg.map((data) => data.id),
            textContent: htmlText.replace(/\n/g, ""),
          };
          if (
            location.pathname === ROUTES_PATH.SEND_NURTURE &&
            type === "launch"
          ) {
            payload["status"] = "ACTIVE";
          }
          handleUpdateAPICall(payload, type);
        }
      }
    }
  };
  const handleUpdateAPICall = (payload, type) => {
    const data = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      nurtureId: props.nurtureDetails.id,
    };
    updateNurtureService(data, payload)
      .then((response) => {
        if (response.status === 200) {
          setFileLoader(false);
          setSaveLoader(false);
          if (location.pathname === ROUTES_PATH.SEND_NURTURE) {
            toastMessage(
              "success",
              type === "launch"
                ? "Nurture launched successfully"
                : "Nurture saved successfully"
            );
            navigate(ROUTES_PATH.NURTURE);
          } else {
            toastMessage("success", "Information updated successfully");
            navigate(ROUTES_PATH.NURTURE_ADD_PERK);
          }
        }
      })
      .catch((error) => {
        setFileLoader(false);
        setSaveLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleDeleteAPICall = () => {
    const data = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      nurtureId: props.nurtureDetails.id,
    };
    setDeleteLoader(true);
    deleteNurtureService(data)
      .then((response) => {
        if (response.status === 204) {
          setDeleteLoader(false);
          toastMessage("success", "Nurture deleted successfully");
          navigate(ROUTES_PATH.NURTURE);
        }
      })
      .catch((error) => {
        setDeleteLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleNurturePerk = (perkId) => {
    const data = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      nurtureId: props.nurtureDetails.id,
    };
    const payload = {
      perk: props.nurtureDetails?.perk ? null : perkId,
    };
    setPerkLoader(true);
    updateNurtureService(data, payload)
      .then((response) => {
        if (response.status === 200) {
          setPerkLoader(false);
          if (props.nurtureDetails?.perk) {
            toastMessage(
              "success",
              "Perk removed from the nurture successfully"
            );
            handleRemovePerkModal();
          } else {
            toastMessage("success", "Perk applied to the nurture successfully");
          }
          const data = {
            businessId:
              props.user.business[localStorage.getItem("businessIndex") || 0]
              .businessForAccount.id,
            nurtureId: props.nurtureDetails.id,
          };
          props.toggleMainLoader(true);
          props.getNurtureDetails(
            props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
          );
        }
      })
      .catch((error) => {
        setPerkLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  return (
    <div className="add-info-main-div">
      <ConfirmationPopup
        openModal={removePerkModal}
        handleModal={handleRemovePerkModal}
        handleBtnClick={handleNurturePerk}
        showLoader={perkLoader}
        subText={`Are you sure you want to remove this perk from nurture ?`}
        heading="Remove perk"
        btnValue="Remove"
      />
      {props.loader.mainLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <Breadcrumbs
            breadcrumbsDetails={nurtureBreadcrumbsDetails}
            active={
              location.pathname === ROUTES_PATH.SEND_NURTURE
                ? "Nurture Preview"
                : "Add Info."
            }
            isEnable={props.nurtureDetails ? true : false}
          />
          <div className="images-div-border">
            <div className="add-info-heading">{"Nurture the Connection"}</div>
            <div className="choose-img-heading">
              Upload a media for this Nurture
            </div>

            <Row style={{ marginRight: "0px" }}>
              {isFileUploading ? (
                <Col>
                  <LoadingState color="#2963ca" />
                </Col>
              ) : (
                <Col md={12} lg={8} xl={8} className="image-selected-col">
                  {isFileUploaded &&
                    fileObject &&
                    fileObject.map((media, index) => {
                      return (
                        <div className="uploaded-media-div" key={index}>
                          <div className="nurture-preview-img-div">
                            {media.resourceType.includes("video") ? (
                              <video controls className="preview-video-css">
                                <source
                                  src={media.url}
                                  type={media.resourceType}
                                />
                              </video>
                            ) : (
                              <img
                                src={media.url}
                                alt=""
                                className="preview-img-css"
                              />
                            )}
                          </div>
                          <ButtonItem
                            className="nurture-img-remove-button"
                            buttonValue="Remove"
                            handleButtonAction={() =>
                              handleRemoveImage(index, media.url)
                            }
                          />
                        </div>
                      );
                    })}
                  {(!fileObject || fileObject?.length < 3) && (
                    <div
                      className="flex-center"
                      style={{
                        height: "max-content",
                        alignItems: "flex-start",
                        marginRight: "20px",
                      }}
                    >
                      <div>
                        <img
                          src={IMG.uploadImage}
                          alt=""
                          className="cursor-pointer"
                          onClick={handleUploadImgBtn}
                        />
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*,video/*"
                          multiple={false}
                          ref={uploadImgRef}
                          onChange={handleImageDataChange}
                        />
                      </div>
                      {/* )} */}
                      <div className="img-upload-text">
                        Start by uploading a media for this ‘Nurture’. Go ahead
                        and Personalize !
                        <div className="video-upload-text">
                          Max Video Time duration should be 30sec
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              )}
              {location.pathname === ROUTES_PATH.SEND_NURTURE &&
                props.nurtureDetails?.perk && (
                  <Col className="perk-div">
                    <div className="send-perk-added-text">Perk Added :</div>
                    {props.nurtureDetails?.perk ? (
                      <div>
                        <div style={{ display: "flex", marginBottom: "9px" }}>
                          <div>
                            <img
                              src={props.nurtureDetails?.perk?.image}
                              alt=""
                              height="90px"
                              width="100px"
                            />
                          </div>
                          <div style={{ marginLeft: "12px" }}>
                            <div className="perk-name-css">
                              {props.nurtureDetails?.perk?.name}
                            </div>
                            <div className="perk-details-name">
                              YOUR PROMO CODE :{" "}
                              <span className="perk-details-value">
                                {props.nurtureDetails?.perk?.promoCode}
                              </span>
                            </div>
                            {props.nurtureDetails?.perk?.productUrl && (
                              <div className="perk-details-name">
                                YOUR PROMO LINK :{" "}
                                <span className="perk-details-value">
                                  {props.nurtureDetails?.perk?.productUrl}
                                </span>
                              </div>
                            )}

                            <div className="perk-details-name">
                              VALIDITY :{" "}
                              <span className="perk-details-value">
                                {moment(
                                  props.nurtureDetails?.perk?.expirationDate
                                ).format("MM-DD-YYYY")}
                              </span>
                            </div>
                            <div className="recco-remove-perk-div">
                              <ButtonItem
                                className="skip-perk-button"
                                buttonValue={
                                  props.nurtureDetails?.perk
                                    ? "Remove Perk"
                                    : "Apply Perk"
                                }
                                handleButtonAction={() => {
                                  handleRemovePerkModal();
                                }}
                                showLoader={perkLoader}
                                disabled={perkLoader}
                                loaderColor="#2963ca"
                                loaderHeight="20px"
                                loaderWidth="20px"
                                type="popup-btn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </Col>
                )}
            </Row>
          </div>
          <Row>
            <Col md={12} lg={12} xl={6}>
              <InfoMessages
                handleSelectText={handleSelectText}
                textTemplate={textTemplate}
                getSelectedText={getSelectedText}
                selectedText={selectedText}
                handleSelectedText={handleSelectedText}
                location={location}
                {...props}
              />
            </Col>
            <Col
              md={12}
              lg={12}
              xl={6}
              className="nurture-template-preview-col"
            >
              <div>
                <div className="phone-preview-heading">Nurture Preview</div>
                <PhonePreview
                  type="Recco"
                  htmlText={htmlText}
                  imgPath={fileObject?.length > 0 ? fileObject[0].url : ""}
                  isFileTypeVideo={
                    fileObject?.length > 0 &&
                    fileObject[0].resourceType.includes("video")
                      ? true
                      : false
                  }
                  fileType={
                    fileObject?.length > 0 && fileObject[0].resourceType
                  }
                  perkDetails={props.nurtureDetails?.perk}
                  isSendPage={location.pathname === ROUTES_PATH.SEND_NURTURE}
                  isSendNurturePage={
                    location.pathname === ROUTES_PATH.SEND_NURTURE
                  }
                  isNurture={true}
                />
              </div>
            </Col>
          </Row>
          {location.pathname === ROUTES_PATH.SEND_NURTURE ? (
            <BottomButtons
              handleBtn3={() => {
                props.nurtureDetails?.status === "ACTIVE"
                  ? navigate(ROUTES_PATH.NURTURE)
                  : handleSaveForLatter();
              }}
              loader={fileLoader}
              btn3Loader={saveLoader}
              handleSaveBtn={() => {
                handleSaveBtn();
              }}
              btnName3={
                props.nurtureDetails?.status === "ACTIVE"
                  ? "Back to Nurture"
                  : "Save For Later"
              }
              btnName2={
                props.nurtureDetails?.status === "ACTIVE"
                  ? "Update Nurture"
                  : `Launch Nurture`
              }
            />
          ) : props.nurtureDetails?.status === "ACTIVE" ? (
            <BottomButtons
              handleBackBtn={() => navigate(ROUTES_PATH.NURTURE)}
              loader={fileLoader}
              handleSaveBtn={() => {
                handleSaveBtn();
              }}
              btnName1={"Back"}
              btnName2={`Update and Continue`}
              btnName3="Remove Nurture"
              handleBtn3={() => handleDeleteAPICall()}
              btn3Loader={deleteLoader}
            />
          ) : (
            <BottomButtons
              handleBackBtn={() => navigate(ROUTES_PATH.NURTURE)}
              loader={fileLoader}
              handleSaveBtn={() => {
                handleSaveBtn();
              }}
              btnName1={"Back"}
              btnName2={`Save and Continue`}
            />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    nurtureDetails: state.nurtureDetails,
    reccoListDetails: state.reccoListDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNurtureDetails: ActionCreators.getNurtureDetails,
      toggleMainLoader: ActionCreators.toggleMainLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNurtureInfo);
