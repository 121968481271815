import React, { useState } from "react";
import { Label, Input } from "reactstrap";
import { IMG } from "../../constants";
import PropTypes from "prop-types";
import "./common.css";

// common component for password field
const PasswordField = (props) => {
  let {
    handlePasswordChange,
    handleKeyPress,
    className,
    maxLength,
    placeholder,
    invalid,
    invalidMsg,
    label,
    name,
  } = props;
  const [showPassword, togglePassword] = useState(false);

  const toggle = () => togglePassword((prevState) => !prevState);
  return (
    <React.Fragment>
      <Label className="page-input-label">{label}</Label>
      <Input
        type={showPassword ? "text" : "password"}
        onChange={handlePasswordChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        className={className}
        maxLength={maxLength}
        name={name}
        autoComplete="new-password"
        id={invalid ? "invalid-input-border" : ""}
      />
      <span
        onClick={toggle}
        className={
          invalid
            ? "pwd-error-icon cursor-pointer"
            : "field-icon cursor-pointer"
        }
      >
        <img
          src={showPassword ? IMG.eyeOpen : IMG.eyeOff}
          alt=" "
          width="25px"
          height="20px"
          className="cursor-pointer"
        />
      </span>
      {invalid && (
        <span className="field-icon">
          <img src={IMG.errorIcon} alt=" " width="25px" height="20px" />
        </span>
      )}
      {invalid && <div className="input-error-style">{invalidMsg}</div>}
    </React.Fragment>
  );
};

PasswordField.propTypes = {
  handlePasswordChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

PasswordField.defaultProps = {
  type: "text",
  className: undefined,
  leftIcon: undefined,
  inputRef: undefined,
  placeholder: "",
  invalid: false,
};

export default PasswordField;
