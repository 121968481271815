import * as actionTypes from "./actionTypes";

export const getSubscriptionPlans = () => ({
  type: actionTypes.GET_SUBSCRIPTION_PLANS,
});
export const saveSubscriptionPlans = (data) => ({
  type: actionTypes.SAVE_SUBSCRIPTION_PLANS,
  data,
});
export const chooseSubscriptionPlans = (data) => ({
  type: actionTypes.CHOOSE_SUBSCRIPTION_PLANS,
  data,
});
