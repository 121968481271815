import React from "react";
import moment from "moment";

const PhonePerkPreview = props => {
  return (
    <div className="perk-preview-div">
      <div className={!props.isSendNurturePage ? "recco-perkInfo" : "perkInfo"}>
        <div className="perk-preview-image">
          <img
            src={props.perkDetails?.image}
            width={props.perkDetails?.image ? 30 : 17}
            height={props.perkDetails?.image ? 46 : 21}
          />
        </div>
        <div className="perkInfo-details">
          <span
            className={
              !props.isSendNurturePage && props.type === "Web"
                ? "recco-perkInfo-full-name"
                : !props.isSendNurturePage
                ? "recco-perkInfo-name"
                : "perkInfo-name"
            }
          >
            {props.perkDetails?.name}
          </span>
          <div
            className={
              !props.isSendNurturePage
                ? "recco-perkInfo-validity"
                : "perkInfo-validity"
            }
          >
            VALIDITY :
            <span
              className={
                !props.isSendNurturePage
                  ? "recco-perkInfo-date"
                  : "perkInfo-date"
              }
            >
              {" " +
                moment(props.perkDetails.expirationDate).format("MM-DD-YYYY")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhonePerkPreview;
