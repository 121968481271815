import * as actionTypes from "../actions/actionTypes";

const initialState = [];

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_RECCO_FAM:
      return data;
    default:
      return state;
  }
};
