/** @format */

import React, { Fragment, useState, useRef } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  IMG,
  invalidImgType,
  inputFieldValidaionMessage,
  requiredFieldMessage,
  internalServerError,
  ROUTES_PATH,
} from "../../constants";
import "./navbar.css";
import {
  Label,
  Row,
  Col,
  Collapse,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import InputField from "../../common/component/inputField";
import LoadingState from "../../common/component/loader";
import { toastMessage } from "../../components/ToastMessage";
import { createBusinessResourceService } from "../../services";
import { validateBusinessName, validateName } from "../../utils/validation";
import { addUserDetailsUrl } from "../../utils/urls";
import axios from "../../axios";
import _ from "lodash";
import CopyComp from "../Copy";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "9vh",
    marginLeft: "50vw",
    // background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },
  dialogContainer: {
    padding: "24px 40px 30px 40px",
  },
  bodyContent: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#222222",
    marginBottom: "24px",
  },
  buttonStyle: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff !important",
    height: "50px",
    width: "102px",
  },
  topWrapper: {
    display: "flex",
  },
  rightContainer: {
    marginTop: "20px",
  },
  textName: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
  },
  textEmail: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    color: "grey",
    fontSize: "12px",
    overflow: "hidden",
  },
  businessListDiv: {},
  businessWrapper: {
    display: "flex",
    cursor: "pointer",
  },
  horizontalLine: {
    color: "grey",
  },
}));

const ProfileDropDown = ({
  openModal,
  handleModal,
  handleLogout,
  username,
  user,
  userEmail,
  businessList,
  getUserDetails,
  navigation,
}) => {
  const classes = useStyles();
  const uploadImgRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);

  let i = 0,
    j = 0;

  const [signupDetails, setSignupDetails] = useState({
    businessName: "",
    description: "",
    slug: "",
  });

  const handleChangeIndex = (index) => {
    localStorage.setItem("businessIndex", index);
    window.location.reload();
  };
  const [fileObject, setFileObject] = useState(null);
  const [open, setOpen] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState("");

  const handleOpen = () => {
    setOpen(true);
    handleModal();
  };
  const closeModal = () => {
    setOpen(false);
    setFileObject(null);
    setSignupDetails({
      businessName: "",
      description: "",
    });
    setLoader(false);
  };

  const handleUploadImgBtn = () => {
    uploadImgRef.current.click();
  };

  const handleImageDataChange = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      if (file.type.split("/")[0] === "image") {
        var reader = new FileReader();
        reader.onloadend = function (e) {
          setFileObject({ url: reader.result, file: file });
        };
        reader.readAsDataURL(file);
        setDisabled(false);
      } else {
        toastMessage("error", invalidImgType);
        event.target.value = null;
      }
    }
  };

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const SignupDetails = { ...signupDetails };
    SignupDetails[input.name] = input.value;
    SignupDetails[msgType] = "";
    SignupDetails[validationType] = false;
    setSignupDetails(SignupDetails);
  };

  const createResourceAPICall = async (payload) => {
    let formData = new FormData();
    formData.append("file", fileObject.file);

    await createBusinessResourceService(user.account.id, formData)
      .then((response) => {
        if (response.status === 201) {
          payload["resourceId"] = response.data.id;
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const handleSubmit = async () => {
    const SignupDetails = { ...signupDetails };
    if (
      SignupDetails.businessName === "" ||
      SignupDetails.businessName === null ||
      SignupDetails.businessName === undefined
    ) {
      SignupDetails.isValidBusinessName = true;
      SignupDetails.invalidBusinessNameMsg =
        requiredFieldMessage("business name");
    } else if (!validateBusinessName(SignupDetails.businessName.trim())) {
      SignupDetails.isValidBusinessName = true;
      SignupDetails.invalidBusinessNameMsg =
        inputFieldValidaionMessage("business name");
    }

    if (
      SignupDetails.description === "" ||
      SignupDetails.description === null ||
      SignupDetails.description === undefined
    ) {
      SignupDetails.isValidDescription = true;
      SignupDetails.isValidDescription = requiredFieldMessage("description");
    } else if (!validateName(SignupDetails.description.trim())) {
      SignupDetails.isValidDescription = true;
      SignupDetails.isValidDescription =
        inputFieldValidaionMessage("description");
    }

    setSignupDetails(SignupDetails);

    const { isValidDescription, isValidBusinessName } = SignupDetails;

    if (!isValidBusinessName && !isValidDescription) {
      const businessPayload = {
        businessName: SignupDetails.businessName.trim(),
        description: SignupDetails.description.trim(),
        slug: SignupDetails.slug.trim(),
        appType: "business",
      };
      setLoader(true);
      if (
        fileObject &&
        !_.isEqual(user?.account?.profilePictureResource, fileObject)
      ) {
        await createResourceAPICall(businessPayload);
      }
      await axios
        .post(addUserDetailsUrl(user.accountId), businessPayload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setLoader(false);
            getUserDetails({}, user.accountId);
            navigation(ROUTES_PATH.SELECT_SUBSCRIPTION_PLAN);
            closeModal();
          }
        })
        .catch((error) => {
          setLoader(false);
          if (
            error.response?.status === 400 ||
            error.response?.status === 404
          ) {
            const errorMessage =
              error.response.data.failures?.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            toastMessage("error", errorMessage);
          } else {
            toastMessage("error", internalServerError);
          }
        });
    }
  };

  const toggle = (id) => {
    if (accordionOpen === id) {
      setAccordionOpen("");
    } else {
      setAccordionOpen(id);
    }
  };

  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleModal}
        fullWidth="md"
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <div className={classes.dialogContainer}>
          <div className={classes.bodyContent}>
            <div className={classes.topWrapper}>
              <img
                src={
                  user.account?.profilePictureResource?.url
                    ? user.account.profilePictureResource.url
                    : IMG.profileImg
                }
                className="profile-image-box-style-big"
                alt=""
              />
              <div className={classes.rightContainer}>
                <div className={classes.textName}>{username}</div>
                <div className={classes.textEmail}>{userEmail}</div>
                <div style={{ marginTop: "30px" }}>
                  <span
                    className="cursor-pointer nav-logout-text"
                    onClick={handleLogout}
                  >
                    <img
                      src={IMG.logout}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Logout
                  </span>
                  <span
                    onClick={handleOpen}
                    className="create-business-btn cursor-pointer"
                  >
                    <img src={IMG.addIcon} alt="" className="add-icon-create" />
                    Create Business
                  </span>
                </div>
              </div>
            </div>
            <br />
            <h4 style={{ fontWeight: 600, marginBottom: 20 }}>Switch to</h4>
            <Accordion flush open={accordionOpen} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="1">
                  <b>My Businesses</b>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <div className={classes.businessListDiv}>
                    {businessList.map(
                      (item, index) =>
                        item.businessForAccount.type === "SUPER_ADMIN" && (
                          <>
                          {i++ !== 0 && (
                                <hr className={classes.horizontalLine} />
                              )}
                            <div className="d-flex justify-content-between align-items-center">
                            <div
                              key={index}
                              onClick={function () {
                                handleChangeIndex(index);
                              }}
                            >
                              <div className={classes.businessWrapper}>
                                <img
                                  src={
                                    item.businessForAccount.resource?.url
                                      ? item.businessForAccount.resource.url
                                      : IMG.profileImg
                                  }
                                  className="profile-image-box-style-medium"
                                  alt=""
                                />
                                <div style={{ marginTop: 10 }}>
                                  <div className={classes.textName}>
                                    {item.businessForAccount.businessName}
                                  </div>
                                  <div className={classes.textEmail}>
                                    {item.businessForAccount.belongsTo.email}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <CopyComp text={item.businessForAccount.reccoUrl} />
                          </div>
                          </>
                          
                        )
                    )}
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">
                  <b>Managed Businesses</b>
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  <div className={classes.businessListDiv}>
                    {businessList.map(
                      (item, index) =>
                        item.businessForAccount.type === "ADMIN" && (
                          <div className="d-flex justify-content-between align-items-center">
                            <div
                              key={index}
                              onClick={function () {
                                handleChangeIndex(index);
                              }}
                            >
                              {j++ !== 0 && (
                                <hr className={classes.horizontalLine} />
                              )}
                              <div className={classes.businessWrapper}>
                                <img
                                  src={
                                    item.businessForAccount.resource?.url
                                      ? item.businessForAccount.resource.url
                                      : IMG.profileImg
                                  }
                                  className="profile-image-box-style-medium"
                                  alt=""
                                />
                                <div style={{ marginTop: 10 }}>
                                  <div className={classes.textName}>
                                    {item.businessForAccount.businessName}
                                  </div>
                                  <div className={classes.textEmail}>
                                    {item.businessForAccount.belongsTo.email}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <CopyComp text={item.businessForAccount.reccoUrl} />
                          </div>
                        )
                    )}
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={open}
        onClose={closeModal}
        className="create-business-modal"
        maxWidth="md"
      >
        <div className="create-business-modal-text">
          <span className="modal-head">Create New Business</span>
        </div>
        <div
          style={{
            padding: 100,
            paddingLeft: 150,
            paddingRight: 150,
          }}
        >
          <div style={{ width: 300 }}>
            <Row>
              <div style={{ display: "flex", marginBottom: 20 }}>
                {fileObject ? (
                  <img src={fileObject?.url} className="uploaded-img" />
                ) : (
                  <div
                    className="cursor-pointer upload-img-modal"
                    onClick={handleUploadImgBtn}
                  >
                    <img src={IMG.addImageIcon} />
                  </div>
                )}
                <Col
                  style={{ marginLeft: 20, justifyContent: "space-between" }}
                >
                  <Label>Upload a Logo</Label>
                  <p className="logo-desc">
                    Give your Business an Identity with a unique logo
                  </p>
                </Col>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple={false}
                  ref={uploadImgRef}
                  onChange={handleImageDataChange}
                />
              </div>

              <Label>Business Name</Label>
              <InputField
                errorClassName="account-field-icon"
                className="input-box-modal-business"
                placeholder={"Give your page a name/title."}
                maxLength="100"
                name="businessName"
                handleInputChange={(e) =>
                  handleInputChange(
                    e,
                    "invalidBusinessName",
                    "isValidBusinessName"
                  )
                }
                inputType="settings"
              />
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Label>Add an identifier</Label>
              <InputField
                className="input-box-modal-business"
                labelClassName="create-perk-label"
                placeholder="Give your business a unique identifier"
                maxLength="3000"
                name="slug"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidIdentifier", "isValidIdentifier")
                }
              />
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Label>Add a Description</Label>
              <InputField
                type="textarea"
                className="input-modal-business"
                labelClassName="create-perk-label"
                placeholder="Type something"
                maxLength="3000"
                name="description"
                handleInputChange={(e) =>
                  handleInputChange(
                    e,
                    "invalidDescription",
                    "isValidDescription"
                  )
                }
              />
            </Row>
            <div className="modal-btn-col">
              <div
                onClick={closeModal}
                className="cursor-pointer  cancel-btn-modal"
              >
                Cancel
              </div>
              <button onClick={handleSubmit} className="create-btn-modal">
                {" "}
                {loader ? (
                  <LoadingState Width={25} Height={25} color="#fff" />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default ProfileDropDown;
