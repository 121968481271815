import React from "react";
import { Calendar as ReactCalendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Label, Input, InputGroup, InputGroupText } from "reactstrap";
import { IMG } from "../../constants";

export const Calendar = ({
  placeholder,
  handleInputClick,
  visibleState,
  handleClick,
  value,
  minDate,
  maxDate,
  date = new Date(),
  handleInputChange,
  handleKeyPress,
  className,
  labelClassName,
  maxLength,
  invalid,
  invalidMsg,
  label,
  name,
  type,
  errorClassName,
}) => {
  const onSelectDate = (date) => {
    handleClick(date);
    handleInputClick();
  };

  return (
    <div style={{ width: "100%", cursor: "pointer" }}>
      <Label className={labelClassName}>{label}</Label>
      <InputGroup onClick={handleInputClick}>
        <InputGroupText
          addonType="prepend"
          className="create-perk-date-prefix"
          id={invalid ? "invalid-input-border" : ""}
          style={{ borderColor: visibleState ? "#000000" : "#cacaca" }}
        >
          To
        </InputGroupText>
        <Input
          type={type}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onClick={handleInputClick}
          placeholder={placeholder}
          className={className}
          maxLength={maxLength}
          autoComplete="off"
          name={name}
          value={value}
          id={invalid ? "invalid-input-border" : ""}
        />
      </InputGroup>
      {invalid && (
        <span className="field-icon">
          <img src={IMG.errorIcon} alt=" " width="25px" height="20px" />
        </span>
      )}
      {invalid && <div className="input-error-style">{invalidMsg}</div>}
      <div
        style={{
          display: visibleState === true ? "block" : "none",
          // position: "absolute",
          // zIndex: zIndex,
          width: "100%",
        }}
      >
        <ReactCalendar
          date={value ? new Date(value) : date}
          onChange={onSelectDate}
          direction="horizontal"
          moveRangeOnFirstSelection={false}
          className="calendar"
          minDate={minDate}
          maxDate={maxDate}
          color="#2963CA"
        />
      </div>
    </div>
  );
};
