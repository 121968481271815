import React, { useState } from "react";
import moment from "moment";
import { IMG } from "../../constants";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./remindersCalender.css";

export const RemindersCalendar = ({
  date,
  setDate,
  highLightDate,
  type,
  minDate,
  maxDate,
}) => {
  const MyContainer = ({ className, children }) => {
    return (
      <div className="reminder-popup-div">
        <div className="reminder-heading">Schedule {type} Reminder</div>
        <div className="reminder-sub-heading">
          Select when to send first ‘Get Recco’
        </div>
        <CalendarContainer className={className}>
          <div>{children}</div>
        </CalendarContainer>
        <div style={{ marginTop: "15px" }}>
          <div className="reminder-bottom-div">
            <div className="first-reminder-div"></div>
            <div className="reminder-text">
              {type === "First"
                ? "First Reminder Date"
                : "Second Reminder Date"}
            </div>
          </div>
          <div className="reminder-bottom-div" style={{ marginTop: "8px" }}>
            <div className="second-reminder-div"></div>
            <div className="reminder-text">
              {type === "Second"
                ? "First Reminder Date"
                : "Second Reminder Date"}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <DatePicker
        selected={date}
        onChange={(date) => {
          setDate(date);
        }}
        calendarContainer={MyContainer}
        highlightDates={[new Date(moment(highLightDate).format("MM/DD/YYYY"))]}
        className="reminder-date-input"
        minDate={minDate}
        maxDate={maxDate}
        popperPlacement={"bottom-end"}
        open={isOpen}
        onClickOutside={() => setIsOpen(false)}
        onInputClick={() => setIsOpen(true)}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      <span className="calender-icon" onClick={() => setIsOpen(true)}>
        <img src={IMG.calenderIcon} alt=" " width="25px" height="20px" />
      </span>
    </div>
  );
};
