import * as React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./reccoTable.css";
import { IMG, ROUTES_PATH, internalServerError } from "../../constants";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import NoDataFound from "../../common/component/noDataFound";
import LoadingState from "../../common/component/loader";
import { toastMessage } from "../ToastMessage";
import axios from "../../axios";
import { deleteReccoListUrl } from "../../utils/urls";

const columns = [
  {
    id: "listName",
    label: "",
  },
  { id: "customers", label: "CUSTOMERS", sort: "numOfCustomers" },
  { id: "dateAdded", label: "DATE ADDED", sort: "createdAt" },
  {
    id: "nudgeStatus",
    label: "REMINDER STATUS",
  },
  {
    id: "noOfNudges",
    label: "NO. OF REMINDERS",
  },
  {
    id: "perkName",
    label: "PERK NAME",
  },
  {
    id: "status",
    label: "STATUS",
  },
  {
    id: "viewAll",
    label: "",
  },
  {
    id: "delete",
    label: "",
  },
];

const ReccoTable = ({
  reccoList,
  loader,
  user,
  handleSort,
  resetState,
  localLoader,
  getReccoFam,
  toggleSecondLoader,
}) => {
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "transperant",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#F7F7F7",
      },
      border: "1px solid black",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: "1px solid black",
    },
  }))(TableRow);
  const StyledTableCell = withStyles((theme) => ({
    root: {
      "&:first-child": {
        borderRadius: "4px 0 0 4px",
        borderLeft: "0.5px solid #C8C8C8",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
        borderRight: "0.5px solid #C8C8C8",
      },
      borderBottom: "0.5px solid #C8C8C8",
      borderTop: "0.5px solid #C8C8C8",
    },
  }))(TableCell);
  const StyledTableHeadCell = withStyles((theme) => ({
    root: {
      "&:first-child": {
        borderRadius: "4px 0 0 4px",
        borderLeft: "1px solid #E3F2FF",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
        borderRight: "1px solid #E3F2FF",
      },
      borderBottom: "1px solid #E3F2FF",
      borderTop: "1px solid #E3F2FF",
      borderSpacing: "0 5px",
    },
  }))(TableCell);

  const navigate = useNavigate();
  const [isConfirmationModalOpen, setConfirmationModal] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [selectedReccoList, setSelectedReccoList] = React.useState({});
  const [sortOrder, setSortOrder] = React.useState(false);

  const handleConfirmationToggle = (e) => {
    e.preventDefault();
    setConfirmationModal(!isConfirmationModalOpen);
  };

  const handleDeleteList = () => {
    setDeleteLoader(true);
    axios
      .delete(deleteReccoListUrl(user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id, selectedReccoList.id))
      .then((response) => {
        if (response.status === 204) {
          setDeleteLoader(false);
          toastMessage("success", "Recco List deleted successfully");
          toggleSecondLoader(true);
          getReccoFam(user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id);
          resetState();
          setConfirmationModal(!isConfirmationModalOpen);
        }
      })
      .catch((error) => {
        setDeleteLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleViewRecco = (row) => {
    sessionStorage.setItem("recco-list", row?.id);
    sessionStorage.setItem("recco-list-title", row?.title);
    navigate(ROUTES_PATH.RECCO_LIST);
  };

  return (
    <React.Fragment>
      <ConfirmationPopup
        openModal={isConfirmationModalOpen}
        handleModal={handleConfirmationToggle}
        handleBtnClick={handleDeleteList}
        showLoader={deleteLoader}
        subText="Are you sure you want to delete this recco list?"
        heading={"Delete " + selectedReccoList.title}
        btnValue="Delete"
      />
      {loader.sortLoader || localLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : !loader.mainLoader && reccoList.length === 0 ? (
        <NoDataFound
          className="no-data-text"
          img={IMG.noReccoListImg}
          message="You currently don’t have any Recco List. Add your first Recco list from Add customers."
        />
      ) : (
        <TableContainer
          className="recco-table"
          id="table-scroll-box"
          // onScroll={handleScroll}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableHeadCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="column-headers"
                  >
                    {column.id === "customers" || column.id === "dateAdded" ? (
                      <div style={{ display: "flex" }}>
                        {column.label}
                        <img
                          src={IMG.arrowDownIcon}
                          style={{ marginLeft: "7px", cursor: "pointer" }}
                          onClick={() => {
                            setSortOrder(!sortOrder);
                            // let sortByStringvar = [];
                            // let orderByStringvar = [];
                            // if (column.id === "customers") {
                            //   sortByStringvar = sortByString;
                            //   sortByStringvar[0] = column.sort;
                            //   setSortByString(sortByStringvar);
                            //   orderByStringvar = orderByString;
                            //   orderByStringvar[0] = sortOrder ? "asc" : "desc";
                            //   setOrderByString(orderByStringvar);
                            // } else if (column.id === "dateAdded") {
                            //   sortByStringvar = sortByString;
                            //   sortByStringvar[1] = column.sort;
                            //   setSortByString(sortByStringvar);
                            //   orderByStringvar = orderByString;
                            //   orderByStringvar[1] = sortOrder ? "asc" : "desc";
                            //   setOrderByString(orderByStringvar);
                            // }
                            handleSort(column.sort, sortOrder ? "asc" : "desc");
                          }}
                        />
                      </div>
                    ) : (
                      column.label
                    )}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ height: "4px", width: "100%" }}></TableRow>
              {reccoList?.map((row) => {
                return (
                  <StyledTableRow tabIndex={-1} key={row.code}>
                    <StyledTableCell className="recco-list-col">
                      {row.title}
                    </StyledTableCell>
                    <StyledTableCell className="customer-date-text">
                      {row.numOfCustomers}
                    </StyledTableCell>
                    <StyledTableCell className="customer-date-text">
                      {moment(row.createdAt).format("MM/DD/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell className="nudge-status-col">
                      <div
                        className={
                          row.totalNudges === 0
                            ? "not-scheduled-text"
                            : "recco-scheduled-text"
                        }
                      >
                        {row.totalNudges === 0 ? "Not scheduled" : "Scheduled"}
                      </div>
                      {row.nudges.length > 0 && (
                        <div className="scheduled-date-text">
                          on{" "}
                          {moment(row.nudges[0].triggerDate).format("MM-DD-YY")}
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell className="nudge-number">
                      {row.totalNudges === 0
                        ? 0
                        : row.totalNudges -
                          row.nudges?.length +
                          "/" +
                          row.totalNudges}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.perk ? (
                        <>
                          <div className="perk-name-text">
                            {" "}
                            {row.perk?.name}{" "}
                          </div>
                          <div className="perk-expiry-date">
                            Expiring on{" "}
                            {moment(row.perk?.expirationDate).format(
                              "MM-DD-YY"
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="perk-name-text"> - </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell className="recco-list-col">
                      <div
                        className="status-text"
                        style={
                          row.reminderStatus === "NOT_SENT"
                            ? { color: "#FF6C02", background: "#FFD9BD" }
                            : { color: "#3c8801", background: "#d6ffac" }
                        }
                      >
                        {row.reminderStatus?.replace("_", " ")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className="view-all-text">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleViewRecco(row)}
                      >
                        View All
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <img
                        src={IMG.deleteIcon}
                        alt="delete"
                        className="cursor-pointer"
                        onClick={(e) => {
                          handleConfirmationToggle(e);
                          setSelectedReccoList(row);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
              {/* {loader.mainLoader && reccoList?.length !== 0 && (
                <TableRow style={{}}>
                  <TableCell colspan="6" style={{ "text-align": "center" }}>
                    <LoadingState />
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default ReccoTable;
