import React from "react";
import PropTypes from "prop-types";
import { Label, Row, Col } from "reactstrap";
import InputField from "./inputField";

// common component for input field
const AccountInputField = (props) => {
  let {
    handleInputChange,
    className,
    labelClassName,
    maxLength,
    placeholder,
    invalid,
    invalidMsg,
    label,
    name,
    value,
    errorClassName,
    type,
    disabled
  } = props;

  return (
    <React.Fragment>
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col md={4}>
          <Label className={labelClassName}>{label}</Label>
        </Col>
        <Col md={8}>
          <InputField
            className={className}
            placeholder={placeholder}
            maxLength={maxLength}
            name={name}
            handleInputChange={handleInputChange}
            value={value}
            invalid={invalid}
            invalidMsg={invalidMsg}
            type={type}
            errorClassName={errorClassName}
            inputType="settings"
            disabled={disabled}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

AccountInputField.propTypes = {
  handleInputChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  invalidMsg: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

AccountInputField.defaultProps = {
  type: "text",
  className: undefined,
  placeholder: "",
  invalid: false,
  labelClassName: "page-input-label",
  errorClassName: "field-icon",
};

export default AccountInputField;
