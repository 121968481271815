import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  mainLoader: false,
  verifyLoader: false,
  secondLoader: false,
  subscribeLoader: false,
  sortLoader: false,
  wordCloudLoader: false,
};

export default (state = false, { type, data }) => {
  switch (type) {
    case actionTypes.SHOW_OR_HIDE_MAIN_LOADER:
      return updateObject(state, {
        mainLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_EMAIL_VERIFY_LOADER:
      return updateObject(state, {
        verifyLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_SECOND_LOADER:
      return updateObject(state, {
        secondLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_SUBSCRIBE_LOADER:
      return updateObject(state, {
        subscribeLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_SORT_LOADER:
      return updateObject(state, {
        sortLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_WORD_CLOUD_LOADER:
      return updateObject(state, {
        wordCloudLoader: data,
      });
    default:
      return state;
  }
};
