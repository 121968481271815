import * as actionTypes from "../actions/actionTypes";

const initialState = {};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_ACCOUNT_DETAILS:
      return { ...state, ...data };
    case actionTypes.SAVE_USER_DETAILS:
      return { ...state, ...data };
    case actionTypes.SAVE_SUBSCRIPTION_DETAILS:
      return { ...state, ...data };
    case actionTypes.SAVE_BUSINESS_DETAILS:
      return { ...state, ...data };
    default:
      return state;
  }
};
