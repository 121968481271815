import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";
import FiltersBlock from "../../common/component/filtersBlock";
import ReccoCloud from "../ReccoCloud";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "100px",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  title: {
    fontFamily: "Work Sans,sans-serif",
    fontSize: "25px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    fontWeight: "bold",
    background: "linear-gradient(91.45deg, #FAFAFA 35.93%, #F1F1F1 73.39%)",
    padding: "20px 78px",
    color: "#4D4D4D",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContainer: {
    padding: "32px 78px",
    background: "#FFFFFF",
    ["@media screen and (max-width:820px)"]: {
      padding: "32px 60px",
    },
  },
}));

const WordCloudPopup = ({
  openModal,
  handleModal,
  getFilterValue,
  reccoCloud,
  loader,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      scroll={"body"}
      fullWidth={true}
      maxWidth="sm"
      open={openModal}
      onClose={handleModal}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      disableBackdropClick
    >
      <div className={classes.title}>
        <span>Get Recco’s Word Cloud</span>
        <img
          src={IMG.cancelIcon}
          alt="Close"
          width="29px"
          height="29px"
          onClick={handleModal}
          className="cursor-pointer"
        />
      </div>
      <div className={classes.dialogContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "40px",
            marginLeft: "15px",
          }}
        >
          <FiltersBlock
            getFilterValue={getFilterValue}
            isDateRequired={true}
            graphType="reccoCloud"
          />
        </div>
        <ReccoCloud reccoCloud={reccoCloud} loader={loader} />
      </div>
    </Dialog>
  );
};
export default WordCloudPopup;
