import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "reactstrap";
import { IMG } from "../../constants";
import ButtonItem from "../../common/component/button";
import "./imageTemplates.css";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingState from "../../common/component/loader";

const useStyles = makeStyles(theme => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "100px",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  dialogContainer: {
    padding: "40px 80px",
  },
  title: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
    color: "#4D4D4D",
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const ImageTemplatesPopup = ({
  openModal,
  handleModal,
  handleSelectTemplate,
  data,
  selectedImg,
  handleSelectedImg,
  getTemplates,
}) => {
  const classes = useStyles();

  const [stopPagination, setStopPagination] = useState(false);
  const [page, setPage] = useState(1);

  const getData = (currentPage = page) => {
    const requestData = {
      type: "image",
      skip: currentPage * 10,
      limit: 10,
    };
    getTemplates(requestData, data);
    setPage(page + 1);
    setStopPagination(true);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleModal}
      fullWidth="md"
      maxWidth="md"
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      disableBackdropClick
    >
      <div className={classes.dialogContainer}>
        <div className={classes.headingDiv}>
          <div className={classes.title}>Choose Image</div>
          <img
            src={IMG.cancelIcon}
            alt="Close"
            width="29px"
            height="29px"
            onClick={() => {
              handleSelectedImg(null, "");
              handleModal();
            }}
            className="cursor-pointer"
          />
        </div>
        <InfiniteScroll
          dataLength={data.length}
          next={getData}
          hasMore={!stopPagination}
          height={"480px"}
          scrollThreshold={1}
          loader={
            <div className="table-loader-center">
              <LoadingState color="#2963ca" />
            </div>
          }
          scrollableTarget={"list"}
          className="scroll"
        >
          <Row style={{ marginTop: "20px" }} className="row-margin">
            {data?.map((img, index) => (
              <Col md={3} className={"col-padding"}>
                <div
                  className={
                    selectedImg === null
                      ? "images-div"
                      : img.id !== selectedImg.id
                      ? "unselected-image images-div"
                      : "selected-image images-div"
                  }
                  key={index}
                  onClick={() => handleSelectedImg(img, "template")}
                >
                  <img src={img.content} alt="" className="image-css" />
                </div>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
        <div className="template-done-div">
          <ButtonItem
            className="template-done-button"
            buttonValue="Done"
            handleButtonAction={() => handleSelectTemplate(selectedImg)}
            disabled={selectedImg ? false : true}
          />
        </div>
      </div>
    </Dialog>
  );
};
export default ImageTemplatesPopup;
