import HomeNew from "../../components/HomeNew";
import HomeReturning from "./homeReturning";
import "./home.css";
import { useEffect, useState } from "react";

const LandingHome = () => {
  const [status, setStatus] = useState("false");

  useEffect(() => {
    const localStatus = localStorage.getItem("isFirstHome");
    setStatus(localStatus);
  }, [status]);

  return (
    <div>
      {status === null || status === "false" ? (
        <HomeReturning />
      ) : (
        <HomeNew setStatus={() => setStatus("false")} />
      )}
    </div>
  );
};
export default LandingHome;
