import React, { Fragment, useState } from "react";
import { Grid, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import AddCardForm from "./AddCardForm";
import { IMG } from "../../constants";

const StripeCards = ({
  stripePromise,
  openStripeModal,
  handleStripeModal,
  user,
  planId,
  getUserDetails,
  toggleMainLoader,
  mainLoader,
  type,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  return (
    <Fragment>
      {openStripeModal && (
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openStripeModal}
          onClose={handleStripeModal}
          disableBackdropClick
        >
          <div className={classes.title}>
            <span>Add Card Details</span>
            <img
              src={IMG.cancelIcon}
              alt="Close"
              width="15px"
              height="15px"
              onClick={handleStripeModal}
              className="cursor-pointer"
            />
          </div>
          <Grid
            container
            direction="column"
            className={classes.dialogContainer}
          >
            <AddCardForm
              stripePromise={stripePromise}
              handleCardFormOpen={handleStripeModal}
              user={user}
              planId={planId}
              getUserDetails={getUserDetails}
              toggleMainLoader={toggleMainLoader}
              mainLoader={mainLoader}
              type={type}
            />
            <div className={classes.paymentNote}>
              Powered by <span className={classes.stripeText}>stripe</span>
            </div>
          </Grid>
        </Dialog>
      )}
    </Fragment>
  );
};
export default StripeCards;

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-0.01em",
    fontWeight: "bold",
    background: "linear-gradient(91.45deg, #FAFAFA 35.93%, #F1F1F1 73.39%)",
    height: "20px",
    padding: "40px 60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContainer: {
    padding: "35px 60px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    ["@media(max-width:768px)"]: {
      padding: "20px",
    },
  },
  paymentNote: {
    marginTop: "25px",
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "130%",
    color: "#A4B0C0",
    textAlign: "center",
  },
  stripeText: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "130%",
    color: "#6D6D6D ",
  },
  paymentDate: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "130%",
    textAlign: "center",
    color: "#2963CA",
  },
}));
