import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import NurtureFamHeading from "../../components/NurtureFamHeading";
import SearchBar from "../../common/component/searchBar";
import IconButton from "../../common/component/iconButton";
import LoadingState from "../../common/component/loader";
import InfiniteScroll from "react-infinite-scroll-component";
import ReccoFamTable from "./reccoFamTable";
import { IMG, ROUTES_PATH } from "../../constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import {
  getReccoFamDetailsService,
  searchReccoFamDetailsService,
  nurtureExportCSVService,
} from "../../services";
import "./nurture.css";

const Nurture = (props) => {
  const navigate = useNavigate();
  const [famList, setFamList] = useState([]);
  const [searchfamsList, setSearchFamsList] = useState([]);
  const [search, setSearch] = useState("");
  const [stopPagination, setStopPagination] = useState(false);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [csvLoader, setCSVLoader] = useState(false);

  useEffect(() => {
    if (search.length > 0) {
      getSearchData();
    } else {
      if (!stopPagination) {
        getData();
      }
    }
  }, [search.length]);

  useEffect(() => {
    props.toggleSecondLoader(true);
    props.getReccoFam(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
    props.toggleMainLoader(true);
    props.getBusinessPerk(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
    props.getRemainingReccoFam(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
  }, []);

  const getData = (currentPage = page, sortBy, orderBy) => {
    const requestData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      skip: currentPage * 10,
      limit: 10,
      sortBy: sortBy,
      orderBy: orderBy,
    };

    getReccoFamDetailsService(requestData)
      .then((res) => {
        if (res.data.length > 0) {
          if (currentPage === 0) {
            setFamList(res.data);
          } else {
            setFamList((prevState) => [...prevState, ...res.data]);
          }
          setPage((prevState) => prevState + 1);
        }
        if (res.data.length < 10) {
          setStopPagination(true);
        }

        props.toggleSortLoader(false);
        setLoader(false);
      })
      .catch((err) => {

        props.toggleSortLoader(false);
        setLoader(false);
      });
  };
  const getSearchData = (currentPage = page) => {
    const requestData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      search: search,
      skip: currentPage * 10,
      limit: 10,
    };
    setLoader(true);
    searchReccoFamDetailsService(requestData)
      .then((res) => {
        if (res.data.length > 0) {
          setSearchFamsList(res.data);
          setPage((prevState) => prevState + 1);
        }
        if (res.data.length < 10) {
          setStopPagination(true);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const handleSort = (orderByStringvar, sortByStringvar) => {
    setLoader(true);
    getData(0, sortByStringvar, orderByStringvar);
  };
  const resetInitialState = () => {
    setLoader(true);
    setFamList([]);
    getData(0);
    props.toggleSecondLoader(true);
    props.getReccoFam(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
    props.toggleMainLoader(true);
    props.getBusinessPerk(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
  };
  const handleSearch = (e) => {
    setSearchFamsList([]);
    setPage(0);
    setSearch(e.target.value);
  };
  const handleExportCSV = () => {
    setCSVLoader(true);
    nurtureExportCSVService(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Recco Fams.csv");
        document.body.appendChild(link);
        link.click();
        setCSVLoader(false);
      })
      .catch((err) => {
        setCSVLoader(false);
      });
  };
  return (
    <div className="nurture-div">
      {props.loader.secondLoader || props.loader.mainLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <div className="nurture-heading-div">Nurture your Recco Fam</div>
          <NurtureFamHeading
            reccoFam={props.reccoFam}
            perk={props.perkDetails}
            remainingReccoFam={props.remainingReccoFam}
          />

          <div md={12} className="recco-fam-heading-text">
            Your Recco Fam :
          </div>
          {famList?.length > 0 && (
            <Row className="serach-row-margin">
              <Col md={12} lg={6} xl={6}>
                <SearchBar
                  className="recco-fam-serach-input"
                  placeholder="Search for customers, e-mails, phone no.s"
                  maxLength="500"
                  name="search"
                  type="search"
                  handleInputChange={(e) => handleSearch(e)}
                />
              </Col>
              <Col md={12} lg={6} xl={6} className="buttons-div">
                <IconButton
                  className="nurture-fam-btn"
                  buttonValue="Nurture Fam"
                  icon={IMG.logoMark}
                  type="upload"
                  iconHeight="23px"
                  iconWidth="23px"
                  handleButtonAction={() =>
                    navigate(ROUTES_PATH.NURTURE_ADD_INFO)
                  }
                />
                <IconButton
                  className="export-csv-btn"
                  buttonValue="Export CSV"
                  icon={IMG.blueUploadIcon}
                  type="upload"
                  iconHeight="17px"
                  iconWidth="17px"
                  handleButtonAction={handleExportCSV}
                  disabled={csvLoader}
                  showLoader={csvLoader}
                  loaderHeight="25px"
                  loaderWidth="25px"
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12}>
              <InfiniteScroll
                dataLength={
                  search || searchfamsList.length > 0
                    ? searchfamsList?.length
                    : famList?.length
                }
                next={
                  search || searchfamsList.length > 0 ? getSearchData : getData
                }
                hasMore={!stopPagination}
                height={600}
                scrollThreshold={1}
                loader={
                  <div className="table-loader-center">
                    <LoadingState color="#2963ca" />
                  </div>
                }
                scrollableTarget={"list"}
                className="recco-table-scroll"
              >
                <ReccoFamTable
                  {...props}
                  handleSort={handleSort}
                  search={search}
                  famList={
                    search || searchfamsList.length > 0
                      ? searchfamsList
                      : famList
                  }
                  resetState={resetInitialState}
                  localLoader={loader}
                />
              </InfiniteScroll>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    reccoFam: state.reccoFam,
    perkDetails: state.perkDetails,
    remainingReccoFam: state.remainingReccoFam,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getReccoFam: ActionCreators.getReccoFam,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      getBusinessPerk: ActionCreators.getBusinessPerk,
      toggleSortLoader: ActionCreators.toggleSortLoader,
      getRemainingReccoFam: ActionCreators.getRemainingReccoFam,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Nurture);
