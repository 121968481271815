import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import "./settings.css";
import { searchAddAdmin, getBusinesAdminsUrl } from "../../utils/urls";
import { removeBusinessAdminService } from "../../services";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import ButtonItem from "../../common/component/button";
import { Dialog } from "@material-ui/core";
import SearchBar from "../../common/component/searchBar";
import axios from "../../axios";
import { DropDownCard } from "../../components/DropDownCard";
import { IMG } from "../../constants";

const columns = [
  {
    id: "name",
    label: "Name",
    sort: "fullName",
    index: 0,
  },

  {
    id: "email",
    label: "E-mail",
  },
  {
    id: "phoneNumber",
    label: "Phone number.",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "delete",
    label: "Action Item",
  },
];

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "150px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },

  heading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "120%",
    padding: "40px 20px 24px 20px",
    letterSpacing: "-0.01em",
    color: "#323232",
    borderBottom: "1px solid #EBEBEB",
  },
  dialogContainer: {
    padding: "24px 50px 30px 50px",
    backgroundColor: "#fff",
  },
  bodyContent: {},
  cancelButton: {
    backgroundColor: "transparent !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2963CA !important",
    height: "50px",
    width: "102px",
    marginRight: "12px",
  },
  buttonStyle: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff !important",
    height: "50px",
    width: "202px",
  },
  searchBar: {
    maxLength: 500,
    width: "99%",
    background: "#F5F5F5",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px 0px 10px 10px",
    padding: 10,
  },
  modalHeading: {
    background: "linear-gradient(91.45deg, #fafafa 35.93%, #f1f1f1 73.39%)",
    borderRadius: "8px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
    color: "#1a1a1a",
    padding: "30px 40px",
  },
}));

const AdminManagement = ({ adminList, props, setUpdater, updater }) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchList, setSearchList] = React.useState([]);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "transperant",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#F7F7F7",
      },
    },
  }))(TableRow);
  const StyledTableCell = withStyles((theme) => ({
    root: {
      "&:first-child": {
        borderRadius: "4px 0 0 4px",
        borderLeft: "0.5px solid #C8C8C8",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
        borderRight: "0.5px solid #C8C8C8",
      },
      borderBottom: "0.5px solid #C8C8C8",
    },
  }))(TableCell);
  const StyledTableHeadCell = withStyles((theme) => ({
    root: {
      borderBottom: "0.5px solid #C8C8C8",
    },
  }))(TableCell);

  const [addAdmin, setAddAdmin] = React.useState(false);
  const [isConfirmationModalOpen, setConfirmationModal] = React.useState(false);

  const handleConfirmationToggle = (e) => {
    e.preventDefault();
    setConfirmationModal(!isConfirmationModalOpen);
  };
  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);

  const handleClose = function () {
    setAddAdmin(false);
    setSearchList([]);
    setSearchQuery("");
  };

  //delete admin states and function
  const [adminToBeDeleted, setAdminToBeDeleted] = React.useState();
  const [removeLoader, setRemoveLoader] = React.useState(false);
  const deleteAdmin = async (businessId, managerId) => {
    setRemoveLoader(true);
    try {
      await removeBusinessAdminService(businessId, managerId);
    } catch (err) {
      
    }
    setRemoveLoader(false);
    setConfirmationModal(false);
    setUpdater(!updater);
  };
  const isOwner =
    props.user.business[localStorage.getItem("businessIndex") || 0]?.businessForAccount.belongsTo
      .id === props.user.account.id;
  const currentId = props.user.account.id;

  const addUser = async (accountInvited) => {
    try {
      const payload = {
        accountInvited: accountInvited,
        accountInvitedBy: props?.user?.account?.id,
      };
      await axios.post(
        getBusinesAdminsUrl(props.user.business[localStorage.getItem("businessIndex") || 0]
        .businessForAccount.id),
        payload
      );
      setUpdater(true);
      setAddAdmin(false);
      setSearchQuery("");
      setSearchList([]);
    } catch (err) {
    }
  };

  const addAdminAccountSearch = async () => {
    try {
      const payload = {
        email: searchQuery,
      };
      const searchData = await axios.post(
        searchAddAdmin(props.user.business[localStorage.getItem("businessIndex") || 0]
        .businessForAccount.id),
        payload
      );
      setSearchList(searchData.data);
    } catch (err) {
    }
  };

  React.useEffect(() => {
    (async () => {
      if (searchQuery?.length > 0) {
        await addAdminAccountSearch();
      }
    })();
  }, [searchQuery]);

  return (
    <div className="settings-main-div">
      {isOwner && (
        <ButtonItem
          className="add-admin-button"
          buttonValue="Add Admin"
          type="popup-btn"
          handleButtonAction={() => setAddAdmin(true)}
        />
      )}

      <Dialog
        open={addAdmin}
        onClose={handleClose}
        fullWidth="md"
        scroll={"body"}
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <div>
          <div
            className={classes.modalHeading}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Add new Administrator{" "}
            <button
              onClick={handleClose}
              style={{ background: "none", border: "none" }}
            >
              <img src={IMG.crossIcon} />
            </button>
          </div>
          <div className={classes.dialogContainer}>
            <SearchBar
              placeholder="Add an admin.."
              maxLength="500"
              className="recco-fam-serach-input"
              name="search"
              type="search"
              handleInputChange={(e) => {
                setSearchList([]);
                setSearchQuery(e.target.value);
              }}
            />
            {searchList?.length > 0 && (
              <div className={classes.searchBar}>
                {searchList?.length > 0 &&
                  searchList.map((adminAccount, index) => (
                    <DropDownCard
                      key={index}
                      account={adminAccount}
                      addUser={addUser}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <TableContainer className="customers-table" id="table-scroll-box">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableHeadCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="recco-fam-column-headers"
                >
                  {column.id === "name" ||
                  column.id === "famSize" ||
                  column.id === "toatlReccos" ? (
                    <div style={{ display: "flex" }}>{column.label}</div>
                  ) : (
                    column.label
                  )}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {adminList.map(
              (item, index) =>
                item?.account && (
                  <StyledTableRow key={index}>
                    <StyledTableCell className="recco-list-col">
                      {item?.account?.displayName}
                    </StyledTableCell>
                    <StyledTableCell className="recco-fam-cells">
                      {item?.account?.email}
                    </StyledTableCell>
                    <StyledTableCell className="recco-fam-cells">
                      {item?.account?.phoneNumber?.phoneNumber
                        ? `(${item?.account?.phoneNumber?.countryCode})
                  ${item?.account?.phoneNumber?.phoneNumber}`
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell className="recco-fam-cells">
                      {item?.status}
                    </StyledTableCell>
                    <StyledTableCell className="recco-fam-cells">
                      {isOwner || currentId === item?.account?.id ? (
                        item.type !== "SUPER_ADMIN" && (
                          <div
                            onClick={function (e) {
                              handleConfirmationToggle(e);
                              setAdminToBeDeleted(item);
                            }}
                            className="remove-action"
                          >
                            Remove
                          </div>
                        )
                      ) : (
                        <></>
                      )}

                      <ConfirmationPopup
                        openModal={isConfirmationModalOpen}
                        handleModal={handleConfirmationToggle}
                        heading="Remove Admin"
                        btnValue="Remove"
                        handleBtnClick={() =>
                          deleteAdmin(
                            adminToBeDeleted?.business?.id,
                            adminToBeDeleted?.account?.id
                          )
                        }
                        showLoader={removeLoader}
                        subText={`Are you sure you want to remove ${adminToBeDeleted?.account?.displayName}`}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminManagement;
