import * as actionTypes from "./actionTypes";

export const getReccoList = requestData => ({
  type: actionTypes.GET_RECCO_LIST,
  requestData,
});
export const saveReccoList = data => ({
  type: actionTypes.SAVE_RECCO_LIST,
  data,
});
export const getReccoFam = businessId => ({
  type: actionTypes.GET_RECCO_FAM,
  businessId,
});
export const saveReccoFam = data => ({
  type: actionTypes.SAVE_RECCO_FAM,
  data,
});
export const getTemplates = (requestData, data) => ({
  type: actionTypes.GET_TEMPLATES,
  requestData,
  data,
});
export const saveImgTemplates = data => ({
  type: actionTypes.SAVE_IMG_TEMPLATES,
  data,
});
export const saveTextTemplates = data => ({
  type: actionTypes.SAVE_TEXT_TEMPLATES,
  data,
});
export const getReccoListAdvocates = requestData => ({
  type: actionTypes.GET_RECCO_LIST_ADVOCATES,
  requestData,
});
export const saveReccoListAdvocates = requestData => ({
  type: actionTypes.SAVE_RECCO_LIST_ADVOCATES,
  requestData,
});
export const getTemplatesInfo = reccoListId => ({
  type: actionTypes.GET_TEMPLATES_INFO,
  reccoListId,
});
export const saveTemplatesInfo = data => ({
  type: actionTypes.SAVE_TEMPLATES_INFO,
  data,
});
export const getRemainingRecco = data => ({
  type: actionTypes.GET_REMAINING_RECCO,
  data,
});
export const saveRemainingRecco = data => ({
  type: actionTypes.SAVE_REMAINING_RECCO,
  data,
});
export const getBusinessPerk = data => ({
  type: actionTypes.GET_BUSINESS_PERK,
  data,
});

export const saveBusinessPerk = data => ({
  type: actionTypes.SAVE_BUSINESS_PERK,
  data,
});
export const getReccoListDetails = data => ({
  type: actionTypes.GET_RECCO_LIST_DETAILS,
  data,
});
export const getEntityList = data => ({
  type: actionTypes.GET_ENTITIES,
  data,
})

export const saveReccoListDetails = data => ({
  type: actionTypes.SAVE_RECCO_LIST_DETAILS,
  data,
});
export const saveEntities = data => ({
  type: actionTypes.SAVE_ENTITIES,
  data,
})
