import React, { useEffect, useState } from "react";
import moment from "moment";
import "./getInsights.css";
import { Row, Col } from "reactstrap";
import InsightsCard from "../../common/component/insightsCard";
import InsightsGraph from "../../common/component/insightsGraph";
import { IMG, insightsInfoArray } from "../../constants";
import ReccoCloud from "../../components/ReccoCloud";
import InsightsBarGraph from "../../common/component/insightsBarGraph";
import FiltersBlock from "../../common/component/filtersBlock";
import VennDiagram from "../../components/VennDiagram";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import LoadingState from "../../common/component/loader";
import CardsInfoPopup from "../../components/CardsInfoPopup";

const GetInsights = (props) => {
  const [isCardInfoModalOpen, setCardInfoModal] = useState(false);

  useEffect(() => {
    const from = moment().startOf("week").format("MM/DD/YYYY");
    const to = moment().format("MM/DD/YYYY");
    handleReccoCloud(from, to);
    handlePerkPerformance(from, to, "week");
    handleNurturePerformance(from, to, "week");
    handleCardData();
  }, []);
  const handleConfirmationToggle = () => {
    setCardInfoModal(!isCardInfoModalOpen);
  };
  const handleCardData = () => {
    props.toggleMainLoader(true);
    props.getInsightsCard(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
    props.toggleSubscribeLoader(true);
    props.getReccoMedium(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
  };

  const getFilterValue = (type, value, graphType) => {
    let from, to;
    if (type === "dateRange") {
      from = moment(value[0]).format("MM/DD/YYYY");
      to = moment(value[1]).format("MM/DD/YYYY");
    } else {
      from = moment().startOf(value).format("MM/DD/YYYY");
      to = moment().format("MM/DD/YYYY");
    }
    if (graphType === "reccoCloud") {
      handleReccoCloud(from, to);
    } else if (graphType === "perkViews") {
      handlePerkPerformance(from, to, type === "dateRange" ? type : value);
    } else if (graphType === "nurtureViews") {
      handleNurturePerformance(from, to, type === "dateRange" ? type : value);
    }
  };

  const handleReccoCloud = (from, to) => {
    const requestedData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      from,
      to,
    };
    props.toggleWordCloudLoader(true);
    props.getReccoCloud(requestedData);
  };
  const handlePerkPerformance = (from, to, filterBy) => {
    const requestedData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      from,
      to,
      filterBy,
    };
    props.toggleSortLoader(true);
    props.getPerkPerformance(requestedData);
  };
  const handleNurturePerformance = (from, to, filterBy) => {
    const requestedData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      from,
      to,
      filterBy,
    };
    props.toggleEmailVerifyLoader(true);
    props.getNurturePerformance(requestedData);
  };
  return (
    <div className="insights-main-div">
      <CardsInfoPopup
        openModal={isCardInfoModalOpen}
        handleModal={handleConfirmationToggle}
        dataArray={insightsInfoArray}
      />
      <div className="insights-main-header">
        Dashboard
        <img
          src={IMG.infoIcon}
          alt="Information"
          style={{ marginLeft: "10px" }}
          className="cursor-pointer"
          onClick={handleConfirmationToggle}
        />
      </div>
      <div className="insights-header">
        Be in the know for all community action
      </div>
      {props.loader?.secondLoader || props.loader?.mainLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <Row className="insights-cards-row">
            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Recco fam"}
                value={props.insightsCards[0]?.totalReccoFam}
                percentage={props.insightsCards[0]?.changesThisWeek}
              />
            </Col>
            {/* <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Friends-of-Friends"}
                value={props.insightsCards[8]?.friends}
                percentage={props.insightsCards[8]?.changesThisWeek}
              />
            </Col> */}
            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Total Addressable Market"}
                value={props.insightsCards[1]?.tam}
                percentage={props.insightsCards[1]?.changesThisWeek}
              />
            </Col>
            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Reccos Saved"}
                value={props.insightsCards[7]?.reccoSaved}
                percentage={props.insightsCards[7]?.changesThisWeek}
              />
            </Col>

            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Recco Index"}
                value={props.insightsCards[2]?.reccoIndex}
                percentage={props.insightsCards[2]?.changesThisWeek}
              />
            </Col>
            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"‘Get reccos’ sent"}
                value={props.insightsCards[6]?.reccoSent}
                percentage={props.insightsCards[6]?.changesThisWeek}
              />
            </Col>
            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Perks Seen"}
                value={props.insightsCards[5]?.perkView}
                percentage={props.insightsCards[5]?.changesThisWeek}
              />
            </Col>
            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Number of Reccos"}
                value={props.insightsCards[3]?.totalreccos}
                percentage={props.insightsCards[3]?.changesThisWeek}
              />
            </Col>
            <Col md={6} lg={4} xl={3} style={{ marginTop: "25px" }}>
              <InsightsCard
                header={"Recco-dittos"}
                value={props.insightsCards[4]?.totalDittos}
                percentage={props.insightsCards[4]?.changesThisWeek}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "60px" }}>
            <Col md={12} lg={12}>
              <div className="insights-graph-header heading-bottom">
                Nurture Performance Over Time
              </div>
              <div>
                <InsightsGraph
                  getFilterValue={getFilterValue}
                  intervals={props.nurturePerformance?.intervals}
                  views={props.nurturePerformance?.nurtureViews}
                  yaxis_title="Nurture Viewed"
                  graphType="nurtureViews"
                  loader={props.loader}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={7}>
              <div
                className="insights-graph-header"
                style={{ marginBottom: "40px" }}
              >
                Perk Performance Over Time
              </div>
              <div>
                <InsightsGraph
                  getFilterValue={getFilterValue}
                  intervals={props.perkPerformance?.intervals}
                  views={props.perkPerformance?.perkViews}
                  yaxis_title="Perks Viewed"
                  graphType="perkViews"
                  loader={props.loader}
                />
              </div>
            </Col>
            <Col md={12} lg={5}>
              <div className="insights-graph-header">
                Total Addressable Market
              </div>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <div className="insights-graph-count">
                  {props.insightsCards[1]?.tam}
                </div>
                <div className="insights-graph-label">
                  <div className="insight-graph-percentage-value">
                    +{props.insightsCards[1]?.changesThisWeek}%
                  </div>
                  <img src={IMG.rightUpArrow} />
                </div>
              </div>

              {props.insightsCards[1]?.tam > 0 ? (
                <VennDiagram
                  tam={props.insightsCards[1]?.tam}
                  reccoFam={props.insightsCards[0]?.totalReccoFam}
                />
              ) : (
                <div
                  className="no-graph-div"
                  style={{ height: "350px", marginTop: "100px" }}
                >
                  Total Addressable market is 0.
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={7} style={{ marginTop: "35px" }}>
              <div
                className="insights-graph-header"
                style={{ marginBottom: "20px" }}
              >
                Here’s what your Recco fam is saying about you
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "40px",
                  }}
                >
                  <FiltersBlock
                    getFilterValue={getFilterValue}
                    isDateRequired={true}
                    graphType="reccoCloud"
                  />
                </div>
                <ReccoCloud isGetInsights={true} {...props} />
              </div>
            </Col>
            <Col md={12} lg={5} style={{ marginTop: "35px" }}>
              <div className="insights-graph-header">Get Recco Medium</div>
              <div style={{ marginTop: "20px" }}>
                <InsightsBarGraph data={props.reccoMedium} />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
    reccoCloud: state.reccoCloud,
    insightsCards: state.insightsCards,
    perkPerformance: state.perkPerformance,
    reccoMedium: state.reccoMedium,
    nurturePerformance: state.nurturePerformance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getReccoCloud: ActionCreators.getReccoCloud,
      toggleWordCloudLoader: ActionCreators.toggleWordCloudLoader,
      getInsightsCard: ActionCreators.getInsightsCard,
      getPerkPerformance: ActionCreators.getPerkPerformance,
      toggleSortLoader: ActionCreators.toggleSortLoader,
      getReccoMedium: ActionCreators.getReccoMedium,
      toggleSubscribeLoader: ActionCreators.toggleSubscribeLoader,
      getNurturePerformance: ActionCreators.getNurturePerformance,
      toggleEmailVerifyLoader: ActionCreators.toggleEmailVerifyLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GetInsights);
