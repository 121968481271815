import Plot from "react-plotly.js";
import "./reccoProgressBar.css";

const ReccoProgressBar = ({ remainingReccos }) => {
  const remaining =
    (remainingReccos?.reccosRemaining / remainingReccos?.reccoAllowed) * 100;
  const used =
    ((remainingReccos?.reccoAllowed - remainingReccos?.reccosRemaining) /
      remainingReccos?.reccoAllowed) *
    100;

  return (
    <div>
      <Plot
        data={[
          {
            values: [used, remaining],
            domain: { x: [1, 1], y: [1, 1] },
            hole: 0.6,
            type: "pie",
            marker: {
              colors: ["#F1F1F1", "#FDB640"],
              line: { color: "#F1F1F1", width: "5" },
            },
            textinfo: "none",
            hoverinfo: "none",
          },
        ]}
        layout={{
          annotations: [
            {
              font: {
                size: 16,
                family: "Inter,sans-serif",
                color: "#527BEF",
              },
              showarrow: false,
              text: `${remainingReccos?.reccosRemaining}`,
              x: 0.5,
              y: 0.5,
            },
          ],
          margin: { t: 2, b: 2, l: 0, r: 0 },
          showlegend: false,
          // direction: "counterclockwise",
        }}
        displayModeBar={false}
        displaylogo={false}
        style={{ width: "100px", height: "100px" }}
      />
    </div>
  );
};
export default ReccoProgressBar;
