import React, { Fragment } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonItem from "../../common/component/button";

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "150px",
    background: "linear-gradient(135.42deg, #FFFFFF 5.26%, #EEEEEE 100%)",
    borderRadius: "10px",
  },

  heading: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "120%",
    padding: "40px 40px 24px 40px",
    letterSpacing: "-0.01em",
    color: "#323232",
    borderBottom: "1px solid #EBEBEB",
  },
  dialogContainer: {
    padding: "24px 40px 30px 40px",
  },
  bodyContent: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#222222",
    marginBottom: "24px",
    maxWidth: "418px",
  },
  cancelButton: {
    backgroundColor: "transparent !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2963CA !important",
    height: "50px",
    width: "102px",
    marginRight: "12px",
  },
  buttonStyle: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "8px !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff !important",
    height: "50px",
    width: "102px",
  },
}));

const ConfirmationPopup = ({
  openModal,
  handleModal,
  showLoader,
  handleBtnClick,
  subText,
  heading,
  btnValue,
  deleteName,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleModal}
        fullWidth="md"
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        disableBackdropClick
      >
        <div>
          <div className={classes.heading}>{heading}</div>
          <div className={classes.dialogContainer}>
            {heading === "Delete Recco" ? (
              <div className={classes.bodyContent}>
                {subText + " "}
                <span style={{ fontWeight: "600" }}>{deleteName}</span> recco?
                <div style={{ marginTop: "5px" }}>
                  {" "}
                  You can’t undo this action.
                </div>
              </div>
            ) : (
              <div className={classes.bodyContent}>{subText}</div>
            )}

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonItem
                className={classes.cancelButton}
                buttonValue="Cancel"
                handleButtonAction={handleModal}
              />
              <ButtonItem
                className={classes.buttonStyle}
                buttonValue={btnValue}
                handleButtonAction={handleBtnClick}
                disabled={showLoader}
                showLoader={showLoader}
                type="popup-btn"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
export default ConfirmationPopup;
