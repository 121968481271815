import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import "./common.css";

const Plans = (props) => {
  let { planName, planDeatils, className, value, type } = props;

  return (
    <Row className={className}>
      <Col className="plans-name-text">{planName}</Col>
      {planDeatils.map((plan, index) => (
        <Col key={index}>
          <div
            className={
              plan === "Custom Integrations" ? "custom-plan-text" : "plans-text"
            }
            style={{
              background:
                type === "update" && value === index
                  ? "rgba(255, 205, 120, 0.3)"
                  : "#ffffff",
            }}
          >
            {plan === " " ? "-" : plan}
          </div>
        </Col>
      ))}
    </Row>
  );
};

Plans.propTypes = {
  className: PropTypes.string,
  planName: PropTypes.string,
  planDeatils: PropTypes.array,
};

export default Plans;
