import { useState } from "react";
import { Row, Col } from "reactstrap";
import ButtonItem from "../../common/component/button";
import moment from "moment";
import CreatePerkPopup from "../../components/CreatePerkPopup";
import { deleteBusinessPerkService } from "../../services";
import { toastMessage } from "../../components/ToastMessage";
import { internalServerError } from "../../constants";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import "../../components/PerkDetails/perk.css";

const PerkDetails = ({
  user,
  perk,
  toggleMainLoader,
  getReccoListDetails,
  getNurtureDetails,
  getBusinessPerk,
}) => {
  const [createPerkModal, setCreatePerkModal] = useState(false);
  const [removePerkModal, setRemovePerkModal] = useState(false);
  const [updatePerkModal, setUpdatePerkModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const handlePerkModal = () => {
    setCreatePerkModal(!createPerkModal);
  };

  const handleRemovePerkModal = () => {
    setRemovePerkModal(!removePerkModal);
  };

  const handleUpdatePerkModal = () => {
    setUpdatePerkModal(!updatePerkModal);
  };

  const handleDeletePerk = () => {
    setLoader(true);

    const data = {
      businessId:
        user.business[localStorage.getItem("businessIndex") || 0]
          .businessForAccount.id,
      perkId: perk?.id,
    };
    deleteBusinessPerkService(data)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          toastMessage(
            "success",
            "Perk deleted from the business successfully"
          );
          toggleMainLoader(true);
          getBusinessPerk(
            user.business[localStorage.getItem("businessIndex") || 0]
              .businessForAccount.id
          );
          handleRemovePerkModal();
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  return (
    <>
      <CreatePerkPopup
        openModal={createPerkModal}
        handleModal={handlePerkModal}
        businessId={
          user.business[localStorage.getItem("businessIndex") || 0]
            .businessForAccount.id
        }
        perk={perk}
        toggleMainLoader={toggleMainLoader}
        getReccoListDetails={getReccoListDetails}
        getNurtureDetails={getNurtureDetails}
        getBusinessPerk={getBusinessPerk}
      />
      <ConfirmationPopup
        openModal={removePerkModal}
        handleModal={handleRemovePerkModal}
        handleBtnClick={handleDeletePerk}
        showLoader={loader}
        subText={
          "Your existing recco list's and nurtures perk will be deleted after deleting this perk. Are you sure you want to delete this perk?"
        }
        heading="Delete perk"
        btnValue="Delete"
      />
      <ConfirmationPopup
        openModal={updatePerkModal}
        handleModal={handleUpdatePerkModal}
        handleBtnClick={() => {
          handleUpdatePerkModal();
          handlePerkModal();
        }}
        showLoader={loader}
        subText="Your existing recco list's and nurtures perk will be replaced after updating this perk. Are you sure you want to update this perk?"
        heading="Update perk"
        btnValue="Continue"
      />
      <div className="current-perk-text">Your Current Perk :</div>
      {perk && (
        <div className="active-div">
          {perk.status === "ACTIVE" ? (
            <>
              <span className="perk-active-round"></span>Active
            </>
          ) : (
            <>
              <span className="perk-expired-round"></span>Expired
            </>
          )}
        </div>
      )}
      <div className="show-perk-div">
        <Row>
          <Col md={8} lg={7} xl={6}>
            {perk ? (
              <div className="perk-data-div">
                <div style={{ display: "flex", marginBottom: "9px" }}>
                  <div>
                    <img src={perk?.image} alt="" height="90px" width="100px" />
                  </div>
                  <div style={{ marginLeft: "12px" }}>
                    <div className="perk-added-text">Perk added !</div>
                    <div className="perk-name-css">{perk?.name}</div>
                    <div className="perk-details-name">
                      YOUR PROMO CODE :{" "}
                      <span className="perk-details-value">
                        {perk?.promoCode}
                      </span>
                    </div>
                    {perk?.productUrl && (
                      <div className="perk-details-name">
                        YOUR PROMO LINK :{" "}
                        <span className="perk-details-value">
                          {perk?.productUrl}
                        </span>
                      </div>
                    )}

                    <div className="perk-details-name">
                      VALIDITY :{" "}
                      <span className="perk-details-value">
                        {moment(perk?.expirationDate).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="remove-perk-div">
                  <ButtonItem
                    className="skip-perk-button"
                    buttonValue={"Delete Perk"}
                    handleButtonAction={() => {
                      handleRemovePerkModal();
                    }}
                    showLoader={loader}
                    disabled={loader}
                    loaderColor="#2963ca"
                    loaderHeight="20px"
                    loaderWidth="20px"
                    type="popup-btn"
                  />
                </div>
              </div>
            ) : (
              <div className="no-perk-data-div">
                <span className="no-perk-text">No Perk Added Yet.</span>
              </div>
            )}
          </Col>
          <Col
            md={{ size: 4 }}
            lg={{ size: 3, offset: 2 }}
            xl={{ size: 2, offset: 4 }}
          >
            {/* <ButtonItem
              className="skip-perk-button"
              buttonValue="Skip Adding Perk"
              // handleButtonAction={handleBackBtn}
            /> */}
            <ButtonItem
              className="create-perk-button"
              buttonValue={perk ? "Update Perk" : "Create Perk"}
              handleButtonAction={() => {
                perk ? handleUpdatePerkModal() : handlePerkModal();
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PerkDetails;
