import logo from "../assets/images/logo.svg";
import signUpImg from "../assets/images/signupImg.svg";
import eyeOff from "../assets/images/eyeOff.svg";
import eyeOpen from "../assets/images/eyeOpen.svg";
import errorIcon from "../assets/images/error.svg";
import signup2Img from "../assets/images/signup2.svg";
import loginImg from "../assets/images/loginImg.svg";
import forgotPwdImg from "../assets/images/forgotPwdImg.svg";
import resetPwdImg from "../assets/images/resetPwdImg.svg";
import afterResetPwd from "../assets/images/afterResetPwd.svg";
import successIcon from "../assets/images/Success.svg";
import toastErrorIcon from "../assets/images/white_error.svg";
import customPlanIcon from "../assets/images/customPlanIcon.svg";
import subscriptionImg from "../assets/images/subscriptionImg.svg";
import verifyEmail from "../assets/images/verifyEmail.svg";
import cancelIcon from "../assets/images/cancelIcon.svg";
import profileImg from "../assets/images/profileImg.svg";
import settingsIcon from "../assets/images/settingsIcon.svg";
import homeOrange from "../assets/images/homeOrange.svg";
import getReccoWhite from "../assets/images/getReccoWhite.svg";
import nurtureWhite from "../assets/images/nurtureWhite.svg";
import managePerksWhite from "../assets/images/managePerksWhite.svg";
import getInsightsWhite from "../assets/images/getInsightsWhite.svg";
import homeWhite from "../assets/images/homeWhite.svg";
import getReccoOrange from "../assets/images/getReccoOrange.svg";
import nurtureOrange from "../assets/images/nurtureOrange.svg";
import managePerksOrange from "../assets/images/managePerksOrange.svg";
import getInsightsOrange from "../assets/images/getInsightsOrange.svg";
import homeImg from "../assets/images/homeImg.svg";
import logoMark from "../assets/images/logoMark.svg";
import addIcon from "../assets/images/add.svg";
import arrowTopRight from "../assets/images/arrowTopRight.svg";
import logout from "../assets/images/logout.svg";
import cloudBgImg from "../assets/images/reccoCloudBg.svg";
import reccoNewImg from "../assets/images/reccoNew.svg";
import stepOneImg from "../assets/images/1.svg";
import stepTwoImg from "../assets/images/2.svg";
import stepThreeImg from "../assets/images/3.svg";
import downloadIcon from "../assets/images/download.svg";
import deleteIcon from "../assets/images/delete.svg";
import batchCustomer from "../assets/images/batchCustomer.svg";
import individualCustomer from "../assets/images/individualCustomer.svg";
import uploadIcon from "../assets/images/upload.svg";
import customerFile from "../assets/files/AddBatchCustomer Template - Sheet.csv";
import searchIcon from "../assets/images/search.svg";
import editIcon from "../assets/images/edit.svg";
import backIcon from "../assets/images/backIcon.svg";
import blueArrow from "../assets/images/blueArrow.svg";
import grayArrow from "../assets/images/grayArrow.svg";
import uploadImg from "../assets/images/uploadImg.svg";
import uploadImage from "../assets/images/uploadImage.svg";
import chooseImg from "../assets/images/chooseImg.svg";
import copy from "../assets/images/copy.svg";
import template from "../assets/images/template.svg";
import phoneImg from "../assets/images/phoneImg.svg";
import undoIcon from "../assets/images/undo.svg";
import redoIcon from "../assets/images/redo.svg";
import stripeIcon from "../assets/images/stripeIcon.svg";
import noReccoListImg from "../assets/images/noReccoList.svg";
import arrowDownIcon from "../assets/images/arrow-drop-down.svg";
import arrowUpIcon from "../assets/images/arrow-drop-up.svg";
import perkIcon from "../assets/images/perkIcon.svg";
import calenderIcon from "../assets/images/calender.svg";
import webImg from "../assets/images/webImg.svg";
import blueUploadIcon from "../assets/images/blueUpload.svg";
import dropDownIcon from "../assets/images/dropDown.svg";
import reccoPreviewImage from "../assets/images/reccoPreviewImage.svg";
import perkGiftImg from "../assets/images/perkGiftImg.svg";
import reccoWebPreviewImage from "../assets/images/reccoWebPreviewImage.svg";
import emptyImage from "../assets/images/emptyImage.svg";
import uploadImageIcon from "../assets/images/uploadIcon.svg";
import noDataImg from "../assets/images/noDataImg.svg";
import rightUpArrow from "../assets/images/rightUpArrow.svg";
import downGrayArrow from "../assets/images/downGrayArrow.svg";
import grayCalenderIcon from "../assets/images/grayCalenderIcon.svg";
import impIcon from "../assets/images/impIcon.svg";
import upgradePlan from "../assets/images/upgradePlan.svg";
import rightArrowIcon from "../assets/images/rightArrow.svg";
import infoIcon from "../assets/images/infoIcon.svg";
import activeLeftArrow from "../assets/images/leftBlueArrow.svg";
import inactiveLeftArrow from "../assets/images/leftGrayArrow.svg";
import activeRightArrow from "../assets/images/rightBlueArrow.svg";
import inactiveRightArrow from "../assets/images/rightGrayArrow.svg";
import addImageIcon from "../assets/images/add-image.svg";
import famCount from "../assets/images/fam-counts.svg";
import reccoCount from "../assets/images/recco-count.svg";
import crossIcon  from '../assets/images/cross-icon.svg';
import otp1Active from '../assets/images/otp-1-active.svg';
import otp1Inactive from '../assets/images/otp-1-inactive.svg';
import otp2Active from '../assets/images/otp-2-active.svg';
import otp2Inactive from '../assets/images/otp-2-inactive.svg';
import activeOtpArrow from '../assets/images/active-otp-arrow.svg';
import inactiveOtpArrow from '../assets/images/inactive-otp-arrow.svg';
import linkIcon from '../assets/images/linkicon.svg'
import greenTickIcon from '../assets/images/greenTickIcon.svg'

export const IMG = {
  logo,
  signUpImg,
  eyeOff,
  eyeOpen,
  errorIcon,
  signup2Img,
  loginImg,
  forgotPwdImg,
  resetPwdImg,
  afterResetPwd,
  successIcon,
  toastErrorIcon,
  customPlanIcon,
  subscriptionImg,
  verifyEmail,
  cancelIcon,
  profileImg,
  settingsIcon,
  homeOrange,
  getReccoWhite,
  nurtureWhite,
  managePerksWhite,
  getInsightsWhite,
  homeImg,
  homeWhite,
  getInsightsOrange,
  getReccoOrange,
  nurtureOrange,
  managePerksOrange,
  logoMark,
  addIcon,
  arrowTopRight,
  logout,
  cloudBgImg,
  reccoNewImg,
  stepOneImg,
  stepTwoImg,
  stepThreeImg,
  downloadIcon,
  deleteIcon,
  batchCustomer,
  individualCustomer,
  uploadIcon,
  customerFile,
  searchIcon,
  editIcon,
  backIcon,
  blueArrow,
  grayArrow,
  uploadImg,
  chooseImg,
  copy,
  template,
  phoneImg,
  undoIcon,
  redoIcon,
  stripeIcon,
  noReccoListImg,
  arrowDownIcon,
  arrowUpIcon,
  perkIcon,
  calenderIcon,
  webImg,
  blueUploadIcon,
  dropDownIcon,
  uploadImage,
  reccoPreviewImage,
  perkGiftImg,
  reccoWebPreviewImage,
  emptyImage,
  uploadImageIcon,
  noDataImg,
  rightUpArrow,
  grayCalenderIcon,
  downGrayArrow,
  impIcon,
  upgradePlan,
  rightArrowIcon,
  infoIcon,
  activeLeftArrow,
  activeRightArrow,
  inactiveLeftArrow,
  inactiveRightArrow,
  addImageIcon,
  famCount,
  reccoCount,
  crossIcon,
  otp1Active,
  otp1Inactive,
  otp2Active,
  otp2Inactive,
  activeOtpArrow,
  inactiveOtpArrow,
  linkIcon,
  greenTickIcon
};
