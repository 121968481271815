import React from "react";
import Select from "react-select";
import { Label, Row, Col } from "reactstrap";
import { counrtyOptions } from "../../utils/commonMethods";

const CountrySelector = (props) => {
  const defaultStyles = {
    indicatorSeparator: () => ({
      border: "none",
    }),
    container: (styls) => ({
      ...styls,
      height: "40px",
    }),
    control: (styls) => ({
      ...styls,
      fontFamily: "Inter,sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
      height: "40px",
      maxWidth: "392px",
      border: "1px solid #DEE5EF",
      boxSizing: "border-box",
      borderRadius: "4px",
      paddingLeft: "11px",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontFamily: "Inter,sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
      paddingLeft: "11px",
    }),
    option: (styls, { data, isDisabled, isFocused, isSelected }) => ({
      ...styls,
      cursor: "pointer",
      fontFamily: "Inter,sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
      backgroundColor: isFocused
        ? "#9ECDFF"
        : isSelected
        ? "#2963CA"
        : "#FFFFFF",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  };

  return (
    <Row style={{ display: "flex", alignItems: "center" }}>
      <Col md={4}>
        <Label className="page-input-label">Country</Label>
      </Col>
      <Col md={8}>
        <Select
          styles={{ ...defaultStyles }}
          options={counrtyOptions(props.options)}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          // menuIsOpen={props.options !== null}
        />
      </Col>
    </Row>
  );
};

export default CountrySelector;
