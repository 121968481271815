import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./login.css";
import InputField from "../../common/component/inputField";
import {
  IMG,
  inputFieldValidaionMessage,
  requiredFieldMessage,
  internalServerError,
  loginEmailNotExist,
  emailPwdInvalid,
  ROUTES_PATH,
} from "../../constants";

import PasswordField from "../../common/component/passwordField";
import ButtonItem from "../../common/component/button";
import { validateEmail } from "../../utils/validation";
import axios from "axios";
import { loginUrl } from "../../utils/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { saveToken } from "../../utils/localStorage";
import { toastMessage } from "../../components/ToastMessage";

const Login = (props) => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
    isValidEmail: false,
    invalidEmailMsg: "",
    isValidPassword: false,
    invalidPasswordMsg: "",
  });
  const [loader, setLoader] = useState(false);
  // let windowSize = window.matchMedia("(min-width: 1500px)");
  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const LoginDetails = { ...loginDetails };

    LoginDetails[input.name] = input.value;
    LoginDetails[msgType] = "";
    LoginDetails[validationType] = false;

    setLoginDetails(LoginDetails);
  };
  const handleSubmit = () => {
    const LoginDetails = { ...loginDetails };
    if (
      loginDetails.email === "" ||
      loginDetails.email === null ||
      loginDetails.email === undefined
    ) {
      LoginDetails.isValidEmail = true;
      LoginDetails.invalidEmailMsg = requiredFieldMessage("email address");
    } else if (!validateEmail(loginDetails.email)) {
      LoginDetails.isValidEmail = true;
      LoginDetails.invalidEmailMsg =
        inputFieldValidaionMessage("email address");
    }
    if (
      loginDetails.password === "" ||
      loginDetails.password === null ||
      loginDetails.password === undefined
    ) {
      LoginDetails.isValidPassword = true;
      LoginDetails.invalidPasswordMsg = requiredFieldMessage("password");
    }

    setLoginDetails(LoginDetails);
    const { isValidEmail, isValidPassword } = LoginDetails;

    if (!isValidEmail && !isValidPassword) {
      // navigate(ROUTES_PATH.HOME);
      const payload = {
        email: loginDetails.email,
        password: loginDetails.password,
      };
      setLoader(true);
      axios
        .post(loginUrl, payload)
        .then((response) => {
          if (response.status === 200) {
            saveToken(response.data.token);
            // props.loginSuccess(response.data);
            props.getUserDetails(
              "login",
              response.data.accountId,
              response.data
            );
            // setLoader(false);
            // navigate(ROUTES_PATH.HOME)
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response?.status === 400) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            if (
              errorMessage ===
              "Please specify a valid email and/or password to continue"
            ) {
              toastMessage("error", emailPwdInvalid);
            } else {
              toastMessage("error", errorMessage);
            }
          } else if (error.response?.status === 404) {
            toastMessage("error", loginEmailNotExist);
          } else {
            toastMessage("error", internalServerError);
          }
        });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!loader) {
        handleSubmit();
      }
    }
  };

  return (
    <div
    // style={{ maxWidth: "1440px", margin: "0 auto" }}
    >
      <Row className="row-mlr">
        <Col
          sm={12}
          md={{ size: 5 }}
          lg={{ size: 4 }}
          xl={{ size: 4 }}
          className="main-login-div"
        >
          <div>
            <img src={IMG.logo} alt="Recco" />
          </div>
          <div className="login-heading">
            Login to your Recco<br></br> Business Account
          </div>
          <div className="login-sub-heading">
            Don't have a business account ?
            <span
              className="signup-link"
              onClick={() => navigate(ROUTES_PATH.SIGNUP)}
            >
              Sign-up
            </span>
          </div>
          <div style={{ marginTop: "48px" }}>
            <div className="mb-22">
              <InputField
                className="page-input-box"
                placeholder="email@example.com"
                maxLength="100"
                label="E-mail"
                name="email"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidEmailMsg", "isValidEmail")
                }
                value={loginDetails.email}
                invalid={loginDetails.isValidEmail}
                invalidMsg={loginDetails.invalidEmailMsg}
              />
            </div>
            <div style={{ marginBottom: "84px", maxWidth: "392px" }}>
              <PasswordField
                handlePasswordChange={(e) =>
                  handleInputChange(e, "invalidPasswordMsg", "isValidPassword")
                }
                handleKeyPress={handleKeyPress}
                className="page-input-box"
                maxLength="15"
                placeholder="password"
                label="Enter Password"
                name="password"
                value={loginDetails.password}
                invalid={loginDetails.isValidPassword}
                invalidMsg={loginDetails.invalidPasswordMsg}
              />
            </div>
            <ButtonItem
              className="page-button-input"
              buttonValue="Login"
              handleButtonAction={handleSubmit}
              disabled={loader}
              showLoader={loader}
            />
            <div className="forgot-pwd-link login-forgot-link">
              <span
                className="cursor-pointer"
                onClick={() => navigate(ROUTES_PATH.FORGOT_PASSWORD)}
              >
                Forgot password?
              </span>
            </div>
            <div className="forgot-pwd-link login-privacy-terms">
              <a
                href="https://reccojoy.com/terms-conditions/"
                className="policy-link"
              >
                Terms of Service
              </a>
              |
              <a
                href="https://reccojoy.com/privacy-policy/"
                className="policy-link"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </Col>
        <Col
          sm={12}
          md={{ size: 5, offset: 2 }}
          lg={{ size: 6, offset: 2 }}
          xl={{ size: 6, offset: 2 }}
          className="col-plr second-login-div"
        >
          <img src={IMG.loginImg} alt="" className="login-side-image" />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginSuccess: ActionCreators.loginSuccess,
      getUserDetails: ActionCreators.getUserDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
