import { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { IMG, internalServerError } from "../../constants";
import { RemindersCalendar } from "../RemindersCalender";
import ButtonItem from "../../common/component/button";
import { scheduleReminderService } from "../../services";
import { toastMessage } from "../ToastMessage";
import "./ScheduleReminder.css";

const useStyles = makeStyles(theme => ({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "100px",
    borderRadius: "10px",
  },
  dialogContainer: {
    padding: "40px 80px",
  },
  headingDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "26px",
    borderBottom: "2px solid #F4F4F4",
  },
  title: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
    color: "#323232",
  },
  mainDiv: {
    padding: "40px 0 350px 0",
  },
  subText: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "19.2px",
    lineHeight: "22px",
    letterSpacing: "-0.01em",
    color: "#1A1A1A",
    marginTop: "35px",
  },
  reminderText: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#000000",
    minWidth: "194px",
    marginBottom: "24px",
  },
  buttonStyle: {
    backgroundColor: "#2963ca !important",
    borderColor: "#2963ca !important",
    borderRadius: "4px !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    fontSize: "11.11px !important",
    lineHeight: "28px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff !important",
    minHeight: "34px",
    maxWidth: "200px",
    width: "100%",
  },
}));

const ScheduleReminder = ({ openModal, handleModal, businessId }) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [firstReminder, setFirstReminder] = useState(new Date());
  const [secondReminder, setSecondReminder] = useState(null);
  const [isSecondReminder, setIsSecondReminder] = useState(false);

  useEffect(() => {
    let createdDate = moment(new Date()).utc().format();
    let expirationDate = moment(createdDate).add(7, "d");
    setFirstReminder(new Date(expirationDate));
    let expirationDate1 = moment(createdDate).add(14, "d");
    setSecondReminder(new Date(expirationDate1));
  }, []);

  const handleScheduleRecco = () => {
    const reccoListId = sessionStorage.getItem("recco-list");
    const payload = {
      firstNudge: moment(firstReminder).format("MM/DD/YYYY"),
      secondNudge: isSecondReminder
        ? moment(secondReminder).format("MM/DD/YYYY")
        : null,
    };
    setLoader(true);
    scheduleReminderService(reccoListId, payload)
      .then(response => {
        if (response.status === 201) {
          toastMessage("success", "Reminders scheduled successfully");
          setLoader(false);
          handleClose();
        }
      })
      .catch(error => {
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const handleClose = () => {
    let createdDate = moment(new Date()).utc().format();
    let expirationDate = moment(createdDate).add(7, "d");
    setFirstReminder(new Date(expirationDate));
    let expirationDate1 = moment(createdDate).add(14, "d");
    setSecondReminder(new Date(expirationDate1));
    handleModal();
    setIsSecondReminder(false);
  };
  return (
    <Dialog
      scroll={"body"}
      fullWidth={true}
      maxWidth="md"
      open={openModal}
      onClose={handleModal}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      disableBackdropClick
    >
      <div className={classes.dialogContainer}>
        <div className={classes.headingDiv}>
          <span className={classes.title}>
            {sessionStorage.getItem("recco-list-title")}
          </span>
          <img
            src={IMG.cancelIcon}
            alt="Close"
            width="29px"
            height="29px"
            onClick={handleClose}
            className="cursor-pointer"
          />
        </div>
        <Row className={classes.mainDiv}>
          <Col>
            <div className={classes.title}>Set Reminder Date</div>
            <div className={classes.subText}>
              You can set the Reminder dates here.<br></br>
              By default first reminder will be after 1 week and second will be
              after 2 week.
            </div>
          </Col>
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.reminderText}>First Reminder:</div>
              <RemindersCalendar
                date={firstReminder}
                setDate={setFirstReminder}
                highLightDate={secondReminder}
                minDate={
                  new Date(
                    moment(moment(new Date()).utc().format()).add(1, "d")
                  )
                }
                maxDate={
                  secondReminder
                    ? new Date(moment(secondReminder).subtract(1, "d"))
                    : moment(moment(new Date()).utc().format()).add(10, "d")
                }
                type="First"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "28px",
              }}
            >
              {isSecondReminder ? (
                <>
                  <div className={classes.reminderText}>Second Reminder:</div>
                  <RemindersCalendar
                    date={secondReminder}
                    setDate={setSecondReminder}
                    highLightDate={firstReminder}
                    minDate={new Date(moment(firstReminder).add(1, "d"))}
                    maxDate={
                      firstReminder
                        ? new Date(moment(firstReminder).add(10, "d"))
                        : moment(moment(new Date()).utc().format()).add(20, "d")
                    }
                    type="Second"
                  />
                </>
              ) : (
                <div
                  className="add-more-reminder"
                  onClick={() => setIsSecondReminder(true)}
                >
                  Add 1 more reminder
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonItem
            className={classes.buttonStyle}
            buttonValue="Schedule Reminder"
            handleButtonAction={handleScheduleRecco}
            disabled={loader}
            showLoader={loader}
            type="popup-btn"
          />
        </div>
      </div>
    </Dialog>
  );
};
export default ScheduleReminder;
