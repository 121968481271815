import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import EditCustomerPopup from "../../components/EditCustomerPopup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { deleteAdvocatesService, updateAdvocatesService } from "../../services";
import { toastMessage } from "../../components/ToastMessage";

const columns = [
  { id: "name", label: "NAME", sort: "firstName" },
  { id: "email", label: "E-MAIL" },
  {
    id: "phoneNo",
    label: "PHONE NO.",
  },
  {
    id: "delete",
    label: "",
  },
];

const rows = [
  {
    name: "Spike Spiegel",
    email: "morty@plumbus.io",
    phoneNo: "555-555-5555",
  },
  {
    name: "Homer Simpson",
    email: "morty@plumbus.io",
    phoneNo: "555-555-5555",
  },
  {
    name: "Spike Spiegel",
    email: "morty@plumbus.io",
    phoneNo: "555-555-5555",
  },
  {
    name: "Homer Simpson",
    email: "morty@plumbus.io",
    phoneNo: "555-555-5555",
  },
];

const CustomersTable = (props) => {
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "transperant",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#F7F8FA",
      },
    },
  }))(TableRow);
  const StyledTableCell = withStyles((theme) => ({
    root: {
      "&:first-child": {
        borderRadius: "4px 0 0 4px",
        borderLeft: "0.5px solid #C8C8C8",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
        borderRight: "0.5px solid #C8C8C8",
      },
      borderBottom: "0.5px solid #C8C8C8",
    },
  }))(TableCell);
  const StyledTableHeadCell = withStyles((theme) => ({
    root: {
      borderBottom: "0.5px solid #C8C8C8",
      borderTop: "1px solid #E7E7E7",
    },
  }))(TableCell);

  const navigate = useNavigate();
  const [isConfirmationModalOpen, setConfirmationModal] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [isEditModalOpen, setEditModal] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState({});

  const handleConfirmationToggle = (e, data) => {
    e.preventDefault();
    setSelectedCustomer(data);
    setConfirmationModal(!isConfirmationModalOpen);
  };
  const handleEditModalToggle = (e, selectedCustomer) => {
    e.preventDefault();
    setSelectedCustomer(selectedCustomer);
    setEditModal(!isEditModalOpen);
  };

  const handleDeleteCustomer = () => {
    const data = {
      reccoListId: selectedCustomer?.belongsToRecco,
      advocateId: selectedCustomer?.id,
    };

    props.toggleSecondLoader(true);
    props.toggleMainLoader(true);
    deleteAdvocatesService(data)
      .then(() => {
        props.resetState();
        props.toggleMainLoader(false);
        setConfirmationModal(!isConfirmationModalOpen);
      })
      .catch((err) => {
        props.toggleMainLoader(false);
      });
  };

  const handleUpdateAdvocates = (payload) => {
    const data = {
      reccoListId: selectedCustomer?.belongsToRecco,
      advocateId: selectedCustomer?.id,
    };

    props.toggleMainLoader(true);
    updateAdvocatesService(data, payload)
      .then(() => {
        props.resetState();
        toastMessage("success", "Customer details edited");
        setEditModal(!isEditModalOpen);
        props.toggleMainLoader(false);
      })
      .catch((err) => {
        props.toggleMainLoader(false);
      });
  };

  return (
    <React.Fragment>
      <ConfirmationPopup
        openModal={isConfirmationModalOpen}
        handleModal={handleConfirmationToggle}
        handleBtnClick={handleDeleteCustomer}
        showLoader={deleteLoader}
        subText="Are you sure you want to delete"
        deleteName={
          selectedCustomer?.firstName + " " + selectedCustomer?.lastName
        }
        heading="Delete Recco"
        btnValue="Delete"
      />
      <EditCustomerPopup
        openModal={isEditModalOpen}
        handleModal={() => setEditModal(!isEditModalOpen)}
        customer={selectedCustomer}
        handleUpdateAdvocate={handleUpdateAdvocates}
      />
      <TableContainer
        sx={{ maxHeight: 440 }}
        //style={{ maxHeight: 440 }}
        className="customers-table"
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableHeadCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="cust-column-headers"
                >
                  {column.id === "name" ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {column.label}
                      <img
                        src={IMG.arrowDownIcon}
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                        onClick={() => {
                          props.setSortOrder(!props.sortOrder);
                          props.handleSort(
                            column.sort,
                            props.sortOrder ? "asc" : "desc"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    column.label
                  )}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.updatedAdvocates.map((row, index) => {
              return (
                <StyledTableRow tabIndex={-1} key={index}>
                  <StyledTableCell className="name-col-css">
                    <div>
                      {row.firstName} {row.lastName}
                    </div>
                    {/* <div className="email-phone-css text-align-left">
                      Recco Profession Tag
                    </div> */}
                  </StyledTableCell>
                  <StyledTableCell className="email-phone-css text-align-center">
                    {row.email ? row.email : "-"}
                  </StyledTableCell>
                  <StyledTableCell className="email-phone-css text-align-center">
                    {row.phoneNumber?.countryCode &&
                    row.phoneNumber?.phoneNumber
                      ? row.phoneNumber?.countryCode +
                        "-" +
                        row.phoneNumber?.phoneNumber
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell className="text-align-right">
                    <img
                      src={IMG.deleteIcon}
                      alt="delete"
                      className="cursor-pointer"
                      onClick={(e) => handleConfirmationToggle(e, row)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    advocates: state.advocates,
    loader: state.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getReccoListAdvocates: ActionCreators.getReccoListAdvocates,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTable);
