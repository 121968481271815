import React from "react";
import PropTypes from "prop-types";
import { Label, Input } from "reactstrap";
import { IMG } from "../../constants";

// common component for input field
const InputField = (props) => {
  let {
    handleInputChange,
    handleKeyPress,
    className,
    labelClassName,
    maxLength,
    placeholder,
    invalid,
    invalidMsg,
    label,
    name,
    type,
    value,
    errorClassName,
    inputType,
    disabled
  } = props;

  return (
    <React.Fragment>
      {inputType === "settings" ? null : (
        <Label className={labelClassName}>{label}</Label>
      )}
      <Input
        type={type}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        className={className}
        maxLength={maxLength}
        autoComplete="off"
        name={name}
        value={value}
        disabled={disabled}
        id={invalid ? "invalid-input-border" : ""}
      />
      {invalid && (
        <span
          className={
            name === "message"
              ? `${errorClassName} field-icon-textarea`
              : `${errorClassName}`
          }
        >
        </span>
      )}
      {invalid && <div className="input-error-style">{invalidMsg}</div>}
    </React.Fragment>
  );
};

InputField.propTypes = {
  handleInputChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  invalidMsg: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

InputField.defaultProps = {
  type: "text",
  className: undefined,
  placeholder: "",
  invalid: false,
  labelClassName: "page-input-label",
  errorClassName: "field-icon",
};

export default InputField;
