import { Row, Col } from "reactstrap";
import ButtonItem from "../../common/component/button";
import "./bottomButtons.css";

const BottomButtons = ({
  handleBackBtn,
  handleSaveBtn,
  loader,
  btnName2,
  btnName1,
  btnName3,
  handleBtn3,
  btn3Loader,
  btn1Loader,
  btn2Disabled,
}) => {
  return (
    <Row style={{ marginTop: "64px" }}>
      <Col className="btn-col">
        {btnName2 !== "Back to Get Recco" && btnName1 && (
          <ButtonItem
            className="back-button-css"
            buttonValue={btnName1}
            handleButtonAction={handleBackBtn}
            style={{
              maxWidth: btnName1 === "Save For Later" ? "200px" : "100px",
            }}
            showLoader={btn1Loader}
            disabled={btn1Loader}
            loaderColor="#2963ca"
            loaderHeight="20px"
            loaderWidth="20px"
            type="popup-btn"
          />
        )}
        {btnName3 && (
          <ButtonItem
            className="back-button-css"
            buttonValue={btnName3}
            handleButtonAction={handleBtn3}
            showLoader={btn3Loader}
            disabled={btn3Loader}
            style={{ maxWidth: "200px" }}
            loaderColor="#2963ca"
            loaderHeight="20px"
            loaderWidth="20px"
            type="popup-btn"
          />
        )}
        <ButtonItem
          className="save-continue-button"
          buttonValue={btnName2}
          handleButtonAction={handleSaveBtn}
          showLoader={loader}
          disabled={loader || btn2Disabled}
          type="popup-btn"
        />
      </Col>
    </Row>
  );
};
export default BottomButtons;
