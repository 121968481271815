import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./nurture.css";
import { IMG, internalServerError } from "../../constants";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import NoDataFound from "../../common/component/noDataFound";
import LoadingState from "../../common/component/loader";
import { toastMessage } from "../../components/ToastMessage";
import axios from "../../axios";
import { deleteReccoFamUrl } from "../../utils/urls";
import MyTooltip from "../../common/component/myTooltip";

const columns = [
  {
    id: "name",
    label: "Name",
    sort: "fullName",
    index: 0,
  },
  { id: "famSize", label: "Recco Fam size", sort: "reccoFamSize", index: 1 },

  {
    id: "toatlReccos",
    label: "Total no. of Reccos",
    sort: "totalNumOfReccos",
    index: 2,
  },
  {
    id: "contact",
    label: "Contact",
  },
  {
    id: "delete",
    label: "",
  },
];

const ReccoFamTable = ({
  famList,
  loader,
  user,
  handleSort,
  resetState,
  localLoader,
}) => {
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "transperant",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#F7F7F7",
      },
    },
  }))(TableRow);
  const StyledTableCell = withStyles((theme) => ({
    root: {
      "&:first-child": {
        borderRadius: "4px 0 0 4px",
        borderLeft: "0.5px solid #C8C8C8",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
        borderRight: "0.5px solid #C8C8C8",
      },
      borderBottom: "0.5px solid #C8C8C8",
    },
  }))(TableCell);
  const StyledTableHeadCell = withStyles((theme) => ({
    root: {
      borderBottom: "0.5px solid #C8C8C8",
    },
  }))(TableCell);

  const [isConfirmationModalOpen, setConfirmationModal] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [selectedFam, setSelectedFam] = React.useState({});
  const [sortOrder, setSortOrder] = React.useState(false);
  const [sortByString, setSortByString] = React.useState([]);
  const [orderByString, setOrderByString] = React.useState([]);

  const handleConfirmationToggle = (e) => {
    e.preventDefault();
    setConfirmationModal(!isConfirmationModalOpen);
  };

  const handleDeleteFam = () => {
    setDeleteLoader(true);
    axios
      .delete(deleteReccoFamUrl(user.business[0].businessForAccount.id, selectedFam.id))
      .then((response) => {
        if (response.status === 200) {
          setDeleteLoader(false);
          toastMessage("success", "Recco fam deleted successfully");
          resetState();
          setConfirmationModal(!isConfirmationModalOpen);
        }
      })
      .catch((error) => {
        setDeleteLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  const handleSorting = (column) => {
    setSortOrder(!sortOrder);
    // const [orderBy, sortBy] = sortFunction(column);
    handleSort(sortOrder ? "asc" : "desc", column.sort);
  };
  const sortFunction = (column) => {
    let sortByStringvar = [];
    let orderByStringvar = [];
    sortByStringvar = sortByString;
    sortByStringvar[column.index] = column.sort;
    setSortByString(sortByStringvar);
    orderByStringvar = orderByString;
    orderByStringvar[column.index] = sortOrder ? "asc" : "desc";
    setOrderByString(orderByStringvar);
    return [sortByStringvar, orderByStringvar];
  };

  return (
    <React.Fragment>
      <ConfirmationPopup
        openModal={isConfirmationModalOpen}
        handleModal={handleConfirmationToggle}
        handleBtnClick={handleDeleteFam}
        showLoader={deleteLoader}
        subText="Are you sure you want to delete this recco fam?"
        heading={"Delete Recco Fam"}
        btnValue="Delete"
      />
      {loader.sortLoader || localLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : !localLoader && famList.length === 0 ? (
        <NoDataFound
          className="no-data-text"
          img={IMG.noDataImg}
          message="You don’t have any Recco fam."
        />
      ) : (
        <TableContainer className="customers-table" id="table-scroll-box">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableHeadCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="recco-fam-column-headers"
                  >
                    {column.id === "name" ||
                    column.id === "famSize" ||
                    column.id === "toatlReccos" ? (
                      <div style={{ display: "flex" }}>
                        {column.label}
                        <img
                          src={IMG.arrowDownIcon}
                          style={{ marginLeft: "7px", cursor: "pointer" }}
                          onClick={() => handleSorting(column)}
                        />
                      </div>
                    ) : (
                      column.label
                    )}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {famList?.map((row) => {
                return (
                  <StyledTableRow tabIndex={-1} key={row.id}>
                    <StyledTableCell className="recco-list-col">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell className="recco-fam-cells">
                      {row.reccoFamSize}
                    </StyledTableCell>
                    <StyledTableCell className="recco-fam-cells">
                      {row.totalNumOfReccos}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="recco-fam-cells"> {row.email} </div>
                      <div className="recco-fam-cells">
                        {row.phoneNumber?.countryCode &&
                        row.phoneNumber?.phoneNumber
                          ? row.phoneNumber?.countryCode +
                            "-" +
                            row.phoneNumber?.phoneNumber
                          : ""}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className="view-reccos-text">
                      <span>
                        {row.reccos?.length > 0 ? (
                          <>
                            {row.reccos
                              .slice(
                                0,
                                row.reccos.length > 4 ? 4 : row.reccos.length
                              )
                              .map((words, index) => {
                                return index === row.reccos.length - 1
                                  ? words
                                  : words + ", ";
                              })}
                            {row.reccos.length > 4 && (
                              <MyTooltip
                                label="Reccos"
                                value={row.reccos}
                                txt={
                                  <span
                                    style={{ color: "#2963CA" }}
                                    className="cursor-pointer"
                                  >
                                    +{row.reccos.length - 4} more
                                  </span>
                                }
                              />
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <img
                        src={IMG.deleteIcon}
                        alt="delete"
                        className="cursor-pointer"
                        onClick={(e) => {
                          handleConfirmationToggle(e);
                          setSelectedFam(row);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default ReccoFamTable;
