// Users Actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SHOW_OR_HIDE_MAIN_LOADER = "SHOW_OR_HIDE_MAIN_LOADER";
export const SHOW_OR_HIDE_EMAIL_VERIFY_LOADER =
  "SHOW_OR_HIDE_EMAIL_VERIFY_LOADER";
export const SHOW_OR_HIDE_SECOND_LOADER = "SHOW_OR_HIDE_SECOND_LOADER";
export const SHOW_OR_HIDE_SUBSCRIBE_LOADER = "SHOW_OR_HIDE_SUBSCRIBE_LOADER";
export const SHOW_OR_HIDE_SORT_LOADER = "SHOW_OR_HIDE_SORT_LOADER";
export const SHOW_OR_HIDE_WORD_CLOUD_LOADER = "SHOW_OR_HIDE_WORD_CLOUD_LOADER";

//Account actions
export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";
export const SAVE_ACCOUNT_DETAILS = "SAVE_ACCOUNT_DETAILS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const GET_BUSINESS_DETAILS = "GET_BUSINESS_DETAILS";
export const SAVE_BUSINESS_DETAILS = "SAVE_BUSINESS_DETAILS";
export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS";
export const SAVE_SUBSCRIPTION_DETAILS = "SAVE_SUBSCRIPTION_DETAILS";

//Subscription actions
export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const SAVE_SUBSCRIPTION_PLANS = "SAVE_SUBSCRIPTION_PLANS";
export const CHOOSE_SUBSCRIPTION_PLANS = "CHOOSE_SUBSCRIPTION_PLANS";

//Get Recco actions
export const GET_RECCO_LIST = "GET_RECCO_LIST";
export const SAVE_RECCO_LIST = "SAVE_RECCO_LIST";
export const GET_RECCO_FAM = "GET_RECCO_FAM";
export const SAVE_RECCO_FAM = "SAVE_RECCO_FAM";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const SAVE_IMG_TEMPLATES = "SAVE_IMG_TEMPLATES";
export const SAVE_TEXT_TEMPLATES = "SAVE_TEXT_TEMPLATES";
export const GET_TEMPLATES_INFO = "GET_TEMPLATES_INFO";
export const SAVE_TEMPLATES_INFO = "SAVE_TEMPLATES_INFO";
export const GET_REMAINING_RECCO = "GET_REMAINING_RECCO";
export const SAVE_REMAINING_RECCO = "SAVE_REMAINING_RECCO";
export const GET_ENTITIES = "GET_ENTITIES";
export const SAVE_ENTITIES = "SAVE_ENTITIES";

//Get Recco List Advocates
export const GET_RECCO_LIST_ADVOCATES = "GET_RECCO_LIST_ADVOCATES";
export const SAVE_RECCO_LIST_ADVOCATES = "SAVE_RECCO_LIST_ADVOCATES";

//Get Recco List Perks
export const GET_BUSINESS_PERK = "GET_BUSINESS_PERK";
export const SAVE_BUSINESS_PERK = "SAVE_BUSINESS_PERK";
export const GET_RECCO_LIST_DETAILS = "GET_RECCO_LIST_DETAILS";
export const SAVE_RECCO_LIST_DETAILS = "SAVE_RECCO_LIST_DETAILS";

//Nurture
export const GET_NURTURE_DETAILS = "GET_NURTURE_DETAILS";
export const SAVE_NURTURE_DETAILS = "SAVE_NURTURE_DETAILS";
export const GET_REMAINING_RECCO_FAM = "GET_REMAINING_RECCO_FAM";
export const SAVE_REMAINING_RECCO_FAM = "SAVE_REMAINING_RECCO_FAM";

//Get Insights & home
export const GET_RECCO_CLOUD = "GET_RECCO_CLOUD";
export const SAVE_RECCO_CLOUD = "SAVE_RECCO_CLOUD";
export const SAVE_RECCO_CLOUD_2 = "SAVE_RECCO_CLOUD_2";
export const GET_COMMUNITY_OVERVIEW = "GET_COMMUNITY_OVERVIEW";
export const SAVE_COMMUNITY_OVERVIEW = "SAVE_COMMUNITY_OVERVIEW";
export const GET_INSIGHTS_CARD = "GET_INSIGHTS_CARD";
export const SAVE_INSIGHTS_CARD = "SAVE_INSIGHTS_CARD";
export const GET_RECCO_FAM_GRAPH = "GET_RECCO_FAM_GRAPH";
export const SAVE_RECCO_FAM_GRAPH = "SAVE_RECCO_FAM_GRAPH";
export const GET_PERK_PERFORMANCE = "GET_PERK_PERFORMANCE";
export const SAVE_PERK_PERFORMANCE = "SAVE_PERK_PERFORMANCE";
export const GET_RECCO_MEDIUM = "GET_RECCO_MEDIUM";
export const SAVE_RECCO_MEDIUM = "SAVE_RECCO_MEDIUM";
export const GET_NURTURE_PERFORMANCE = "GET_NURTURE_PERFORMANCE";
export const SAVE_NURTURE_PERFORMANCE = "SAVE_NURTURE_PERFORMANCE";

//Payment history urls
export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY";
export const SAVE_PAYMENT_HISTORY = "SAVE_PAYMENT_HISTORY";
