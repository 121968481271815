import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import "./forgotPassword.css";
import InputField from "../../common/component/inputField";
import {
  ROUTES_PATH,
  IMG,
  inputFieldValidaionMessage,
  requiredFieldMessage,
  internalServerError,
  loginEmailNotExist,
  emailPwdInvalid,
} from "../../constants";

import ButtonItem from "../../common/component/button";
import { validateEmail } from "../../utils/validation";
import axios from "axios";
import { forgotPasswordUrl } from "../../utils/urls";
import { toastMessage } from "../../components/ToastMessage";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [forgotPwdDetails, setForgotPwdDetails] = useState({
    email: "",
    isValidEmail: false,
    invalidEmailMsg: "",
    isSuccessForgot: false,
  });

  const [loader, setLoader] = useState(false);

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const ForgotPwdDetails = { ...forgotPwdDetails };

    ForgotPwdDetails[input.name] = input.value;
    ForgotPwdDetails[msgType] = "";
    ForgotPwdDetails[validationType] = false;

    setForgotPwdDetails(ForgotPwdDetails);
  };
  const handleSubmit = () => {
    const ForgotPwdDetails = { ...forgotPwdDetails };
    if (
      forgotPwdDetails.email === "" ||
      forgotPwdDetails.email === null ||
      forgotPwdDetails.email === undefined
    ) {
      ForgotPwdDetails.isValidEmail = true;
      ForgotPwdDetails.invalidEmailMsg = requiredFieldMessage("email address");
    } else if (!validateEmail(forgotPwdDetails.email)) {
      ForgotPwdDetails.isValidEmail = true;
      ForgotPwdDetails.invalidEmailMsg =
        inputFieldValidaionMessage("email address");
    }

    setForgotPwdDetails(ForgotPwdDetails);

    if (!ForgotPwdDetails.isValidEmail) {
      const payload = {
        email: forgotPwdDetails.email,
      };
      setLoader(true);
      axios
        .post(forgotPasswordUrl, payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setLoader(false);
            setForgotPwdDetails({ isSuccessForgot: true });
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response?.status === 400) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            if (
              errorMessage ===
              "Please specify a valid email and/or password to continue"
            ) {
              toastMessage("error", emailPwdInvalid);
            } else {
              toastMessage("error", errorMessage);
            }
          } else if (error.response?.status === 404) {
            toastMessage("error", loginEmailNotExist);
          } else {
            toastMessage("error", internalServerError);
          }
        });
      // navigate(ROUTES_PATH.RESET_PASSWORD);
      // setForgotPwdDetails({ isSuccessForgot: true });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!loader) {
        handleSubmit();
      }
    }
  };
  return (
    <div>
      <Row className="row-mlr">
        <Col
          md={{ size: 6 }}
          lg={{ size: 6 }}
          xl={{ size: 4 }}
          className="main-forgot-div"
        >
          <div>
            <img src={IMG.logo} alt="Recco" />
          </div>
          <div className="forgot-heading">Reset Your Password</div>
          <div className="forgot-sub-heading">
            {forgotPwdDetails.isSuccessForgot
              ? "An email with instructions to reset your password was sent to your account."
              : "Please enter your registered email address and we will send you a password reset link."}
          </div>
          <div
            style={
              forgotPwdDetails.isSuccessForgot
                ? { marginTop: "84px" }
                : { marginTop: "62px" }
            }
          >
            {!forgotPwdDetails.isSuccessForgot ? (
              <Fragment>
                <div style={{ marginBottom: "84px", maxWidth: "392px" }}>
                  <InputField
                    className="page-input-box"
                    placeholder="email@example.com"
                    maxLength="100"
                    label="E-mail"
                    name="email"
                    handleInputChange={(e) =>
                      handleInputChange(e, "invalidEmailMsg", "isValidEmail")
                    }
                    handleKeyPress={handleKeyPress}
                    value={forgotPwdDetails.email}
                    invalid={forgotPwdDetails.isValidEmail}
                    invalidMsg={forgotPwdDetails.invalidEmailMsg}
                  />
                </div>
                <ButtonItem
                  className="page-button-input"
                  buttonValue="Send Link"
                  handleButtonAction={handleSubmit}
                  disabled={loader}
                  showLoader={loader}
                />
              </Fragment>
            ) : (
              <ButtonItem
                className="page-button-input"
                buttonValue="Back to Login"
                handleButtonAction={() => navigate(ROUTES_PATH.LOGIN)}
                disabled={false}
                showLoader={false}
              />
            )}
            <div className="forgot-pwd-link forgot-privacy-terms">
              <a
                href="https://reccojoy.com/terms-conditions/"
                className="policy-link"
              >
                Terms of Service
              </a>
              |
              <a
                href="https://reccojoy.com/privacy-policy/"
                className="policy-link"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </Col>
        <Col
          md={{ size: 6 }}
          lg={{ size: 5, offset: 1 }}
          xl={{ size: 6, offset: 2 }}
          className="col-plr second-forgot-div"
        >
          <img
            src={
              forgotPwdDetails.isSuccessForgot
                ? IMG.afterResetPwd
                : IMG.forgotPwdImg
            }
            alt=""
            className="forgot-side-image"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
