import { put, call } from "redux-saga/effects";
import * as serviceCreators from "../../services";
import * as actionCreators from "../actions";

export function* getNurtureDetailsSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getNurtureDetailsService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveNurtureDetails(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    if (error.response.status === 404) {
      yield put(actionCreators.saveNurtureDetails(null));
    }
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getRemainingReccoFamsSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getRemainingReccoFamService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveRemainingReccoFam(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
