import { put, call } from "redux-saga/effects";
import * as serviceCreators from "../../services";
import * as actionCreators from "../actions";

export function* getReccoCloudSaga(data) {
  try {
    const resp = yield call(serviceCreators.getReccoCloudService, data.data);
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleWordCloudLoader(false));

      yield put(actionCreators.saveReccoCloud(resp.data));
    } else {
      yield put(actionCreators.toggleWordCloudLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleWordCloudLoader(false));
  }
}

export function* getCommunityOverviewSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getCommunityOverviewService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleSecondLoader(false));
      yield put(actionCreators.saveCommunityOverview(resp.data));
    } else {
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSecondLoader(false));
  }
}
export function* getInsightsCardSaga(data) {
  try {
    const resp = yield call(serviceCreators.getInsightsCardService, data.data);

    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveInsightsCard(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getReccoFamGraphSaga(data) {
  try {
    const resp = yield call(serviceCreators.getReccoFamGraphService, data.data);

    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleSortLoader(false));
      yield put(actionCreators.saveReccoFamGraph(resp.data));
    } else {
      yield put(actionCreators.toggleSortLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSortLoader(false));
  }
}
export function* getPerkPerformanceSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getPerkPerformanceService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleSortLoader(false));
      yield put(actionCreators.savePerkPerformance(resp.data));
    } else {
      yield put(actionCreators.toggleSortLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSortLoader(false));
  }
}
export function* getReccoMediumSaga(data) {
  try {
    const resp = yield call(serviceCreators.getReccoMediumService, data.data);
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleSubscribeLoader(false));
      yield put(actionCreators.saveReccoMedium(resp.data));
    } else {
      yield put(actionCreators.toggleSubscribeLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSubscribeLoader(false));
  }
}
export function* getNurturePerformanceSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getNurturePerformanceService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleEmailVerifyLoader(false));
      yield put(actionCreators.saveNurturePerformance(resp.data));
    } else {
      yield put(actionCreators.toggleEmailVerifyLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleEmailVerifyLoader(false));
  }
}
