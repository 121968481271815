import { put, call } from "redux-saga/effects";
import * as serviceCreators from "../../services";
import * as actionCreators from "../actions";

export function* getPaymentHistorySaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getPaymentHistoryService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleSecondLoader(false));
      yield put(actionCreators.savePaymentHistory(resp.data));
    } else {
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSecondLoader(false));
  }
}
