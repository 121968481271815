import React from "react";
import "./navbar.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import ConfirmationPopup from "../../common/component/confirmationPopup";
import { IMG, ROUTES_PATH } from "../../constants";
import ProfileDropDown from "./profileDropDown";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      UserDetailOpen: false,
      isLogoutModalOpen: false,
      isConfirmDisabled: false,
      showLoader: false,
      isExtensionOpen: false,
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  toggleExtension = () => {
    this.setState({ isExtensionOpen: !this.state.isExtensionOpen });
  };

  handleUserProfile = (e) => {
    e.stopPropagation();
    this.setState({
      UserDetailOpen: !this.state.UserDetailOpen,
    });
  };

  handleLogoutToggle = (e) => {
    e.preventDefault();
    this.setState({ isLogoutModalOpen: !this.state.isLogoutModalOpen });
  };

  handleLogout = () => {
    this.props.logoutSuccess();
    this.props.history.push(ROUTES_PATH.LOGIN);
  };

  render() {
    let { isOpen, isLogoutModalOpen, showLoader, isExtensionOpen } = this.state;
    const { user, history, navigation } = this.props;

    return (
      <div>
        <ConfirmationPopup
          openModal={isLogoutModalOpen}
          handleModal={this.handleLogoutToggle}
          handleBtnClick={this.handleLogout}
          showLoader={showLoader}
          subText="Are you sure you want to logout?"
          heading="Logout"
          btnValue="Logout"
        />
        <ProfileDropDown
          openModal={isExtensionOpen}
          handleModal={this.toggleExtension}
          username={user.account.displayName}
          userEmail={user.account.email}
          businessList={user.business}
          getUserDetails={this.props.getUserDetails}
          user={user}
          navigation={navigation}
          handleLogout={this.handleLogoutToggle}
        />
        <Navbar expand="sm" id="home-navbar" light>
          <NavbarBrand className="logo-image">
            <div onClick={() => navigation(ROUTES_PATH.HOME)}>
              <img src={IMG.logo} alt="" height="32px" width="120px" />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} className="border-0" />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="container-fluid pb-1" navbar>
              <NavItem className="d-flex flex-nowrap w-100 justify-content-xs-start justify-content-sm-end">
                <div
                  className="inline-flex dropdown-profile-nameshow"
                  onClick={this.handleUserProfile}
                >
                  <span className="user-name-text text-ellipse">
                    {user.account.displayName}
                  </span>
                  <img
                    src={
                      user.account?.profilePictureResource?.url
                        ? user.account.profilePictureResource.url
                        : IMG.profileImg
                    }
                    className="profile-image-box-style"
                    alt=""
                    onClick={this.toggleExtension}
                  />
                  <img
                    src={IMG.settingsIcon}
                    alt="Settings"
                    className="cursor-pointer"
                    onClick={() => navigation(ROUTES_PATH.SETTINGS)}
                  />
                </div>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutSuccess: ActionCreators.logoutSuccess,
      getBusinessDetails: ActionCreators.getBusinessDetails,
      getUserDetails: ActionCreators.getUserDetails
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
