import * as actionTypes from "../actions/actionTypes";

const initialState = {};

export default (state = false, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_TEMPLATES_INFO:
      return data;
    default:
      return state;
  }
};
