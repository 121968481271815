import { put, call } from "redux-saga/effects";
import { toastMessage } from "../../components/ToastMessage";
import { internalServerError } from "../../constants";
import * as serviceCreators from "../../services";
import * as actionCreators from "../actions";

export function* getReccoListSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getReccoListService,
      data.requestData
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveReccoList(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getReccoFamSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getReccoFamService,
      data.businessId
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleSecondLoader(false));
      yield put(actionCreators.saveReccoFam(resp.data));
    } else {
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSecondLoader(false));
  }
}

export function* getTemplates(data) {
  try {
    const resp = yield call(
      serviceCreators.getTemplatesService,
      data.requestData
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      if (data.requestData.type === "image") {
        if (data !== undefined && data !== [] && data.requestData.skip > 0) {
          yield put(
            actionCreators.saveImgTemplates([...data.data, ...resp.data])
          );
        } else {
          yield put(actionCreators.saveImgTemplates(resp.data));
        }
      } else if (data.requestData.type === "text") {
        if (data !== undefined && data !== [] && data.requestData.skip > 0) {
          yield put(
            actionCreators.saveTextTemplates([...data.data, ...resp.data])
          );
        } else {
          yield put(actionCreators.saveTextTemplates(resp.data));
        }
      }
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getReccoListAdvocatesSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getReccoListAdvocatesService,
      data.requestData
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveReccoListAdvocates(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getTemplatesInfoSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getTemplatesInfoService,
      data.reccoListId
    );

    const res = {
      image: resp.imageResponse.data,
      text: resp.textResponse.data,
    };

    if (
      (resp.imageResponse.status === 200 ||
        resp.imageResponse.status === 201) &&
      (resp.textResponse.status === 200 || resp.textResponse.status === 201)
    ) {
      yield put(actionCreators.toggleSecondLoader(false));
      yield put(actionCreators.saveTemplatesInfo(res));
    } else {
      yield put(actionCreators.toggleSecondLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleSecondLoader(false));
  }
}
export function* getRemainingReccosSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getRemainingReccosService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveRemainingRecco(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getBusinessPerkSaga(data) {
  try {
    const resp = yield call(serviceCreators.getBusinessPerkService, data.data);
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveBusinessPerk(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    if (error.response.status === 404) {
      yield put(actionCreators.saveBusinessPerk(false));
    }
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getReccoListDetailsSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getReccoListDetailsService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveReccoListDetails(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
export function* getEntityListSaga(data) {
  try {
    const resp = yield call(
      serviceCreators.getEntityListService,
      data.data
    );
    if (resp.status === 200 || resp.status === 201) {
      yield put(actionCreators.toggleMainLoader(false));
      yield put(actionCreators.saveEntities(resp.data));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
  }
}
