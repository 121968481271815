import Plot from "react-plotly.js";
import LoadingState from "../../common/component/loader";

const ReccoFamGraph = ({ reccoFamGraph, loader }) => {
  return (
    <div>
      {loader?.sortLoader ? (
        <div
          className="main-loader-center"
          // style={{ height: isGetInsights ? "300px" : "500px" }}
        >
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <Plot
          data={[
            {
              type: "scatter",
              mode: "lines",
              marker: { color: "#007AFF" },
              line: { shape: "spline" },
              fill: "tozeroy",
              fillcolor: "rgba(0, 122, 255, 0.33)",
              x: reccoFamGraph?.intervals,
              y: reccoFamGraph?.reccoAsked,
              name: "Asked for Recco",
            },
            {
              x: reccoFamGraph?.intervals,
              y: reccoFamGraph?.reccoGiven,
              type: "scatter",
              mode: "lines",
              line: { shape: "spline" },
              marker: { color: "#FFA000" },
              fill: "tonexty",
              // fillcolor: "rgba(255, 255, 255, 0)",
              name: "Gave Recco",
            },
          ]}
          layout={{
            autosize: true,
            height: "550",
            font: {
              family: "Montserrat, sans-serif",
              size: "12px",
              color: "#9C9C9C",
              lineHeight: "16px",
            },
            margin: {
              l: 0,
              r: 20,
              b: 20,
              t: 0,
              pad: 20,
            },
            showlegend: true,
            legend: {
              xanchor: "right",
              yanchor: "top",
              x: 1,
              y: 1.1,
              traceorder: "normal",
              orientation: "h",
              font: {
                family: "Montserrat,sans-serif",
                size: "12px",
                color: "#9C9C9C",
                lineHeight: "16px",
              },
            },
            xaxis_type: "category",
            xaxis: {
              title: { text: "Time Period", standoff: 20 },
              titlefont: {
                family: "Work Sans",
                size: 10,
                color: "#9C9C9C",
                height: 12,
              },
              tick0: 0,
              fixedrange: true,
              automargin: true,
              zeroline: true,
              showgrid: true,
            },
            yaxis_type: "category",
            yaxis: {
              title: { text: "NO. OF CUSTOMERS", standoff: 20 },
              titlefont: {
                family: "Work Sans",
                size: 10,
                color: "#9C9C9C",
                height: 12,
              },
              rangemode: "tozero",
              tick0: 0,
              fixedrange: true,
              automargin: true,
              zeroline: true,
              showgrid: false,
              showticksuffix: "all",
              // ticksuffix: "K",
              // tickformat: "S",
            },
          }}
          displayModeBar={false}
          displaylogo={false}
          responsive={true}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </div>
  );
};
export default ReccoFamGraph;
