const REDUX_STATE = "state";
const ACCESS_TOKEN = "AutherizationToken";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_STATE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(REDUX_STATE, serializedState);
  } catch (err) {
    //log this
  }
};

export const saveToken = (accessToken) => {
  try {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  } catch (err) {}
};

export const retrieveToken = () => {
  let token = localStorage.getItem(ACCESS_TOKEN);
  return !token || token == null || token === "" ? "sdjnsc" : token;
};
