import axiosInstance from "../axios";
import * as url from "../utils/urls";

export const getAccountDetailsService = (accountId) => {
  return axiosInstance.get(url.getAccountDetailsUrl(accountId));
};
export const getSubscriptionPlansService = () => {
  return axiosInstance.get(url.getPlansUrl);
};
export const chooseSubscriptionPlansService = (payload) => {
  return axiosInstance.post(url.choosePlansUrl, payload);
};
export const getUserDetailsService = async (accountId, businessId) => {

  const accountResponse = await axiosInstance.get(url.getAccountDetailsUrl(accountId));
  const businessResponse = await axiosInstance.get(url.getBusinessDetailsUrl(accountId));
  let subscriptionResponse = {};

  if (businessResponse.data.length) {
    businessId = businessId ? businessId : businessResponse.data[localStorage.getItem("businessIndex") || 0].businessForAccount.id;
    subscriptionResponse = await axiosInstance.get(url.getSubscriptionDetailsUrl(accountId, businessId));
  }

   const response = { accountResponse, businessResponse, subscriptionResponse };
  return response;
};
export const getBusinessDetailsService = (accountId) => {
  return axiosInstance.get(url.getBusinessDetailsUrl(accountId));
};
export const getSubscriptionDetailsService = (accountId) => {
  return axiosInstance.get(url.getSubscriptionDetailsUrl(accountId));
};
export const getReccoListService = (data) => {
  return axiosInstance.get(url.getReccoListUrl(data));
};
export const getReccoFamService = (businessId) => {
  return axiosInstance.get(url.reccoFamCountUrl(businessId));
};
export const getTemplatesService = (data) => {
  return axiosInstance.get(url.getTemplatesUrl(data));
};
export const getReccoListAdvocatesService = (data) => {
  return axiosInstance.get(url.getReccoListAdvocatesUrl(data));
};
export const getTemplatesInfoService = async (reccoListId) => {
  const [textResponse, imageResponse] = await Promise.all([
    axiosInstance.get(url.getTemplatesInfoUrl(reccoListId, "text")),
    axiosInstance.get(url.getTemplatesInfoUrl(reccoListId, "image")),
  ]);
  const response = { textResponse, imageResponse };
  return response;
};
export const updateAdvocatesService = (data, payload) => {
  return axiosInstance.patch(url.updateAdvocatesUrl(data), payload);
};
export const deleteAdvocatesService = (data) => {
  return axiosInstance.delete(url.deleteAdvocatesUrl(data));
};
export const searchAdvocatesService = (data) => {
  return axiosInstance.get(url.searchAdvocatesUrl(data));
};
export const getRemainingReccosService = (data) => {
  return axiosInstance.get(url.getRemainingReccosUrl(data));
};
export const getBusinessPerkService = (data) => {
  return axiosInstance.get(url.getBusinessPerkUrl(data));
};
export const getReccoListDetailsService = (data) => {
  return axiosInstance.get(url.getReccoListDetailsUrl(data));
};
export const updateReccoListDetailsService = (data, payload) => {
  return axiosInstance.patch(url.updateReccoListDetailsUrl(data), payload);
};
export const scheduleReminderService = (data, payload) => {
  return axiosInstance.post(url.scheduleReminderUrl(data), payload);
};
export const launchReccoService = (data) => {
  return axiosInstance.post(url.launchReccoUrl(data), null);
};
export const createNurtureService = (businessId, payload) => {
  return axiosInstance.post(url.createNurtureUrl(businessId), payload);
};
export const getNurtureDetailsService = (businessId) => {
  return axiosInstance.get(url.getNurtureUrl(businessId));
};
export const updateNurtureService = (data, payload) => {
  return axiosInstance.patch(url.updateNurtureUrl(data), payload);
};
export const deleteNurtureService = (data) => {
  return axiosInstance.delete(url.updateNurtureUrl(data));
};
export const getEntityListService = (data) => {
  return axiosInstance.get(url.getEntityListUrl(data));
};
export const getReccoFamDetailsService = (data) => {
  return axiosInstance.get(url.getReccoFamUrl(data));
};
export const searchReccoFamDetailsService = (data) => {
  return axiosInstance.get(url.searchReccoFamUrl(data));
};
export const getRemainingReccoFamService = (data) => {
  return axiosInstance.get(url.getRemainingReccoFamUrl(data));
};
export const nurtureExportCSVService = (data) => {
  return axiosInstance.get(url.nurtureExportCSVUrl(data), {
    responseType: "blob",
  });
};
export const deleteBusinessPerkService = (data) => {
  return axiosInstance.delete(url.deleteBusinessPerkUrl(data));
};
export const getReccoCloudService = (data) => {
  return axiosInstance.get(url.getReccoCloudUrl(data));
};
export const getCommunityOverviewService = (data) => {
  return axiosInstance.get(url.getCommunityOverviewUrl(data));
};
export const getInsightsCardService = (data) => {
  return axiosInstance.get(url.getInsightsCardsUrl(data));
};
export const getReccoFamGraphService = (data) => {
  return axiosInstance.get(url.getReccoFamGraphUrl(data));
};
export const getPerkPerformanceService = (data) => {
  return axiosInstance.get(url.getPerkPerformanceUrl(data));
};
export const getReccoMediumService = (data) => {
  return axiosInstance.get(url.getReccoMediumUrl(data));
};
export const createResourceService = (businessId, payload) => {
  return axiosInstance.post(url.createResourceUrl(businessId), payload);
};
export const updateProfileDetailsService = (data, payload) => {
  return axiosInstance.patch(url.updateProfileDetailsUrl(data), payload);
};
export const createBusinessResourceService = (accountId, payload) => {
  return axiosInstance.post(url.createBusinessResourceUrl(accountId), payload);
};
export const updatePlanService = (data, payload) => {
  return axiosInstance.patch(url.updatePlansUrl(data), payload);
};
export const getPaymentHistoryService = (data) => {
  return axiosInstance.get(url.getPaymentHistoryUrl(data));
};
export const getNurturePerformanceService = (data) => {
  return axiosInstance.get(url.getNurturePerformanceUrl(data));
};

export const getBusinessAdminsService = (businessId) => {
  return axiosInstance.get(url.getBusinesAdminsUrl(businessId));
};

export const inviteBusinessAdminService = (businessId, payload) => {
  return axiosInstance.post(url.getBusinesAdminsUrl(businessId), payload);
};

//{{base_url}}/businesses/:businessId/managers/:managerId
export const removeBusinessAdminService = (businessId, managerId) => {
  return axiosInstance.delete(
    url.removeBusinessAdminUrl(businessId, managerId)
  );
};

export const addAdminManage = (businessId,payload) =>{
  return  axiosInstance.post(url.getBusinesAdminsUrl(businessId), payload);
}

export const otpVerify = (accountId,payload)=>{
  return  axiosInstance.post(url.otpVerify(accountId), payload);
}

export const sendOtp = (accountId) =>{
  return axiosInstance.post(url.sendOtp(accountId));
}

export const linkedAccount = (businessId, payload) => {
  return axiosInstance.post(url.linkedAccounts(businessId),payload)
}
