import { Fragment, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import AddAdvocateBtn from "../../common/component/addAdvocateBtn";
import OverviewCard from "../../common/component/overviewCard";
import AddAdvocatesPopup from "../../components/AddAdvocates";
import ReccoTable from "../../components/ReccoTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import LoadingState from "../../common/component/loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { getReccoListService } from "../../services";
import UpgradePlanPopup from "../../components/UpgradePlanPopup";

const ReccoReturning = (props) => {
  const [isAddAdvocatesOpen, setAddAdvocatesOpen] = useState(false);
  const [reccoList, setReccoList] = useState([]);
  const [stopPagination, setStopPagination] = useState(false);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);

  useEffect(() => {
    if (!stopPagination) {
      getData();
    }
  }, []);

  useEffect(() => {
    props.toggleSecondLoader(true);
    props.getReccoFam(
      props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
    );
    const data = {
      accountId: props.user.accountId,
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
    };
    props.getRemainingRecco(data);
  }, []);

  const handleAddAdvocatesModel = () => {
    setAddAdvocatesOpen(!isAddAdvocatesOpen);
  };
  const handleSort = (orderByStringvar, sortByStringvar) => {
    props.toggleSortLoader(true);
    getData(0, orderByStringvar, sortByStringvar);
  };
  const getData = (currentPage = page, sortBy, orderBy) => {
    const requestData = {
      businessId:
        props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id,
      skip: currentPage * 10,
      limit: 10,
      sortBy: sortBy,
      orderBy: orderBy,
    };
    props.toggleMainLoader(true);
    getReccoListService(requestData)
      .then((res) => {
        if (res.data.length > 0) {
          if (currentPage === 0) {
            setReccoList(res.data);
          } else {
            setReccoList((prevState) => [...prevState, ...res.data]);
          }
          setPage((prevState) => prevState + 1);
        }
        if (res.data.length < 10) {
          setStopPagination(true);
        }
        props.toggleMainLoader(false);
        props.toggleSortLoader(false);
        setLoader(false);
      })
      .catch((err) => {
        props.toggleMainLoader(false);
        props.toggleSortLoader(false);
        setLoader(false);
      });
  };
  const resetInitialState = () => {
    setLoader(true);
    setReccoList([]);
    getData(0);
  };
  const handleUpgradePlanModal = () => {
    setUpgradePlanModal(!upgradePlanModal);
  };
  return (
    <Fragment>
      <AddAdvocatesPopup
        openModal={isAddAdvocatesOpen}
        handleModal={handleAddAdvocatesModel}
        businessId={
          props.user.business[localStorage.getItem("businessIndex") || 0].businessForAccount.id
        }
        accountId={props.user.accountId}
        resetState={resetInitialState}
        remainingReccos={props.remainingRecco?.reccosRemaining}
        type="recco"
        {...props}
      />
      <UpgradePlanPopup
        openModal={upgradePlanModal}
        handleModal={handleUpgradePlanModal}
        allowedValues={props.remainingRecco.reccoAllowed}
      />
      {props.loader.secondLoader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <>
          <Row>
            <Col md={4} lg={4} xl={4}>
              <div className="recco-pages-heading">
                Let’s build your Recco Fam.
              </div>
              <div className="recco-pages-sub-heading">
                Build and manage your community of authentic advocates.
              </div>
            </Col>
            <Col md={{ size: 4 }} lg={{ size: 4 }} xl={{ size: 3, offset: 1 }}>
              <AddAdvocateBtn
                className="advocates-button-input"
                buttonValue="Add Customers"
                handleButtonAction={handleAddAdvocatesModel}
                disabled={
                  props.remainingRecco?.reccosRemaining <= 0 ? true : false
                }
              />
              {props.remainingRecco?.reccosRemaining <= 0 && (
                <div
                  className="input-error-style"
                  style={{ marginLeft: "15px" }}
                >
                  Reccos Limit exceeded.
                  <span
                    style={{
                      color: "rgba(22, 117, 204, 1)",
                      marginLeft: "10px",
                    }}
                    className="cursor-pointer"
                    onClick={() => handleUpgradePlanModal()}
                  >
                    Upgrade Plan
                  </span>
                </div>
              )}
            </Col>
            <Col md={{ size: 4 }} lg={{ size: 4 }} xl={{ size: 3, offset: 1 }}>
              <OverviewCard
                data={{
                  count: props.reccoFam.totalAdvocates,
                  title: "Recco Fam",
                  subTitle: "",
                  percentage: props.reccoFam.changesThisMonth,
                }}
              />
            </Col>
          </Row>
          <Row className="mt-40">
            <Col md={12} className="recco-pages-heading">
              ‘Get Recco’ List
            </Col>
            <Col md={12} style={{ marginTop: "16px" }}>
              <InfiniteScroll
                dataLength={reccoList?.length}
                next={getData}
                hasMore={!stopPagination}
                height={600}
                scrollThreshold={1}
                loader={
                  <div className="table-loader-center">
                    <LoadingState color="#2963ca" />
                  </div>
                }
                scrollableTarget={"list"}
                className="recco-table-scroll"
              >
                <ReccoTable
                  {...props}
                  handleSort={handleSort}
                  reccoList={reccoList}
                  resetState={resetInitialState}
                  localLoader={loader}
                />
              </InfiniteScroll>
            </Col>
          </Row>
        </>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    reccoList: state.reccoList,
    loader: state.loader,
    reccoFam: state.reccoFam,
    remainingRecco: state.remainingRecco,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: ActionCreators.toggleMainLoader,
      getReccoList: ActionCreators.getReccoList,
      getReccoFam: ActionCreators.getReccoFam,
      toggleSecondLoader: ActionCreators.toggleSecondLoader,
      toggleSortLoader: ActionCreators.toggleSortLoader,
      getRemainingRecco: ActionCreators.getRemainingRecco,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReccoReturning);
