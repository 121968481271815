import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./signup.css";
import InputField from "../../common/component/inputField";
import {
  IMG,
  inputFieldValidaionMessage,
  requiredFieldMessage,
  passwordValidation,
  confirmPasswordValidation,
  termsAndPrivacyRequired,
  internalServerError,
  emailPwdInvalid,
  loginEmailNotExist,
  ROUTES_PATH,
} from "../../constants";
import CheckBoxField from "../../common/component/checkBox";
import PasswordField from "../../common/component/passwordField";
import ButtonItem from "../../common/component/button";
import {
  validateEmail,
  validatePassword,
  validateConfirmPassword,
} from "../../utils/validation";
import axios from "axios";
import { signUpUrl, loginUrl } from "../../utils/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import { saveToken } from "../../utils/localStorage";
import { toastMessage } from "../../components/ToastMessage";
import EmailExistPopup from "../../components/EmailExistPopup";

const SignUp = (props) => {
  const navigate = useNavigate();
  const [signupDetails, setSignupDetails] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    isValidEmail: false,
    invalidEmailMsg: "",
    isValidPassword: false,
    invalidPasswordMsg: "",
    isValidConfirmPassword: false,
    invalidConfirmPasswordMsg: "",
    isTermsPolicy: false,
    isValidTermsPolicy: false,
  });
  const [loader, setLoader] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModal] = useState(false);

  const handleConfirmationToggle = () => {
    setConfirmationModal(!isConfirmationModalOpen);
  };

  const handleInputChange = ({ target: input }, msgType, validationType) => {
    const SignupDetails = { ...signupDetails };
    if (input.name === "isTermsPolicy") {
      SignupDetails[input.name] = input.checked;
      SignupDetails[validationType] = false;
    } else {
      SignupDetails[input.name] = input.value;
      SignupDetails[msgType] = "";
      SignupDetails[validationType] = false;
    }
    setSignupDetails(SignupDetails);
  };
  const handleSubmit = () => {
    const SignupDetails = { ...signupDetails };
    if (
      signupDetails.email === "" ||
      signupDetails.email === null ||
      signupDetails.email === undefined
    ) {
      SignupDetails.isValidEmail = true;
      SignupDetails.invalidEmailMsg = requiredFieldMessage("email address");
    } else if (!validateEmail(signupDetails.email)) {
      SignupDetails.isValidEmail = true;
      SignupDetails.invalidEmailMsg =
        inputFieldValidaionMessage("email address");
    }
    if (
      signupDetails.password === "" ||
      signupDetails.password === null ||
      signupDetails.password === undefined
    ) {
      SignupDetails.isValidPassword = true;
      SignupDetails.invalidPasswordMsg = requiredFieldMessage("password");
    } else if (!validatePassword(signupDetails.password)) {
      SignupDetails.isValidPassword = true;
      SignupDetails.invalidPasswordMsg = passwordValidation;
    }
    if (
      signupDetails.confirmPassword === "" ||
      signupDetails.confirmPassword === null ||
      signupDetails.confirmPassword === undefined
    ) {
      SignupDetails.isValidConfirmPassword = true;
      SignupDetails.invalidConfirmPasswordMsg =
        requiredFieldMessage("confirm password");
    } else if (
      signupDetails.password &&
      !validateConfirmPassword(
        signupDetails.password,
        signupDetails.confirmPassword
      )
    ) {
      SignupDetails.isValidConfirmPassword = true;
      SignupDetails.invalidConfirmPasswordMsg = confirmPasswordValidation;
    }
    if (!signupDetails.isTermsPolicy) {
      SignupDetails.isValidTermsPolicy = true;
    }
    setSignupDetails(SignupDetails);
    const {
      isValidConfirmPassword,
      isValidEmail,
      isValidPassword,
      isValidTermsPolicy,
    } = SignupDetails;

    if (
      !isValidEmail &&
      !isValidPassword &&
      !isValidConfirmPassword &&
      !isValidTermsPolicy
    ) {
      const payload = {
        email: signupDetails.email,
        password: signupDetails.password,
        appType: "business",
      };
      setLoader(true);
      axios
        .post(signUpUrl, payload)
        .then((response) => {
          if (response.status === 201) {
            handleAccessToken();
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response?.status === 400) {
            const errorMessage =
              error.response.data.failures.length > 0
                ? error.response.data.failures[0]["message"]
                : error.response.data.message;
            if (errorMessage === "An account with this email already exists") {
              handleConfirmationToggle();
            } else {
              toastMessage("error", errorMessage);
            }
          } else {
            toastMessage("error", internalServerError);
          }
        });
    }
  };
  const handleAccessToken = () => {
    const payload = {
      email: signupDetails.email,
      password: signupDetails.password,
    };
    setLoader(true);
    axios
      .post(loginUrl, payload)
      .then((response) => {
        if (response.status === 200) {
          saveToken(response.data.token);

          props.getUserDetails(
            "signUp",
            response.data.accountId,
            response.data
          );
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          if (
            errorMessage ===
            "Please specify a valid email and/or password to continue"
          ) {
            toastMessage("error", emailPwdInvalid);
          } else {
            toastMessage("error", errorMessage);
          }
        } else if (error.response?.status === 404) {
          toastMessage("error", loginEmailNotExist);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  return (
    <div className="main-signup-div">
      <EmailExistPopup
        openModal={isConfirmationModalOpen}
        handleModal={handleConfirmationToggle}
      />
      <Row>
        <Col md={{ size: 5 }} lg={{ size: 4 }} xl={4}>
          <div>
            <img src={IMG.logo} alt="Recco" />
          </div>
          <div className="signup-heading">
            Create your Recco<br></br> Business Account
          </div>
          <div className="signup-sub-heading">
            Already have a business account ?
            <span
              className="login-link"
              onClick={() => navigate(ROUTES_PATH.LOGIN)}
            >
              Login
            </span>
          </div>
          <div style={{ marginTop: "48px" }}>
            <div className="mb-22">
              <InputField
                className="page-input-box"
                placeholder="email@example.com"
                maxLength="100"
                label="E-mail"
                name="email"
                handleInputChange={(e) =>
                  handleInputChange(e, "invalidEmailMsg", "isValidEmail")
                }
                value={signupDetails.email}
                invalid={signupDetails.isValidEmail}
                invalidMsg={signupDetails.invalidEmailMsg}
              />
            </div>
            <div className="mb-22">
              <PasswordField
                handlePasswordChange={(e) =>
                  handleInputChange(e, "invalidPasswordMsg", "isValidPassword")
                }
                className="page-input-box"
                maxLength="15"
                placeholder="password"
                label="Password"
                name="password"
                value={signupDetails.password}
                invalid={signupDetails.isValidPassword}
                invalidMsg={signupDetails.invalidPasswordMsg}
              />
              {!signupDetails.isValidPassword && (
                <div className="password-msg-style">{passwordValidation}</div>
              )}
            </div>

            <div className="mb-22">
              <PasswordField
                handlePasswordChange={(e) =>
                  handleInputChange(
                    e,
                    "invalidConfirmPasswordMsg",
                    "isValidConfirmPassword"
                  )
                }
                className="page-input-box"
                maxLength="15"
                placeholder="confirm password"
                label="Confirm Password"
                name="confirmPassword"
                value={signupDetails.confirmPassword}
                invalid={signupDetails.isValidConfirmPassword}
                invalidMsg={signupDetails.invalidConfirmPasswordMsg}
              />
            </div>
            <CheckBoxField
              name="isTermsPolicy"
              label={<div>I agree to the <a href="https://reccojoy.com/terms-conditions/" target="_blank" >Terms of service</a> and <a href="https://reccojoy.com/privacy-policy/" target="_blank">privacy policy</a> agreement statement.</div>}
              handleInputChange={(e) =>
                handleInputChange(
                  e,
                  "termsAndPrivacyRequired",
                  "isValidTermsPolicy"
                )
              }
              invalid={signupDetails.isValidTermsPolicy}
              invalidMsg={termsAndPrivacyRequired}
            />
            <ButtonItem
              className="page-button-input"
              buttonValue="Sign-up"
              handleButtonAction={handleSubmit}
              disabled={loader}
              showLoader={loader}
            />
          </div>
        </Col>
        <Col
          md={{ size: 5, offset: 2 }}
          lg={{ size: 6, offset: 2 }}
          xl={{ size: 6, offset: 2 }}
        >
          <img src={IMG.signUpImg} alt="" className="side-image" />
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginSuccess: ActionCreators.loginSuccess,
      getUserDetails: ActionCreators.getUserDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
