import React from "react";
import { useState, useEffect } from "react";
import { Navbar, NavLink, Nav, NavItem, NavbarText, Badge } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { IMG, ROUTES_PATH } from "../../constants";
import "./secondNavBar.css";

const SecondNavBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  let localBusinessIndex = localStorage.getItem("businessIndex");

  if (localBusinessIndex === null || localBusinessIndex === undefined) {
    localBusinessIndex = 0;
  } else {
    localBusinessIndex = parseInt(localBusinessIndex);
  }

  const handleNavigation = (navigateTo) => {
    navigate(navigateTo);
  };

  return (
    <div>
      <Navbar expand="md" className="second-nav-bar">
        <NavbarText className="navbar-text">
          <img
            src={
              props.user.business[localBusinessIndex]?.businessForAccount
                .resource?.url
                ? props.user.business[localBusinessIndex]?.businessForAccount
                    .resource.url
                : IMG.profileImg
            }
            className="navbar-business-image"
          />
          {
            props.user.business[localBusinessIndex].businessForAccount
              .businessName
          }
          &nbsp;
          <Badge>
            {props.user.business[localBusinessIndex]?.businessForAccount
              .type === "SUPER_ADMIN"
              ? "Owner"
              : "Manager"}
          </Badge>
        </NavbarText>
        <Nav className="ml-auto" navbar>
          <NavItem className="mr-25 cursor-pointer">
            <NavLink
              className={
                location.pathname === ROUTES_PATH.HOME
                  ? "active-navbar-text"
                  : "navbar-text-font"
              }
              onClick={() => handleNavigation(ROUTES_PATH.HOME)}
            >
              <img
                src={
                  location.pathname === ROUTES_PATH.HOME
                    ? IMG.homeOrange
                    : IMG.homeWhite
                }
                alt=""
                className="mr-10 mrb-5"
                width="18px"
                height="18px"
              />
              Home
            </NavLink>
          </NavItem>
          <NavItem className="mr-25 cursor-pointer">
            <NavLink
              className={
                location.pathname.includes(ROUTES_PATH.GET_RECCO)
                  ? "active-navbar-text"
                  : "navbar-text-font"
              }
              onClick={() => navigate(ROUTES_PATH.GET_RECCO)}
            >
              <img
                src={
                  location.pathname.includes(ROUTES_PATH.GET_RECCO)
                    ? IMG.getReccoOrange
                    : IMG.getReccoWhite
                }
                alt=""
                className="mr-10 mrb-5"
              />
              Get Recco
            </NavLink>
          </NavItem>
          <NavItem className="mr-25 cursor-pointer">
            <NavLink
              className={
                location.pathname.includes(ROUTES_PATH.NURTURE)
                  ? "active-navbar-text"
                  : "navbar-text-font"
              }
              onClick={() => navigate(ROUTES_PATH.NURTURE)}
            >
              <img
                src={
                  location.pathname.includes(ROUTES_PATH.NURTURE)
                    ? IMG.nurtureOrange
                    : IMG.nurtureWhite
                }
                alt=""
                className="mr-10 mrb-5"
              />
              Nurture
            </NavLink>
          </NavItem>
          <NavItem className="mr-25 cursor-pointer">
            <NavLink
              className={
                location.pathname.includes(ROUTES_PATH.MANAGE_PERKS)
                  ? "active-navbar-text"
                  : "navbar-text-font"
              }
              onClick={() => navigate(ROUTES_PATH.MANAGE_PERKS)}
            >
              <img
                src={
                  location.pathname.includes(ROUTES_PATH.MANAGE_PERKS)
                    ? IMG.managePerksOrange
                    : IMG.managePerksWhite
                }
                alt=""
                className="mr-10 mrb-5"
              />
              Manage Perks
            </NavLink>
          </NavItem>
          <NavItem className="mr-25 cursor-pointer">
            <NavLink
              className={
                location.pathname === ROUTES_PATH.GET_INSIGHTS
                  ? "active-navbar-text"
                  : "navbar-text-font"
              }
              onClick={() => navigate(ROUTES_PATH.GET_INSIGHTS)}
            >
              <img
                src={
                  location.pathname === ROUTES_PATH.GET_INSIGHTS
                    ? IMG.getInsightsOrange
                    : IMG.getInsightsWhite
                }
                alt=""
                className="mr-10 mrb-5"
              />
              Dashboard
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
  };
};

export default connect(mapStateToProps)(SecondNavBar);
