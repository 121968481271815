import { useState } from "react";
import { IMG } from "../../constants";
import "./copy.css";

const CopyComp = ({text}) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyText = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <div className="copy-container" onClick={handleCopyText}>
      <img src={IMG.copy} alt="Copy" className="cursor-pointer" />
      {isCopied && <div className="copied-text">Copied!</div>}
    </div>
  );
};
export default CopyComp;
