export const passwordValidation =
  "Password must be 8-15 characters long and contain at least 1 upper case, 1 lower case, 1 number and 1 special character(@,$,&,%,*)";

export const loginFormValiadtion =
  "Either email id or the password you have entered is incorrect";

export const forgotPasswordValiadtion = "Email you have entered does not exist";

export const resetPasswordValidation = `"Reset password request expired. Please check latest request send on your email"`;

export const confirmPasswordValidation =
  "Confirm password does not match with password";

export const internalServerError = "Internal Server Error";
export const signUpSuccess = "User signed up successfully";
export const userDetailsSuccess = "User details added successfully";
export const loginEmailNotExist =
  "This email address is not associated with any account.";
export const emailPwdInvalid = "Email address or password is invalid";
export const passwordChangeMessage = "Password updated successfully!";

export const termsAndPrivacyRequired =
  "Please agree to the Terms of service and privacy policy";

export const planSubscribed = "You have been subscribed to a plan successfully";

export const invalidFileType =
  "Invalid file type. only csv and excel files are allowed.";

export const invalidImgType = "Invalid file type. only images are allowed.";
export const videoLimit = "Video duration should be less than 30s.";

export const uploadFailed = "Oh no, the upload failed. Please try again.";

export const requiredFieldMessage = (name) => {
  return `Please enter your ${name}`;
};

export const inputFieldValidaionMessage = (name) => {
  return `Please enter a valid ${name}`;
};
