import React from "react";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";

function LoadingState({ Width, Height, color }) {
  return (
    <div className="item-center">
      <TailSpin
        color={color}
        height={Height}
        width={Width}
        // timeout={3000} //3 secs
      />
    </div>
  );
}
LoadingState.propTypes = {
  Width: PropTypes.string,
  Height: PropTypes.string,
  color: PropTypes.string,
};

LoadingState.defaultProps = {
  color: "#FFFFFF",
};
export default LoadingState;
