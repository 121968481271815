import { takeLatest, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as AccountsMiddleware from "./accountSaga";
import * as SubscriptionMiddleware from "./subscriptionSags";
import * as ReccoListMiddleware from "./reccoListSaga";
import * as NurtureMiddleware from "./nurtureSaga";
import * as InsightsMiddleware from "./getInsightsSaga";
import * as PaymentsMiddleware from "./paymentHistorySaga";

export default function* mySaga() {
  yield takeLatest(
    actionTypes.GET_ACCOUNT_DETAILS,
    AccountsMiddleware.getAccountDetails
  );
  yield takeLatest(
    actionTypes.GET_SUBSCRIPTION_PLANS,
    SubscriptionMiddleware.getSubscriptionPlans
  );
  yield takeLatest(
    actionTypes.CHOOSE_SUBSCRIPTION_PLANS,
    SubscriptionMiddleware.chooseSubscriptionPlans
  );
  yield takeLatest(
    actionTypes.GET_USER_DETAILS,
    AccountsMiddleware.getUserDetails
  );
  yield takeLatest(
    actionTypes.GET_BUSINESS_DETAILS,
    AccountsMiddleware.getBusinessDetails
  );
  yield takeLatest(
    actionTypes.GET_SUBSCRIPTION_DETAILS,
    AccountsMiddleware.getSubscriptionDetails
  );
  yield takeLatest(
    actionTypes.GET_RECCO_LIST,
    ReccoListMiddleware.getReccoListSaga
  );
  yield takeLatest(
    actionTypes.GET_RECCO_FAM,
    ReccoListMiddleware.getReccoFamSaga
  );
  yield takeEvery(actionTypes.GET_TEMPLATES, ReccoListMiddleware.getTemplates);
  yield takeLatest(
    actionTypes.GET_RECCO_LIST_ADVOCATES,
    ReccoListMiddleware.getReccoListAdvocatesSaga
  );
  yield takeLatest(
    actionTypes.GET_TEMPLATES_INFO,
    ReccoListMiddleware.getTemplatesInfoSaga
  );
  yield takeLatest(
    actionTypes.GET_REMAINING_RECCO,
    ReccoListMiddleware.getRemainingReccosSaga
  );
  yield takeLatest(
    actionTypes.GET_BUSINESS_PERK,
    ReccoListMiddleware.getBusinessPerkSaga
  );
  yield takeLatest(
    actionTypes.GET_RECCO_LIST_DETAILS,
    ReccoListMiddleware.getReccoListDetailsSaga
  );
  yield takeLatest(
    actionTypes.GET_ENTITIES,
    ReccoListMiddleware.getEntityListSaga
  );
  yield takeLatest(
    actionTypes.GET_NURTURE_DETAILS,
    NurtureMiddleware.getNurtureDetailsSaga
  );
  yield takeLatest(
    actionTypes.GET_REMAINING_RECCO_FAM,
    NurtureMiddleware.getRemainingReccoFamsSaga
  );
  yield takeLatest(
    actionTypes.GET_RECCO_CLOUD,
    InsightsMiddleware.getReccoCloudSaga
  );
  yield takeLatest(
    actionTypes.GET_COMMUNITY_OVERVIEW,
    InsightsMiddleware.getCommunityOverviewSaga
  );
  yield takeLatest(
    actionTypes.GET_INSIGHTS_CARD,
    InsightsMiddleware.getInsightsCardSaga
  );
  yield takeLatest(
    actionTypes.GET_RECCO_FAM_GRAPH,
    InsightsMiddleware.getReccoFamGraphSaga
  );
  yield takeLatest(
    actionTypes.GET_PERK_PERFORMANCE,
    InsightsMiddleware.getPerkPerformanceSaga
  );
  yield takeLatest(
    actionTypes.GET_RECCO_MEDIUM,
    InsightsMiddleware.getReccoMediumSaga
  );
  yield takeLatest(
    actionTypes.GET_PAYMENT_HISTORY,
    PaymentsMiddleware.getPaymentHistorySaga
  );
  yield takeLatest(
    actionTypes.GET_NURTURE_PERFORMANCE,
    InsightsMiddleware.getNurturePerformanceSaga
  );
}
