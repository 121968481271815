let apiVersion = process.env.REACT_APP_API_URL;
// let apiVersion = "https://48f5-2409-4053-e86-6b20-e50a-2775-bc8b-e844.ngrok.io";

//Authentication urls
export const loginUrl = `${apiVersion}/accounts/access_token`;
export const signUpUrl = `${apiVersion}/businesses/accounts`;
export const logoutUrl = `${apiVersion}/api/user/logout`;
export const forgotPasswordUrl = `${apiVersion}/accounts/forgot-password`;
export const resetpasswordUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/reset-password`;
export const resendEmailVerifyUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/send-verification-email`;
export const verifyEmailUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/email/verify`;
export const getAccountDetailsUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}`;
export const upadateUserDetailsUrl = `${apiVersion}/accounts/`;
export const addUserDetailsUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/businesses`;
export const getUserDetailsUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/business-subscription`;
export const getSubscriptionDetailsUrl = (accountId, businessId) =>
  `${apiVersion}/accounts/${accountId}/businesses/${businessId}/subscriptions`;
export const getBusinessDetailsUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/businesses`;
export const updateProfileDetailsUrl = (data) =>
  `${apiVersion}/accounts/${data.accountId}/businesses/${data.businessId}`;
export const createBusinessResourceUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/resources`;

//subscription plan urls
export const getPlansUrl = `${apiVersion}/plans`;
export const choosePlansUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/subscriptions`;
export const getInTouchUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/subscriptions/get-in-touch`;
export const updatePlansUrl = (data) =>
  `${apiVersion}/accounts/${data.accountId}/subscriptions/${data.subscriptionId}`;

//Get Recco list urls
export const getReccoListUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/recco-lists?skip=${data.skip}&limit=${data.limit}&sortBy=${data.sortBy}&orderBy=${data.orderBy}`;

export const addIndividualAdvocatesUrl = (businessId, reccoListId) =>
  `${apiVersion}/businesses/${businessId}/recco-list/${reccoListId}/advocates`;

export const createReccoListUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/recco-lists`;

export const deleteReccoListUrl = (businessId, reccoListId) =>
  `${apiVersion}/businesses/${businessId}/recco-lists/${reccoListId}`;

export const reccoFamCountUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/advocates`;

export const uploadReccoListFileUrl = (businessId, reccoListId) =>
  `${apiVersion}/businesses/${businessId}/recco-list/${reccoListId}/advocates/import`;

export const getTemplatesUrl = (data) =>
  `${apiVersion}/templates?type=${data.type}&skip=${data.skip}&limit=${data.limit}`;

export const saveAddInfoUrl = (reccoListId) =>
  `${apiVersion}/recco-lists/${reccoListId}/templates`;

export const getReccoListAdvocatesUrl = (data) =>
  `${apiVersion}/recco-list/${data.reccoListId}/advocates/list?skip=${data.skip}&limit=${data.limit}&sortBy=${data.sortBy}&orderBy=${data.orderBy}`;

export const getTemplatesInfoUrl = (reccoListId, type) =>
  `${apiVersion}/recco-lists/${reccoListId}/templates?type=${type}`;

export const updateAdvocatesUrl = (data) =>
  `${apiVersion}/recco-list/${data.reccoListId}/advocates/${data.advocateId}`;

export const deleteAdvocatesUrl = (data) =>
  `${apiVersion}/recco-list/${data.reccoListId}/advocates/${data.advocateId}`;

export const searchAdvocatesUrl = (data) =>
  `${apiVersion}/recco-list/${data.reccoListId}/advocates?search=${data.search}&skip=${data.skip}&limit=${data.limit}`;

export const getRemainingReccosUrl = (data) =>
  `${apiVersion}/accounts/${data.accountId}/businesses/${data.businessId}/get-remaining-reccos`;

export const createPerkUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/perks`;

export const getBusinessPerkUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/perks`;

export const getReccoListDetailsUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/recco-lists/${data.reccoListId}`;

export const updateReccoListDetailsUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/recco-lists/${data.reccoListId}`;

export const updatePerkUrl = (businessId, perkId) =>
  `${apiVersion}/businesses/${businessId}/perks/${perkId}`;

export const scheduleReminderUrl = (reccoListId) =>
  `${apiVersion}/recco-lists/${reccoListId}/nudges`;

export const launchReccoUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/recco-lists/${data.reccoListId}/launch-recco`;

//Get entity urls
export const getEntityListUrl = (data) =>
  `${apiVersion}/business/${data.businessId}/${data.accountId}/entity-page`;

//Nuture urls
export const createNurtureUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/nurtures`;

export const getNurtureUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/nurtures`;

export const updateNurtureUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/nurtures/${data.nurtureId}`;

export const getPerkDetailsUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/nurtures/${data.nurtureId}`;

export const getReccoFamUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/reccoFams?skip=${data.skip}&limit=${data.limit}&sortBy=${data.sortBy}&orderBy=${data.orderBy}`;

export const deleteReccoFamUrl = (businessId, famId) =>
  `${apiVersion}/businesses/${businessId}/reccoFams/${famId}`;

export const searchReccoFamUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/reccoFams/search?q=${data.search}&skip=${data.skip}&limit=${data.limit}`;

export const getRemainingReccoFamUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/reccoFams/famSize`;

export const nurtureExportCSVUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/reccoFams/export-csv`;

export const deleteBusinessPerkUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/perks/${data.perkId}`;

export const createResourceUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/nurture/resources`;

//get insights urls
export const getReccoCloudUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/recco-cloud?from=${data.from}&to=${data.to}`;

export const getCommunityOverviewUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/community-overview`;

export const getInsightsCardsUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/insights`;

export const getReccoFamGraphUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/reccoFam-overview?from=${data.from}&to=${data.to}&filterBy=${data.filterBy}`;

export const getPerkPerformanceUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/perks-performance?from=${data.from}&to=${data.to}&filterBy=${data.filterBy}`;

export const getReccoMediumUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/recco-medium`;

export const getNurturePerformanceUrl = (data) =>
  `${apiVersion}/businesses/${data.businessId}/nurtures-performance?from=${data.from}&to=${data.to}&filterBy=${data.filterBy}`;

//Payment history urls
export const getPaymentHistoryUrl = (data) =>
  data.afterId
    ? `${apiVersion}/accounts/${data.accountId}/subscriptions/${data.subscriptionId}/preview-invoice?limit=${data.limit}&startingAfter=${data.afterId}`
    : `${apiVersion}/accounts/${data.accountId}/subscriptions/${data.subscriptionId}/preview-invoice?limit=${data.limit}`;

//business admin urls
export const getBusinesAdminsUrl = (businessId) =>
  `${apiVersion}/businesses/${businessId}/managers`;
export const removeBusinessAdminUrl = (businessId, managerId) =>
  `${apiVersion}/businesses/${businessId}/managers/${managerId}`;

export const mergeAccountsUrl = (accountId) =>
  `${apiVersion}/accounts/${accountId}/merge`;

export const searchAddAdmin = (businessId) =>
  `${apiVersion}/businesses/${businessId}/accounts/search`;

export const otpVerify = (accountId) =>
  `${apiVersion}/accounts/${accountId}/verify`;

export const sendOtp = (accountId) => `${apiVersion}/accounts/${accountId}/otp`;

export const linkedAccounts = (businessId) =>
  `${apiVersion}/businesses/${businessId}/accounts/link`;
