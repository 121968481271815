import { Row, Col } from "reactstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "./emailVerify.css";
import {
  ROUTES_PATH,
  IMG,
  internalServerError,
  emailPwdInvalid,
} from "../../constants";

import ButtonItem from "../../common/component/button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActionCreators from "../../modules/actions";
import axios from "../../axios";
import { verifyEmailUrl, resendEmailVerifyUrl } from "../../utils/urls";
import { toastMessage } from "../../components/ToastMessage";
import LoadingState from "../../common/component/loader";

const EmailVerify = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountId } = useParams();
  const [isSuccess, setIsSuccess] = useState(true);
  const [loader, setLoader] = useState(false);
  const [pathState, setPathState] = useState({
    accountId: "",
    token: "",
  });

  useEffect(() => {
    
    if (null !== location.state && location.state) {
      const { accountId, token } = location.state;
      if (!props.user.account.isEmailVerified) {
        handleSubmit(token, accountId);
      }
      setPathState({
        accountId,
        token,
      });
    } else {
      if (
        location.search !== null &&
        undefined !== location.search &&
        undefined !== accountId &&
        accountId !== null
      ) {
        const token = new URLSearchParams(location.search).get("token");
        setPathState({
          accountId,
          token,
        });
        handleSubmit(token, accountId);
      } else {
        navigate(ROUTES_PATH.ADD_DETAILS);
      }
    }
  }, []);

  const handleSubmit = (token, accountId) => {
    const payload = {
      token: token,
    };
    setLoader(true);
    axios
      .post(verifyEmailUrl(accountId), payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setLoader(false);
          props.toggleMainLoader(true);
          props.getUserDetails("checkStatus", accountId);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setLoader(false);
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          if (
            errorMessage ===
            "Please specify a valid email and/or password to continue"
          ) {
            toastMessage("error", emailPwdInvalid);
          } else {
            toastMessage("error", errorMessage);
          }
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };

  const handleResendVerifyEmail = () => {
    const payload = {
      appType: "business",
    };
    props.toggleEmailVerifyLoader(true);
    axios
      .post(resendEmailVerifyUrl(props.user.accountId), payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          props.toggleEmailVerifyLoader(false);
          toastMessage("success", "Email verification mail sent successfully.");
        }
      })
      .catch((error) => {
        props.toggleEmailVerifyLoader(false);
        if (error.response?.status === 400 || error.response?.status === 404) {
          const errorMessage =
            error.response.data.failures.length > 0
              ? error.response.data.failures[0]["message"]
              : error.response.data.message;
          toastMessage("error", errorMessage);
        } else {
          toastMessage("error", internalServerError);
        }
      });
  };
  return (
    <div>
      {props.loader.mainLoader || loader ? (
        <div className="main-loader-center">
          <LoadingState color="#2963ca" />
        </div>
      ) : (
        <Row className="row-mlr">
          <Col
            md={{ size: 6 }}
            lg={{ size: 6 }}
            xl={{ size: 4 }}
            className="main-verify-div"
          >
            <div>
              <img src={IMG.logo} alt="Recco" />
            </div>
            {!isSuccess ? (
              <div className="verify-heading">Verify Your Email</div>
            ) : (
              <div className="verify-heading">
                Verified
                <img
                  src={IMG.verifyEmail}
                  alt=""
                  style={{ marginLeft: "4px" }}
                />
              </div>
            )}
            {/* <div className="verify-sub-heading">
              {!isSuccess
                ? "Please verify that " +
                  `${props.user.account.email} is your email address by clicking on the button below.`
                : "Your email address is successfully verified"}
            </div> */}
            <div className="verify-sub-heading">
              {!isSuccess
                ? "Something went wrong! Please go back to login."
                : "Your email address is successfully verified"}
            </div>
            <div style={{ marginTop: "84px" }}>
              {/* {!isSuccess ? (
                <ButtonItem
                  className="page-button-input"
                  buttonValue="Verify"
                  handleButtonAction={handleSubmit}
                  disabled={loader}
                  showLoader={loader}
                />
              ) : ( */}
              <ButtonItem
                className="page-button-input"
                buttonValue="Back to Login"
                handleButtonAction={() => navigate(ROUTES_PATH.LOGIN)}
                disabled={false}
                showLoader={false}
              />
              {/* )} */}
              {/* {!isSuccess && (
                <div className="forgot-pwd-link">
                  <span
                    className="cursor-pointer"
                    onClick={handleResendVerifyEmail}
                  >
                    Resend Verify Email
                  </span>
                </div>
              )} */}

              <div className="forgot-pwd-link verify-privacy-terms">
                <a
                  href="https://reccojoy.com/terms-conditions/"
                  className="policy-link"
                >
                  Terms of Service
                </a>
                |
                <a
                  href="https://reccojoy.com/privacy-policy/"
                  className="policy-link"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </Col>
          <Col
            md={{ size: 6 }}
            lg={{ size: 5, offset: 1 }}
            xl={{ size: 6, offset: 2 }}
            className="col-plr second-verify-div"
          >
            <img src={IMG.afterResetPwd} alt="" className="verify-side-image" />
          </Col>
        </Row>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loader: state.loader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleEmailVerifyLoader: ActionCreators.toggleEmailVerifyLoader,
      getUserDetails: ActionCreators.getUserDetails,
      toggleMainLoader: ActionCreators.toggleMainLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerify);
