import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerHeight: height } = window;
  var customHeight = document.getElementById("graph-div");
  let tem2 = 0;
  if (customHeight) {
    tem2 = customHeight.offsetWidth;
  }
  return {
    width: tem2,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
