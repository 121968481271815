import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IMG } from "../../constants";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    height: 40,
    width: "100%",
    padding: 10,
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
    marginBottom: 20
  },
  profileImg: {
    width: 40,
    height: 40,
    borderRadius: "100%",
    marginTop: 5
  },
  userStrip: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    marginLeft: 15,
  },
  userName: {
    fontSize: 13,
    fontWeight: 700,
    display: "inline",
    float: "left",
  },
  userTitleStrip: {
    fontSize: 7,
    fontWeight: 500,
    display: "inline",
    float: "left",
    background: "#EBEBEB",
    borderRadius: "10px",
    color: "#6D6D6D",
    paddingLeft: 10,
    paddingRight: 10,
    padding: 5,
    marginTop: 10,
    marginLeft: 10,
  },
  countDataRow: {
    fontSize: 7,
    display: "inline",
    float: "left",
    color: "#828282",
    marginRight:20
  },
  countDataCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  countDataRow2: {
    fontSize: 8,
    display: "inline",
    float: "left",
    color: "#828282",
  },
  addBtnTxt: {
    fontSize: 12,
    fontWeight: 700,
    color: "#2963CA",
  },
}));

export const DropDownCard = ({account, addUser}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.cardContainer}>
        <img
          alt="profile image"
          className={classes.profileImg}
          src={account?.profilePictureResource  ? account.profilePictureResource : IMG.profileImg }
        />
        <div className={classes.userStrip}>
          <div style={{marginBottom:-10}}>
            <p className={classes.userName}>{account?.displayName}</p>
           {account?.title && <p className={classes.userTitleStrip}>{account?.title}</p>}
          </div>
          <div style={{marginTop:-5}} >
            <div className={classes.countDataRow}>
              <div className={classes.countDataCol}>
                <img
                  alt="Fam count image"
                  src={IMG.famCount}
                  width="16"
                  height="16"
                  style={{marginRight:5}}
                />
                {account?.followingsCount}
              </div>
            </div>
            <div className={classes.countDataRow2}>
              <div className={classes.countDataCol}>
                <img
                  alt="recco count image"
                  src={IMG.reccoCount}
                  width="16"
                  height="16"
                  style={{marginRight:5}}
                />
                 {account?.reccosCount}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.countDataCol}>
          <button style={{border:'none', background:'none', marginTop:10}}  onClick={()=>addUser(account?.id)}>
            <p className={classes.addBtnTxt}>
              ADD
            </p>
          </button>
        </div>
      </div>
    </Fragment>
  );
};
